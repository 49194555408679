import React, { Component } from "react";
import { connect } from "react-redux";
import PreviousQ from "../component/PreviousYearQuestions";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createPreviousYearQuestion,
    updatePreviousYearQuestion,
    deletePreviousYearQuestion,
    viewAllPreviousYearQuestion,
    viewAllPdf,
    viewAllExams,
    setPreviousYearId,
    searchPrevious
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <PreviousQ {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createPreviousYearQuestion: (pdf_url,pdf_name) => {
      dispatch(createPreviousYearQuestion(pdf_url,pdf_name))
    },
    updatePreviousYearQuestion: (exam_id,access) => {
      dispatch(updatePreviousYearQuestion(exam_id,access))
    },
    deletePreviousYearQuestion: (pdf_id) => {
      dispatch(deletePreviousYearQuestion(pdf_id))
    },
    viewAllPreviousYearQuestion: () => {
      dispatch(viewAllPreviousYearQuestion())
    },
    viewAllPdf:() =>{
      dispatch(viewAllPdf())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    viewAllExams:() =>{
      dispatch(viewAllExams())
    },
    setPreviousYearId: (payload) => {
      dispatch(setPreviousYearId(payload))
    },
    searchPrevious: (all_previous_year, e) => {
      dispatch(searchPrevious(all_previous_year, e))
    },

    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);