export const SET_CATEGORY="SET_CATEGORY";
export const SET_EXAM="SET_EXAM";
export const SET_STUDENT="SET_STUDENT";
export const SET_STAGE="SET_STAGE";
export const SET_SUBJECT="SET_SUBJECT";
export const SET_SECTION="SET_SECTION";
export const SET_CHAPTER="SET_CHAPTER";
export const SET_LESSON="SET_LESSON";
export const SET_VIDEO="SET_VIDEO";
export const SET_PACKAGECATEGORY="SET_PACKAGECATEGORY";
export const SET_PDF="SET_PDF";
export const SET_QUIZ="SET_QUIZ";
export const SET_BANNER="SET_BANNER";
export const SET_MINIPACKAGE="SET_MINIPACKAGE";
export const SET_SELECT_CHAPTER="SET_SELECT_CHAPTER";
export const SET_QUESTION="SET_QUESTION";
export const SET_QUESTION_IMAGE="SET_QUESTION_IMAGE";
export const SET_OPATION1_IMAGE="SET_OPATION1_IMAGE";
export const SET_OPATION2_IMAGE="SET_OPATION2_IMAGE";
export const SET_OPATION3_IMAGE="SET_OPATION3_IMAGE";
export const SET_OPATION4_IMAGE="SET_OPATION4_IMAGE";
export const SET_ANSWER_IMAGE="SET_ANSWER_IMAGE";
export const SET_QUESTION_IMAGE_REMOVE="SET_QUESTION_IMAGE_REMOVE";
export const SET_OPATION1_IMAGE_REMOVE="SET_OPATION1_IMAGE_REMOVE";
export const SET_OPATION2_IMAGE_REMOVE="SET_OPATION2_IMAGE_REMOVE";
export const SET_QUIZ_ID="SET_QUIZ_ID";
export const SET_TQUIZ_ID="SET_TQUIZ_ID";
export const SET_OPATION3_IMAGE_REMOVE="SET_OPATION3_IMAGE_REMOVE";
export const SET_OPATION4_IMAGE_REMOVE="SET_OPATION4_IMAGE_REMOVE";
export const SET_ANSWER_IMAGE_REMOVE="SET_ANSWER_IMAGE_REMOVE";
export const SET_RESSET="SET_RESSET";
export const SET_REVIEW_BY_ID="SET_REVIEW_BY_ID";
export const SET_REVIEW_BY_TQUIZID="SET_REVIEW_BY_TQUIZID";
export const SET_REVIEW_QUASTION_ID="SET_REVIEW_QUASTION_ID";
export const SET_QUESTION_ID="SET_QUESTION_ID";
export const SET_QUESTION_BY_ID="SET_QUESTION_BY_ID";
export const SET_CHAPTER_SELECT="SET_CHAPTER_SELECT";
export const SET_PRIVIOUS_YEAR_SELECT="SET_PRIVIOUS_YEAR_SELECT";
export const SET_CHAPTER_SELECT_LESSON="SET_CHAPTER_SELECT_LESSON";
export const SET_VIDEO_SELECT="SET_VIDEO_SELECT";
export const SET_PDF_SELECT="SET_PDF_SELECT";
export const SET_QUIZ_SELECT="SET_QUIZ_SELECT";
export const SET_CATEGORY_SELECT ="SET_CATEGORY_SELECT";
export const SET_EXAM_SELECT="SET_EXAM_SELECT";
export const SET_EXAMM_SELECT="SET_EXAMM_SELECT";
export const SET_STAGE_SELECT="SET_STAGE_SELECT";
export const SET_SUBJECT_SELECT="SET_SUBJECT_SELECT";
export const NOTIFICATION="NOTIFICATION";
export const SET_MINI_SELECT="SET_MINI_SELECT";
export const SET_TEST_SELECT="SET_TEST_SELECT";
export const SET_TEST_TYPES_SELECT="SET_TEST_TYPES_SELECT";
export const SET_QUIZZ_SELECT="SET_QUIZZ_SELECT";
export const SET_PACKAGE="SET_PACKAGE";
export const SET_NEW_QUESTION_IMAGE="SET_NEW_QUESTION_IMAGE";
export const SET_NEW_OPTION1_IMAGE="SET_NEW_OPTION1_IMAGE";
export const SET_NEW_OPTION2_IMAGE="SET_NEW_OPTION2_IMAGE";
export const SET_NEW_OPTION3_IMAGE="SET_NEW_OPTION3_IMAGE";
export const SET_NEW_OPTION4_IMAGE="SET_NEW_OPTION4_IMAGE";
export const SET_NEW_ANSWER_IMAGE="SET_NEW_ANSWER_IMAGE";
export const SET_EDITED_QUESTION_IMAGE_REMOVE="SET_EDITED_QUESTION_IMAGE_REMOVE";
export const SET_EDITED_OPATION1_IMAGE_REMOVE="SET_EDITED_OPATION1_IMAGE_REMOVE";
export const SET_EDITED_OPATION2_IMAGE_REMOVE="SET_EDITED_OPATION2_IMAGE_REMOVE";
export const SET_EDITED_OPATION3_IMAGE_REMOVE="SET_EDITED_OPATION3_IMAGE_REMOVE";
export const SET_EDITED_OPATION4_IMAGE_REMOVE="SET_EDITED_OPATION4_IMAGE_REMOVE";
export const SET_EDITED_ANSWER_IMAGE_REMOVE="SET_EDITED_ANSWER_IMAGE_REMOVE";
export const SET_CHAPTER_FILTERED_VALUE="SET_CHAPTER_FILTERED_VALUE";
export const SET_LESSON_FILTERED_VALUE="SET_LESSON_FILTERED_VALUE";
export const SET_VIDEO_FILTERED_VALUE="SET_VIDEO_FILTERED_VALUE";
export const SET_PDF_FILTERED_VALUE="SET_PDF_FILTERED_VALUE";
export const SET_QUIZ_FILTERED_VALUE="SET_QUIZ_FILTERED_VALUE";
export const SET_CATEGORY_FILTERED_VALUE="SET_CATEGORY_FILTERED_VALUE";
export const SET_EXAM_FILTERED_VALUE="SET_EXAM_FILTERED_VALUE";
export const SET_STAGE_FILTERED_VALUE="SET_STAGE_FILTERED_VALUE";
export const SET_SUBJECT_FILTERED_VALUE="SET_SUBJECT_FILTERED_VALUE";
export const SET_MINIPACKAGE_FILTERED_VALUE="SET_MINIPACKAGE_FILTERED_VALUE";
export const SET_PRIVIOUS_YEAR="SET_PRIVIOUS_YEAR";
export const SEARCH_LANGUAGE="SEARCH_LANGUAGE";
export const SET_PRIVIOUS_YEAR_FILTERED_VALUE="SET_PRIVIOUS_YEAR_FILTERED_VALUE";
export const SET_SELECT_PRIVIOUS_YEAR="SET_SELECT_PRIVIOUS_YEAR";

export const SET_TESTSERIES="SET_TESTSERIES";
export const SET_TESTS="SET_TESTS";
export const SET_TESTS_FILTERED_VALUE="SET_TESTS_FILTERED_VALUE";
export const SET_TEST_TYPES="SET_TEST_TYPES";
export const SET_TESTTYPE_FILTERED_VALUE="SET_TESTTYPE_FILTERED_VALUE";
export const SET_TESTSERIES_FILTERED_VALUE="SET_TESTSERIES_FILTERED_VALUE";
export const SET_TESTSERIES_SELECT="SET_TESTSERIES_SELECT";
export const SET_TRENDS="SET_TRENDS";
export const SET_EXAM_AI="SET_EXAM_AI";
export const SET_TREND_SELECT="SET_TREND_SELECT";
export const SET_TREND_FILTERED_VALUE="SET_TREND_FILTERED_VALUE";
export const SET_NOTIFICATION_ID="SET_NOTIFICATION_ID";
export const SET_NOTIFICATION="SET_NOTIFICATION";

export const SET_EXAM_ID="SET_EXAM_ID";
export const SET_NOT_NAME="SET_NOT_NAME";
export const SET_NOT_DESC="SET_NOT_DESC";
export const SET_NOT_ISSUED_ON="SET_NOT_ISSUED_ON";
export const SET_APPLICATION_START_DT="SET_APPLICATION_START_DT";
export const SET_LAST_DT_TO_APPLY="SET_LAST_DT_TO_APPLY";
export const SET_ONLINE_APP_TIME="SET_ONLINE_APP_TIME";
export const SET_OFFLINE_CHALAN="SET_OFFLINE_CHALAN";
export const SET_PAY_THROUGH_CHALAN="SET_PAY_THROUGH_CHALAN";
export const SET_OFFLINE_CHALAN_TIME="SET_OFFLINE_CHALAN_TIME";
export const SET_APP_CORRECT_DT="SET_APP_CORRECT_DT";
export const SET_APP_CORRECT_TIME="SET_APP_CORRECT_TIME";
export const SET_COM_EXAM_DT="SET_COM_EXAM_DT";
export const SET_CON_BODY="SET_CON_BODY";
export const SET_EXAM_NAME="SET_EXAM_NAME";
export const SET_CON_FOR="SET_CON_FOR";
export const SET_POST_NAME="SET_POST_NAME";
export const SET_APP_MODE="SET_APP_MODE";
export const SET_APP_DATE="SET_APP_DATE";

export const SET_VACANCY="SET_VACANCY";
export const SET_SALARY="SET_SALARY";
export const SET_OFFICIAL_NOT_LINK="SET_OFFICIAL_NOT_LINK";
export const SET_OFFICIAL_LINK="SET_OFFICIAL_LINK";
export const SET_FORM_LINK="SET_FORM_LINK";

export const SET_EXAMAI_SELECT="SET_EXAMAI_SELECT";


export const SEARCH_PREVIOUS_YEAR="SEARCH_PREVIOUS_YEAR";

export const SET_ALL_EXAM_AI="SET_ALL_EXAM_AI";
export const VIEW_ALL_COUPON="VIEW_ALL_COUPON";
export const SET_MINIPACKAGE_ID="SET_MINIPACKAGE_ID";
export const SET_TESTSERIES_ID="SET_TESTSERIES_ID";

export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_MINIPACKAGE_BY_ID = 'SET_MINIPACKAGE_BY_ID';
export const SET_PACKAGE_BY_ID = 'SET_PACKAGE_BY_ID';
export const SET_PREVIOUSYEAR_ID = 'SET_PREVIOUSYEAR_ID';
export const SET_EXAMAI_ID = 'SET_EXAMAI_ID';
export const SET_TESTSERIES_IDS = 'SET_TESTSERIES_IDS';


export const ADD_QUIZ_QUESTION = 'ADD_QUIZ_QUESTION';
export const SEARCH_PREVIOUS = 'SEARCH_PREVIOUS';
export const SEARCH_PACKAGES = 'SEARCH_PACKAGES';
export const SEARCH_MINIPACKAGE = 'SEARCH_MINIPACKAGE';
export const SEATCH_TESTSERIES = 'SEATCH_TESTSERIES';
export const SEATCH_EXAMAI = 'SEATCH_EXAMAI';
export const SEARCH_QUIZES = 'SEARCH_QUIZES';
export const TEST_SEARCH_QUIZES = 'TEST_SEARCH_QUIZES';

export const SET_UUID = 'SET_UUID';
export const SEARCH_PDFS = 'SEARCH_PDFS';
export const SET_QUIZ_R_ID = 'SET_QUIZ_R_ID';
export const SEARCH_VIDEOES = 'SEARCH_VIDEOES';
export const SET_SUBJECT_ID = 'SET_SUBJECT_ID';
export const SET_SUBJECTS_ID = 'SET_SUBJECTS_ID';
export const SET_ALL_LESSON = 'SET_ALL_LESSON';
export const VIEW_SUBJECT_BY_QUIZ = 'VIEW_SUBJECT_BY_QUIZ';
export const VIEW_ALL_LANGUAGE = 'VIEW_ALL_LANGUAGE';
export const SET_LANGUAGE_FILTERED_VALUE = 'SET_LANGUAGE_FILTERED_VALUE';
export const VIEW_ALL_NOTIFICATION = 'VIEW_ALL_NOTIFICATION';
export const VIEW_ALL_TESTPASS = 'VIEW_ALL_TESTPASS';
export const SET_SECTION_ID = 'SET_SECTION_ID';
export const SET_SECTION_FILTERED_VALUE = 'SET_SECTION_FILTERED_VALUE';
export const SET_LANGUAGE_ID = 'SET_LANGUAGE_ID';
export const SET_SUBJECT_IDS = 'SET_SUBJECT_IDS';
export const SET_OPATION5_IMAGE = 'SET_OPATION5_IMAGE';
export const SET_OPATION5_IMAGE_REMOVE = 'SET_OPATION5_IMAGE_REMOVE';
export const SET_EDITED_OPATION5_IMAGE_REMOVE= 'SET_EDITED_OPATION5_IMAGE_REMOVE';
export const SET_NEW_OPTION5_IMAGE= 'SET_NEW_OPTION5_IMAGE';
export const SET_EXAMS= 'SET_EXAMS';
