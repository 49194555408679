import React, { Component } from "react";
import { connect } from "react-redux";
import Section from "../component/section";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createSection,
    updateSection,
    deleteSection,
    viewAllSection
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Section {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createSection: (Section_name) => {
      dispatch(createSection(Section_name))
    },
    updateSection: (Section_id,Section_name) => {
      dispatch(updateSection(Section_id,Section_name))
    },
    deleteSection: (Section_id) => {
      dispatch(deleteSection(Section_id))
    },
    viewAllSection: () => {
      dispatch(viewAllSection())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);