import React, { Component } from "react";
import { connect } from "react-redux";
import TestTypes from "../component/TestType";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createTestType,
    updateTestType,
    deleteTestType,
    viewAllTestsType
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <TestTypes {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createTestType: (test_type_name) => {
      dispatch(createTestType(test_type_name))
    },
    updateTestType: (test_type_id, test_type_name) => {
      dispatch(updateTestType(test_type_id, test_type_name))
    },
    deleteTestType: (test_type_id) => {
      dispatch(deleteTestType(test_type_id))
    },
    viewAllTestsType: () => {
      dispatch(viewAllTestsType())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);