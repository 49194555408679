import React, { Component } from "react";
import { connect } from "react-redux";
import ReviewTquiz from "../component/ReviewTquiz";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  setRID,
    viewalldatabyTQuizId
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <ReviewTquiz {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    setRID: (id) => {
      dispatch(setRID(id))
    },
    viewalldatabyTQuizId: (id) => {
      dispatch(viewalldatabyTQuizId(id))
    },
   
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);