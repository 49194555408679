import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Tooltip,
    Typography,
    DialogContent,
    Avatar

} from "@mui/material";
import "../style/category.css"
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            banner_name: "",
            banner_url: ""

        }
    }

    componentDidMount() {
        this.props.viewAllBanner();
    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Banner</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                    banner_id: '',
                                    banner_url: "",
                                    banner_name: ''
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Banner
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell style={{ textAlign: "left" }}>Banner Name</TableCell>
                                            <TableCell style={{ textAlign: "right" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {admin.all_banner.map((c) => {
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell style={{ display: 'flex', alignItems: "center", gap: 20 }}>
                                                        <Avatar
                                                            variant="rounded"
                                                            sx={{ height: 100, width: 100, marginTop: 2, cursor: "pointer" }}
                                                            src={c.banner_url}
                                                            onClick={() => {
                                                                this.setState({
                                                                    view: true,
                                                                    banner_id: c._id,
                                                                    banner_url: c.banner_url
                                                                })
                                                            }}
                                                        />
                                                        <br />
                                                        " {c.banner_name} "
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "right" }} >
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        banner_name: c.banner_name,
                                                                        banner_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} alt='' height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>

                    {/*/---------------------------------Add, Delete, View--------------------------------/*/}

                    <Dialog
                        open={this.state.add}
                    >
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Add Banner name</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "210px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    <TextField
                                        required
                                        placeholder='Enter Banner name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ banner_name: e.target.value }) }}
                                    />
                                </div>
                                {this.state.banner_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                    startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Upload
                                    <input hidden type="file" multiple
                                        onChange={(e) => { this.setState({ banner_url: e.target.files[0] }) }}

                                    />
                                </Button>}
                                {this.state.banner_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                    startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                    Successfully uploaded
                                </Button>}
                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ add: false, banner_url: "" });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        disabled={this.state.banner_name === "" && this.state.banner_url === ""}
                                        onClick={() => {
                                            this.props.add_upload_Banner(this.state.banner_url, this.state.banner_name);
                                            this.setState({ add: false, banner_url: "" });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>

                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the Banner <b>"{this.state.banner_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                            this.setState({ add: false, banner_id: "" });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        onClick={() => { this.props.deleteBanner(this.state.banner_id); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>

                    <Dialog
                        open={this.state.view}
                        style={{ height: "600px" }}
                        onClose={() => {
                            this.setState({ view: false, banner_id: "", banner_url: "" })
                        }}
                    >
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <img src={this.state.banner_url} alt='' style={{ width: "100%", height: '100%', objectFit: 'cover' }} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid>
        )
    }
}