import React, { Component } from "react";
import { connect } from "react-redux";
import AddNotifications from "../component/Addnotifications";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import { uploadNotification, viewAllExams } from "../action"


export class Controller extends Component {
  render() {
    return (
      <AddNotifications {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    uploadNotification: (
      exam_id, 
      not_name,
    not_desc,
    not_issued_on,
    application_start_dt,
    last_dt_to_apply,
    ld_online_application_date,
    ld_online_application_time,
    ld_offline_chalan,
    ld_pay_through_chalan,
    ld_offline_chalan_time,
    application_correction_dt,
    application_correction_time,
    computer_exam_dt,
    conducting_body,
    exam_name,
    conducting_for,
    appliction_date,
    appliction_mode,
    vacency,
    salary,
    official_not_link,
    official_link,
    form_link
    ) => {
      dispatch(uploadNotification(exam_id, not_name,
        not_desc,
        not_issued_on,
        application_start_dt,
        last_dt_to_apply,
        ld_online_application_date,
        ld_online_application_time,
        ld_offline_chalan,
        ld_pay_through_chalan,
        ld_offline_chalan_time,
        application_correction_dt,
        application_correction_time,
        computer_exam_dt,
        conducting_body,
        exam_name,
        conducting_for,
        appliction_date,
        appliction_mode,
        vacency,
        salary,
        official_not_link,
        official_link,
        form_link
        ))
    },

    viewAllExams: () => {
      dispatch(viewAllExams())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };


};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);

