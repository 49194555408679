import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Tooltip,
    Typography,
    FormControl,


} from "@mui/material";
import "../style/category.css"
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import { Link } from 'react-router-dom';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";
import Loader from '../../../common/loader/containers/loader_cont';

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            view: false,
            pdf_name: "",
            pdf_url: ""
        }
    }

    componentDidMount() {
        this.props.viewAllPdf();
        this.props.viewAllExamAi()
        // )
    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        // const { imageUrl, imageAlt } = this.state;
        // if (this.state.redirect === true) {
        //     return <Navigate to="/" />;
        // }
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>

                    <center>
                        <Button className='categorynames'>Exam Ai</Button>
                    </center>


                    <Box style={{ marginRight: "" }}>
                        <center style={{ borderRadius: 0, }}>
                            <Link to='/AddExamAi'>
                                <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'cennter', padding: '7px 14px 6px 0px', marginTop: '10px' }}
                                >
                                    <IconButton>
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                  Add Exam Ai
                                </Button>
                            </Link>
                        </center>
                    </Box>
                </Grid>
                <Paper
                    component="form"
                    style={{
                        marginTop:"5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft:"4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                        <img src={Search} style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className='serach-text'
                        placeholder="Search "
                        inputProps={{ "aria-label": "Search " }}
                        onChange={(e) => {
                            this.props.searchExamAi(admin.all_exam_ai, e.target.value)
                        }}
                    />
                </Paper>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Exam Ai Name</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.search_examAi) && admin.search_examAi.sort((a, b) => a.examAI_name?.localeCompare(b.examAI_name)).map((c) => {
                                           return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>
                                                        {c.examAI_name}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>
                                                        <Tooltip title="Edit">
                                                            <Link to="/EditExamAi">
                                                            <IconButton
                                                                onClick={() => {
                                                                       this.props.setExamAi(c) 
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                <img src={Edit} alt='' height={24} width={24} />
                                                            </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        examAi_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} alt='' height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>


                    <Dialog
                        open={this.state.edit}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Edit Exam Ai Name</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "160px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "8%", marginTop: "1%" }} >

                                            <FormControl style={{ width: '30vw', marginLeft: "60px", marginTop: "20px" }}>
                                                {/* <InputLabel id="demo-simple-select-label">Enter </InputLabel> */}
                                                <TextField
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    placeholder="enter Exam Ai Name"
                                                    value={this.state.exam_ai_name}
                                                    onChange={(e) => {
                                                        this.setState({ exam_ai_name: e.target.value });

                                                    }}
                                                >
                                                    </TextField>
                                            </FormControl>
                                    </Grid>
                                {/* </div> */}
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false });
                                        }}
                                        style={{color: "#6257CD", marginBottom: "0px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => {
                                            this.props.updateExamAi(this.state.examAi_id, this.state.exam_ai_name);
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "0px", marginTop: "20px", color: "white" }}>Edit/Submit</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the course <b>"{this.state.pdf_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => { this.props.deleteExamAi(this.state.examAi_id); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>


                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
<Loader/>
            </Grid>
        )
    }
}