import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography

} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Hint from '../images/view.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Skeleton from '@mui/material/Skeleton';
import { Link } from 'react-router-dom';
import "../../admin/component/minipackage.css";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            minipackage_name: "",
            minipackage_id: ""

        }
    }

    componentDidMount() {
        this.props.viewAllMiniPackager();
        // );
    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        // const { imageUrl, imageAlt } = this.state;
        // if (this.state.redirect === true) {
        //     return <Navigate to="/" />;
        // }
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Mini Package</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1%" }}>
                        <center style={{ borderRadius: 0 }}>
                            <Link to='/addminipackage' style={{ textDecoration: "none" }}>



                                <Button className='categoryadd' style={{ color: "white ", textAlign: 'center' }}
                                // onClick={() => {
                                //     this.props.setUUID(crypto.randomUUID());
                                // }}
                                >
                                    <IconButton>
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                    Add Mini Package

                                </Button>
                            </Link>
                        </center>
                    </Box>

                </Grid>
                <Paper
                    component="form"
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft: "4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                        <img src={Search} style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className='serach-text'
                        placeholder="Search "
                        inputProps={{ "aria-label": "Search " }}
                        onChange={(e) => {
                            this.props.searchMiniPackages(admin.all_minipackages, e.target.value)
                        }}
                    />
                </Paper>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >

                                            <TableCell>Hint  <IconButton

                                                style={{ marginLeft: "-10px" }}
                                            >
                                                <TableCell>Mini Package Name</TableCell>
                                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                                <img src={Hint} height={24} width={24} />
                                            </IconButton></TableCell>
                                            <TableCell style={{ paddingLeft: "5%" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.search_minipackage) && admin.search_minipackage.sort((a, b) => a.mini_hint?.localeCompare(b.mini_hint)).map((c) => {

                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell >{c.mini_hint}</TableCell>
                                                    <TableCell >{c.minipackage_name}</TableCell>

                                                    <TableCell style={{ paddingLeft: "4%" }} >
                                                        <Tooltip title="Edit">
                                                            <Link to="/editMinipackage" style={{ textDecoration: "none" }}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        
                                                                        this.props.setMinipackageId(c);
                                                                        // this.props.setMinipackage(c);
                                                                        // this.props.setMinipackageHint(c.mini_hint);
                                                                        // this.props.setMinipackageDesc(c.mini_des);

                                                                    }}
                                                                    style={{ marginLeft: "-10px" }}
                                                                >
                                                                    {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                    <img src={Edit} alt='' height={24} width={24} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        // category_name: c.category_name,
                                                                        minipackage_name: c.minipackage_name,
                                                                        minipackage_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>

                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>




                    <Dialog
                        open={this.state.edit}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Edit Category name</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "160px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter category name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        value={this.state.category_name}
                                        onChange={(e) => { this.setState({ category_name: e.target.value }) }}
                                    />
                                </div>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => {
                                            // this.props.updateCategory(this.state.category_id, this.state.category_name);
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Edit/Submit</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the course <b>"{this.state.minipackage_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'
                                        onClick={() => { this.props.deleteMinipackage(this.state.minipackage_id); this.setState({ delete: false }) }}


                                        // onClick={() => {
                                        //     this.props.deleteMinipackage(this.state.minipackage_id); this.setState({ delete: false })
                                        //     
                                        // }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          /> */}

            </Grid>
        )
    }
}
