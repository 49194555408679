import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/superadminCont";
import Dashboard from "../../pages/admin/container/dashboardCont";
import Category from "../../pages/admin/container/categoryCont";
import Minipackage from "../../pages/admin/container/minipackageCont";
import AddMinipackage from "../../pages/admin/container/addminipackageCont";
import Textseries from "../../pages/admin/container/textseriesCont";
import AddTextseries from "../../pages/admin/container/addtextseriesCont"
import Exams from "../../pages/admin/container/examsConst";
import Stages from "../../pages/admin/container/stageCont";
import Subject from "../../pages/admin/container/SubejctCont";
import Lesson from "../../pages/admin/container/lessonCont";
import Section from "../../pages/admin/container/sectionCont";
import Chapter from "../../pages/admin/container/chapterCont";
import Video from "../../pages/admin/container/videoCont";
import Pdf from "../../pages/admin/container/pdfCont";
import Quiz from "../../pages/admin/container/quizCont";
import Addquiz from "../../pages/admin/container/addQuizCont";
import Review from "../../pages/admin/container/reviewQuizCont";
import ReviewTquiz from "../../pages/admin/container/reviewTquizCount";
import Banner from "../../pages/admin/container/bannerCont";
import Package from "../../pages/admin/container/packageCont";
import Editquiz from "../../pages/admin/container/editquizCont";
import EditTquiz from "../../pages/admin/container/editTquizCont";
import AddPackage from "../../pages/admin/container/addpackageCont";
import PackageCategory from "../../pages/admin/container/packagecategoryCont";
import Drawer_option_con from "../containers/drawer_cont";
import Error from "../../404";
// import { Dashboard } from "@material-ui/icons";
import Tests from "../../pages/admin/container/testcont";
import TestTypes from "../../pages/admin/container/TestTypeCont";
import TestSubject from "../../pages/admin/container/TestsSubjectCont";
import TestQuiz from "../../pages/admin/container/TestQuizCont";
import AddTestQuiz from "../../pages/admin/container/addTQuizCont";
import PreviousYearQuestion from "../../pages/admin/container/PreviousYearQuestionCont"
// import AddPreviousyear from "../../pages/admin/component/AddPreviousyear";
import Quill from "../../pages/admin/component/Quiil";
import AddPreviousyear from "../../pages/admin/container/AddPreviousCont";
import AddExamAi from "../../pages/admin/container/AddExamAiCount.js";
import ExamAi from "../../pages/admin/container/ExamAiCount.js";
// import AddPreviousyear from "../../pages/admin/container/AddPreviousCont"
import Student from "../../pages/admin/container/studentConst"
import Trend from "../../pages/admin/container/TrendCount.js";
import Notifications from "../../pages/admin/container/notificationsCont";
import AddNotifications from "../../pages/admin/container/AddnotificationsCont";
import EditNotifications from "../../pages/admin/container/EditnotificationsCont";
import Coupon from "../../pages/admin/container/CouponCont.js";
import EditMinipackage from "../../pages/admin/container/EditMinipackageCount.js";
import Edittextseries from "../../pages/admin/container/EdittextseriesCont";
import EditExamAi from "../../pages/admin/container/EditExamAiCount";
import EditPreviousYear from "../../pages/admin/container/EditPreviousYearCount";
import Editpackage from "../../pages/admin/container/EditpackageCont";
import NewNotification from "../../pages/admin/container/newNotificationCont.js";
import Testpasspro from "../../pages/admin/container/testpassproCount.js";

class Routers extends Component {
  render() {
    const { login, onLogout } = this.props;
    let body;

    if (login.isHome) {
      body = (
        <div>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            <Controller />
            <Routes>

              <Route exact path="/" element={<Login />} />



              <Route exact path="*" element={<Error />} />


            </Routes>

          </main>
        </div>
      );
    } else {
      if (login.type === "A") {
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: 250
              }}
            >
              <Controller />
              <Routes>
                {/* <Route exact path="/" element={<Login />} /> */}
                <Route exact path="/" element={<Dashboard />} />

                <Route exact path="/category" element={<Category />} />
                <Route exact path="/minipackage" element={<Minipackage />} />
                <Route exact path="/addminipackage" element={<AddMinipackage />} />
                <Route exact path="/textseries" element={<Textseries />} />
                <Route exact path="/addtextseries" element={<AddTextseries />} />
                <Route exact path="/exams" element={<Exams />} />
                <Route exact path="/stages" element={<Stages />} />
                <Route exact path="/subjects" element={<Subject />} />
                <Route exact path="/sections" element={<Section />} />
                <Route exact path="/chapters" element={<Chapter />} />
                <Route exact path="/lessons" element={<Lesson />} />
                <Route exact path="/videos" element={<Video />} />
                <Route exact path="/quizes" element={<Quiz />} />
                <Route exact path="/addquiz" element={<Addquiz />} />
                <Route exact path="/reviewquiz" element={<Review />} />
                <Route exact path="/reviewTquiz" element={<ReviewTquiz />} />
                <Route exact path="/packagecategory" element={<PackageCategory />} />
                <Route exact path="/pdf" element={<Pdf />} />
                <Route exact path="/banner" element={<Banner />} />
                <Route exact path="/package" element={<Package />} />
                <Route exact path="/editquiz/:quiz_id" element={<Editquiz />} />
                <Route exact path="/editTquiz/:quiz_id" element={<EditTquiz />} />
                <Route exact path="/addpackage" element={<AddPackage />} />
                <Route exact path="/PreviousYearQuestion" element={<PreviousYearQuestion />} />
                <Route exact path="/AddPreviousyear" element={<AddPreviousyear />} />
                <Route exact path="/AddExamAi" element={<AddExamAi />} />
                <Route exact path="/ExamAi" element={<ExamAi />} />
                <Route exact path="/Quill" element={<Quill />} />
                <Route exact path="/Trend" element={<Trend />} />
                <Route exact path="/Notifications" element={<Notifications />} />
                <Route exact path="/AddNotifications" element={<AddNotifications />} />
                <Route exact path="/EditExamAi" element={<EditExamAi />} />
                
                <Route exact path="/EditNotifications" element={<EditNotifications />} />
                <Route exact path="/tests" element={<Tests />} />
                <Route exact path="/testtypes" element={<TestTypes />} />
                <Route exact path="/testsubject" element={<TestSubject />} />
                <Route exact path="/testquiz" element={<TestQuiz />} />
                <Route exact path="/addtestquiz" element={<AddTestQuiz />} />
                <Route exact path="/reviewquiz" element={<Review />} />
                <Route exact path="/student" element={<Student />} />
                <Route exact path="/Coupon" element={<Coupon />} />
                <Route exact path="/editMinipackage" element={<EditMinipackage />} />
                <Route exact path="/Edittextseries" element={<Edittextseries />} />
                <Route exact path="/EditPreviousYear" element={<EditPreviousYear />} />
                <Route exact path="/editpackage" element={<Editpackage />} />
                <Route exact path="/newNotification" element={<NewNotification />} />
                <Route exact path="/testpasspro" element={<Testpasspro />} />



              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;
