import React, { Component } from 'react'
import { Grid, Button, MenuItem, Select, InputLabel, FormControl, TableCell, } from '@mui/material'
import Textarea from '@mui/joy/Textarea';
import "./addpdf.css";
import InputBase from "@mui/material/InputBase";
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Pdf from '../images/pdf.svg';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import Loader from '../../../common/loader/containers/loader_cont';
import Pagination from '@mui/material/Pagination';
// import Fil from "../../../assets/img/fil.png";
// import InputBase from "@mui/material/InputBase"
// import SearchIcon from '@material-ui/icons/Search';
import Paper from "@mui/material/Paper";
import { debounce } from 'lodash';
import {
    IconButton,
    Typography,
    Box
} from "@mui/material";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));



export default class AddPreviousyear extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Lid: 0,
            Vid: 0,
            Pid: 0,
            Qid: 0,
            Lang: 0,
            Year: 0,
            mini_pack: "",
            pdfmodal: false,
            languagemodal: false,
            yearsmodal: false,
            subjectmodal: false,
            category_name: "",
            pdf_count: 0,
            pdfcount: -1,
            countpdf: -1,
            lesscount: -1,
            pdf_content: [],
            chap: "",
            count: -1,
            pdf_name: [],
            pdfs: [],
            filteredData: [],
            pdfmodal: "",
            exam_id: '',
            subject_id: '',
            access: this.props.admin.previousYears.access,
            all: this.props.admin.previousYears.prev_content,
            details: [
                {
                    access_name: "Paid"
                },
                {
                    access_name: "Free"
                },
            ],
            category: this.props.admin.previousYears.category,
            exam: this.props.admin.previousYears.exam_id,
            stage: this.props.admin.previousYears.stage,
            package_name: this.props.admin.previousYears.name,
            package_desc: this.props.admin.previousYears.description,
            sub: "",
            // language_id: this.props.admin.previousYears.language_id,
            year: this.props.admin.previousYears.year,
            counttestsub: 0,
            pdfChecked: [],
            pdf_filtered: [],
            subject_filtered: [],
            pdfChecked: [],
            language_filtered: [],
            languageChecked: [],

            page: 0,
            setPage: 0,
            rowsPerPage: 30,

            searchTerm: '',
            searchpdf: '',
        }
        this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 500);
        this.debouncedSearchpdf = debounce(this.debouncedSearchpdf.bind(this), 500);

    }


    debouncedSearch(value) {
        // this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, value)
        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, value)
    }

    handleSearchChange = (event) => {
        const value = event.target.value;
        this.setState({ searchTerm: value });
        this.debouncedSearch(value);
    };
    debouncedSearchpdf(value) {
        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, value)
        // this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, value)
    }

    handleSearchChangepdf = (event) => {
        const value = event.target.value;
        this.setState({ searchpdf: value });
        this.debouncedSearch(value);
    };

    UnCheckedlanguage = (all_language, itemToUncheck) => {
        this.setState((prevState) => ({
            languageChecked: prevState.languageChecked.filter((item) => item !== itemToUncheck),
            language_filtered: all_language,
            // ... other state updates
        }));
    };
    UnCheckedpdf = (all_pdf, itemToUncheck) => {
        this.setState((prevState) => ({
            pdfChecked: prevState.pdfChecked.filter((item) => item !== itemToUncheck),
            pdf_filtered: all_pdf,
            // ... other state updates
        }));
    };

    UnCheckedSubject = (all_subject, itemToUncheck) => {
        this.setState((prevState) => {
            const subjectChecked = prevState.subjectChecked || []; // Handle undefined subjectChecked

            return {
                subjectChecked: subjectChecked.filter((item) => item !== itemToUncheck),
                subject_filtered: all_subject,
                // ... other state updates
            };
        });
    };
    handleDeleteyear = (index) => {
        try {


            // Filter out the item at the given index from the years array
            const updatedYears = this.state.all.years.filter((item, i) => i !== index);



            // Update the state with the updated years array
            this.setState(prevState => ({
                all: {
                    ...prevState.all,
                    years: updatedYears
                }
            }));
        } catch (error) {
            console.error("Error deleting year:", error);
        }
    }


    handleDelete = (Year, id) => {
        this.setState(prevState => {
            // Create a shallow copy of the state
            const newState = { ...prevState };

            // Create a shallow copy of the subject array to be modified
            const newArr = [...newState.all.years[Year].subject];
            // const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            // Use splice to remove the element at the specified index
            newArr.splice(id, 1);

            // Update the state with the modified subject array
            newState.all.years[Year].subject = newArr;

            // Return the updated state
            return newState;
        });
    }

    handleDeleteL = (Year, Lid, id) => {
        this.setState(prevState => {
            // Create a shallow copy of the state
            const newState = { ...prevState };

            // Create a shallow copy of the subject array to be modified
            const newArr = [...newState.all.years[Year].subject[Lid].language];
            // const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            // Use splice to remove the element at the specified index
            newArr.splice(id, 1);

            // Update the state with the modified subject array
            newState.all.years[Year].subject[Lid].language = newArr;

            // Return the updated state
            return newState;
        });
    }
    handleDeletePdf = (Year, Lid, Lang, id) => {
        this.setState(prevState => {
            // Create a shallow copy of the state
            const newState = { ...prevState };

            // Create a shallow copy of the subject array to be modified
            const newArr = [...newState.all.years[Year].subject[Lid].language[Lang].pdf];
            // const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            // Use splice to remove the element at the specified index
            newArr.splice(id, 1);

            // Update the state with the modified subject array
            newState.all.years[Year].subject[Lid].language[Lang].pdf = newArr;

            // Return the updated state
            return newState;
        });
    }



    handleDescriptionChange = (e) => {
        this.setState({ years: e.target.value });
    };

    handleSave = () => {
        const { all, years } = this.state;

        try {
            // Ensure prev_content is initialized as an object
            const updatedPrevContent = {
                ...all,
                years: [
                    ...(all?.years || []),
                    { years: years }
                ]
            };

            // Set the state with the updated object
            this.setState({ all: updatedPrevContent });
        } catch (error) {
            console.error("Error saving description:", error);
        }
    }

    Checkedpdf(p, index, pdfs) {
        // 
        if (p.target.checked) {
            this.props.Checkedpdf(index, true, 1, pdfs)
        }
        else {
            this.props.Checkedpdf(index, false, 0, pdfs)
        }

    }
    Checkedsubject(p, index, subjects) {
        // 
        if (p.target.checked) {
            this.props.Checkedsubject(index, true, 1, subjects)
        }
        else {
            this.props.Checkedsubject(index, false, 0, subjects)


        }
    }
    componentDidMount() {
        this.props.viewAllCategory();
        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
        this.props.viewAllStages();
        this.props.viewAllLanguage();
        this.props.viewAllCategoryExam(this.state.category)

        console.log("this.props.admin.previousYears", this.props.admin.all_exams);

    }
    filterData = () => {
        const { admin } = this.props;
        const filteredArray = admin.all_pdf.filter(el => el.quantity > 0);
        this.setState({ filteredData: filteredArray });
        // 
    }
    render() {

        const { filteredData, all, Lid, Vid, Lang, Year } = this.state;
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;


        const language = [
            { label: 'Hindi' },
            { label: 'English' },
        ]
        const handleChangePage = (event, newPage) => {
            this.props.viewAllSubject(newPage - 1, this.state.rowsPerPage, "");
            this.props.viewAllPdf(newPage - 1, this.state.rowsPerPage, "");
            this.setState({
                page: newPage,

            });

        };
        return (
            <>

                <Grid style={{ marginLeft: "10%" }}  >
                    <Link to="/PreviousYearQuestion" style={{ textDecoration: "none" }}>
                        <Button style={{ backgroundColor: "#6257CD", height: "45px" }} variant="contained" startIcon={<ArrowBackIcon />}>
                            Previous Year Question
                        </Button>
                    </Link>
                    <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: "8px", fontSize: "20px", fontWeight: 700 }}>Previous Year Question</Typography>

                </Grid>
                {/*------------------------------ Name ----------------------------*/}
                <Grid className='mainsecation' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%" }}>
                    <Box className='addmininamess'>
                        <center
                        >
                            <Typography style={{ fontWeight: "bold", width: "100%" }}> Name
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Name'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                InputLabelProps={{ shrink: true, required: true }}
                                value={this.state.package_name}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ package_name: e.target.value }) }}
                            />

                        </div>

                    </Grid>
                </Grid>
                {/* -----------------------------------------------------------name------------------------------------- */}


                {/* -----------------------------------------------------------Desc------------------------------------- */}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >
                    <Box className='addmininamess'>
                        <center
                        >
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Description
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Description'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.package_desc}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ package_desc: e.target.value }) }}
                            />
                        </div>
                    </Grid>
                </Grid>
                {/* -----------------------------------------------------------Desc------------------------------------- */}

                {/* -----------------------------------------------------------------Category--------------------------------------- */}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Category
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Hint'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.category}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => {
                                    this.setState({ category: e.target.value })
                                    this.props.viewAllCategoryExam(e.target.value)
                                }}
                            >{admin.all_category.map((e) => {
                                return (
                                    <MenuItem value={e._id}>{e.category_name}</MenuItem>
                                )
                            })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                {/* ----------------------------------------------------------------------category------------------------------------------ */}



                {/* --------------------------------------------------------------------------Exam------------------------------------------- */}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Exam
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Hint'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.exam}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ exam: e.target.value }) }}
                            >
                                {Array.isArray(admin.all_exams) && admin.all_exams?.map((e) => {
                                    return (
                                        <MenuItem value={e._id}>{e.exam_name}</MenuItem>
                                    )
                                })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                {/* --------------------------------------------------------------------------Exam------------------------------------------- */}



                {/* --------------------------------------------------------------------------Stage------------------------------------------- */}
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%", marginTop: '1%' }}
                >

                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ fontWeight: "bold", width: "100%" }}>Stage
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Hint'
                                select
                                rows={1}
                                // defaultValue="Default Value"
                                value={this.state.stage}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "40px", width: "100%" }}
                                onChange={(e) => { this.setState({ stage: e.target.value }) }}
                            >
                                {admin.all_stages.map((e) => {
                                    return (
                                        <MenuItem value={e._id}>{e.stage_name}</MenuItem>
                                    )
                                })
                                }
                            </TextField>

                        </div>

                    </Grid>

                </Grid>
                {/* --------------------------------------------------------------------------Stage------------------------------------------- */}
                {/* //--------------------------------------------------------Languange--------------------------------------------------// */}
                {/* <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%" }}
                >
                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ marginTop: "-11%", fontWeight: "bold", width: "100%" }}>Select Language:
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "1%", width: 570, marginRight: "4%" }}>
                        <div class="input-group mb-3" style={{ marginLeft: "20px", }}>
                            <Select
                                style={{
                                    background: "white",
                                    height: "50px", width: "450px",
                                    border: "1px solid #888",
                                    fontSize: 16,
                                    borderRadius: 5,
                                    padding: 7,
                                    color: 'gray'
                                }}
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                label="Select Langauage"
                                select
                                value={this.state.language_id}
                                onChange={(e) => {
                                    this.setState({ language_id: e.target.value });
                                }}
                            >
                                <MenuItem value={"Langauge"}>{"Select Language"}</MenuItem>
                                {Array.isArray(admin.all_language) && admin.all_language?.map((e) => {
                                    // 
                                    return (
                                        <MenuItem value={e._id}>{e.language}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>
                </Grid> */}

                {/* --------------------------------------------------------------------Year--------------------------------------------- */}
                {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "7%", marginTop: "1%" }} >
                    <Grid>
                        <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                            Select Year:
                        </Typography>
                    </Grid>
                    <Grid >
                        <input type="number"
                            value={this.state.year}
                            style={{ width: '30vw', height: "5vh" }} placeholder="YYYY" min="1900"
                            onChange={(e) => { this.setState({ year: e.target.value }) }}>
                        </input>
                    </Grid>
                </Grid> */}
                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >

                        <Button
                            variant="outlined"
                            style={{ border: "2px solid #6257cd" }}

                        >

                            Add Years</Button>
                        {/* } */}

                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center" }}>

                        {Array.isArray(all?.years) && all?.years?.map((e, index) => {
                            // 
                            return (
                                <div key={index} style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>
                                    <Chip
                                        label={e.years}
                                        style={{
                                            backgroundColor: this.state.Year === index ? "#6257CD" : '',
                                            color: this.state.Year === index ? "white" : ''
                                        }}
                                        variant={(all?.years?.[index]?.hasOwnProperty('subject')) ? "filled" : "outlined"}
                                        onClick={() => {
                                            this.setState({ Year: index })
                                        }}
                                        onDelete={() => {
                                            this.handleDeleteyear(index)
                                        }}
                                    />
                                </div>
                            );
                        })}

                        <AddIcon onClick={() => {
                            this.setState({
                                yearsmodal: true,
                            })
                        }} />
                    </Grid>


                </Box>
                {/* -------------------------------------Access------------------------------------ */}
                {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "8%", marginTop: "1%" }} >
                    <Grid>
                        <Typography style={{ fontSize: "20px", fontWeight: 500, marginLeft: "10px" }}>
                            Access:
                        </Typography>
                    </Grid>
                    <Grid >
                        <FormControl style={{ width: '30vw', marginLeft: "60px" }}>
                            <InputLabel id="demo-simple-select-label">Select Access</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="select category"
                                select
                                value={this.state.access}
                                onChange={(e) => {
                                    this.setState({ access: e.target.value });

                                }}
                            >
                                <MenuItem value={"Access"}>{"Select Access"}</MenuItem>
                                {this.state.details.map((e) => (
                                    <MenuItem value={e.access_name}>{e.access_name}</MenuItem>
                                ))}

                            </Select>

                        </FormControl>
                    </Grid>
                </Grid> */}

                {/*--------------------------------------Subject----------------------------------------- */}
                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >


                        <Button
                            variant="outlined"
                            style={{ border: "2px solid #6257cd" }}

                        >


                            Add Subject</Button>
                        {/* } */}

                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center" }}>
                        {Array.isArray(all?.years?.[Year]?.subject) && all.years?.[Year]?.subject?.length > 0 && all?.years?.[Year]?.subject?.map((e, index) => {
                            return (
                                <div style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>
                                    <Chip label={e.subject_name}
                                        style={{
                                            backgroundColor: this.state.Lid == index ? "#6257CD" : '',
                                            color: this.state.Lid == index ? "white" : ''
                                        }}

                                        variant={this.state.all?.years?.[Year]?.subject?.[index].hasOwnProperty('language') ? "filled" : "outlined"}

                                        onClick={() => {
                                            this.setState({ Lid: index })
                                        }
                                        }
                                        onDelete={() => {
                                            this.handleDelete(Year, e._id)
                                            this.UnCheckedSubject(admin.all_subject, e.subject_name)
                                        }} />

                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                subjectmodal: true,
                            })
                        }} />
                    </Grid>


                </Box>

                {/* //--------------------------------------------------Language -------------------------------------// */}
                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >
                        {/* {this.state.package_name !== "" && this.state.package_desc !== "" && this.state.category !== "" && this.state.exam !== "" && this.state.stage !== "" && */}
                        {/* // this.state.package_category !== "" && */}

                        <Button
                            variant="outlined"
                            style={{ border: "2px solid #6257cd" }}
                        // onClick={() => {
                        //     this.setState({
                        //         subjectmodal: true,
                        //     })
                        // }}
                        >


                            Add Language</Button>
                        {/* } */}

                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center" }}>
                        {(all?.years?.[Year]?.subject?.[Lid]?.language == undefined ? [] : all?.years?.[Year]?.subject?.[Lid]?.language ?? []).map((e, index) => {
                            return (
                                <div style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>
                                    <Chip
                                        label={e.language}
                                        style={{
                                            backgroundColor: this.state.Lang === index ? "#6257CD" : '',
                                            color: this.state.Lang === index ? "white" : ''
                                        }}
                                        variant={this.state.all?.years?.[Year]?.subject?.[Lid].language[index]?.hasOwnProperty('pdf') ? "filled" : "outlined"}
                                        onClick={() => {
                                            this.setState({ Lang: index });
                                        }}
                                        onDelete={() => {
                                            this.handleDeleteL(Year, Lid, index);
                                            this.UnCheckedlanguage(admin.all_language, e.language);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                languagemodal: true,
                            });
                        }} />
                    </Grid>



                </Box>

                {/* //-------------------------------pdf----------------------------------------------------------------/ */}
                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >
                        <Button
                            variant="outlined"
                            style={{ border: "2px solid #6257cd" }}
                        // onClick={() => {
                        //     this.setState({
                        //         pdfmodal: true,
                        //     })
                        // }}
                        >


                            Add PDF</Button>
                        {/* } */}

                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "5%", marginRight: "12xx%", border: "2px solid #6257cd", alignItems: "center" }}>
                        {(all?.years?.[Year]?.subject?.[Lid]?.language?.[Lang]?.pdf == undefined ? [] : all?.years?.[Year]?.subject?.[Lid]?.language?.[Lang]?.pdf ?? [])?.map((e, index) => {
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>
                                    <Chip
                                        label={e.pdf_hint}
                                        // style={{
                                        //     backgroundColor: this.state.Vid == index ? "#6257CD" : '',
                                        //     color: this.state.Vid == index ? "white" : ''
                                        // }}
                                        onClick={() => this.setState({ Vid: index })}
                                        onDelete={() => {
                                            this.handleDeletePdf(Year, Lid, Lang, index);
                                            this.UnCheckedpdf(admin.all_pdf, e.pdf_hint);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                pdfmodal: true,
                            });
                        }} />
                    </Grid>


                </Box>


                <center>
                    {/* {(this.state.package_name !== "" && this.state.package_desc !== "" && this.state.year !== "" && this.state.access !== "" && admin.subject_select !== "" && this.state.category !== "" && this.state.exam !== "" && this.state.stage !== "") && */}
                    <Link to='/PreviousYearQuestion'>
                        <Button
                            className='btns'

                            onClick={() => {
                                // this.props.createPreviousYearQuestion(this.state.exam_id, admin.all_pdf.filter(el => el.quantity > 0), this.state.select_year, this.state.description, this.state.subject_id)
                                this.props.updatePreviousYearQuestion(this.props.admin.previousYears._id, this.state.package_name, this.state.package_desc, this.state.year, this.state.access, this.state.category, this.state.exam, this.state.stage, this.state.all, this.state.language_id)

                                this.setState({ exam_id: '', subject_id: '', year: '', description: '', access: '', all: "" })
                                // }
                            }}
                            style={{ marginBottom: "30px", marginTop: "8%", color: "white", width: "80px" }}
                        >SUBMIT</Button>

                    </Link>
                    {/* } */}
                </center>

                <Dialog

                    open={this.state.languagemodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ languagemodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Subject"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchlanguage(admin.all_language, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.filter_language) && admin.filter_language?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.language}</Typography>

                                        <Checkbox
                                            value={e.language}
                                            checked={all?.years?.[Year]?.subject?.[Lid]?.language?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                const currentLesson = all?.years[Year]?.subject?.[Lid];

                                                if (!currentLesson?.hasOwnProperty('language')) {
                                                    currentLesson.language = [];
                                                }

                                                const videoAlreadyExists = currentLesson.language && currentLesson.language.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.language = [...(currentLesson.language || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        languageChecked: [...(prevState.languageChecked || []), e.language],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.language = currentLesson.language.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        languageChecked: (prevState.languageChecked || []).filter(desc => desc !== e.language),
                                                    }));
                                                }
                                            }}
                                        />





                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                <Dialog
                    open={this.state.pdfmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "")
                        this.setState({ pdfmodal: false, searchpdf: '' })
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid style={{ display: "flex", flexdirection: "row", width: "100%", marginTop: 20 }}>
                            <div>
                                <Paper
                                    component="form"
                                    style={{
                                        marginTop: "5%",
                                        display: "flex",
                                        width: "350px",
                                        height: "42px",
                                        marginLeft: "4%",
                                        boxShadow: "none",
                                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                    }}
                                >
                                    <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                                        <SearchIcon style={{ fontSize: "25px" }} />
                                    </IconButton>
                                    <InputBase
                                        className="search-text"
                                        placeholder="Search"
                                        inputProps={{ "aria-label": "Search" }}
                                        value={this.state.searchpdf}
                                        onChange={this.handleSearchChangepdf}
                                    />
                                </Paper>
                            </div>

                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            <div style={{ marginTop: "40px" }}>
                                <Pagination
                                    count={parseInt(admin.pdf_length / this.state.rowsPerPage) + 1}
                                    siblingCount={0}
                                    size="small"
                                    onChange={handleChangePage}
                                />
                            </div>
                            {Array.isArray(admin.all_pdf) && admin.all_pdf.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <IconButton

                                            style={{ marginLeft: "-0px" }}
                                        >
                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                            <img src={Pdf} height={24} width={24} />
                                        </IconButton>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.pdf_hint}</Typography>

                                        {/* <Checkbox
                                            value={e.pdf_hint}
                                            checked={all?.[Lid]?.pdf?.some(item2 => e?._id === item2?._id)}
                                            onClick={(p) => {
                                                const currentLesson = all[Lid];

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.pdf && currentLesson.pdf.some(pdf => pdf._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        // Use spread operator to create a new object with the updated pdf array
                                                        all[Lid] = {
                                                            ...currentLesson,
                                                            pdf: [...(currentLesson.pdf || []), e],
                                                        };
                                                    }
                                                }
                                            }}

                                        /> */}

                                        <Checkbox
                                            value={e.pdf_hint}
                                            checked={
                                                // (this.state.pdfChecked.includes(e.pdf_hint)) &&
                                                // (
                                                all?.years?.[Year]?.subject?.[Lid]?.language?.[Lang]?.pdf?.some(item2 => e?._id === item2?._id)
                                                // )
                                            }
                                            onClick={() => {
                                                // this.props.viewAllPdf();
                                                const currentLesson = all?.years?.[Year]?.subject?.[Lid]?.language?.[Lang];

                                                if (!currentLesson?.hasOwnProperty('pdf')) {
                                                    currentLesson.pdf = [];
                                                }

                                                const videoAlreadyExists = currentLesson.pdf && currentLesson.pdf.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.pdf = [...(currentLesson.pdf || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        pdfChecked: [...prevState.pdfChecked, e.pdf_hint],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.pdf = currentLesson.pdf.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        pdfChecked: prevState.pdfChecked.filter(desc => desc !== e.pdf_hint),
                                                    }));
                                                }
                                            }}
                                        />


                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ pdfmodal: false, searchpdf: '' });
                                    // this.props.UnCheckedpdf(admin.all_pdf);
                                    this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    // this.setState({ vid:"1" }) 
                                    this.setState({ pdfmodal: false, searchpdf: '' });
                                    this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                <Dialog

                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ subjectmodal: false })
                        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Paper
                                component="form"
                                style={{
                                    marginTop: "5%",
                                    display: "flex",
                                    width: "350px",
                                    height: "42px",
                                    marginLeft: "4%",
                                    boxShadow: "none",
                                    border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                }}
                            >
                                <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                                    <SearchIcon style={{ fontSize: "25px" }} />
                                </IconButton>
                                <InputBase
                                    className="search-text"
                                    placeholder="Search"
                                    inputProps={{ "aria-label": "Search" }}
                                    value={this.state.searchTerm}
                                    onChange={this.handleSearchChange}
                                />
                            </Paper>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            <div style={{ marginTop: "40px" }}>
                                <Pagination
                                    count={parseInt(admin.subject_length / this.state.rowsPerPage) + 1}
                                    siblingCount={0}
                                    size="small"
                                    onChange={handleChangePage}
                                />
                            </div>
                            {Array.isArray(admin.all_subject) && admin.all_subject?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        {/* <Checkbox
                                            checked={all?.some(item2 => e?._id === item2?._id)}
                                            value={e.subject_name}
                                            onClick={() => {
                                                const subjectExists = all && all?.some(item => item._id === e._id);

                                                if (!subjectExists) {
                                                    this.setState(prevState => ({
                                                        all: [...(prevState.all || []), e],
                                                        subjectChecked: [...(prevState?.subjectChecked || []), e.subject_name],
                                                    }));
                                                } else {
                                                    this.setState(prevState => ({
                                                        all: (prevState.all || []).filter(item => item._id !== e._id),
                                                        subjectChecked: (prevState?.subjectChecked || []).filter(desc => desc !== e.subject_name),
                                                    }));
                                                }
                                            }}
                                        /> */}


                                        <Checkbox
                                            value={e.subject_name}
                                            checked={all?.years?.[Year]?.subject?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                const currentLesson = all?.years[Year];

                                                if (!currentLesson?.hasOwnProperty('subject')) {
                                                    currentLesson.subject = [];
                                                }

                                                const videoAlreadyExists = currentLesson.subject && currentLesson.subject.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.subject = [...(currentLesson.subject || []), e];

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        subjectChecked: [...(prevState.subjectChecked || []), e.subject_name],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.subject = currentLesson.subject.filter(video => video._id !== e._id);

                                                    // Update the checked state using callback form of setState
                                                    this.setState((prevState) => ({
                                                        subjectChecked: (prevState.subjectChecked || []).filter(desc => desc !== e.subject_name),
                                                    }));
                                                }
                                            }}
                                        />




                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                    this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                    this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>


                <Dialog

                    open={this.state.yearsmodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ yearsmodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid style={{ justifyContent: "space-around", marginLeft: "3%", marginTop: "1%" }}>
                            <Grid>
                                <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                                    Years:
                                </Typography>
                            </Grid>
                            <Grid>
                                <FormControl style={{ width: '30vw', marginLeft: "3%" }}>
                                    <TextField
                                        placeholder="Enter years"
                                        onChange={this.handleDescriptionChange}
                                        inputProps={{ maxLength: 4, pattern: "\\d{4}" }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>


                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ yearsmodal: false });

                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'
                                onClick={() => {
                                    this.handleSave();
                                    this.setState({ yearsmodal: false, });

                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                    <Loader />
                </Dialog>

                <Loader />

            </>

        )
    }
}
