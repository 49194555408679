import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Tooltip,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import "../style/category.css"
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            exam_name: "",
            category_id: ''
        }
    }
    componentDidMount() {
        this.props.viewAllExams();
        this.props.viewAllCategory();
    }
    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>
                            <Button style={{ color: "white ", textAlign: 'center', }}>Exam</Button>
                        </center>
                    </Box>
                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Exams
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell >Exam Name</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.all_exam) && admin.all_exam.sort((a, b) => a.exam_name?.localeCompare(b.exam_name)).map((c, index) => {
                                      
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell >{c.exam_name}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        edit: true,
                                                                        exam_id: c._id,
                                                                        exam_name: c.exam_name,
                                                                        category_id: c.category_id
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                <img src={Edit} height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        exam_name: c.exam_name,
                                                                        exam_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>
                    <Dialog
                        open={this.state.add}
                        maxWidth="xl"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <h2 style={{ position: "relative", marginLeft: "2%" }}>Add Exam name</h2>
                        <Grid>
                            <Grid style={{ minWidth: "450px", height: "300px" }}>
                                <Grid>
                                    <div class="input-group mb-3" style={{ marginLeft: "11px" }}>
                                        <TextField
                                            required
                                            placeholder='Enter Exam name'
                                            InputLabelProps={{ shrink: true, required: true }}
                                            style={{ width: "420px", padding: "1%" }}
                                            onChange={(e) => { this.setState({ exam_name: e.target.value }) }}
                                        />
                                    </div>
                                    <Grid>
                                        <Typography style={{ color: "#000", marginLeft: "11px", marginTop: "10px" }}>
                                            Select Category:
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <FormControl style={{ height: "50px", width: "420px", marginLeft: "11px", marginTop: "7px", padding: "1%" }}>
                                            <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="select category"
                                                name=""
                                                Select
                                                onChange={(e) => {
                                                    this.setState({ category_id: e.target.value });
                                                }}>
                                                <MenuItem value={"All Category"}>{"All Category"}</MenuItem>
                                                {Array.isArray(this.props.admin.all_category) && this.props.admin.all_category.map((e) => (
                                                    <MenuItem value={e._id}>{e.category_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <center>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                this.setState({ add: false });
                                            }}
                                            style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                        <Button
                                            className='btns'
                                            disabled={(this.state.exam_name === '') || (this.state.category_id === '')}
                                            onClick={() => {
                                                this.props.createExam(this.state.exam_name, this.state.category_id);
                                                this.setState({ add: false, exam_name: '', category_id: '' });
                                            }}
                                            style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                    </center>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Dialog>
                    <Dialog
                        open={this.state.edit}
                        style={{ height: "auto",paddingBottom:"40px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Edit Exam name</Typography>
                        <Grid style={{ backgroundColor: "#fff", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                                    <TextField
                                        required
                                        placeholder='Enter Exam name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        value={this.state.exam_name}
                                        onChange={(e) => { this.setState({ exam_name: e.target.value }) }}
                                    />

                                    <Grid>
                                        <Typography style={{ color: "#000", marginLeft: "11px", marginTop: "10px" }}>
                                            Select Category:
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <FormControl style={{ height: "50px", width: "430px", marginLeft: "11px", marginTop: "7px", padding: "1%" }}>
                                            <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="select category"
                                                name=""
                                                Select
                                                value={this.state.category_id}
                                                onChange={(e) => {
                                                    this.setState({ category_id: e.target.value });
                                                }}>
                                                <MenuItem value={"All Category"}>{"All Category"}</MenuItem>
                                                {Array.isArray(this.props.admin.all_category) && this.props.admin.all_category.map((e) => (
                                                    <MenuItem value={e._id}>{e.category_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </div>
                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'
                                        onClick={() => {
                                            this.props.updateExam(this.state.exam_id, this.state.exam_name,this.state.category_id);
                                            this.setState({ edit: false, exam_id: '', exam_name: '' });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Edit/Submit</Button>
                                </center>
                            </Grid>
                        </Grid>
                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the exam <b>"{this.state.exam_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        onClick={() => { this.props.deleteExam(this.state.exam_id); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>
                        </Grid>
                    </Dialog>
                </Box>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </Grid>
        )
    }
}