import React, { Component } from "react";
import { connect } from "react-redux";
import Quiz from "../component/addquiz";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createquiz,
    createQuestion,
  updatequiz,
  deletequiz,
  viewAllQuestion,
  DeleteAllQuestionrefersh,
  UploadQuestion,
  UploadOpatin1,
  UploadOpatin2,
  UploadOpatin3,
  UploadOpatin4,
  UploadAnswer,
  resetValue,
  resetValueopation1,
  resetValueopation2,
  resetValueopation3,
  resetValueopation4,
  resetValueopation5,
  resetValueanswer,
  setRes,
  setID,
  viewAllSubject,
  searchsubject,
  subject_id,
  createQuestionSubmit

} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Quiz {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createQuestion: (question_text,
      question_desc,
      question_img,
      option1,
      option1_img,
      option1_desc,
      option2,
      option2_img,
      option2_desc,
      option3,
      option3_img,
      option3_desc,
      option4,
      option4_img,
      option4_desc,
      option5,
      option5_img,
      option5_desc,
      correct_ans,
      correct_ans_img,
      correct_ans_desc,Uid,subject_id) => {
      dispatch(createQuestion(question_text,
        question_desc,
        question_img,
        option1,
        option1_img,
        option1_desc,
        option2,
        option2_img,
        option2_desc,
        option3,
        option3_img,
        option3_desc,
        option4,
        option4_img,
        option4_desc,
        option5,
        option5_img,
        option5_desc,
        correct_ans,
        correct_ans_img,
        correct_ans_desc,Uid,subject_id))
    },
    createQuestionSubmit: (question_text,
      question_desc,
      question_img,
      option1,
      option1_img,
      option1_desc,
      option2,
      option2_img,
      option2_desc,
      option3,
      option3_img,
      option3_desc,
      option4,
      option4_img,
      option4_desc,
      option5,
      option5_img,
      option5_desc,
      correct_ans,
      correct_ans_img,
      correct_ans_desc,Uid,subject_id,quiz_name,quiz_desc,language,id,time,marks,markss,subject) => {
      dispatch(createQuestionSubmit(question_text,
        question_desc,
        question_img,
        option1,
        option1_img,
        option1_desc,
        option2,
        option2_img,
        option2_desc,
        option3,
        option3_img,
        option3_desc,
        option4,
        option4_img,
        option4_desc,
        option5,
        option5_img,
        option5_desc,
        correct_ans,
        correct_ans_img,
        correct_ans_desc,Uid,subject_id,quiz_name,quiz_desc,language,id,time,marks,markss,subject))
    },
    updatequiz: (quiz_id,quiz_name,set,questions) => {
      dispatch(updatequiz(quiz_id,quiz_name,set,questions))
    },
    createquiz: (quiz_name,quiz_desc,language,id,time,marks,markss,subject) => {
      dispatch(createquiz(quiz_name,quiz_desc, language,id,time,marks,markss,subject))
    },
    deletequiz: (quiz_id) => {
      dispatch(deletequiz(quiz_id))
    },
    UploadQuestion: (add_qustion_url) => {
      dispatch(UploadQuestion(add_qustion_url))
    },
    UploadOpatin1: (option1_url) => {
      dispatch(UploadOpatin1(option1_url))
    },
    UploadAnswer: (answer_url) => {
      dispatch(UploadAnswer(answer_url))
    },
    resetValue: () => {
      dispatch(resetValue())
    },
    setRes: (payload) => {
      dispatch(setRes(payload))
    },
    resetValueopation1: () => {
      dispatch(resetValueopation1())
    },
    resetValueopation2: () => {
      dispatch(resetValueopation2())
    },
    resetValueopation3: () => {
      dispatch(resetValueopation3())
    },
    resetValueopation4: () => {
      dispatch(resetValueopation4())
    },
    resetValueopation5: () => {
      dispatch(resetValueopation5())
    },
    resetValueanswer: () => {
      dispatch(resetValueanswer())
    },
    UploadOpatin2: (option2_url) => {
      dispatch(UploadOpatin2(option2_url))
    },
    UploadOpatin3: (option3_url) => {
      dispatch(UploadOpatin3(option3_url))
    },
    UploadOpatin4: (option4_url) => {
      dispatch(UploadOpatin4(option4_url))
    },
    viewAllQuestion: () => {
      dispatch(viewAllQuestion())
    },
    DeleteAllQuestionrefersh: () => {
      dispatch(DeleteAllQuestionrefersh())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    setID: (payload) => {
      dispatch(setID(payload))
    },
    viewAllSubject: () => {
      dispatch(viewAllSubject())
    },
   
    searchsubject: (all_subject, e) => {
      dispatch(searchsubject(all_subject, e))
    },
    subject_id: (payload) => {
      dispatch(subject_id(payload))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);