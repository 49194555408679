import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// export default class Quill extends Component {
    class Quill extends Component {
        constructor(props) {
          super(props);
      
          this.state = {
            editorHtml: '',
          };
      
          this.handleEditorChange = this.handleEditorChange.bind(this);
        }
      
        handleEditorChange(html) {
          this.setState({ editorHtml: html });
        }
      
        render() {
            return (
                <div>
                  <ReactQuill style={{ paddingBottom:"20px",width: "93%",borderRadius: "15px",  borderColor: "#c4c4c4", marginLeft: "15px", marginTop: "20px" }}
                    value={this.state.editorHtml}
                    onChange={this.handleEditorChange}
                    modules={Quill.modules}
                    formats={Quill.formats}
                  />
                </div>
              );
            }
          }
          
          Quill.modules = {
            toolbar: [
              [{ 'header': '1' }, { 'header': '2' }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['link', 'image'],
            ],
          };
          
          Quill.formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet',
            'link', 'image',
          ];
          
          export default Quill;
        
