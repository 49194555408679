import React, { Component } from "react";
import { connect } from "react-redux";
import Notification from "../component/newNotification";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  uploadUpdateNotification,
  deleteNotifications,
  viewAllNotification,
  uploadNotifications,
  viewAllCategory,
  viewAllCategoryExam,
  viewAllStages
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Notification {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    uploadNotifications: (image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details,page_number, rowPerPage, searchQuery) => {
      dispatch(uploadNotifications(image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery))
    },

    uploadUpdateNotification: (notification_id, old_image, image, noti_name, date, description, link,youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery) => {
      dispatch(uploadUpdateNotification(notification_id, old_image, image, noti_name, date, description, link,youTubelink, category, exam, stage, notify_details,page_number, rowPerPage, searchQuery))
    },
    deleteNotifications: (notification_id) => {
      dispatch(deleteNotifications(notification_id))
    },
    viewAllNotification: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllNotification(page_number, rowPerPage, searchQuery))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllCategoryExam: (category_id) => {
      dispatch(viewAllCategoryExam(category_id));
    },
    viewAllStages: () => {
      dispatch(viewAllStages());
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);