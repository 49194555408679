import {
  LOGIN,
  LOGOUT,
} from "./constant";
const initial_state = {
  email: "",
  isHome: true,
  type: "",
  user_id: "",
  name: ""
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case LOGIN:
      return state = {
        ...state,
        isHome: false,
        email: action.payload.email,
        user_id: action.payload._id,
        type: action.payload.type,
        name: action.payload.name
      };

    case LOGOUT:
      return state = {
        ...state,
        isHome: true,
        user_id: "",
        email: "",
        password: "",
        type: ""
      };

    default:
      return state;
  }
}
