import React, { Component } from "react";
import { connect } from "react-redux";
import MiniPackage from "../component/minipackage";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
// add_upload_Banner,
deleteMinipackage,
  viewAllMiniPackager,
  setMinipackageId,
  searchMiniPackages,
  
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <MiniPackage {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
   
    deleteMinipackage: (minipackage_id) => {
      dispatch(deleteMinipackage(minipackage_id))
    },
    viewAllMiniPackager: () => {
      dispatch(viewAllMiniPackager())
    },
    setMinipackageId: (payload) => {
      dispatch(setMinipackageId(payload))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    searchMiniPackages: (all_minipackages,e) => {
      dispatch(searchMiniPackages(all_minipackages, e));
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);