import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography,
    FormControl, InputLabel,
    MenuItem, Select,

} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Vedio from '../images/video-camera.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            video_name: "",
            english_url: '',
            hindi_url: "",
            old_english_url: '',
            old_hindi_url: "",
            db: '',
            access: "Paid",
            video_hint: "",

            details: [
                {
                    access_name: "Paid"
                },
                {
                    access_name: "Free"
                },
            ],
        }
    }

    componentDidMount() {
        this.props.viewAllVideo();


    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        // const { imageUrl, imageAlt } = this.state;
        // if (this.state.redirect === true) {
        //     return <Navigate to="/" />;
        // }
        const language = [
            { label: 'Hindi' },
            { label: 'English' },
        ]
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Video</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Video
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Paper
                    component="form"
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft: "4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                        <img src={Search} style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className='serach-text'
                        placeholder="Search "
                        inputProps={{ "aria-label": "Search " }}
                        onChange={(e) => {
                            this.props.searchvideos(admin.all_video, e.target.value)
                        }}
                    />
                </Paper>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Video Hint</TableCell>
                                            <TableCell> Video Name</TableCell>
                                            {/* <TableCell> Hindi Video Name</TableCell> */}
                                            <TableCell style={{}}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.search_videoes) && admin.search_videoes.sort((a, b) => a?.video_hint?.localeCompare(b?.video_hint)).map((c) => {
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell >{c.video_hint}</TableCell>
                                                    <TableCell >

                                                        <IconButton

                                                            style={{ marginLeft: "-10px" }}
                                                        >
                                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                                            <img src={Vedio} height={24} width={24} />
                                                        </IconButton>
                                                        {c.eng_video_name}
                                                    </TableCell>
                                                    {/* <TableCell >

                                                        <IconButton

                                                            style={{ marginLeft: "-10px" }}
                                                        >
                                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                                    {/* <img src={Vedio} height={24} width={24} />
                                                        </IconButton>
                                                        {c.hnd_video_name}
                                                    </TableCell> */}

                                                    <TableCell style={{}} >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        edit: true,
                                                                        video_id: c._id,
                                                                        video_name: c.eng_video_name,
                                                                        // hindi_video_name: c.hnd_video_name,
                                                                        // old_hindi_url: c.hindi_url,
                                                                        old_english_url: c.english_url,
                                                                        access: c.access,
                                                                        video_hint: c.video_hint
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                <img src={Edit} height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        video_id: c._id,
                                                                        video_name: c.video_name,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>



                    <Dialog
                        open={this.state.add}
                    // style={{ height: "600px" }}
                    >
                        <Typography style={{ color: "#000", padding: "20px 0px 0px 20px" }}>Add Video</Typography>
                        <Grid style={{ width: "500px" }}>
                            <Grid>
                                <div style={{ margin: "20px" }}>


                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter video name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        // style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ video_name: e.target.value }) }}
                                    />
                                    {/* <TextField

                                        required
                                        fullWidth
                                        placeholder='Enter Hindi video name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "15px" }}
                                        // style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ hindi_video_name: e.target.value }) }}
                                    /> */}
                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter video Hint'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "10px" }}
                                        onChange={(e) => { this.setState({ video_hint: e.target.value }) }}
                                    />
                                    <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Video</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {this.state.english_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Upload
                                                <input hidden type="file" multiple
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    onChange={(e) => { this.setState({ english_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.english_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Video (Hin)</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {this.state.hindi_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Upload
                                                <input hidden type="file" multiple
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    onChange={(e) => { this.setState({ hindi_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.hindi_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid> */}

                                    <Grid container style={{ marginTop: "20px", alignItems: "center" }}>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000" }}>Access</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "15px" }}>

                                                <TextField
                                                    required
                                                    id="outlined-multiline-static"
                                                    placeholder='Enter Access'
                                                    select
                                                    // className={classes.root}
                                                    rows={1}
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    style={{ height: "8vh", width: "95%", }}
                                                    value={this.state.access}
                                                    onChange={(e) => {
                                                        this.setState({ access: e.target.value })
                                                    }}
                                                >
                                                    <MenuItem value={"Paid"}>{"Paid"}</MenuItem>
                                                    <MenuItem value={"Free"}>{"Free"}</MenuItem>

                                                </TextField>

                                            </div>
                                        </Grid>
                                    </Grid>

                                </div>


                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ add: false, english_url: '', });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "35px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        disabled={this.state.video_name === "" && this.state.video_hint == "" && this.state.english_url === ""}
                                        onClick={() => {
                                            this.props.add_eng_upload_Video(this.state.video_name, this.state.video_hint, this.state.english_url, this.state.access);
                                            this.setState({ add: false, english_url: '', });
                                            // 
                                        }}

                                        style={{ marginLeft: "20px", marginBottom: "35px", marginTop: "20px", color: "white" }}>Add</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.edit}
                    >
                        <Typography style={{ color: "#000", padding: "20px 0px 0px 20px" }}>Edit Video</Typography>
                        <Grid style={{ width: "500px" }}>
                            <Grid>
                                <div style={{ margin: "20px" }}>


                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter video name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        // style={{ height: "50px", width: "450px" }}
                                        value={this.state.video_name}
                                        onChange={(e) => { this.setState({ video_name: e.target.value }) }}
                                    />
                                    {/* <TextField

                                        required
                                        fullWidth
                                        placeholder='Enter Hindi video name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "15px" }}
                                        value={this.state.hindi_video_name}
                                        // style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ hindi_video_name: e.target.value }) }}
                                    /> */}

                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Enter video Hint'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ marginTop: "10px" }}
                                        value={this.state.video_hint}
                                        onChange={(e) => { this.setState({ video_hint: e.target.value }) }}
                                    />
                                    <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Video</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {(this.state.old_english_url !== "" && this.state.english_url === "") && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                {this.state.old_english_url !== "" ? "Change" : "Upload"}
                                                <input hidden type="file" multiple
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    onChange={(e) => { this.setState({ english_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.english_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000", marginTop: "25px" }}>Upload Video (Hin)</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            {(this.state.old_hindi_url !== "" && this.state.hindi_url === "") && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                {this.state.old_hindi_url !== "" ? "Change" : "Upload"}
                                                <input hidden type="file" multiple
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    onChange={(e) => { this.setState({ hindi_url: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                            {this.state.hindi_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "95%", marginTop: "5%", marginLeft: "4.5%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                                Successfully uploaded
                                            </Button>}
                                        </Grid>
                                    </Grid> */}

                                    <Grid container style={{ marginTop: "20px", alignItems: "center" }}>
                                        <Grid item xs={3.5}>
                                            <Typography style={{ color: "#000" }}>Access</Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7.5}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Access</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="select category"
                                                    select
                                                    value={this.state.access}
                                                    onChange={(e) => {
                                                        this.setState({ access: e.target.value });

                                                    }}
                                                >
                                                    <MenuItem value={"Access"}>{"Select Access"}</MenuItem>
                                                    {/* {this.state.details.map((e) => ( */}
                                                        <MenuItem value={"Paid"}>{"Paid"}</MenuItem>
                                                        <MenuItem value={"Free"}>{"Free"}</MenuItem>
                                                    {/* ))/} */}

                                                </Select>

                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                </div>


                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false, hindi_url: "", english_url: '', });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "35px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        // disabled={this.state.video_name === "" && this.state.video_hint === "" }
                                        onClick={() => {
                                            this.props.edit_eng_upload_Video(this.state.video_id, this.state.video_name, this.state.video_hint, this.state.old_english_url, this.state.english_url, this.state.access);

                                            this.setState({ edit: false, hindi_url: "", english_url: '', });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "35px", marginTop: "20px", color: "white" }}>Update</Button>
                                </center>
                            </Grid>

                        </Grid>
                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the video <b>"{this.state.video_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        onClick={() => {
                                            this.props.deleteVideo(this.state.video_id);
                                            this.setState({ delete: false })
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid>
        )
    }
}