/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Typography,
    Tooltip

} from "@mui/material";
import "../../admin/style/category.css";

import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Delete from '../images/trash.svg';
import Edit from "../images/pencil.svg";
export default class Trend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trendedit: false,
            trenddelete: false,
            trendadd: false,
            view: false,
            trends_name: "",


            salesdate: [
                {

                    name: "subh",

                },
                {
                    name: "subh",
                },

                {
                    name: "subh",
                },
            ],
        }
    }
    componentDidMount() {
        this.props.viewAllTrends();
    }
    render() {
        const { admin } = this.props;
        return (
            <>
                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "5%", marginRight: "15%" }}>

                    <Button className='categorynames' style={{ color: "white ", textAlign: 'center', }}>Trend</Button>



                    <Box style={{ marginRight: "1%" }}>
                        <center style={{ borderRadius: 0 }}>
                            <Link to="" style={{ textDecoration: "none" }}>
                                <Button className='categorynames' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                    this.setState({
                                        trendadd: true,
                                    })
                                }}>
                                    <IconButton>
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                    Add Trend
                                </Button></Link>
                        </center>
                    </Box>
                </Grid>










                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >

                                            <TableCell>Name</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(admin.view_all_trends) && admin.view_all_trends.sort((a, b) => a.trend_type?.localeCompare(b.trend_type)).map((item) => {
                                            return (
                                                <TableRow>
                                                    {/* <Link to="/user" className="table-row" style={{ display: "contents", textDecoration: "none" }}> */}

                                                    <TableCell> {item.trend_type}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        trendedit: true,
                                                                        trends_id: item._id,
                                                                        trends_name: item.trend_type
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                <img src={Edit} alt='' height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        trenddelete: true,
                                                                        trends_id: item._id,
                                                                        trends_name: item.trend_type
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} alt='' height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>


                                                    {/* </Link> */}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Box>
                </Box>





                <Dialog
                    open={this.state.trenddelete}
                    style={{ height: "600px" }}>
                    <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                    <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the trend <b>"{this.state.trends_name}"</b> has been successfully trenddeleted from the admin panel as per your request.</Typography>
                            <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                            <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                            <center>
                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ trenddelete: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                <Button
                                    // className='btns'

                                    onClick={() => {
                                        this.setState({ trenddelete: false })
                                        this.props.deleteTrends(this.state.trends_id)
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes,Delete</Button>
                            </center>
                        </Grid>

                    </Grid>

                </Dialog>





                <Dialog
                    open={this.state.trendadd}
                    style={{ height: "600px" }}>

                    <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Add Trend name</Typography>
                    <Grid style={{ backgroundColor: "#fff", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>

                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    placeholder='Enter Trend name'
                                    // InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ trends_name: e.target.value }) }}
                                />

                            </div>
                            <center>
                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ trendadd: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                <Button
                                    className='btns'


                                    onClick={() => {

                                        this.setState({ trendadd: false, trends_name: "" });
                                        this.props.createTrends(this.state.trends_name)
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}
                                    disabled={( this.state.trends_name === "" ) }
                                    >Add</Button>
                            </center>
                        </Grid>

                    </Grid>

                </Dialog>


                <Dialog
                    open={this.state.trendedit}
                    style={{ height: "600px" }}>

                    <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Edit Trend name</Typography>
                    <Grid style={{ backgroundColor: "#fff", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>

                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required

                                    placeholder='Enter Trend name'
                                    // InputLabelProps={{ shrink: true, required: true }}
                                    value={this.state.trends_name}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ trends_name: e.target.value }) }}

                                />
                            </div>
                            <center>
                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ trendedit: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                <Button
                                    className='btns'


                                    onClick={() => {

                                        this.setState({ trendedit: false, trends_name: "" });
                                        this.props.updateTrends(this.state.trends_id, this.state.trends_name)
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Update</Button>
                            </center>
                        </Grid>

                    </Grid>

                </Dialog>











            </>
        )
    }
}
