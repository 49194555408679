import React, { Component } from "react";
import { connect } from "react-redux";
import Testpass from "../component/testpasspro";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createTestPass,
    updateTestPass,
    deleteTestpass,
    viewAllTestPass
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Testpass {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createTestPass: (name, title, price,  discount, discounted_price, offer, ref_offer, total_amount) => {
      dispatch(createTestPass(name, title, price,  discount, discounted_price, offer, ref_offer, total_amount))
    },
    updateTestPass: (testpass_id,name, title, price,  discount, discounted_price, offer, ref_offer, total_amount) => {
      dispatch(updateTestPass(testpass_id,name, title, price,  discount, discounted_price, offer, ref_offer, total_amount))
    },
    deleteTestpass: (testpass_id) => {
      dispatch(deleteTestpass(testpass_id))
    },
    viewAllTestPass: () => {
      dispatch(viewAllTestPass())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);