import React, { Component } from "react";
import { connect } from "react-redux";
import EditPreviousYear from "../component/EditPreviousYear";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createPreviousYearQuestion,
    updatePreviousYearQuestion,
    deletePreviousYearQuestion,
    viewAllPreviousYearQuestion,
    viewAllPdf,
    viewAllCategory,
    viewAllSubject,
    Checkedpdf,
    StorePdf,
    searchpdf,
    UnCheckedpdf,
    NullPdf,
    searchLanguage,
    viewAllExams,
    viewAllCategoryExam,
    SelectTPreSubject,
    Checkedsubject,
    SelectPdf,
    setPreviousYearId,
    viewAllLanguage,
    searchsubject,
    searchlanguage,
    viewAllStages
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <EditPreviousYear {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    deletePreviousYearQuestion: (pdf_id) => {
      dispatch(deletePreviousYearQuestion(pdf_id))
    },
    viewAllPreviousYearQuestion: () => {
      dispatch(viewAllPreviousYearQuestion())
    },
    Checkedpdf: (index, checked, quantity, all_pdf) => {
      dispatch(Checkedpdf(index, checked, quantity, all_pdf))
    },
    UnCheckedpdf: (all_pdf) => {
      dispatch(UnCheckedpdf(all_pdf))
    },
    viewAllPdf: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllPdf(page_number, rowPerPage, searchQuery))
    },
    searchpdf: (pdf,e) => {
      dispatch(searchpdf(pdf,e))
    },
    StorePdf:(lesson,pdfcount,pdf,pdf_count)=>{
      dispatch(StorePdf(lesson,pdfcount,pdf,pdf_count))
    },
    NullPdf:()=>{
      dispatch(NullPdf())
    },
    viewAllCategory: () => {
        dispatch(viewAllCategory())
      },
      viewAllSubject: (page_number, rowPerPage, searchQuery) => {
        dispatch(viewAllSubject(page_number, rowPerPage, searchQuery))
      },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    searchLanguage:(pdf, e) => {
      dispatch(searchLanguage(pdf, e));
    },

    viewAllExams:() => {
      dispatch(viewAllExams());
    },  
    viewAllStages:() => {
      dispatch(viewAllStages());
    },   
    viewAllCategoryExam: (category_id) => {
      dispatch(viewAllCategoryExam(category_id))
    },
    SelectTPreSubject: (subject) => {
      dispatch(SelectTPreSubject(subject) )
    },
    Checkedsubject: (index,checked,quantity,all_subject) => {
      dispatch(Checkedsubject(index,checked,quantity,all_subject))
    },
    SelectPdf: (trends, count, pdf) => {
      dispatch(SelectPdf(trends, count, pdf))
    },
    setPreviousYearId: (payload) => {
        dispatch(setPreviousYearId(payload))
      },
      updatePreviousYearQuestion:( previousYears_id,package_name, package_desc, year, access, category, exam, stage , prev_content,language_id) => {
        dispatch(updatePreviousYearQuestion( previousYears_id,package_name, package_desc, year, access, category, exam, stage , prev_content,language_id))
      },
      viewAllLanguage: () => {
        dispatch(viewAllLanguage())
      },
      searchsubject: (all_subject,e) => {
        dispatch(searchsubject(all_subject,e))
      },
      searchlanguage: (all_language, e) => {
        dispatch(searchlanguage(all_language, e))
      },
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);