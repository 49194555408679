import React, { Component } from "react";
import { connect } from "react-redux";
import Trend from "../component/Trend";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createTrends,
    viewAllTrends,
    updateTrends,
    deleteTrends
   
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Trend {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createTrends: (trends_name) => {
      dispatch(createTrends(trends_name))
    },
    updateTrends: (trends_id,trends_name) => {
        dispatch(updateTrends(trends_id,trends_name))
      },
      viewAllTrends: () => {
        dispatch(viewAllTrends())
      },
      deleteTrends:(trends_id) => {
        dispatch(deleteTrends(trends_id))
      },

    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);