import {
    LOGIN,
    LOGOUT,
    HANDLEDRAWER,
    CLEAR_LOGIN,
    SET_ADMIN_ID,
} from "./constant";
import UNIVERSAL from "../config/config";
import { setLoader, unsetLoader } from "../common/loader/action";
import { set_snack_bar } from "../common/snackbar/action";


 
 
export function super_login(email, password) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(setAdminLogin(responseJson.result));
                    dispatch(setAdminId(responseJson.result._id));
                    // 
                    dispatch(set_snack_bar(responseJson.status, "Login  Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function clear_login() {
    return {
        type: CLEAR_LOGIN,
    };
}
export function setAdminId(payload) {
    return {
        type: SET_ADMIN_ID,
        payload: payload
    }
}

export function setAdminLogin(payload) {
    localStorage.setItem("olangeruser_id", payload._id);
    localStorage.setItem("olangertype", payload.type);
    localStorage.setItem("olangername", payload.name);
    localStorage.setItem("olangeremail", payload.email);
   
    return {
        type: LOGIN,
        payload: payload,
    };
}


export function handleDrawerToggle(mobileOpen) {
    mobileOpen = !mobileOpen;
    return {
        type: HANDLEDRAWER,
        payload: mobileOpen,
    };
}
export function onLogout() {
    localStorage.removeItem("olangeruser_id");
    localStorage.removeItem("olangername");
    localStorage.removeItem("olangeremail");
    localStorage.removeItem("olangertype");
    return {
        type: LOGOUT,
    };
}
