import React, { Component } from "react";
import { connect } from "react-redux";
import Lesson from "../component/lesson";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createLesson,
    updateLesson,
    deleteLesson,
    viewAllLesson,
    viewAllLanguage
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Lesson {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createLesson: (Lesson_name) => {
      dispatch(createLesson(Lesson_name))
    },
    updateLesson: (Lesson_id,Lesson_name) => {
      dispatch(updateLesson(Lesson_id,Lesson_name))
    },
    deleteLesson: (Lesson_id) => {
      dispatch(deleteLesson(Lesson_id))
    },
    viewAllLesson: () => {
      dispatch(viewAllLesson())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
    viewAllLanguage: () => {
      dispatch(viewAllLanguage())
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);