import React, { Component } from 'react';


import TextField from '@mui/material/TextField';
import { Box, Grid, Button, Divider, Typography, Card } from '@mui/material'
import MenuItem from "@mui/material/MenuItem";
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import "../../admin/style/category.css";
import { Link } from 'react-router-dom';
import Loader from '../../../common/loader/containers/loader_cont';


import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default class Addnotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notiadd: false,
            category: '',
            exam_id: 'N',
            not_name: '',
            not_desc: '',
            not_issued_on: '',
            application_start_dt: '',
            last_dt_to_apply: '',
            ld_online_application_date: '',
            ld_online_application_time: '',
            ld_offline_chalan: '',
            ld_pay_through_chalan: '',
            ld_offline_chalan_time: '',
            application_correction_dt: '',
            application_correction_time: '',
            computer_exam_dt: '',
            conducting_body: '',
            exam_names: '',
            conducting_for: '',
            appliction_date: '',
            appliction_mode: '',
            vacency: '',
            salary: '',
            official_not_link: '',
            official_link: '',
            form_link: '',
        }
    }

    componentDidMount() {
        this.props.viewAllExams();
        
    }
    render() {
        const { category, selectedValue } = this.state;
        return (
            <>

                <a href="/Notifications">
                    <ArrowBackIosIcon style={{ color: "black", fontSize: 20, marginLeft: "3%", cursor: "pointer" }}
                        onClick={() => {
                            this.setState({
                                not_name: '',
                                not_desc: '',
                                not_issued_on: '',
                                application_start_dt: '',
                                last_dt_to_apply: '',
                                ld_online_application_date: '',
                                ld_online_application_time: '',
                                ld_offline_chalan: '',
                                ld_pay_through_chalan: '',
                                ld_offline_chalan_time: '',
                                application_correction_dt: '',
                                application_correction_time: '',
                                computer_exam_dt: '',
                                conducting_body: '',
                                exam_names: '',
                                conducting_for: '',
                                appliction_date: '',
                                appliction_mode: '',
                                vacency: '',
                                salary: '',
                                official_not_link: '',
                                official_link: '',
                                form_link: '',

                            });
                        }}
                    />
                </a>
                <center>

                    <Card style={{ height: "auto", width: "720px", marginBottom: "20px", backgroundColor: "#EEECFE" }}>

                        <Box style={{ textAlign: "center" }}>

                            <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Add Notifications</Typography>


                            <Grid style={{ marginTop: "5%", backgroundColor: "#EEECFE" }}>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}>
                                        Exam Name:
                                    </Typography>
                                    <TextField
                                        select
                                        placeholder='Select Exam'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px" }}
                                        onChange={(e) => {
                                            this.setState({ exam_id: e.target.value })
                                        }}
                                        value={this.state.exam_id} // Add this line

                                    >
                                        <MenuItem value={"N"}>{"Select Exam"}</MenuItem>
                                        {Array.isArray(this.props.admin.all_exam) && this.props.admin.all_exam.map((item) => (
                                            <MenuItem key={item._id} value={item._id}>{item.exam_name}</MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignSelf: 'center', marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}
                                    >Notification Name:</Typography>
                                    <TextField
                                        required
                                        placeholder='Enter Notifiction name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px" }}
                                        onChange={(e) => {
                                            this.setState({ not_name: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}
                                    >Notification Description:</Typography>
                                    {/* <input style={{ display: "none" }} type="text" id="text" 
                                    onChange={(e) => { this.setState({ not_desc: e.target.value }) }}
                                    ></input> */}
                                    <TextField
                                        required
                                        multiline
                                        placeholder='Enter Notifiction Description'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px" }}
                                        onChange={(e) => {
                                            this.setState({ not_desc: e.target.value })
                                            
                                        }}
                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Notification Issued On:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ not_issued_on: e.target.value })
                                        }}

                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Application Started On:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px", }}
                                        onChange={(e) => {
                                            this.setState({ application_start_dt: e.target.value })
                                        }}

                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Last Date To Apply:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ last_dt_to_apply: e.target.value })
                                        }}
                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Last date for receipt of online applications:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ ld_online_application_date: e.target.value })
                                        }}
                                    ></input>
                                </Grid>

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}
                                    > Last time for receipt of online applications:</Typography>

                                    <input type="time" id="time-input" name="time-input" pattern="[0-9]{2}:[0-9]{2}" required style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ ld_online_application_time: e.target.value })
                                        }}

                                    />


                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}


                                    >Last date for generation of offline Challan:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ ld_offline_chalan: e.target.value })
                                        }}

                                    ></input>
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Last  time for generation of offline Challan</Typography>

                                    <input type="time" id="time-input" name="time-input" pattern="[0-9]{2}:[0-9]{2}" required style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ ld_offline_chalan_time: e.target.value })
                                        }}

                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Last date for payment through Challan (during working hours of Bank)</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ ld_pay_through_chalan: e.target.value })
                                        }}
                                    ></input>
                                </Grid>

                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "350px", textAlign: "left" }}
                                    >Dates of ‘Window for Application Form Correction’ and online payment of
                                        Correction Charges</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ application_correction_dt: e.target.value })

                                        }}
                                    ></input>
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Time of ‘Window for Application Form Correction’ and online payment of
                                        Correction Charges</Typography>

                                    <input type="time" id="time-input" name="time-input" pattern="[0-9]{2}:[0-9]{2}" required style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ application_correction_time: e.target.value })

                                        }}

                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}

                                    >Schedule of Computer Based Examination</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "150px" }}
                                        onChange={(e) => {
                                            this.setState({ computer_exam_dt: e.target.value })

                                        }}
                                    ></input>
                                </Grid>

                                <Box style={{ MarginTop: "3%" }}>
                                </Box>


                            </Grid>




                        </Box>

                        <div style={{ marginTop: "4%", }}>

                        </div>

                    </Card>
                </center>

                <center>

                    <Card style={{ height: "auto", width: "720px", marginBottom: "50px", marginTop: "5%", backgroundColor: "#EEECFE" }}>

                        <Box style={{ textAlign: "center", }}>



                            <Grid style={{ marginTop: "5%", }}>


                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}>
                                        Exam conducting body:
                                    </Typography>


                                    <TextField
                                        required
                                        placeholder='Enter Exam conducting body'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px", marginLeft: "230px", }}
                                        onChange={(e) => {
                                            this.setState({ conducting_body: e.target.value })

                                        }}
                                    />

                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' sstyle={{ width: "300px" }}
                                    >Examination name:</Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Examination name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px", marginLeft: "230px", }}
                                        onChange={(e) => {
                                            this.setState({ exam_names: e.target.value })

                                        }}
                                    />


                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification'
                                    >Exam conducting for:</Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Exam conducting for'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px", marginLeft: "230px", }}
                                        onChange={(e) => {
                                            this.setState({ conducting_for: e.target.value })
                                        }}
                                    />

                                </Grid>



                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}>Application dates:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", marginLeft: "240px", width: "245px", backgroundColor: "#EEECFE" }}
                                        onChange={(e) => {
                                            this.setState({ appliction_date: e.target.value })
                                        }}
                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}

                                    >Application mode:</Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Application mode'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px", marginLeft: "230px", }}
                                        onChange={(e) => {
                                            this.setState({ appliction_mode: e.target.value })
                                        }}
                                    />


                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}
                                    >Vacancy:
                                    </Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Vacancy'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px", marginLeft: "230px", }}
                                        onChange={(e) => {
                                            this.setState({ vacency: e.target.value })
                                        }}
                                    />



                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}

                                    >Salary:
                                    </Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Salary'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px", marginLeft: "230px", }}
                                        onChange={(e) => {
                                            this.setState({ salary: e.target.value })

                                            
                                        }}
                                    />



                                </Grid>






                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>

                                    <label htmlFor="file">
                                        <Typography className='Notification'

                                        >Notification:</Typography>
                                    </label>
                                    <Grid>
                                        {this.state.official_not_link !== undefined && <Button variant="outlined" component="label" className='upload' style={{ color: 'green', borderColor: "green", marginRight: 20 }}
                                            startIcon={<CheckIcon />}>
                                            Successfully uploaded
                                        </Button>}
                                        <Button variant="outlined" component="label" className='upload'
                                            startIcon={<NorthIcon />}>
                                            {this.state.official_not_link === undefined ? 'Upload' : 'Change'}
                                            <input hidden type="file" multiple
                                                accept="application/pdf,application/vnd.ms-excel"
                                                onChange={(e) => {
                                                    this.setState({ official_not_link: e.target.files[0] })
                                                }}
                                            />
                                        </Button>

                                    </Grid>




                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />



                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}>Official Website
                                    </Typography>


                                    <TextField
                                        required
                                        placeholder='Enter official_link'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px", marginLeft: "230px", }}
                                        onChange={(e) => {
                                            this.setState({ official_link: e.target.value })
                                        }}
                                    />



                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>

                                    <label htmlFor="file">
                                        <Typography className='Notification' >
                                            Application Form: </Typography>


                                    </label>

                                    <Grid>
                                        {this.state.form_link !== undefined && <Button variant="outlined" component="label" className='upload' style={{ color: 'green', borderColor: "green", marginRight: 20 }}
                                            startIcon={<CheckIcon />}>
                                            Successfully uploaded
                                        </Button>}
                                        <Button variant="outlined" component="label" className='upload'
                                            startIcon={<NorthIcon />}>
                                            {this.state.form_link === undefined ? 'Upload' : 'Change'}
                                            <input hidden type="file" multiple
                                                accept="application/pdf,application/vnd.ms-excel"
                                                onChange={(e) => {
                                                    this.setState({ form_link: e.target.files[0] })
                                                  
                                                }}
                                            />
                                        </Button>

                                    </Grid>




                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />






                            </Grid>




                        </Box>

                        {/* <Button style={{ backgroundColor: "#6257CD", color: "white" }}>
                            Submit
                          
                        </Button> */}
                        {(
                            this.state.exam_id !== "" &&
                            this.state.not_name !== "" &&
                            this.state.not_desc !== "" &&
                            this.state.not_issued_on !== "" &&
                            this.state.application_start_dt !== "" &&
                            this.state.last_dt_to_apply !== "" &&
                            this.state.ld_online_application_date !== "" &&
                            this.state.ld_online_application_time !== "" &&
                            this.state.ld_offline_chalan !== "" &&
                            this.state.ld_pay_through_chalan !== "" &&
                            this.state.ld_offline_chalan_time !== "" &&
                            this.state.application_correction_dt !== "" &&
                            this.state.application_correction_time !== "" &&
                            this.state.computer_exam_dt !== "" &&
                            this.state.conducting_body !== "" &&
                            this.state.exam_names !== "" &&
                            this.state.conducting_for !== "" &&
                            this.state.appliction_date !== "" &&
                            this.state.appliction_mode !== "" &&
                            this.state.vacency !== "" &&
                            this.state.salary !== "" &&
                            this.state.official_not_link !== "" &&
                            this.state.official_link !== "" &&
                            this.state.form_link !== ""

                        ) &&
                            //  <a href="/Notifications"> 
                            <Link to="/Notifications">
                            <Button
                                onClick={() => {
                                    this.props.uploadNotification(
                                        this.state.exam_id,
                                        this.state.not_name,
                                        this.state.not_desc,
                                        this.state.not_issued_on,
                                        this.state.application_start_dt,
                                        this.state.last_dt_to_apply,
                                        this.state.ld_online_application_date,
                                        this.state.ld_online_application_time,
                                        this.state.ld_offline_chalan,
                                        this.state.ld_pay_through_chalan,
                                        this.state.ld_offline_chalan_time,
                                        this.state.application_correction_dt,
                                        this.state.application_correction_time,
                                        this.state.computer_exam_dt,
                                        this.state.conducting_body,
                                        this.state.exam_names,
                                        this.state.conducting_for,
                                        this.state.appliction_date,
                                        this.state.appliction_mode,
                                        this.state.vacency,
                                        this.state.salary,
                                        this.state.official_not_link,
                                        this.state.official_link,
                                        this.state.form_link,
                                    )

                                    this.setState({
                                        notiadd: false,
                                        exam_id: '',
                                        not_name: '',
                                        not_desc: '',
                                        not_issued_on: '',
                                        application_start_dt: '',
                                        last_dt_to_apply: '',
                                        ld_online_application_date: '',
                                        ld_online_application_time: '',
                                        ld_offline_chalan: '',
                                        ld_pay_through_chalan: '',
                                        ld_offline_chalan_time: '',
                                        application_correction_dt: '',
                                        application_correction_time: '',
                                        computer_exam_dt: '',
                                        conducting_body: '',
                                        exam_names: '',
                                        conducting_for: '',
                                        appliction_date: '',
                                        appliction_mode: '',
                                        vacency: '',
                                        salary: '',
                                        official_not_link: '',
                                        official_link: '',
                                        form_link: '',

                                    });
                                }}
                                style={{ backgroundColor: "#6257CD", color: "white", marginTop: 20 }}
                                
                            >Submit
                            </Button>
                          </Link> 
                        }
                        <div style={{ marginTop: "3%", backgroundColor: "#EEECFE" }}>

                        </div>


<Loader/>
                    </Card>
                </center>





            </>

        )
    }
}
