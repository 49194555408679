import React, { Component } from "react";
import { connect } from "react-redux";
import Subject from "../component/subject";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createSubject,
    updateSubject,
    deleteSubject,
    viewAllSubject
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Subject {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createSubject: (stage_name,page_number, rowPerPage,) => {
      dispatch(createSubject(stage_name,page_number, rowPerPage,))
    },
    updateSubject: (subject_id,stage_name,page_number, rowPerPage,) => {
      dispatch(updateSubject(subject_id,stage_name,page_number, rowPerPage,))
    },
    deleteSubject: (subject_id,page_number, rowPerPage,) => {
      dispatch(deleteSubject(subject_id,page_number, rowPerPage,))
    },
    viewAllSubject: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllSubject(page_number, rowPerPage, searchQuery))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);