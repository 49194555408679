import React, { Component } from 'react';


 import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Box, Grid, Button, Divider, Typography, Card } from '@mui/material'
import "../../admin/style/category.css";
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import Loader from '../../../common/loader/containers/loader_cont';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default class Editnotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_notification: [],
            category: '',
            not_name: ''
        }
    }

    componentDidMount() {
        
    }

    render() {
        const { category, selectedValue, admin } = this.state;
        return (
            <>

                <a href="/Notifications">
                    <ArrowBackIosIcon style={{ color: "black", fontSize: 20, marginLeft: "3%", cursor: "pointer" }} />
                </a>
                <center>

                    {/* {Array.isArray(admin.all_notification) && admin.all_notification.map((c) => {
                        return ( */}

                    <Card style={{ height: "auto", width: "720px", marginBottom: "20px", backgroundColor: "#EEECFE" }}>

                        <Box style={{ textAlign: "center" }}>

                            <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Edit Notifications</Typography>

                            <Grid style={{ marginTop: "5%", backgroundColor: "#EEECFE" }}>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}>
                                        Exam Name:
                                    </Typography>
                                    <TextField
                                        select
                                        placeholder='Select Exam'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "250px" }}
                                        value={this.props.admin.email_id}
                                        onChange={(e) => {
                                            this.props.setExamId(e.target.value)
                                            this.setState({ exam_id: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={"N"}>{"Select Exam"}</MenuItem>
                                        {Array.isArray(this.props.admin.all_exam) && this.props.admin.all_exam.map((item) => (
                                            <MenuItem key={item._id} value={item._id}>{item.exam_name}</MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}
                                    >Notification Name:</Typography>
                                    <TextField
                                        required
                                        placeholder='Enter Notifiction name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: "white" }}
                                        value={this.props.admin.not_name}
                                        onChange={(e) => {
                                            this.props.setnot_name(e.target.value)
                                            this.setState({ not_name: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}
                                    >Notification Description:</Typography>
                                    {/* <input style={{ display: "none" }} type="text" id="text" 
                                    onChange={(e) => { this.setState({ not_desc: e.target.value }) }}
                                    ></input> */}
                                    <TextField
                                        required
                                        placeholder='Enter Notifiction Description'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: "white" }}
                                        value={this.props.admin.not_desc}
                                        onChange={(e) => {
                                            this.props.setnot_desc(e.target.value)
                                            this.setState({ not_desc: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Notification Issued On:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", border: "1px solid lightgray" }}
                                        value={this.props.admin.not_issued_on}
                                        onChange={(e) => {
                                            this.props.setnot_issued_on(e.target.value)
                                            this.setState({ not_issued_on: e.target.value })
                                        }}

                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Application Started On:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.app_start_dt}
                                        onChange={(e) => {
                                            this.props.setapplication_start_dt(e.target.value)
                                            this.setState({ application_start_dt: e.target.value })
                                        }}

                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Last date for receipt of online applications:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.last_dt_to_apply}
                                        onChange={(e) => {
                                            this.props.setlast_dt_to_apply(e.target.value)
                                            this.setState({ last_dt_to_apply: e.target.value })
                                        }}
                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}
                                    > Last time for receipt of online applications:</Typography>

                                    <input type="time" id="time-input" name="time-input" pattern="[0-9]{2}:[0-9]{2}" required style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.online_app_time}
                                        onChange={(e) => {
                                            this.props.setld_online_application_time(e.target.value)
                                            this.setState({ ld_online_application_time: e.target.value })
                                        }}

                                    />


                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}


                                    >Last date for generation of offline Challan:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.officail_chalan}
                                        onChange={(e) => {
                                            this.props.setld_offline_chalan(e.target.value)
                                            this.setState({ ld_offline_chalan: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ ld_offline_chalan: e.target.value }) }}

                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Last date for payment through Challan (during working hours of Bank)</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.pay_through_chalan}
                                        onChange={(e) => {
                                            this.props.setld_pay_through_chalan(e.target.value)
                                            this.setState({ ld_pay_through_chalan: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ ld_pay_through_chalan: e.target.value }) }}
                                    ></input>
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Last  time for generation of offline Challan</Typography>

                                    <input type="time" id="time-input" name="time-input" pattern="[0-9]{2}:[0-9]{2}" required style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.official_chalan_time}
                                        onChange={(e) => {
                                            this.props.setld_offline_chalan_time(e.target.value)
                                            this.setState({ ld_offline_chalan_time: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ ld_offline_chalan_time: e.target.value }) }}

                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "350px", textAlign: "left" }}
                                    >Dates of ‘Window for Application Form Correction’ and online payment of
                                        Correction Charges</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.app_correct_dt}
                                        onChange={(e) => {
                                            this.props.setapplication_correction_dt(e.target.value)
                                            this.setState({ application_correction_dt: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ application_correction_dt: e.target.value }) }}
                                    ></input>
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{ width: "300px", textAlign: "left" }}

                                    >Time of ‘Window for Application Form Correction’ and online payment of
                                        Correction Charges</Typography>

                                    <input type="time" id="time-input" name="time-input" pattern="[0-9]{2}:[0-9]{2}" required style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.app_correct_time}
                                        onChange={(e) => {
                                            this.props.setapplication_correction_time(e.target.value)
                                            this.setState({ application_correction_time: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ application_correction_time: e.target.value }) }}

                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}

                                    >Schedule of Computer Based Examination</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", border: "1px solid lightgray", }}
                                        value={this.props.admin.con_exam_dt}
                                        onChange={(e) => {
                                            this.props.setcomputer_exam_dt(e.target.value)
                                            this.setState({ computer_exam_dt: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ computer_exam_dt: e.target.value }) }}
                                    ></input>
                                </Grid>

                                <Box style={{ marginTop: "3%" }}>
                                </Box>
                            </Grid>
                        </Box>

                        <div style={{ marginTop: "4%", }}>
                        </div>
                    </Card>
                    {/* )
                    })}  */}
                </center>

                <center>

                    <Card style={{ height: "auto", width: "720px", marginBottom: "50px", marginTop: "5%", backgroundColor: "#EEECFE" }}>

                        <Box style={{ textAlign: "center", }}>
                            <Grid style={{ marginTop: "5%", }}>


                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}>
                                        Exam conducting body:
                                    </Typography>
                                    <TextField
                                        required
                                        placeholder='Enter Exam conducting body'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: "white" }}
                                        value={this.props.admin.con_body}
                                        onChange={(e) => {
                                            this.props.setconducting_body(e.target.value)
                                            this.setState({ conducting_body: e.target.value })
                                        }}
                                    />

                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' sstyle={{ width: "300px" }}
                                    >Examination name:</Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Examination name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: 'white' }}
                                        value={this.props.admin.exam_name}
                                        onChange={(e) => {
                                            this.props.setexam_name(e.target.value)
                                            this.setState({ exam_name: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ exam_name: e.target.value }) }}
                                    />

                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification'
                                    >Exam conducting for:</Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Exam conducting for'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: "white" }}
                                        value={this.props.admin.con_for}
                                        onChange={(e) => {
                                            this.props.setconducting_for(e.target.value)
                                            this.setState({ conducting_for: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ conducting_for: e.target.value }) }}
                                    />

                                </Grid>

                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}>Application dates:</Typography>

                                    <input className='Notification' type="date" style={{ height: "30px", width: "200px", backgroundColor: "white", border: "1px solid lightgray" }}
                                        value={this.props.admin.app_date}
                                        
                                        onChange={(e) => {
                                            this.props.setappliction_date(e.target.value)
                                            this.setState({ application_date: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ post_name: e.target.value }) }}
                                    ></input>
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}

                                    >Application mode:</Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Application mode'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: 'white' }}
                                        value={this.props.admin.app_mode}
                                        onChange={(e) => {
                                            this.props.setappliction_mode(e.target.value)
                                            this.setState({ appliction_mode: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ appliction_mode: e.target.value }) }}
                                    />

                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}
                                    >Vacancy:
                                    </Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Vacancy'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: 'white' }}
                                        value={this.props.admin.vacancy}
                                        onChange={(e) => {
                                            this.props.setvacency(e.target.value)
                                            this.setState({ vacency: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ vacency: e.target.value }) }}
                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}

                                    >Salary:
                                    </Typography>

                                    <TextField
                                        required
                                        placeholder='Enter Salary'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: 'white' }}
                                        value={this.props.admin.salary}
                                        onChange={(e) => {
                                            this.props.setsalary(e.target.value)
                                            this.setState({ salary: e.target.value })
                                        }}
                                    />
                                </Grid>

                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    {/* <Typography className='Notification1' style={{ color: "#6257CD", textDecoration: "underline" }}>SSC MTS Notification 2023</Typography> */}


                                    <label htmlFor="file">
                                        <Typography className='Notification1' style={{ color: "#6257CD", textDecoration: "underline" }}

                                        >Notification</Typography>

                                        {/* >Notification</Typography> */}
                                    </label>
                                    <Grid>
                
                                        {(this.props.admin.official_not_link !== "" && this.state.official_not_link === "") &&
                                            <Button variant="outlined" component="label" className='upload'
                                                startIcon={<NorthIcon />}>
                                                {this.props.admin.official_not_link !== "" ? "Change" : "Upload"}
                                                <input hidden type="file" multiple
                                                    accept="application/pdf,application/vnd.ms-excel"
                                                    onChange={(e) => { this.setState({ new_official_not_link: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                        {this.state.official_not_link !== "" && <Button variant="outlined" component="label" className='upload'
                                            startIcon={<CheckIcon />}>
                                            Uploaded
                                        </Button>}
                                    </Grid>



                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>
                                    <Typography className='Notification' style={{}}>Official Website:
                                    </Typography>


                                    <TextField
                                        required
                                        placeholder='Enter official_link'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ width: "200px", backgroundColor: 'white' }}
                                        value={this.props.admin.official_link}
                                        onChange={(e) => {
                                            this.props.setofficial_link(e.target.value)
                                            this.setState({ official_link: e.target.value })
                                        }}
                                    // onChange={(e) => { this.setState({ official_link: e.target.value }) }}
                                    />
                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "4%" }}>


                                    {/* <input style={{ display: "none" }} type="file" id="file"
                                        onChange={(e) => { this.setState({ form_link: e.target.value }) }} /> */}
                                    <label htmlFor="file">
                                        <Typography className='Notification1' style={{ color: "#6257CD", textDecoration: "underline" }}>Application Form</Typography>


                                    </label>
                                    <Grid>
                                        {/* <input style={{ display: "none" }} type="file" id="file"

                                            onChange={(e) => { this.setState({ form_link: e.target.value }) }}
                                        /> */}
                                        {(this.props.admin.form_link !== "" && this.state.form_link === "") &&
                                            <Button variant="outlined" component="label" className='upload'
                                                startIcon={<NorthIcon />}>
                                                {this.props.admin.form_link !== "" ? "Change" : "Upload"}
                                                <input hidden type="file" multiple
                                                    accept="application/pdf,application/vnd.ms-excel"
                                                    onChange={(e) => { this.setState({ new_form_link: e.target.files[0] }) }}
                                                />
                                            </Button>}
                                        {this.state.form_link !== "" && <Button variant="outlined" component="label" className='upload'
                                            startIcon={<CheckIcon />}>
                                            Uploaded
                                        </Button>}
                                    </Grid>




                                </Grid>
                                <Divider style={{ width: "100%", borderColor: " 2px solid gray", marginTop: "1%" }} />






                            </Grid>

                        </Box>

                        {/* <Button style={{ backgroundColor: "#6257CD", color: "white" }}>
                            Submit
                          
                        </Button> */}
                        <a href="/Notifications">
                            <Button
                                // className='btns'
                                onClick={() => {
                                    this.props.updateUploadNotification(
                                        this.props.admin.notify_id,
                                        (this.state.exam_id === undefined ? this.props.admin.email_id : this.state.exam_id),
                                        (this.state.not_name === undefined ? this.props.admin.not_name : this.state.not_name),
                                        (this.state.not_desc === undefined ? this.props.admin.not_desc : this.state.not_desc),
                                        (this.state.not_issued_on === undefined ? this.props.admin.not_issued_on : this.state.not_issued_on),
                                        (this.state.application_start_dt === undefined ? this.props.admin.application_start_dt : this.state.application_start_dt),
                                        (this.state.last_dt_to_apply === undefined ? this.props.admin.last_dt_to_apply : this.state.last_dt_to_apply),
                                        (this.state.ld_online_application_time === undefined ? this.props.admin.online_app_time : this.state.ld_online_application_time),
                                        (this.state.ld_offline_chalan === undefined ? this.props.admin.officail_chalan : this.state.ld_offline_chalan),
                                        (this.state.ld_pay_through_chalan === undefined ? this.props.admin.pay_through_chalan : this.state.ld_pay_through_chalan),
                                        (this.state.ld_offline_chalan_time === undefined ? this.props.admin.official_chalan_time : this.state.this.props.admin.official_chalan_time),
                                        (this.state.application_correction_dt === undefined ? this.props.admin.app_correct_dt : this.state.application_correction_dt),
                                        (this.state.application_correction_time === undefined ? this.props.admin.app_correct_time : this.state.this.props.admin.application_correction_time ),
                                        (this.state.computer_exam_dt === undefined ? this.props.admin.con_exam_dt : this.state.computer_exam_dt),
                                        (this.state.conducting_body === undefined ? this.props.admin.con_body : this.state.conducting_body),
                                        (this.state.exam_name === undefined ? this.props.admin.exam_name : this.state.exam_name),
                                        (this.state.conducting_for === undefined ? this.props.admin.con_for : this.state.conducting_for),
                                        (this.state.application_date === undefined ? this.props.admin.application_date : this.state.application_date),
                                        (this.state.appliction_mode === undefined ? this.props.admin.app_mode : this.state.appliction_mode),
                                        (this.state.vacency === undefined ? this.props.admin.vacancy : this.state.vacency),
                                        (this.state.salary === undefined ? this.props.admin.salary : this.state.salary),
                                        this.props.admin.official_not_link,
                                        this.state.new_official_not_link,
                                        (this.state.official_link === undefined ? this.props.admin.official_link : this.state.official_link),
                                        this.props.admin.form_link,
                                        this.state.new_form_link,
                                    )
                                    this.setState({
                                        notiadd: false,
                                        not_name: '',
                                        not_desc: '',
                                        not_issued_on: '',
                                        application_start_dt: '',
                                        last_dt_to_apply: '',
                                        ld_online_application_time: '',
                                        ld_offline_chalan: '',
                                        ld_pay_through_chalan: '',
                                        ld_offline_chalan_time: '',
                                        application_correction_dt: '',
                                        application_correction_time: '',
                                        computer_exam_dt: '',
                                        conducting_body: '',
                                        exam_name: '',
                                        conducting_for: '',
                                        application_date: '',
                                        appliction_mode: '',
                                        vacency: '',
                                        salary: '',
                                        official_not_link: '',
                                        official_link: '',
                                        form_link: '',

                                    });
                                }}
                                style={{ backgroundColor: "#6257CD", color: "white" }}>Submit
                            </Button>
                        </a>
                        <div style={{ marginTop: "3%", backgroundColor: "#EEECFE" }}>

                        </div>
                    </Card>
                </center>
                <Loader/>
            </>

        )
    }
}
