import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography,
    DialogContent

} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            name: "",
            title: "",
            price: "",
            discount: "",
            discounted_price: "",
            offer: "",
            ref_offer: "",
            total_amount: "",
            testpass_id: "",


        }
    }

    componentDidMount() {
        this.props.viewAllTestPass();
    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;

        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Test Pass Pro</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                    name: "",
                                    title: "",
                                    price: "",
                                    discount: "",
                                    discounted_price: "",
                                    offer: "",
                                    ref_offer: "",
                                    total_amount: "",
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Test Pass
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Test Pass Title</TableCell>
                                            <TableCell>Headers</TableCell>
                                            <TableCell style={{ paddingLeft: "10%" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.view_all_testpass !== undefined && admin.view_all_testpass) && admin.view_all_testpass.sort((a, b) => a.testpass_name?.localeCompare(b.testpass_name)).map((c) => {
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}>
                                                    <TableCell >{c?.title}</TableCell>
                                                    <TableCell >{c?.name}</TableCell>
                                                    <TableCell style={{ paddingLeft: "10%" }} >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        edit: true,
                                                                        testpass_id: c._id,
                                                                        name: c.name,
                                                                        title: c.title,
                                                                        price: c.price,
                                                                        discount: c.discount,
                                                                        discounted_price: c.discounted_price,
                                                                        offer: c.offer,
                                                                        ref_offer: c.ref_offer,
                                                                        total_amount: c.total_amount
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                <img src={Edit} height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        testpass_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>



                    <Dialog
                        open={this.state.add}
                    >
                        <DialogTitle><Typography style={{ color: "#000", }}>Add Test Pass Pro</Typography></DialogTitle>

                        <DialogContent>
                            <Grid>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}> */}


                                <Typography style={{ fontWeight: "bold", marginTop: "1%" }}>Testpass Header</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Header'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                                <Typography style={{ fontWeight: "bold", marginTop: "1%" }}>Testpass Title</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Title'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ title: e.target.value }) }}
                                />

                                <Typography style={{ fontWeight: "bold", }}>Actual Price</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Actual Price'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ price: e.target.value }) }}
                                />

                                <Typography style={{ fontWeight: "bold", }}>Discount in %</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Discount in % '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ discount: e.target.value }) }}
                                />

                                <Typography style={{ fontWeight: "bold" }}>Discounted Price</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Discounted Price'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ discounted_price: e.target.value }) }}
                                />

                                <Typography style={{ fontWeight: "bold", }}>New User Offer</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass New User Offer'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ offer: e.target.value }) }}
                                />

                                <Typography style={{ fontWeight: "bold", }}>Referral Offer</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Referral Offer'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ ref_offer: e.target.value }) }}
                                />

                                <Typography style={{ fontWeight: "bold", }}>Total Amount</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Total Amount'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    onChange={(e) => { this.setState({ total_amount: e.target.value }) }}
                                />

                                {/* </div> */}


                            </Grid>

                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ add: false });
                                }}
                                style={{ color: "#6257CD", borderColor: "#6257CD" }}>Close</Button>
                            <Button
                                className='btns'

                                // disabled={this.state.name === "" ||
                                //     this.state.title === "" ||
                                //     this.state.price === "" ||
                                //     this.state.discount === "" ||
                                //     this.state.discounted_price === "" ||
                                //     this.state.offer === "" ||
                                //     this.state.ref_offer === "" ||
                                //     this.state.total_amount === ""}
                                onClick={() => {
                                    this.props.createTestPass(this.state.name, this.state.title, this.state.price, this.state.discount, this.state.discounted_price, this.state.offer, this.state.ref_offer, this.state.total_amount);
                                    this.setState({ add: false });
                                }}
                                style={{ color: "white" }}>Add</Button>

                        </DialogActions>

                    </Dialog>
                    <Dialog
                        open={this.state.edit}
                    >
                        <DialogTitle><Typography style={{ color: "#000" }}>Edit Subject name</Typography></DialogTitle>

                        <DialogContent >
                            <Grid>
                                {/* <div class="input-group mb-3" style={{ marginTop: "20px" }}> */}
                                <Typography style={{ fontWeight: "bold", marginTop: "1%" }}>Testpass Header</Typography>
                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Header'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.name}
                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />
                                <Typography style={{ fontWeight: "bold", marginTop: "1%" }}>Testpass Title</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Title'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.title}
                                    onChange={(e) => { this.setState({ title: e.target.value }) }}
                                />
                                <Typography style={{ fontWeight: "bold", }}>Actual Price</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Actual Price'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.price}
                                    onChange={(e) => { this.setState({ price: e.target.value }) }}
                                />
                                <Typography style={{ fontWeight: "bold", }}>Discount in %</Typography>

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Discount in % '
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.discount}
                                    onChange={(e) => { this.setState({ discount: e.target.value }) }}
                                />
                                <Typography style={{ fontWeight: "bold", }}>Discounted Price</Typography>
                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Discounted Price'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.discounted_price}
                                    onChange={(e) => { this.setState({ discounted_price: e.target.value }) }}
                                />
                                <Typography style={{ fontWeight: "bold", }}>New User Offer</Typography>
                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass New User Offer'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.offer}
                                    onChange={(e) => { this.setState({ offer: e.target.value }) }}
                                />
                                <Typography style={{ fontWeight: "bold", }}>Referral Offer</Typography>
                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Referral Offer'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.ref_offer}
                                    onChange={(e) => { this.setState({ ref_offer: e.target.value }) }}
                                />
                                <Typography style={{ fontWeight: "bold", }}>Total Amount</Typography>
                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    placeholder='Enter Testpass Total Amount'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "450px" }}
                                    value={this.state.total_amount}
                                    onChange={(e) => { this.setState({ total_amount: e.target.value }) }}
                                />

                                {/* </div> */}
                            </Grid>

                        </DialogContent>
                        <DialogActions style={{justifyContent: 'center'}}>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ edit: false });
                                }}
                                style={{ color: "#6257CD", borderColor: "#6257CD" }}>Close</Button>
                            <Button
                                className='btns'

                                // disabled={this.state.category_name === ""}
                                onClick={() => {
                                    this.props.updateTestPass(this.state.testpass_id, this.state.name, this.state.title, this.state.price, this.state.discount, this.state.discounted_price, this.state.offer, this.state.ref_offer, this.state.total_amount);
                                    this.setState({ edit: false });
                                }}
                                style={{  color: "white" }}>Edit/Submit</Button>
                        </DialogActions>

                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the course <b>"{this.state.subject_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => { this.props.deleteTestpass(this.state.testpass_id); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}

            </Grid>
        )
    }
}
