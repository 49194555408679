import { Grid, Box, Button, Typography, TextField, Dialog, Chip } from '@mui/material'
import React, { Component } from 'react'
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from "@material-ui/core/styles";
import One from "../images/count.svg"
import { Link } from 'react-router-dom';



const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00f" // Set the hover border color
        }
    }
});

class addquiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quiz_title: "",
            quiz_desc: "",
            add_qustion_url: "",
            qustion_title: "",
            quastion_desc: "",
            opation1: "",
            option1_url: "",
            option1_desc: "",
            opation2: "",
            option2_url: "",
            option2_desc: "",
            opation3: "",
            option3_url: "",
            option3_desc: "",
            opation4: "",
            option4_url: "",
            option4_desc: "",
            answer: "",
            answer_url: "",
            answer_desc: "",
            showWarning: false


        }
    }

    componentDidMount() {
        // this.props.viewVarient();
        // this.props.viewAllCategory();
        // this.props.viewModel();
        // componentDidMount(){
        // if (this.props.admin.quiz_id !== "") {
        //     this.props.viewalldatabyQuizId(this.props.admin.quiz_id);
        // }

        if (this.props.admin.quiz_id !== "") {
            // this.props.viewalldatabyQuizId(this.props.admin.quiz_id);
        }

        // 
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid>
                <Box className='categoryname'>
                    {/* <Link to='/editquiz'> */}
                    <center>
                        <Button style={{ color: "white ", textAlign: 'center', }}>Review Quiz</Button>
                    </center>
                    {/* </Link> */}
                </Box>



                <Grid className='boxdesign' style={{ border: "1px solid #888", height: 500, width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                        Quiz title & Hint
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Title
                        {/* {this.props.admin.all_quiz_by_id.quiz_name} */}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                        {/* Quiz Title */}
                        {this.props.admin.all_quiz_by_id.quiz_name}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Hint
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                        {/* Quiz Title */}
                        {this.props.admin.all_quiz_by_id.quiz_desc}
                    </Typography>
                    <div style={{ direction: "row", display: "flex" }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Time:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Time */}
                            {this.props.admin.all_quiz_by_id?.time}
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Marks:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Marks */}
                            {this.props.admin.all_quiz_by_id?.total_marks}
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Total Questions:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Cut Marks */}
                            {this.props.admin.all_quiz_by_id?.cut_marks}
                        </Typography>
                        
                    </div>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Subject:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Cut Marks */}
                            {this.props.admin.all_quiz_by_id?.subject_name}
                        </Typography>





                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ "display": "flex", "flexDirection": "row", "gap": "11px", "width": "47%", "flexWrap": "wrap", marginLeft: "4%", marginTop: "10px" }}>

                        {this.props.admin.all_quastion_by_id.map((c, index) => {
                            return (
                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold" }}>

                                    {index + 1}



                                </Typography>
                            );
                        })}
                    </div>
                    {/* <div style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "4%", marginTop: "1%", alignItems: "center", width: "70%" }}>

                        {(this.props.admin.all_quiz_by_id.subject ?? []).map((e, index) => {

                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                    <Chip label={e.subject_name}
                                   


                                    />


                                </div>
                            );
                        })}


                    </div> */}

                    {this.props.admin.all_quastion_by_id.map((c, index) => {
                        return (
                            <Grid>
                                <Grid className='boxdesign' style={{ border: "1px solid #888", height: "100%", width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        {index + 1}
                                    </Typography>
                                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        Question
                                    </Typography>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>

                                        {/* {c.question_text} */}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.question_text }}></div>
                                    </Typography>

                                    {c.question_img !== "" && <img src={c.question_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold " }}>
                                        Options

                                    </Typography>
                                    <div style={{ display: "flex" }}>
                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                            {c.option1}.
                                        </Typography>
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option1_desc }}></div>

                                    </div>
                                    {c.option1_img !== "" && c.option1_img !== null && <img src={c.option1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                            {c.option2}.
                                        </Typography>
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option2_desc }}></div>
                                    </div>
                                    {c.option2_img !== "" && c.option2_img !== null && <img src={c.option2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                            {c.option3}.
                                        </Typography>
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option3_desc }}></div>

                                    </div>
                                    {c.option3_img !== "" && c.option3_img !== null && <img src={c.option3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                    <div style={{ display: "flex" }}>
                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                            {c.option4}.
                                        </Typography>
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option4_desc }}></div>
                                    </div>
                                    {c.option4_img !== "" && c.option4_img !== null && <img src={c.option4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option5_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option5}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option5_desc }}></div>
                                    </div>
                                    {c.option5_img !== "" && c.option5_img !== null && <img src={c.option5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        Correct Option
                                    </Typography>

                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                        {c.correct_ans}
                                    </Typography>
                                    {/* <div contentEditable={true} dangerouslySetInnerHTML={{ __html: c.correct_ans_desc}}></div> */}


                                    <Typography dangerouslySetInnerHTML={{ __html: c.correct_ans_desc }} style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                        {/* {c.correct_ans} */}
                                    </Typography>
                                    {/* <div contentEditable={true} dangerouslySetInnerHTML={{ __html: c.correct_ans_desc}}></div> */}


                                    {/* {c.correct_ans_img !== "" && c.correct_ans_img !== null && <img src={c.correct_ans_img} height={150} width={440} style={{ marginLeft: "20px" }} />} */}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Box
                    className='saveandcnts'
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                    <Link to='/editquiz'>
                        <Button onClick={() => this.props.setRID(this.props.admin.all_quiz_by_id._id)} style={{ backgroundColor: "#6257CD", color: "white ", textTransform: "capitalize", width: "100%" }}>Back</Button>
                    </Link>
                    <Link to='/quizes'>
                        <Button style={{ backgroundColor: "#6257CD", color: "white ", textTransform: "capitalize", width: "100%" }}>Done</Button>
                    </Link>
                </Box>
            </Grid>

        )
    }
}
export default withStyles(styles)(addquiz);

