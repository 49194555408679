export const all_admin_option = [
    { id: 0, name: "Dashboard", icon: "donut_small", link: "/" },
    { id: 1, name: "Banner", icon: "pendingactions", link: "/banner" },
    { id: 1, name: "Notification", icon: "pendingactions", link: "/newNotification" },
    { id: 1, name: "Test Pass Pro", icon: "pendingactions", link: "/testpasspro" },
    {
        id: 2, name: "Courses", expand: true, item: [
            { id: 1, name: "Package Category", icon: "opacity", link: "/packagecategory" },
            { id: 2, name: "Package", icon: "battery_charging_full", link: "/package" },
            { id: 3, name: "Category", icon: "commute", link: "/category" },
            { id: 4, name: "Exams", icon: "storage", link: "/exams" },
            { id: 5, name: "Stages", icon: "3p", link: "/stages" },
            { id: 6, name: "Subjects", icon: "article", link: "/subjects" },
            // { id: 7, name: "Sections", icon: "settings", link: "/sections" },
            { id: 8, name: "Mini Package", icon: "dashboard_customize", link: "/minipackage" },
            { id: 9, name: "Chapters", icon: "airport_shuttle", link: "/chapters" },
            { id: 10, name: "Lessons", icon: "diversity_3", link: "/lessons" },
            { id: 11, name: "Videos", icon: "diversity_3", link: "/videos" },
            { id: 12, name: "Pdf", icon: "diversity_3", link: "/pdf" },
            { id: 13, name: "Quizes", icon: "pendingactions", link: "/quizes" },
        ]
    },
    { id: 3, name: "Previous Year Questions", icon: "playlist_add_check_circle  ", link: "/PreviousYearQuestion" },
    {
        id: 4, name: "Test Series", expand: true, item: [
            { id: 1, name: "Test Series", icon: "opacity", link: "/textseries" },
            { id: 2, name: "Tests", icon: "opacity", link: "/tests" },
            { id: 3, name: "Types", icon: "opacity", link: "/testtypes" },
            { id: 4, name: "Subjects", icon: "opacity", link: "/testsubject" },
            { id: 5, name: "Quiz", icon: "opacity", link: "/testquiz" },
            { id: 6, name: "Answers", icon: "pendingactions", link: "/student" },
            { id: 7, name: "Sections", icon: "settings", link: "/sections" },

        ]
    },
    {
        id: 5, name: "Exam AI", expand: true, item: [
            { id: 1, name: "Exam AI", icon: "storage", link: "/ExamAi" },
            { id: 2, name: "Trend", icon: "pendingactions", link: "/Trend" },
        ]
    },
    // { id: 6, name: "Examys Inspire", icon: "pendingactions", link: "/Notifications" },
    { id: 7, name: "Coupon", icon: "pendingactions", link: "/Coupon" },


];


