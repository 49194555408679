import React, { Component } from "react";
import { connect } from "react-redux";
import Editquiz from "../component/editTquiz";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  update_testseries_question,
  viewalldatabyTQuizId,
  viewAllQuestionByID,
  setQuestionID,
  updatequiz,
  updateQuestion,
  EditUploadQuestion,
  EditUploadoption1,
  EditUploadoption2,
  EditUploadoption3,
  EditUploadoption4,
  EditUploadANSimg,
  resetEditedquestionimage,
  resetEditedValueopation1,
  resetEditedValueopation2,
  resetEditedValueopation3,
  resetEditedValueopation4,
  resetEditedValueopation5,
  resetEditedValueanswer,
  setID,
  addQuizQuestion,
  addQuizTQuestion,
  add_update_testseries_quiz,
  delete_question_update_testseries_quiz,
  viewAllTSubject,
  updateTquiz,
  updateTQuizSubject,
  searchsubject,
  searchSection,
  section_id,
  viewAllSection,
  subjectss_id,
  language_id,
  viewAllLanguage,
  update_section,
  EditUploadoption5,
  searchlanguage,
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Editquiz {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {

    viewalldatabyTQuizId: (id) => {
      dispatch(viewalldatabyTQuizId(id))
    },
    delete_question_update_testseries_quiz: (id, Qid,section_id,subject_id,language_id) => {
      dispatch(delete_question_update_testseries_quiz(id, Qid,section_id,subject_id,language_id))
    },
    viewAllQuestionByID: (id) => {
      dispatch(viewAllQuestionByID(id))
    },
    setQuestionID: (id) => {
      dispatch(setQuestionID(id))
    },
    updateTquiz: (quiz_id, quiz_name, quiz_desc, quiz_time, quiz_marks, language, setSection, total_question,access) => {
      dispatch(updateTquiz(quiz_id, quiz_name, quiz_desc, quiz_time, quiz_marks, language, setSection, total_question,access))
    },

    setID: (payload) => {
      dispatch(setID(payload))
    },

    add_update_testseries_quiz: (question_id, question_text,
      question_desc,
      question_img,
      option1,
      option1_img,
      option1_desc,
      option2,
      option2_img,
      option2_desc,
      option3,
      option3_img,
      option3_desc,
      option4,
      option4_img,
      option4_desc,
      correct_ans,
      correct_ans_img,
      correct_ans_desc, id, subject_id, section_id, question_marks, cut_marks) => {
      dispatch(add_update_testseries_quiz(question_id, question_text,
        question_desc,
        question_img,
        option1,
        option1_img,
        option1_desc,
        option2,
        option2_img,
        option2_desc,
        option3,
        option3_img,
        option3_desc,
        option4,
        option4_img,
        option4_desc,
        correct_ans,
        correct_ans_img,
        correct_ans_desc, id, subject_id, section_id, question_marks, cut_marks))
    },
    update_testseries_question: (
      question_id,
      question_text,
      question_desc,
      question_img,
      option1,
      option1_img,
      option1_desc,
      option2,
      option2_img,
      option2_desc,
      option3,
      option3_img,
      option3_desc,
      option4,
      option4_img,
      option4_desc,
      option5,
      option5_img,
      option5_desc,
      correct_ans,
      correct_ans_img,
      correct_ans_desc, id, cut_marks, question_marks, section_id, subject_id, language_id) => {
      dispatch(update_testseries_question(
        question_id,
        question_text,
        question_desc,
        question_img,
        option1,
        option1_img,
        option1_desc,
        option2,
        option2_img,
        option2_desc,
        option3,
        option3_img,
        option3_desc,
        option4,
        option4_img,
        option4_desc,
        option5,
        option5_img,
        option5_desc,
        correct_ans,
        correct_ans_img,
        correct_ans_desc, id, cut_marks, question_marks, section_id, subject_id, language_id))
    },

    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    EditUploadQuestion: (question_url) => {
      dispatch(EditUploadQuestion(question_url));
    },
    EditUploadoption1: (new_option1_url) => {
      dispatch(EditUploadoption1(new_option1_url));
    },
    EditUploadoption2: (new_option2_url) => {
      dispatch(EditUploadoption2(new_option2_url));
    },
    EditUploadoption3: (new_option3_url) => {
      dispatch(EditUploadoption3(new_option3_url));
    },
    EditUploadoption4: (new_option4_url) => {
      dispatch(EditUploadoption4(new_option4_url));
    },
    EditUploadoption5: (new_option5_url) => {
      dispatch(EditUploadoption5(new_option5_url));
    },
    EditUploadANSimg: (new_ans_url) => {
      dispatch(EditUploadANSimg(new_ans_url));
    },
    resetEditedquestionimage: () => {
      dispatch(resetEditedquestionimage())
    },
    resetEditedValueopation1: () => {
      dispatch(resetEditedValueopation1())
    },
    resetEditedValueopation2: () => {
      dispatch(resetEditedValueopation2())
    },
    resetEditedValueopation3: () => {
      dispatch(resetEditedValueopation3())
    },
    resetEditedValueopation4: () => {
      dispatch(resetEditedValueopation4())
    },
    resetEditedValueopation5: () => {
      dispatch(resetEditedValueopation5())
    },
    resetEditedValueanswer: () => {
      dispatch(resetEditedValueanswer())
    },
    viewAllTSubject: () => {
      dispatch(viewAllTSubject())
    },
    updateTQuizSubject: (quiz_id, subject) => {
      dispatch(updateTQuizSubject(quiz_id, subject))
    },
    viewAllSection: () => {
      dispatch(viewAllSection())

    },
    section_id: (payload) => {
      dispatch(section_id(payload))
    },
    subjectss_id: (payload) => {
      dispatch(subjectss_id(payload))
    },
    language_id: (payload) => {
      dispatch(language_id(payload))
    },

    searchSection: (all_section, e) => {
      dispatch(searchSection(all_section, e))
    },
    searchsubject: (all_subject, e) => {
      dispatch(searchsubject(all_subject, e))
    },
    viewAllLanguage: () => {
      dispatch(viewAllLanguage())
    },
    update_section: (quiz_id, section) => {
      dispatch(update_section(quiz_id, section))
    },
    searchlanguage: (all_language, e) => {
      dispatch(searchlanguage(all_language, e))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);