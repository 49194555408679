import { Grid, Box, Button, Typography, MenuItem, IconButton, FormControl, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import React, { Component } from 'react'
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from "@material-ui/core/styles";
import One from "../images/count.svg"
import { Link } from 'react-router-dom';
import Edit from '../images/pencil.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactQuill from 'react-quill';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Loader from '../../../common/loader/containers/loader_cont';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';

import { styled, alpha } from "@mui/material/styles";
import Checkbox from '@mui/material/Checkbox';
import InputBase from "@mui/material/InputBase"
import SearchIcon from "@mui/icons-material/Search";
// import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00f" // Set the hover border color
        }
    }
});
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));
class addquiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueId: uuidv4(),
            delete: false,
            Deleteid: null,
            quiz_name: "",
            quiz_title: "",
            quiz_desc: "",
            qustions_text: "",
            add_qustion_url: "",
            question_url: "",
            new_option1_url: "",
            new_option2_url: "",
            new_option3_url: "",
            new_option4_url: "",
            new_option5_url: "",
            new_ans_url: "",
            subjectmodal: false,
            // qustion_title: "",

            quastion_desc: "",
            opation1: "",
            option1_url: "",
            option1_desc: "",
            opation2: "",
            option2_url: "",
            option2_desc: "",
            opation3: "",
            option3_url: "",
            option3_desc: "",
            opation4: "",
            opation5: "",
            option4_url: "",
            option5_url: "",
            option4_desc: "",
            option5_desc: "",
            answer: "",
            answer_url: "",
            answer_desc: "",
            showWarning: false,
            quiz: false,
            questionmodal: false,
            cut_marks: "",
            question_marks: "",
            question_id: "",
            question_desc: "",
            question_img: "",
            option4_img: "",
            option5_img: "",
            option3_img: "",
            option2_img: "",
            option1_img: "",
            correct_ans_img: "",

            option1: "1",
            option2: "2",
            option3: "3",
            option4: "4",
            option5: "5",
            image: false,
            option1img: false,
            option2img: false,
            option3img: false,
            option4img: false,
            option5img: false,
            timemodal: false,
            sectionmodal: false,
            languagemodal: false,
            setSection: false,
            ansimg: false,
            quiz_time: "",
            quiz_marks: "",
            quiz_cut_marks: "",
            // qustion_title: '',
            subject: [],
            // questions: [],

            language: "",
            section: this.props.admin.all_tquiz_by_tid?.section,
            Vid: 0,
            Qid: 0,
            Lang: 0,
            question_time: 0,
            a: "",
            b: "",
            c: "",
            d: "",
            e: "",
            f: "",
            g: "",
            addQuizTQuestion: false,
            section_filtered: [],
            sectionChecked: [],
            subjectChecked: [],
            subject_filtered: [],
            language_filtered: [],
            languageChecked: [],
            questions_marks: "",
            cuts_marks: "",
            questionCreated: true,
            total_question: "",
            access: "Paid",
            submitTest: false,
            section_time: "",
            rightAnswers: [
                {
                    option: "1"
                },
                {
                    option: "2"
                },
                {
                    option: "3"
                },
                {
                    option: "4"
                },
                {
                    option: "5"
                },
            ],
            tssection: '',
            tssubject: '',
            tslang: '',
            ques_id: '',
            uniqueId: "",

        }
        this.handleEditorChange0 = this.handleEditorChange0.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleEditorChange1 = this.handleEditorChange1.bind(this);
        this.handleEditorChange2 = this.handleEditorChange2.bind(this);
        this.handleEditorChange3 = this.handleEditorChange3.bind(this);
        this.handleEditorChange4 = this.handleEditorChange4.bind(this);
        this.handleEditorChange5 = this.handleEditorChange5.bind(this);
    }

    createTQuestion = (questionData, Qid, Vid, Lang) => {
        console.log("questionData", questionData);
        if (this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language[Lang].questions !== undefined) {
            this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language[Lang].questions.push(questionData)
        } else {
            console.log("aaaaaaaa", questionData);
            const { all_tquiz_by_tid } = this.props.admin;
            const section = [...all_tquiz_by_tid.section];

            if (section[Qid] && section[Qid].subject && section[Qid].subject[Vid] && section[Qid].subject[Vid].language && section[Qid].subject[Vid].language[Lang]) {
                // If the nested properties exist, push the new question data
                section[Qid].subject[Vid].language[Lang].questions = [
                    ...(section[Qid].subject[Vid].language[Lang].questions || []),
                    questionData,
                ];
                // Update the state with the modified section
                console.log(section, "section");
                this.setState({
                    section: section,
                    questionCreated: true,
                });
            } else {
                console.error(`Invalid indices for Vid and Lang in section at index ${Qid}.`);
            }
        }
        // if (Qid >= 0 && Qid < this.props.admin.all_tquiz_by_tid.section.length) {
        //      this.props.admin.all_tquiz_by_tid.section[Qid] = { ...this.props.admin.all_tquiz_by_tid.section[Qid] };

        //     if (this.props.admin.all_tquiz_by_tid.section[Qid].subject && this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid] && this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language && this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language[Lang]) {
        //         this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language[Lang].questions = [...(this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language[Lang].questions || []), questionData];
        //     } else {
        //         console.error(`Invalid indices for Vid and Lang in section at index ${Qid}.`);
        //     }

        //     updatedSections[Qid] = updatedSection;


        // } else {
        //     console.error(`Section at index ${Qid} does not exist.`);
        // }

        // Update the state with updatedSections and set questionCreated to true
        //     return {
        //         section: updatedSections,
        //         questionCreated: true
        //     };
        // });
    };


    handleDeleteSec = (id) => {

        let updated = this.props.admin.all_tquiz_by_tid?.section.filter(i => i._id !== id)

        this.setState(() => this.props.admin.all_tquiz_by_tid.section = updated)

    }
    // handleDeleteSec = (id) => {
    //     this.setState(prevState => {
    //         const newState = { ...prevState };
    //         const newArr = [...newState.section];
    //         newArr.splice(id, 1);
    //         newState.section = newArr;
    //         return newState;
    //     });
    // }

    handleDeleteSub = (Qid, id) => {
        this.setState(prevState => {
            const newState = { ...prevState };
            const newArr = [...this.props.admin.all_tquiz_by_tid.section[Qid].subject];
            newArr.splice(id, 1);
            this.props.admin.all_tquiz_by_tid.section[Qid].subject = newArr;
            return this.props.admin.all_tquiz_by_tid.section[Qid].subject;
        });
    }
    handleDeleteLang = (Qid, Vid, id) => {
        this.setState(prevState => {
            const newState = { ...prevState };
            const newArr = [...this.props.admin.all_tquiz_by_tid?.section[Qid].subject[Vid].language];
            newArr.splice(id, 1);
            this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language = newArr;
            return this.props.admin.all_tquiz_by_tid.section[Qid].subject[Vid].language;
        });
    }
    UnCheckedSection = (all_section, itemToUncheck) => {
        this.setState((prevState) => ({
            sectionChecked: prevState.sectionChecked.filter((item) => item !== itemToUncheck),
            section_filtered: all_section,
            // ... other state updates
        }));
    };
    UnCheckedSubject = (all_subject, itemToUncheck) => {
        this.setState((prevState) => ({
            subjectChecked: prevState.subjectChecked.filter((item) => item !== itemToUncheck),
            subject_filtered: all_subject,
            // ... other state updates
        }));
    };
    UnCheckedLanguage = (all_language, itemToUncheck) => {
        this.setState((prevState) => ({
            languageChecked: prevState.languageChecked.filter((item) => item !== itemToUncheck),
            language_filtered: all_language,
            // ... other state updates
        }));
    };

    handleDescriptionChange = (e) => {
        this.setState({ section_time: e.target.value });
    };

    handleSave = () => {
        try {
            const { section, Qid, section_time } = this.state;

            // Ensure section[Qid] is initialized as an object
            if (!this.props.admin.all_tquiz_by_tid.section[Qid]) {
                this.props.admin.all_tquiz_by_tid.section[Qid] = {};
            }

            // Ensure section[Qid].section_time is initialized as an empty string
            if (!this.props.admin.all_tquiz_by_tid.section[Qid].section_time) {
                this.props.admin.all_tquiz_by_tid.section[Qid].section_time = "";
            }

            // Update section[Qid].section_time with the new value
            this.props.admin.all_tquiz_by_tid.section[Qid].section_time = section_time;

            // Log the updated state for debugging (optional)


            // Set the state for the 'section' property
            // this.props.admin.all_tquiz_by_tid?.section
        } catch (error) {
            console.error("Error saving description:", error);
        }
    }


    componentDidMount() {
        this.props.viewAllTSubject()
        this.props.viewAllSection();
        this.props.viewAllLanguage();
        // this.props.viewalldatabyTQuizId(this.props.admin.tquiz_id);
        this.props.viewalldatabyTQuizId(this.props.params.quiz_id)
        this.props.resetEditedquestionimage();
        this.props.resetEditedValueopation1();
        this.props.resetEditedValueopation2();
        this.props.resetEditedValueopation3();
        this.props.resetEditedValueopation4();
        this.props.resetEditedValueopation5();
        this.props.resetEditedValueanswer();
        this.setState({
            tssection: this.props.admin.all_tquiz_by_tid?.section?.[0]._id,
            tssubject: this.props.admin.all_tquiz_by_tid?.section?.[0].subject?.[0]._id,
            tslang: this.props.admin.all_tquiz_by_tid?.section?.[0]?.subject?.[0]?.language?.[0]._id,
        });
        // console.log("testquiz by id", this.props.admin.all_tquiz_by_tid?.section[0]._id);
    }



    // handleCreateQuestionAndQuiz = async () => {
    //     const { Qid, Vid, Lang } = this.state;
    //     const questionData = {
    //         qustion_title: this.state.qustions_text,
    //         // this.state.question_desc,
    //         // this.props.admin.new_question_image === "" ?
    //         question_img: this.props.admin.new_question_image,
    //         option1: this.state.option1,
    //         // this.props.admin.new_opation1_img === "" ?
    //         opation1_img: this.props.admin.new_opation1_img,
    //         option1_desc: this.state.option1_desc,
    //         option2: this.state.option2,
    //         // this.props.admin.new_opation2_img === "" ?
    //         opation2_img: this.props.admin.new_opation2_img,
    //         option2_desc: this.state.option2_desc,
    //         option3: this.state.option3,
    //         // this.props.admin.new_opation3_img === "" ?
    //         opation3_img: this.props.admin.new_opation3_img,
    //         option3_desc: this.state.option3_desc,
    //         option4: this.state.option4,
    //         // this.props.admin.new_opation4_img === "" ?
    //         opation4_img: this.props.admin.new_opation4_img,
    //         option4_desc: this.state.option4_desc,
    //         option5: this.state.option5,
    //         // this.props.admin.new_opation4_img === "" ?
    //         opation5_img: this.props.admin.new_opation5_img,
    //         option5_desc: this.state.option5_desc,
    //         answer: this.state.correct_ans,
    //         // this.props.admin.new_answer_img === "" ?
    //         answer_img: this.props.admin.new_answer_img,
    //         editorHtml: this.state.correct_ans_desc,
    //         quiz_id: this.props.admin.tquiz_id,
    //         subject_id: this.props.admin.subject_id,
    //         section_id: this.props.admin.set_section_id,
    //         question_marks: this.state.questions_marks,
    //         cut_marks: this.state.cuts_marks,
    //     }


    //     // Execute createTQuestion
    //     await this.createTQuestion(questionData, Qid, Vid, Lang);

    //     // Check if createTquiz should be executed
    //     
    //     if (this.state.questionCreated) {
    //         this.props.update_section(this.props.admin.tquiz_id, this.state.section)
    //         this.setState({

    //             section: [],

    //             questionCreated: false,
    //             addQuizTQuestion: false
    //         });
    //     }

    //     // Reset values to null or empty strings

    //     this.props.admin.opation2_img = "";
    //     this.props.admin.opation1_img = "";
    //     this.props.admin.opation3_img = "";
    //     this.props.admin.opation4_img = "";
    //     this.props.admin.opation5_img = "";
    //     this.props.admin.answer_img = "";
    //     this.props.admin.question_img = "";
    //     // ... reset other properties

    //     this.setState({
    //         // ... reset other state properties
    //         qustions_text: "",
    //         question_desc: "",
    //         question_img: "",
    //         new_question_image: "",
    //         option1_img: "",
    //         new_opation1_img: "",
    //         option2_img: "",
    //         new_opation2_img: "",
    //         option3_img: "",
    //         new_opation3_img: "",
    //         option4_img: "",
    //         new_opation4_img: "",
    //         option5_img: "",
    //         new_opation5_img: "",
    //         correct_ans_img: "",
    //         new_answer_img: "",
    //         correct_ans_desc: "",
    //         option4_desc: "",
    //         option5_desc: "",
    //         correct_ans: "",
    //         option3_desc: "",
    //         option2_desc: "",
    //         option1_desc: "",
    //         questions_marks: "",
    //         cuts_marks: "",
    //         questionCreated: false,
    //     });

    //     this.handleButtonClick();

    // };
    handleEditorChange0(html) {
        this.setState({ qustions_text: html });
    }
    handleEditorChange4(html) {
        this.setState({ option4_desc: html });
    }
    handleEditorChange5(html) {
        this.setState({ option5_desc: html });
    }
    handleEditorChange3(html) {
        this.setState({ option3_desc: html });
    }
    handleEditorChange2(html) {
        this.setState({ option2_desc: html });
    }
    handleEditorChange1(html) {
        this.setState({ option1_desc: html });
    }
    handleEditorChange(html) {
        this.setState({ correct_ans_desc: html });
    }
    render() {
        const { classes, admin } = this.props;
        const { Vid, Qid, Lang, subject } = this.state;
        // 
        // 

        return (
            <Grid>
                <Box className='categoryname'>
                    <center style={{ display: "flex" }}>
                        <Link to="/testquiz" style={{ textDecoration: "none" }}>
                            <IconButton
                                // style={{ marginLeft: "18px" }}
                                onClick={() => {
                                    // this.props.setID(c._id)
                                    this.props.admin.all_quastion_by_id.push([])
                                    this.setState({ tslang: "", tssubject: "", tssection: "", })
                                }}
                            >
                                <ArrowBackIcon style={{ color: "white" }} />
                            </IconButton>
                        </Link>
                        <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: "8px" }}>Edit Quiz</Typography>


                        <Button style={{ color: "white ", textAlign: 'center', }}></Button>
                    </center>
                </Box>
                <Grid className='boxdesign' style={{ border: "1px solid #888", height: 450, width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Hint
                        </Typography>
                        <IconButton
                            onClick={() => {
                                this.setState({
                                    quiz: true,
                                    quiz_name: this.props.admin.all_tquiz_by_tid.quiz_name,
                                    quiz_desc: this.props.admin.all_tquiz_by_tid.quiz_desc,
                                    // language: this.props.admin.all_quiz_by_id.language,
                                    quiz_time: this.props.admin.all_tquiz_by_tid.time,
                                    quiz_marks: this.props.admin.all_tquiz_by_tid.marks,
                                    setSection: this.props.admin.all_tquiz_by_tid.setSection,
                                    total_question: this.props.admin.all_tquiz_by_tid.total_question,
                                    access: this.props.admin.all_tquiz_by_tid.access,

                                });
                            }}
                        >
                            {/* <EditIcon style={{ color: 'green' }} /> */}
                            <img src={Edit} height={24} width={24} />
                        </IconButton>
                    </Grid>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Title
                        {/* {this.props.admin.all_quiz_by_id.quiz_name} */}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                        {this.props.admin.all_tquiz_by_tid?.quiz_name}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Quiz Hint
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                        {/* Quiz Title */}
                        {this.props.admin.all_tquiz_by_tid?.quiz_desc}
                    </Typography>
                    {/* <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                        Language
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                        {/* Quiz Title */}
                    {/* {this.props.admin.all_quiz_by_id?.language} */}
                    {/* </Typography> */}
                    <div style={{ direction: "row", display: "flex" }}>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Time:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Time */}
                            {this.props.admin.all_tquiz_by_tid?.time}
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Marks:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Marks */}
                            {this.props.admin.all_tquiz_by_tid?.marks}
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Total Questions:
                        </Typography>
                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>
                            {/* Quiz Marks */}
                            {this.props.admin.all_tquiz_by_tid?.total_question}
                        </Typography>

                        <div style={{ marginLeft: "50px", marginTop: "2%" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.admin.all_tquiz_by_tid?.setSection}
                                    />
                                }
                                label="Set Section Time"
                            />
                        </div>

                    </div>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", marginTop: "-10px" }}>
                        Access
                        {/* {this.props.admin.all_quiz_by_id.quiz_name} */}
                    </Typography>
                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px", height: "45px" }}>
                        {this.props.admin.all_tquiz_by_tid?.access}
                        {/* {console.log(this.props.admin.all_tquiz_by_tid)} */}
                    </Typography>



                </Grid>


                <Grid style={{ display: "flex", alignSelf: 'center', marginTop: '20px' }}>
                    <Typography onClick={() => {

                        this.setState({
                            addQuizTQuestion: true,
                            qustions_text: "",
                        })
                    }} style={{ cursor: "pointer", borderRadius: "23px", padding: "0px 5px", backgroundColor: "#6257CD", color: "white", display: "inline-flex", fontFamily: 'Poppins', marginLeft: "40px" }}>
                        Add Question
                    </Typography>
                    <div style={{ marginLeft: "40px", border: '1px solid #6257CD', height: '25px', width: '25px', borderRadius: '50%' }}>
                        <center>
                            <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", }}>

                                {this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language?.[Lang]?.questions?.length || 0}
                                {/* jsnddnnd */}
                            </Typography>
                        </center>
                    </div>
                </Grid>



                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-enenly", marginTop: "4%", marginRight: "3%" }}>
                    {/* <center style={{ height: '44px', color: '#6257CD', textAlign: 'center', textTransform: 'capitalize', backgroundColor: '#fff', width: '100%', borderRadius: 5, display: 'flex', flexDirection: 'row' }}> */}
                    <Button variant="outlined" style={{ border: '2px solid #6257cd', marginLeft: "2%" }}>
                        Select Section
                    </Button>
                    {/* </center> */}

                    <Grid container md={7} xl={7} lg={8} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "4%", marginRight: "12xx%", alignItems: "center" }}>
                        {Array.isArray(this.props.admin.all_tquiz_by_tid?.section) && (this.props.admin.all_tquiz_by_tid?.section ?? [])?.map((e, index) => {
                            // console.log(this.props.admin.all_tquiz_by_tid);
                            return (
                                <div style={{ borderColor: 'black', display: 'flex', flexDirection: 'row', borderWidth: '10px', margin: 5 }} key={index}>
                                    <Chip
                                        label={e.section_name}
                                        style={{
                                            backgroundColor: this.state.Qid === index ? "#6257CD" : '',
                                            color: this.state.Qid === index ? "white" : ''
                                        }}
                                        variant={this.props.admin.all_tquiz_by_tid?.section?.[index] === undefined ? "outlined" : "filled"}
                                        onClick={() => {
                                            this.setState({ Qid: index, tssection: e._id, Vid: 0, Lang: 0 });
                                            this.props.section_id(e._id);
                                        }}
                                        onDelete={() => {
                                            this.handleDeleteSec(e._id);
                                            this.UnCheckedSection(admin.all_section, e.section_name);
                                        }}
                                    />
                                </div>
                            )
                        })}
                        <AddIcon onClick={() => {
                            this.setState({ sectionmodal: true });
                        }} />
                    </Grid>
                </Box>
                {this.props.admin.all_tquiz_by_tid?.setSection === true && (
                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-enenly", marginTop: "4%", marginRight: "12%" }}>
                        {/* <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}> */}
                        <Button
                            variant="outlined"
                            style={{ border: "2px solid #6257cd", marginLeft: "2%" }}

                        >

                            Section Time In Minute</Button>

                        {/* </center> */}
                        <Grid container md={7} xl={7} lg={8} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "5.5%", marginRight: "12xx%", alignItems: "center" }}>

                            <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>
                                {this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.section_time}
                            </div>

                            <AddIcon onClick={() => {
                                this.setState({
                                    timemodal: true,
                                })
                            }} />
                        </Grid>
                    </Box>
                )}
                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-enenly", marginTop: "4%", marginRight: "3%" }}>

                    <Button
                        variant="outlined"
                        style={{ border: "2px solid #6257cd", marginLeft: "2%" }}

                    >

                        Select Subject</Button>




                    <Grid container md={7} xl={7} lg={8} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "4%", marginRight: "12xx%", alignItems: "center" }}>
                        {(this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject ?? [])?.map((e, index) => {
                            // 
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                    <Chip label={e.subject_name}
                                        style={{
                                            backgroundColor: this.state.Vid == index ? "#6257CD" : '',
                                            color: this.state.Vid == index ? "white" : ''
                                        }}
                                        variant={this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject[index].hasOwnProperty('language') ? "filled" : "outlined"}

                                        onClick={() => {
                                            this.setState({ Vid: index, tssubject: e._id, Lang: 0 })
                                            this.props.subjectss_id(e._id);
                                            // console.log(e._id)
                                        }}

                                        onDelete={() => {
                                            this.handleDeleteSub(Qid, index)
                                            this.UnCheckedSubject(admin.all_subject, e.subject_name)
                                        }}


                                    />


                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                subjectmodal: true,
                                subject: this.props.admin.all_tquiz_by_tid.subject
                            })
                        }} />
                    </Grid>


                </Box>
                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-enenly", marginTop: "4%", marginRight: "5%" }}>

                    <Button
                        variant="outlined"
                        style={{ border: "2px solid #6257cd", marginLeft: "2%" }}

                    >

                        Select Language</Button>




                    <Grid container md={7} xl={7} lg={8} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "3%", marginRight: "12xx%", alignItems: "center" }}>
                        {(this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language ?? [])?.map((e, index) => {
                            // 
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>

                                    <Chip label={e.language}
                                        style={{
                                            backgroundColor: this.state.Lang == index ? "#6257CD" : '',
                                            color: this.state.Lang == index ? "white" : ''
                                        }}
                                        // variant={this.state.section?.[Qid]?.subject[index].hasOwnProperty('language') ? "filled" : "outlined"}
                                        variant={this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language.hasOwnProperty('questions') ? "filled" : "outlined"}

                                        // variant={this.state.test_content.test[index] == undefined ? "outlined" : "filled"}
                                        onClick={() => {
                                            this.setState({ Lang: index, tslang: e._id })
                                            this.props.language_id(e._id);
                                        }}
                                        // onDelete={() => this.handleDeleteL(all.chapter[Lid]?.lesson,e._id) } 

                                        onDelete={() => {
                                            this.handleDeleteLang(Qid, Vid, index)
                                            this.UnCheckedLanguage(admin.all_language, e.language)
                                        }}


                                    />


                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                languagemodal: true,
                                // subject: this.props.admin.all_quiz_by_id.subject
                            })
                        }} />
                    </Grid>


                </Box>

                <Grid style={{ display: "flex", flexDirection: "column" }}>
                    {(this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language?.[Lang]?.questions ?? [])?.map((c, index) => {
                        // 
                        return (
                            <Grid>
                                <Grid className='boxdesign' style={{ border: "1px solid #888", height: "100%", width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}>
                                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                            {index + 1}
                                        </Typography>
                                        <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                                Question Marks:
                                            </Typography>
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                                {c.question_marks}
                                            </Typography>


                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                                Cut Marks:
                                            </Typography>
                                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                                {c.cut_marks}
                                            </Typography>

                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>

                                            <IconButton
                                                onClick={() => {
                                                    console.log('tssection', this.state.tssection);
                                                    console.log('tssubject', this.state.tssubject);
                                                    console.log('tslang', this.state.tslang);
                                                    this.setState({
                                                        questionmodal: true,
                                                        question_id: index,
                                                        ques_id: c._id,
                                                        uniqueId: c.uniqueId,
                                                        question_img: c.question_img,
                                                        // question_img1: c.question_img,
                                                        // question_img2: c.question_img,
                                                        // question_img3: c.question_img,
                                                        qustions_text: c.qustion_title,
                                                        // question_desc: c.question_desc,
                                                        option4_img: c.opation4_img,
                                                        option4_desc: c.option4_desc,
                                                        option4: c.option4,
                                                        option5_img: c.opation5_img,
                                                        option5_desc: c.option5_desc,
                                                        option5: c.option5,
                                                        option3_img: c.opation3_img,
                                                        option3_desc: c.option3_desc,
                                                        option3: c.option3,
                                                        option2_img: c.opation2_img,
                                                        option2_desc: c.option2_desc,
                                                        option2: c.option2,
                                                        option1_img: c.opation1_img,
                                                        option1_desc: c.option1_desc,
                                                        option1: c.option1,
                                                        correct_ans_img: c.answer_img,
                                                        correct_ans_desc: c.editorHtml,
                                                        correct_ans: c.answer,
                                                        question_marks: c.question_marks,
                                                        cut_marks: c.cut_marks
                                                    });
                                                    // this.props.setQuestionID(c._id);
                                                }}
                                            >
                                                <img src={Edit} height={24} width={24} />
                                            </IconButton>

                                            < DeleteForeverOutlinedIcon onClick={() => this.setState({ delete: true, Deleteid: c.uniqueId })} style={{ fontSize: 25, marginRight: 10, cursor: "pointer" }} />

                                        </div>

                                    </Grid>


                                    {/* <Grid className='boxdesign' style={{ border: "1px solid #888", height: "100%", width: "70%", borderRadius: 10, borderColor: "#c4c4c4" }}> */}
                                    {/* <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        {index + 1}
                                    </Typography> */}
                                    <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        Question
                                    </Typography>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", border: "1px solid #888", borderRadius: 10, padding: " 12px 16px" }}>

                                        {/* {c.question_text} */}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.qustion_title }}></div>
                                    </Typography>

                                    {c.question_img !== "" && <img src={c.question_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold " }}>
                                        Options

                                    </Typography>
                                    <div style={{ display: "flex" }}>
                                        {c.option1_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option1}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option1_desc }}></div>

                                    </div>
                                    {c.opation1_img !== "" && c.opation1_img !== null && <img src={c.opation1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option2_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option2}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option2_desc }}></div>
                                    </div>
                                    {c.opation2_img !== "" && c.opation2_img !== null && <img src={c.opation2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option3_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option3}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option3_desc }}></div>
                                    </div>
                                    {c.opation3_img !== "" && c.opation3_img !== null && <img src={c.opation3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    <div style={{ display: "flex" }}>
                                        {c.option4_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option4}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option4_desc }}></div>
                                    </div>
                                    {c.opation4_img !== "" && c.opation4_img !== null && <img src={c.opation4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}

                                    <div style={{ display: "flex" }}>
                                        {c.option5_desc !== '' &&
                                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                                {c.option5}
                                            </Typography>}
                                        <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.option5_desc }}></div>
                                    </div>
                                    {c.opation5_img !== "" && c.opation5_img !== null && c.opation5_img !== " " && c.opation5_img !== undefined && <img src={c.opation5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}


                                    <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                        Correct Option
                                    </Typography>

                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                        {c.answer}
                                    </Typography>

                                    {/* <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.correct_ans_desc }}></div> */}


                                    <Typography dangerouslySetInnerHTML={{ __html: c.editorHtml }} style={{ color: "#000", fontFamily: 'Poppins', margin: "18px", fontWeight: "500", }}>
                                        {/* {c.correct_ans} */}
                                    </Typography>

                                    {/* <div contentEditable={false} dangerouslySetInnerHTML={{ __html: c.correct_ans_desc }}></div> */}

                                    {c.answer_img !== "" && c.answer_img !== null && <img src={c.answer_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                                    {/* </Grid> */}



                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>

                <Dialog
                    open={this.state.quiz}
                    style={{ height: "auto",paddingBottom:"30px" }}
                >

                    <Grid className='boxnewdesign' style={{ height: "auto", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography>


                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", width: "90%", fontWeight: "500" }}>
                            Quiz Title
                        </Typography>
                        <TextField
                            required
                            placeholder='Enter quiz name'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "50px", width: "450px", marginLeft: "11px" }}
                            value={this.state.quiz_name}
                            onChange={(e) => { this.setState({ quiz_name: e.target.value }) }}
                        />




                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", width: "90%", fontWeight: "500" }}>
                            Quiz Hint
                        </Typography>

                        <TextField
                            required
                            placeholder='Enter quiz description'
                            InputLabelProps={{ shrink: true, required: true }}
                            style={{ height: "50px", width: "450px", marginLeft: "11px" }}
                            value={this.state.quiz_desc}
                            onChange={(e) => { this.setState({ quiz_desc: e.target.value }) }}
                        />

                        <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                Time In Minutes:
                            </Typography>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                            <TextField
                                required
                                // id="outlined-required"
                                // label="Category Name"
                                className={classes.root}
                                size="small"
                                placeholder='Enter Time '
                                type='number'
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "8vh", width: "15%", }}
                                value={this.state.quiz_time}
                                onChange={(e) => { this.setState({ quiz_time: e.target.value }) }}
                            />

                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                Marks:
                            </Typography>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                            <TextField
                                required
                                // id="outlined-required"
                                // label="Category Name"
                                className={classes.root}
                                size="small"
                                placeholder='Enter Marks '
                                type='number'
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "8vh", width: "15%", }}
                                value={this.state.quiz_marks}
                                onChange={(e) => { this.setState({ quiz_marks: e.target.value }) }}
                            />


                            <div style={{ marginLeft: "15px", marginTop: "5px" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={this.state.setSection}
                                            checked={this.state.setSection}
                                            onChange={(e) => { this.setState({ setSection: e.target.checked }) }}
                                            color="primary"
                                        />
                                    }
                                    label="Set Section Time"
                                />
                            </div>
                            {/* </div> */}
                        </div>
                        <div style={{ display: "flex" }}>
                            <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                Total Questions:
                            </Typography>

                            <TextField
                                required
                                // id="outlined-required"
                                // label="Category Name"
                                value={this.state.total_question}
                                className={classes.root}
                                size="small"
                                placeholder='Enter Total Question '
                                type='number'
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "8vh", width: "22%", marginLeft: "10px", marginTop: "10px" }}
                                onChange={(e) => { this.setState({ total_question: e.target.value }) }}
                            />
                        </div>

                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500", marginTop: "-10px" }}>
                            Access
                        </Typography>
                        {/* <Grid style={{ marginTop: "2%", width: 600 }}> */}
                        <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "15px" }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Access'
                                select
                                className={classes.root}
                                rows={1}
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "8vh", width: "95%", }}
                                value={this.state.access}
                                onChange={(e) => {
                                    this.setState({ access: e.target.value })
                                }}
                            >
                                <MenuItem value={"Free"}>{"Free"}</MenuItem>
                                <MenuItem value={"Paid"}>{"Paid"}</MenuItem>

                            </TextField>

                        </div>

                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ quiz: false });
                                }}
                                style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    this.props.updateTquiz(this.props.params.quiz_id, this.state.quiz_name, this.state.quiz_desc, this.state.quiz_time, this.state.quiz_marks, this.state.language, this.state.setSection, this.state.total_question, this.state.access)
                                    this.setState({ quiz: false, quiz_name: "", quiz_desc: "", quiz_cut_marks: "", quiz_marks: "", quiz_time: "" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>

                <Dialog
                    fullScreen
                    open={this.state.questionmodal}
                >
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} justifyContent={'space-evenly'} display={'flex'}>
                                <Grid display={'flex'}>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', fontWeight: "500" }}>
                                        Question Marks:
                                    </Typography>
                                    <TextField
                                        required
                                        fullWidth
                                        className={classes.root}
                                        size="small"
                                        placeholder='Enter Time '
                                        type='number'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        // style={{ height: "8vh", width: "15%", }}
                                        value={this.state.question_marks}
                                        onChange={(e) => { this.setState({ question_marks: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid display={'flex'}>
                                    <Typography style={{ color: "#000", fontFamily: 'Poppins', fontWeight: "500" }}>
                                        Cut Marks:
                                    </Typography>
                                    {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                                    <TextField
                                        required
                                        fullWidth
                                        className={classes.root}
                                        size="small"
                                        placeholder='Enter Marks '
                                        type='number'
                                        value={this.state.cut_marks}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        // style={{ height: "8vh", width: "15%", }}
                                        onChange={(e) => { this.setState({ cut_marks: e.target.value }) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} justifyContent={'space-evenly'}>
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                                            Question
                                        </Typography>
                                        <ReactQuill
                                            fullWidth
                                            style={{ marginTop: 10 }}
                                            value={this.state.qustions_text}
                                            onChange={this.handleEditorChange0}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} justifyContent={'space-between'}>
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={8}>
                                        {this.state.question_url !== "" &&
                                            this.props.admin.new_question_image !== "" &&
                                            this.state.a !== "" &&
                                            <img alt='' src={this.props.admin.new_question_image}
                                                height={150} width={440}
                                                style={{ marginTop: "25%" }} />}
                                        {this.state.question_img !== "" && this.state.a === "" &&
                                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                <img src={this.state.question_img} alt='' height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            image: true,
                                                        });
                                                    }}
                                                    style={{ marginTop: "24%", height: "0%" }}>
                                                    <img alt='' src={Edit} height={24} width={24} />
                                                </IconButton>
                                            </Grid>}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", margin: "15px", fontSize: 25, }}>
                                            Options..
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* {this.state.option1_desc !== '' && */}
                                        <ReactQuill fullWidth style={{ marginTop: 10 }}
                                            value={this.state.option1_desc}
                                            onChange={this.handleEditorChange1}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option1_url !== "" && this.props.new_option1_url !== "" && <img alt='' src={this.props.admin.new_option1_url} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option1_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option1_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option1img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option2}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* {this.state.option2_desc !== '' && */}
                                        <ReactQuill fullWidth style={{ marginTop: 10 }}
                                            value={this.state.option2_desc}
                                            onChange={this.handleEditorChange2}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option2_url !== "" && this.props.new_option2_url !== "" && <img alt='' src={this.props.admin.new_option2_url} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option2_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option2_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option2img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option3}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* {this.state.option3_desc !== '' && */}
                                        <ReactQuill fullWidth style={{ marginTop: 10 }}
                                            value={this.state.option3_desc}
                                            onChange={this.handleEditorChange3}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option3_url !== "" && this.props.new_opation3_img !== "" && <img alt='' src={this.props.admin.new_opation3_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option3_img !== "" && this.state.d === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option3_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option3img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option4}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* {this.state.option4_desc !== '' && */}
                                        <ReactQuill fullWidth style={{ marginTop: 10 }}
                                            value={this.state.option4_desc}
                                            onChange={this.handleEditorChange4}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option4_url !== "" && this.props.new_opation4_img !== "" && <img alt='' src={this.props.admin.new_opation4_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option4_img !== "" && this.state.e === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option4_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option4img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2}>
                                        {this.state.option5 !== "" && this.state.option5 !== " " && this.state.option5 !== null && this.state.option5 !== undefined &&
                                            <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                                Option:{this.state.option5}
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* {this.state.option5_desc !== '' && this.state.option5_desc !== ' ' && this.state.option5_desc !== null && this.state.option5_desc !== undefined && */}
                                        <ReactQuill fullWidth style={{ marginTop: 10 }}
                                            value={this.state.option5_desc}
                                            onChange={this.handleEditorChange5}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option5_url !== "" && this.props.new_opation5_img !== "" && this.state.new_option5_url !== " " && this.props.new_opation5_img !== " " && this.state.new_option5_url !== null && this.props.new_opation5_img !== null && this.state.new_option5_url !== undefined && this.props.new_opation5_img !== undefined && <img src={this.props.admin.new_opation5_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option5_img !== "" && this.state.g === "" && this.state.option5_img !== null && this.state.g === null && this.state.option5_img !== " " && this.state.g === " " && this.state.option5_img !== undefined && this.state.g === undefined && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img src={this.state.option4_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option5img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Correct Option
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <select
                                                    fullWidth
                                                    value={this.state.correct_ans}
                                                    style={{
                                                        background: "white",
                                                        height: '55px',
                                                        border: "1px solid #888",
                                                        fontSize: 16,
                                                        borderRadius: 5,
                                                        padding: 7,
                                                        color: 'gray',
                                                        margin: 15
                                                    }}
                                                    select

                                                    onChange={(e) => {
                                                        this.setState({ correct_ans: e.target.value });

                                                    }}
                                                >
                                                    <option value="" >Select Right Answer</option>
                                                    {this.state.rightAnswers.map((e) => (
                                                        <option value={e.option}>{e.option}</option>
                                                    ))}
                                                </select>
                                                {this.state.new_ans_url !== "" && this.props.new_answer_img !== "" && <img alt='' src={this.props.admin.new_answer_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                                {this.state.correct_ans_img !== "" && this.state.f === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.correct_ans_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                ansimg: true,

                                                            });
                                                        }}
                                                        style={{ marginTop: "24%", height: "0%" }}
                                                    >
                                                        <img alt='' src={Edit} height={24} width={24} />
                                                    </IconButton></Grid>}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {this.state.correct_ans_desc !== '' &&
                                                    <ReactQuill fullWidth style={{ margin: 15 }}
                                                        value={this.state.correct_ans_desc}
                                                        onChange={this.handleEditorChange}
                                                        modules={addquiz.modules}
                                                        formats={addquiz.formats}
                                                    />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} gap={2}>
                                <center>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ questionmodal: false });
                                            this.setState({ question_id: "", question_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "" })

                                        }}
                                        style={{ color: "#6257CD", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        onClick={() => {
                                            this.props.update_testseries_question(
                                                // this.state.question_id,
                                                this.state.uniqueId,
                                                this.state.qustions_text,
                                                this.state.question_desc,
                                                this.props.admin.new_question_image === "" ?
                                                    this.state.question_img :
                                                    this.props.admin.new_question_image,
                                                this.state.option1,
                                                this.props.admin.new_opation1_img === "" ?
                                                    this.state.option1_img :
                                                    this.props.admin.new_opation1_img,
                                                this.state.option1_desc,
                                                this.state.option2,
                                                this.props.admin.new_opation2_img === "" ?
                                                    this.state.option2_img :
                                                    this.props.admin.new_opation2_img,
                                                this.state.option2_desc,
                                                this.state.option3,
                                                this.props.admin.new_opation3_img === "" ?
                                                    this.state.option3_img :
                                                    this.props.admin.new_opation3_img,
                                                this.state.option3_desc,
                                                this.state.option4,
                                                this.props.admin.new_opation4_img === "" ?
                                                    this.state.option4_img :
                                                    this.props.admin.new_opation4_img,
                                                this.state.option4_desc,
                                                this.state.option5,
                                                this.props.admin.new_opation5_img === "" ?
                                                    this.state.option5_img :
                                                    this.props.admin.new_opation5_img,
                                                this.state.option5_desc,
                                                this.state.correct_ans,
                                                this.props.admin.new_answer_img === "" ?
                                                    this.state.correct_ans_img :
                                                    this.props.admin.new_answer_img,
                                                this.state.correct_ans_desc,
                                                this.props.params.quiz_id,
                                                parseFloat(this.state.cut_marks),
                                                parseFloat(this.state.question_marks),
                                                this.props.admin.set_section_id === "" ?
                                                    this.state.tssection :
                                                    this.props.admin.set_section_id,
                                                this.props.admin.set_subject_id === "" ?
                                                    this.state.tssubject :
                                                    this.props.admin.set_subject_id,
                                                this.props.admin.set_language_id === "" ?
                                                    this.state.tslang :
                                                    this.props.admin.set_language_id,
                                            )
                                            this.setState({ questionmodal: false, uniqueId: "", question_id: "", qustions_text: "", question_text: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "", option5_img: "", new_opation5_img: "", option5_desc: "", })
                                        }}
                                        style={{ marginLeft: "20px", color: "white", backgroundColor: "#C0392B" }}>Edit</Button>
                                </center>
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>



                {/*-------------------------------------------- -addQuizTQuestion ---------------------------------------------- */}




                <Dialog
                    open={this.state.addQuizTQuestion}
                    fullScreen={true}
                >

                    <Grid style={{ backgroundColor: "#fff", height: "860px", minWidth: "580px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", flexDirection: "row", display: "flex", marginBottom: "40px" }}>

                        <Grid>
                            <Grid style={{ display: "flex", alignSelf: 'center', marginTop: '20px' }}>
                                <Typography style={{ cursor: "pointer", borderRadius: "23px", padding: "0px 5px", backgroundColor: "#6257CD", color: "white", display: "inline-flex", fontFamily: 'Poppins', marginLeft: "40px" }}>
                                    Questions :-
                                </Typography>
                                <div style={{ marginLeft: "40px", border: '1px solid #6257CD', height: '25px', width: '25px', borderRadius: '50%' }}>
                                    <center>
                                        <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', fontWeight: "bold", }}>

                                            {this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language?.[Lang]?.questions?.length}
                                            {/* jsnddnnd */}
                                        </Typography>
                                    </center>
                                </div>
                            </Grid>

                            <div style={{ display: "flex", direction: "row", marginTop: "2%" }}>
                                <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                    Question Marks:
                                </Typography>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    className={classes.root}
                                    size="small"
                                    placeholder='Enter Time '
                                    type='number'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "15%", }}
                                    value={this.state.questions_marks}
                                    onChange={(e) => { this.setState({ questions_marks: e.target.value }) }}
                                />

                                <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                                    Cut Marks:
                                </Typography>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}> */}

                                <TextField
                                    required
                                    // id="outlined-required"
                                    // label="Category Name"
                                    className={classes.root}
                                    size="small"
                                    placeholder='Enter Marks '
                                    type='number'
                                    value={this.state.cuts_marks}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "8vh", width: "15%", }}
                                    onChange={(e) => { this.setState({ cuts_marks: e.target.value }) }}
                                />


                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "15px" }}>
                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "10px", fontWeight: "bold" }}>
                                    Question
                                </Typography>
                                <ReactQuill style={{ width: "600px", marginTop: 10 }}
                                    value={this.state.qustions_text}
                                    onChange={this.handleEditorChange0}
                                    modules={addquiz.modules}
                                    formats={addquiz.formats}
                                />
                            </div>
                            {this.state.question_url !== "" && this.props.admin.new_question_image !== "" && this.state.a !== "" && <img alt='' src={this.props.admin.new_question_image} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                            {this.state.question_img !== "" && this.state.a === "" &&
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <img src={this.state.question_img} alt='' height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />


                                    <IconButton
                                        onClick={() => {
                                            this.setState({
                                                image: true,

                                            });
                                        }}
                                        style={{ marginTop: "24%", height: "0%" }}
                                    >
                                        <img alt='' src={Edit} height={24} width={24} />
                                    </IconButton>
                                </Grid>}
                            {/* <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "15px" }}>
                                <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', marginTop: "85px", fontWeight: "bold", marginLeft: "10px" }}>
                                    Question Description
                                </Typography>
                                <TextField
                                    required
                                    multiline
                                    rows={4}
                                    // placeholder='Enter Question name'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "550px", marginTop: 10 }}
                                    value={this.state.question_desc}
                                    onChange={(e) => { this.setState({ question_desc: e.target.value }) }}
                                />
                            </div> */}

                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 25, }}>
                                    Options..
                                </Typography>
                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "10px" }}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* {this.state.option1_desc !== '' && */}
                                        <ReactQuill style={{ height: "50px", width: "550px", marginTop: 10 }}
                                            value={this.state.option1_desc}
                                            onChange={this.handleEditorChange1}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option1_url !== "" && this.props.new_opation1_img !== "" && <img alt='' src={this.props.admin.new_opation1_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option1_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option1_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option1img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                </Grid>
                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option2}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* {this.state.option2_desc !== '' && */}
                                        <ReactQuill style={{ height: "50px", width: "550px", marginTop: 10 }}
                                            value={this.state.option2_desc}
                                            onChange={this.handleEditorChange2}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option2_url !== "" && this.props.new_option2_url !== "" && <img alt='' src={this.props.admin.new_option2_url} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option2_img !== "" && this.state.b === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option2_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option1img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                </Grid>
                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option3}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* {this.state.option3_desc !== '' && */}
                                        <ReactQuill style={{ height: "50px", width: "550px", marginTop: 10 }}
                                            value={this.state.option3_desc}
                                            onChange={this.handleEditorChange3}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option3_url !== "" && this.props.new_opation3_img !== "" && <img alt='' src={this.props.admin.new_opation3_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option3_img !== "" && this.state.d === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option3_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option3img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                </Grid>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option4}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* {this.state.option4_desc !== '' && */}
                                        <ReactQuill style={{ height: "50px", width: "550px", marginTop: 10 }}
                                            value={this.state.option4_desc}
                                            onChange={this.handleEditorChange4}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option4_url !== "" && this.props.new_opation4_img !== "" && <img alt='' src={this.props.admin.new_opation4_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option4_img !== "" && this.state.e === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option4_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option4img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                </Grid>
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                            Option:{this.state.option5}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {/* {this.state.option4_desc !== '' && */}
                                        <ReactQuill style={{ height: "50px", width: "550px", marginTop: 10 }}
                                            value={this.state.option5_desc}
                                            onChange={this.handleEditorChange5}
                                            modules={addquiz.modules}
                                            formats={addquiz.formats}
                                        />
                                        {/* } */}
                                        {this.state.new_option5_url !== "" && this.props.new_opation5_img !== "" && this.state.new_option5_url !== " " && this.props.new_opation5_img !== " " && this.state.new_option5_url !== null && this.props.new_opation5_img !== null && this.state.new_option5_url !== undefined && this.props.new_opation5_img !== undefined && <img alt='' src={this.props.admin.new_opation5_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                                        {this.state.option5_img !== "" && this.state.g === "" && this.state.option5_img !== " " && this.state.g === " " && this.state.option5_img !== null && this.state.g === null && this.state.option5_img !== undefined && this.state.g === undefined && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.option5_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        option5img: true,

                                                    });
                                                }}
                                                style={{ marginTop: "24%", height: "0%" }}
                                            >
                                                <img alt='' src={Edit} height={24} width={24} />
                                            </IconButton></Grid>}
                                    </Grid>
                                </Grid>
                            </div>






                            <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "85px" }}>
                                <Typography style={{ color: "#6257cd", fontFamily: 'Poppins', fontWeight: "bold ", fontSize: 13 }}>
                                    Correct Option
                                </Typography>

                                <div class="input-group mb-3" style={{ marginLeft: "15px", marginTop: "20px" }}>

                                    <select
                                        value={this.state.correct_ans}
                                        style={{
                                            background: "white",
                                            height: '50px',
                                            width: "85%",
                                            border: "1px solid #888",
                                            fontSize: 16,
                                            borderRadius: 5,
                                            padding: 7,
                                            color: 'gray'
                                        }}
                                        select

                                        onChange={(e) => {
                                            this.setState({ correct_ans: e.target.value });

                                        }}
                                    >
                                        <option value="" >Select Right Answer</option>
                                        {this.state.rightAnswers.map((e) => (
                                            <option value={e.option}>{e.option}</option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                            {this.state.new_ans_url !== "" && this.props.new_answer_img !== "" && <img alt='' src={this.props.admin.new_answer_img} height={150} width={440} style={{ marginLeft: "20px", marginTop: "25%" }} />}
                            {this.state.correct_ans_img !== "" && this.state.f === "" && <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><img alt='' src={this.state.correct_ans_img} height={150} width={400} style={{ marginLeft: "20px", marginTop: "25%" }} />

                                <IconButton
                                    onClick={() => {
                                        this.setState({
                                            ansimg: true,

                                        });
                                    }}
                                    style={{ marginTop: "24%", height: "0%" }}
                                >
                                    <img alt='' src={Edit} height={24} width={24} />
                                </IconButton></Grid>}
                            {/* {this.state.correct_ans_desc !== '' && */}
                            <ReactQuill style={{ height: "50px", width: "550px", margin: 15 }}
                                value={this.state.correct_ans_desc}
                                onChange={this.handleEditorChange}
                                modules={addquiz.modules}
                                formats={addquiz.formats}
                            />
                            {/* } */}



                            <center>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ addQuizTQuestion: false, });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "10px", marginTop: "90px", borderColor: "#6257CD" }}>Close</Button>
                                <Button

                                    onClick={() => {
                                        // this.props.add_update_testseries_quiz(
                                        // this.state.question_id,
                                        const uniqueId = uuidv4();
                                        const questionData = {
                                            uniqueId: uniqueId,
                                            qustion_title: this.state.qustions_text,
                                            // this.state.question_desc,
                                            // this.props.admin.new_question_image === "" ?
                                            question_img: this.props.admin.new_question_image,
                                            option1: this.state.option1,
                                            // this.props.admin.new_opation1_img === "" ?
                                            opation1_img: this.props.admin.new_opation1_img,
                                            option1_desc: this.state.option1_desc,
                                            option2: this.state.option2,
                                            // this.props.admin.new_opation2_img === "" ?
                                            opation2_img: this.props.admin.new_opation2_img,
                                            option2_desc: this.state.option2_desc,
                                            option3: this.state.option3,
                                            // this.props.admin.new_opation3_img === "" ?
                                            opation3_img: this.props.admin.new_opation3_img,
                                            option3_desc: this.state.option3_desc,
                                            option4: this.state.option4,
                                            // this.props.admin.new_opation4_img === "" ?
                                            opation4_img: this.props.admin.new_opation4_img,
                                            option4_desc: this.state.option4_desc,
                                            option5: this.state.option5,
                                            // this.props.admin.new_opation4_img === "" ?
                                            opation5_img: this.props.admin.new_opation5_img,
                                            option5_desc: this.state.option5_desc,
                                            answer: this.state.correct_ans,
                                            // this.props.admin.new_answer_img === "" ?
                                            answer_img: this.props.admin.new_answer_img,
                                            editorHtml: this.state.correct_ans_desc,
                                            quiz_id: this.props.params.quiz_id,
                                            subject_id: this.props.admin.subject_id,
                                            section_id: this.props.admin.set_section_id,
                                            question_marks: parseFloat(this.state.questions_marks),
                                            cut_marks: parseFloat(this.state.cuts_marks),
                                            question_time: this.state.question_time,
                                        }
                                        this.createTQuestion(questionData, Qid, Vid, Lang);
                                        this.setState({ qustions_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "", question_marks: "", option5_desc: "", option5_img: "", new_opation5_img: "", })

                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "10px", marginTop: "90px", color: "white", backgroundColor: "blue" }}>Save & Next</Button>
                                {/* <Link to='/reviewTquiz'> */}

                                <Button

                                    // onClick={() => {
                                    //     if (
                                    //         (this.state.qustions_text !== "<p><br></p>" || this.state.qustions_text !== "") ||
                                    //         (this.state.option1_desc !== "<p><br></p>" || this.state.option1_desc !== "") ||
                                    //         (this.state.option2_desc !== "<p><br></p>" || this.state.option2_desc !== "") ||
                                    //         (this.state.option3_desc !== "<p><br></p>" || this.state.option3_desc !== "") ||
                                    //         (this.state.option4_desc !== "<p><br></p>" || this.state.option4_desc !== "") ||
                                    //         // this.state.option5_desc !== "<p><br></p>" ||
                                    //         (this.state.correct_ans_desc !== "<p><br></p>" || this.state.correct_ans_desc !== "")
                                    //     ) {
                                    //         
                                    //         this.handleCreateQuestionAndQuiz()
                                    //     } else {
                                    //         this.props.update_section(this.props.admin.tquiz_id, this.state.section)
                                    //         this.setState({ section: "", addQuizTQuestion: false })
                                    //     }

                                    // }}
                                    onClick={() => {
                                        // this.props.add_update_testseries_quiz(
                                        // this.state.question_id,
                                        if (this.state.qustions_text !== "" || this.state.option1_desc !== "") {
                                            const uniqueId = uuidv4();
                                            const questionData = {
                                                uniqueId: uniqueId,
                                                qustion_title: this.state.qustions_text,
                                                // this.state.question_desc,
                                                // this.props.admin.new_question_image === "" ?
                                                question_img: this.props.admin.new_question_image,
                                                option1: this.state.option1,
                                                // this.props.admin.new_opation1_img === "" ?
                                                opation1_img: this.props.admin.new_opation1_img,
                                                option1_desc: this.state.option1_desc,
                                                option2: this.state.option2,
                                                // this.props.admin.new_opation2_img === "" ?
                                                opation2_img: this.props.admin.new_opation2_img,
                                                option2_desc: this.state.option2_desc,
                                                option3: this.state.option3,
                                                // this.props.admin.new_opation3_img === "" ?
                                                opation3_img: this.props.admin.new_opation3_img,
                                                option3_desc: this.state.option3_desc,
                                                option4: this.state.option4,
                                                // this.props.admin.new_opation4_img === "" ?
                                                opation4_img: this.props.admin.new_opation4_img,
                                                option4_desc: this.state.option4_desc,
                                                option5: this.state.option5,
                                                // this.props.admin.new_opation4_img === "" ?
                                                opation5_img: this.props.admin.new_opation5_img,
                                                option5_desc: this.state.option5_desc,
                                                answer: this.state.correct_ans,
                                                // this.props.admin.new_answer_img === "" ?
                                                answer_img: this.props.admin.new_answer_img,
                                                editorHtml: this.state.correct_ans_desc,
                                                quiz_id: this.props.params.quiz_id,
                                                subject_id: this.props.admin.subject_id,
                                                section_id: this.props.admin.set_section_id,
                                                question_marks: parseFloat(this.state.questions_marks),
                                                cut_marks: parseFloat(this.state.cuts_marks),
                                                question_time: this.state.question_time,
                                            }
                                            this.createTQuestion(questionData, Qid, Vid, Lang);
                                            this.setState({
                                                submitTest: true,
                                                qustions_text: "", question_desc: "", question_img: "", new_question_image: "", option1_img: "", new_opation1_img: "", option2_img: "", new_opation2_img: "", option3_img: "", new_opation3_img: "", option4_img: "", new_opation4_img: "", correct_ans_img: "", new_answer_img: "", correct_ans_desc: "", option4_desc: "", correct_ans: "", option3_desc: "", option2_desc: "", option1_desc: "", option5_desc: "", option5_img: "", new_opation5_img: "",
                                            })
                                        } else {
                                            this.setState({ submitTest: true, })
                                        }


                                    }}

                                    style={{ marginLeft: "20px", marginBottom: "10px", marginTop: "90px", color: "white", backgroundColor: "#C0392B" }}>Save & Review</Button>
                                {/* </Link> */}
                            </center>

                        </Grid>

                    </Grid>

                </Dialog>



                {/* Test Subject */}
                <Dialog

                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Subject"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchsubject(this.props.admin.all_subjects, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(this.props.admin.subject_filtered) && this.props.admin.subject_filtered.map((e, index) => {
                                // 
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        <Checkbox
                                            value={e.subject_name}
                                            // onClick={(p) => { this.Checkedsubject(p, index, admin.all_subject) }}
                                            onClick={() => {
                                                // Ensure subject is initialized as an array
                                                // if (!this.state.subject || !Array.isArray(this.state.subject)) {
                                                //     this.setState({ subject: [] });
                                                // }

                                                // Check for duplicate _id in the subject array
                                                if (!this.state.subject.some(chapter => chapter._id === e._id)) {
                                                    // Update state to include the new element
                                                    this.setState(prevState => ({
                                                        subject: [...prevState.subject, e]
                                                    }));
                                                } else {
                                                    // Handle duplicate value (e.g., display an error, log a message)

                                                }
                                                // Additional code/logic can go here
                                            }}



                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {

                                    this.setState({ subjectmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>







                {/*-------------------------------------------- ----------------------------------------------------------------- */}




                <Dialog
                    open={this.state.image}
                    style={{ height: "500px", }}
                >

                    <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                        {this.state.question_url !== "" && this.props.admin.new_question_image !== "" && <img src={this.props.admin.new_question_image} height={150} width={440} style={{ marginLeft: "20px" }} />}
                        {this.state.question_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"

                                onChange={(e) => {
                                    this.setState({ question_url: e.target.files[0] });


                                }}

                            />
                        </Button>}

                        <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                            {this.state.question_url !== "" && <Button variant="outlined" component="label" className='upload'
                                style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Successfully uploaded

                            </Button>}
                            {this.state.question_url !== "" && <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ question_url: "", a: "" });
                                    this.props.resetEditedquestionimage()
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                            >
                                Clear

                            </Button>}
                            {this.state.question_url !== "" && this.state.ques === "" && <Button variant="outlined" component="label"
                                onClick={() => {
                                    // this.setState({ ques: "1" });
                                    this.props.EditUploadQuestion(this.state.question_url)
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                            >
                                Upload

                            </Button>}
                        </Grid>
                        <center>

                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ image: false, ques: "" });
                                    this.props.resetEditedquestionimage();

                                }}
                                style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    this.setState({ image: false, a: "1", question_img: "" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>

                <Dialog
                    open={this.state.delete}
                // style={{ width: "600px" }}
                >
                    <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold", color: "#C0392B" }}>Warning!</Typography>
                    <Grid style={{ backgroundColor: "#fff", padding: " 1em 6em", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <Typography style={{ fontWeight: "bold", color: "#141226", marginTop: "30px", marginLeft: "56px", textAlign: "justify", marginRight: "24px", }}>Are You sure ? </Typography>
                            <center>
                                <Button
                                    // className='btns'
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ delete: false });
                                    }}
                                    style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                <Button
                                    // className='btns'

                                    // disabled={this.state.category_name === ""}
                                    onClick={() => {
                                        this.props.delete_question_update_testseries_quiz(this.state.Deleteid, this.props.params.quiz_id, this.state.tssection,
                                            // this.props.admin.set_section_id,
                                            // this.props.admin.set_subject_id === "" ?
                                            this.state.tssubject,
                                            // this.props.admin.set_subject_id,
                                            // this.props.admin.set_language_id === "" ?
                                            this.state.tslang,);
                                        this.setState({ delete: false });
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                            </center>
                        </Grid>

                    </Grid>

                </Dialog>





                <Dialog
                    open={this.state.option1img}
                    style={{ height: "500px", }}
                >

                    <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                        {this.state.new_option1_url !== "" && this.props.admin.new_opation1_img !== "" && <img alt='' src={this.props.admin.new_opation1_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                        {this.state.new_option1_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                onChange={(e) => {
                                    this.setState({ new_option1_url: e.target.files[0] });


                                }}

                            />
                        </Button>}

                        <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                            {this.state.new_option1_url !== "" && <Button variant="outlined" component="label" className='upload'
                                style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Successfully uploaded

                            </Button>}
                            {this.state.new_option1_url !== "" && <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ new_option1_url: "", });
                                    this.props.resetEditedValueopation1()
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                            >
                                Clear

                            </Button>}
                            <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ opt1: "1", });
                                    this.props.EditUploadoption1(this.state.new_option1_url)
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                            >
                                Upload

                            </Button>
                        </Grid>
                        <center>

                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ option1img: false, opt1: "" });
                                    this.props.resetEditedValueopation1();

                                }}
                                style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                    this.setState({ option1img: false, b: "1" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>
                <Dialog
                    open={this.state.option2img}
                    style={{ height: "500px", }}
                >

                    <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                        {this.state.new_option2_url !== "" && this.props.admin.new_opation2_img !== "" && <img alt='' src={this.props.admin.new_opation2_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                        {this.state.new_option2_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                onChange={(e) => {
                                    this.setState({ new_option2_url: e.target.files[0] });


                                }}

                            />
                        </Button>}

                        <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                            {this.state.new_option2_url !== "" && <Button variant="outlined" component="label" className='upload'
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Successfully uploaded
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                            </Button>}
                            {this.state.new_option2_url !== "" && <Button variant="outlined" component="label"
                                // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                onClick={() => {
                                    this.setState({ new_option2_url: "", });
                                    this.props.resetEditedValueopation2()
                                }}

                                // className='upload' 
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                            // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                            >
                                Clear
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                            </Button>}
                            <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ opt2: "1", });
                                    this.props.EditUploadoption2(this.state.new_option2_url)
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                            >
                                Upload

                            </Button>
                        </Grid>
                        <center>

                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ option2img: false, opt2: "" });

                                    this.props.resetEditedValueopation2();

                                }}
                                style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                    this.setState({ option2img: false, c: "1" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>
                <Dialog
                    open={this.state.option3img}
                    style={{ height: "500px", }}
                >

                    <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                        {this.state.new_option3_url !== "" && this.props.admin.new_opation3_img !== "" && <img alt='' src={this.props.admin.new_opation3_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                        {this.state.new_option3_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                onChange={(e) => {
                                    this.setState({ new_option3_url: e.target.files[0] });


                                }}

                            />
                        </Button>}

                        <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                            {this.state.new_option3_url !== "" && <Button variant="outlined" component="label" className='upload'
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Successfully uploaded
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                            </Button>}
                            {this.state.new_option3_url !== "" && <Button variant="outlined" component="label"
                                // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                onClick={() => {
                                    this.setState({ new_option3_url: "", });
                                    this.props.resetEditedValueopation3()
                                }}

                                // className='upload' 
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                            // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                            >
                                Clear
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                            </Button>}
                            <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ opt3: "1", });
                                    this.props.EditUploadoption3(this.state.new_option3_url)
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                            >
                                Upload

                            </Button>
                        </Grid>
                        <center>

                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ option3img: false, opt3: "" });

                                    this.props.resetEditedValueopation3();

                                }}
                                style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                    this.setState({ option3img: false, d: "1" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>
                <Dialog
                    open={this.state.option4img}
                    style={{ height: "500px", }}
                >

                    <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                        {this.state.new_option4_url !== "" && this.props.admin.new_opation4_img !== "" && <img alt='' src={this.props.admin.new_opation4_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                        {this.state.new_option4_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                onChange={(e) => {
                                    this.setState({ new_option4_url: e.target.files[0] });


                                }}

                            />
                        </Button>}

                        <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                            {this.state.new_option4_url !== "" && this.state.new_option4_url !== " " && this.state.new_option4_url !== undefined && this.state.new_option4_url !== null && <Button variant="outlined" component="label" className='upload'
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Successfully uploaded
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                            </Button>}
                            {this.state.new_option4_url !== "" && this.state.new_option4_url !== " " && this.state.new_option4_url !== null && this.state.new_option4_url !== undefined && <Button variant="outlined" component="label"
                                // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                onClick={() => {
                                    this.setState({ new_option4_url: "", });
                                    this.props.resetEditedValueopation4()
                                }}

                                // className='upload' 
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                            // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                            >
                                Clear
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                            </Button>}
                            <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ opt4: "1", });
                                    this.props.EditUploadoption4(this.state.new_option4_url)
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                            >
                                Upload

                            </Button>
                        </Grid>
                        <center>

                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ option4img: false, opt4: "" });

                                    this.props.resetEditedValueopation4();
                                }}
                                style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                    this.setState({ option4img: false, e: "1" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>
                <Dialog
                    open={this.state.option5img}
                    style={{ height: "500px", }}
                >

                    <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                        {this.state.new_option5_url !== "" && this.props.admin.new_opation5_img !== "" && <img alt='' src={this.props.admin.new_opation5_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                        {this.state.new_option5_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                onChange={(e) => {
                                    this.setState({ new_option5_url: e.target.files[0] });


                                }}

                            />
                        </Button>}

                        <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                            {this.state.new_option5_url !== "" && <Button variant="outlined" component="label" className='upload'
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Successfully uploaded
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}

            /> */}
                            </Button>}
                            {this.state.new_option5_url !== "" && <Button variant="outlined" component="label"
                                // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                onClick={() => {
                                    this.setState({ new_option5_url: "", });
                                    this.props.resetEditedValueopation5()
                                }}

                                // className='upload' 
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                            // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                            >
                                Clear
                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                                {/* <input hidden type="file" multiple
                accept="video/mp4,video/x-m4v,video/*"
                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                
            /> */}
                                {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                            </Button>}
                            <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ opt5: "1", });
                                    this.props.EditUploadoption5(this.state.new_option5_url)
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                            >
                                Upload

                            </Button>
                        </Grid>
                        <center>

                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ option5img: false, opt5: "" });

                                    this.props.resetEditedValueopation5();
                                }}
                                style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                    this.setState({ option5img: false, g: "1" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>
                <Dialog
                    open={this.state.ansimg}
                    style={{ height: "500px", }}
                >

                    <Grid className='boxnewdesign' style={{ height: "400px", minWidth: "600px", scrollBehavior: "smooth", alignSelf: "center", flexDirection: "column", display: "flex", overflow: "hidden", border: "2px solid #888" }}>


                        {/* <Typography style={{ color: "#6257CD", fontFamily: 'Poppins', margin: "15px", fontWeight: "bold" }}>
                            Add Quiz title & Description
                        </Typography> */}

                        {this.state.new_ans_url !== "" && this.props.admin.new_answer_img !== "" && <img alt='' src={this.props.admin.new_answer_img} height={150} width={440} style={{ marginLeft: "20px" }} />}
                        {this.state.new_ans_url === "" && <Button variant="outlined" component="label" className='upload' style={{ width: "93%", marginTop: "10%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                            startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>

                            Upload
                            {/* <input hidden accept="image/*" multiple type="file" /> */}
                            <input hidden type="file" multiple
                                accept="application/pdf,application/vnd.ms-excel,image/png, image/jpeg"
                                // onchange = {(e)=>{this.setState({video_url:e.target.file[0]})}}
                                // onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                onChange={(e) => {
                                    this.setState({ new_ans_url: e.target.files[0] });


                                }}

                            />
                        </Button>}

                        <Grid style={{ display: "flex", flexDirection: "row", marginBottom: "40px" }}>

                            {this.state.new_ans_url !== "" && <Button variant="outlined" component="label" className='upload'
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "73%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "38px", backgroundColor: "#fff", textTransform: "capitalize", }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>

                                Successfully uploaded

                            </Button>}
                            {this.state.new_ans_url !== "" && <Button variant="outlined" component="label"
                                // onChange={(e)=> { this.setState({ add_qustion_url:"" }) }}
                                onClick={() => {
                                    this.setState({ new_ans_url: "", });
                                    this.props.resetEditedValueanswer()
                                }}

                                // className='upload' 
                                //    style={{width:"95%",marginTop:"5%",marginLeft:"4.5%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between",color:"#c4c4c4",borderColor:"#c4c4c4",height:"50px",backgroundColor:"#fff",textTransform:"capitalize"}}
                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "red", textTransform: "capitalize", }}
                            // startIcon={<CheckIcon style={{height:24,color:"#6257CD"}}/>}
                            >
                                Clear

                                {/* onChange={(e) => { this.setState({ add_qustion_url: "" }) }} */}
                            </Button>}
                            {this.state.new_ans_url !== "" && this.state.new_ans === "" && <Button variant="outlined" component="label"
                                onClick={() => {
                                    this.setState({ new_ans: "1", });
                                    this.props.EditUploadANSimg(this.state.new_ans_url)
                                }}

                                style={{ width: "0%", marginTop: "2%", marginLeft: "2%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#fff", height: "38px", backgroundColor: "#6257CD", textTransform: "capitalize", }}

                            >
                                Upload

                            </Button>}
                        </Grid>
                        <center>

                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ ansimg: false, new_ans: "" });
                                    this.props.resetEditedValueanswer();
                                }}
                                style={{ marginLeft: "30px", color: "#6257CD", marginBottom: "30px", marginTop: "60px", borderColor: "#6257CD" }}>close</Button>
                            <Button

                                onClick={() => {
                                    // this.props.updatequiz(this.props.admin.quiz_id, this.state.quiz_name, this.state.quiz_desc)
                                    this.setState({ ansimg: false, f: "1" })
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "40px", color: "white", backgroundColor: "#C0392B" }}>submit</Button>
                        </center>
                    </Grid>


                </Dialog>





                <Box className='saveandcnts'>

                    <Link to='/testquiz'>
                        <Button style={{ backgroundColor: "#6257CD", color: "white ", textTransform: "capitalize", width: "100%" }}
                            onClick={() => {
                                this.props.updateTQuizSubject(this.props.params.quiz_id, this.props.admin.all_tquiz_by_tid.section)
                                this.setState({ subject: "", tslang: "", tssubject: "", tssection: "", })
                            }}>Done</Button>
                    </Link>

                </Box>



                <Loader />
                <Dialog
                    open={this.state.timemodal}
                    style={{ height: "100%" }}
                >
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid style={{ justifyContent: "space-around", marginLeft: "3%", marginTop: "1%" }}>
                            <Grid>
                                <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                                    Enter Section Time:
                                </Typography>
                            </Grid>
                            <Grid>
                                <FormControl style={{ width: '30vw', marginLeft: "3%" }}>
                                    <TextField
                                        placeholder="Enter Section Time In Minute"
                                        onChange={this.handleDescriptionChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ timemodal: false, section_time: '' });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}
                            >
                                Close
                            </Button>

                            <Button
                                className='btns'
                                onClick={() => {
                                    this.handleSave();
                                    this.setState({ timemodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}
                            >
                                Save
                            </Button>
                        </center>
                    </div>
                </Dialog>

                <Dialog

                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Subject"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(event) => this.props.searchsubject(admin.all_subject, event.target.value)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(this.props.admin.subject_filtered) && this.props.admin.subject_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        <Checkbox
                                            value={e.subject_name}
                                            checked={this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.some(item2 => e?._id === item2?._id)}
                                            // checked={this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.some(item2 => e?._id === item2?._id)}
                                            onClick={() => {
                                                const updatedSection = Array.isArray(this.props.admin.all_tquiz_by_tid?.section) ? [...this.props.admin.all_tquiz_by_tid?.section] : [];
                                                const currentLesson = updatedSection?.[Qid];

                                                if (!currentLesson?.hasOwnProperty('subject')) {
                                                    currentLesson.subject = [];
                                                }

                                                const videoAlreadyExists = currentLesson.subject && currentLesson.subject.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    // Add the entire subject object to the subject array
                                                    currentLesson.subject = [...(currentLesson.subject || []), e];
                                                } else {
                                                    // Remove the entire subject object from the subject array
                                                    currentLesson.subject = currentLesson.subject.filter(video => video._id !== e._id);
                                                }

                                                // Update the checked state using callback form of setState
                                                this.setState({
                                                    section: updatedSection,
                                                    subjectChecked: updatedSection?.[Qid]?.subject?.map(subject => subject.subject_name) || [],
                                                });
                                            }}
                                        />




                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ subjectmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                <Dialog

                    open={this.state.sectionmodal}
                    style={{ height: "100%" }}
                // fullScreen={"100px"}

                // fullWidth={true}
                >
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#EEECFE",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Section "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    // onChange={(e) => this.props.searchSection(admin.all_section, e)}
                                    onChange={(event) => this.props.searchSection(admin.all_section, event.target.value)}
                                />
                            </Search>
                        </Grid>

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.section_filter) && admin.section_filter.map((e, index) => (
                                <div key={index} style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                    <Typography style={{ color: "#000", margin: "10px" }}>{e.section_name}</Typography>

                                    <Checkbox
                                        value={e.section_name}
                                        checked={this.props.admin.all_tquiz_by_tid?.section?.some(item2 => e?._id === item2?._id)}
                                        onClick={() => {
                                            // this.props.viewAllTests();
                                            const currentLesson = this.props.admin.all_tquiz_by_tid;

                                            if (!currentLesson.hasOwnProperty('section')) {
                                                currentLesson.section = [];
                                            }

                                            const videoAlreadyExists = currentLesson.section && currentLesson.section.some(video => video._id === e._id);

                                            if (!videoAlreadyExists) {
                                                // Check the item
                                                currentLesson.section = [...(currentLesson.section || []), e];

                                                // Update the checked state
                                                this.setState((prevState) => ({
                                                    sectionChecked: [...prevState.sectionChecked, e.section_name],
                                                }));
                                            } else {
                                                // Uncheck the item
                                                currentLesson.section = currentLesson.section.filter(video => video._id !== e._id);

                                                // Update the checked state
                                                this.setState((prevState) => ({
                                                    sectionChecked: prevState.sectionChecked.filter(type => type !== e.section_name),
                                                }));
                                            }
                                        }}
                                    />




                                </div>
                            ))}

                        </Grid>


                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ sectionmodal: false });
                                    this.props.viewAllSection();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                onClick={() => {
                                    // this.setState({ chap: "1" })
                                    // this.props.setChapter(this.state.userinfo);
                                    this.setState({ sectionmodal: false });
                                    this.props.viewAllSection();
                                }}
                                // style={{ marginLeft: "450px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Submit</Button>
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>

                        </center>
                    </div>

                </Dialog>



                <Dialog

                    open={this.state.languagemodal}
                    style={{ height: "100%" }}>

                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Exam "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchlanguage(admin.all_language, e)}
                                />
                            </Search>
                        </Grid>


                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.filter_language) && admin.filter_language?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.language}</Typography>

                                        <Checkbox
                                            value={e.language}
                                            checked={
                                                this.props.admin.all_tquiz_by_tid?.section?.[Qid]?.subject?.[Vid]?.language?.some(item2 => e?._id === item2?._id)
                                            }
                                            onClick={() => {
                                                const updatedSection = Array.isArray(this.props.admin.all_tquiz_by_tid?.section) ? [...this.props.admin.all_tquiz_by_tid?.section] : [];
                                                const currentLesson = updatedSection?.[Qid]?.subject?.[Vid];

                                                if (!currentLesson?.hasOwnProperty('language')) {
                                                    currentLesson.language = [];
                                                }

                                                const videoAlreadyExists = currentLesson.language && currentLesson.language.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    // Add the entire subject object to the subject array
                                                    currentLesson.language = [...(currentLesson.language || []), e];
                                                } else {
                                                    // Remove the entire subject object from the subject array
                                                    currentLesson.language = currentLesson.language.filter(video => video._id !== e._id);
                                                }

                                                // Update the checked state using callback form of setState
                                                this.setState({
                                                    section: updatedSection,
                                                    languageChecked: updatedSection?.[Qid]?.subject?.[Vid]?.language.map(subject => subject.language) || [],
                                                });
                                            }}
                                        />



                                    </div>
                                );
                            })}
                        </Grid>

                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>

                <Dialog

                    open={this.state.submitTest}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "300px", overflowX: "hidden" }}>

                        <Typography style={{ color: "#000", fontFamily: 'Poppins', margin: "15px", fontWeight: "500" }}>
                            Quiz Added SuccesFully
                        </Typography>

                        <center>
                            {/* <Link to='/reviewTquiz'> */}
                            <Button
                                className='btns'
                                onClick={() => {
                                    this.props.update_section(this.props.params.quiz_id, this.props.admin.all_tquiz_by_tid.section)
                                    this.setState({ submitTest: false, addQuizTQuestion: false, questions_marks: "", cuts_marks: "", });

                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>OK</Button>
                            {/* </Link> */}
                        </center>
                    </div>

                </Dialog>

            </Grid>



        )
    }
}
addquiz.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
    ],
};

addquiz.formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image',
];

export default withParams(withStyles(styles)(addquiz));