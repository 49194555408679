import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography

} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";



export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            // exam_name: ""
            student_id: ""

        }
    }

    componentDidMount() {
        this.props.view_all_answar();
        
    }

    render() {
        const {
            students,
            admin,
            snackbar,
            close_snack_bar
        } = this.props;

        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%", textAlign: 'center', }}>
                    <Box className='categoryname' >
                        <center>
                            <Button style={{ color: "white ", textAlign: "center",marginTop:"8px"}}>Answer Sheet</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>
                        </center>
                    </Box>
                </Grid>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell style={{ textAlign: 'left' }} >Student Details</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Quiz Details</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Array.isArray(admin.all_student) && admin.all_student.sort((a, b) => a.student_detail.student_name?.localeCompare(b.student_detail.student_name)).map((item, index) => {
                                            
                                            return (
                                                <TableRow>
                                                    
                                                    <TableCell style={{ textAlign: 'left' }} >{item?.student_detail?.student_name !== undefined && item?.student_detail?.student_name}</TableCell> 
                                                    <TableCell style={{ textAlign: 'center' }} >{item?.quiz_detail?.quiz_name !== undefined && item?.quiz_detail?.quiz_name}</TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>

                </Box>
                <LoaderCon />
                {/* <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          /> */}

            </Grid>
        )
    }
}