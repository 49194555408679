import React, { Component } from "react";
import { connect } from "react-redux";
import Quiz from "../component/quiz";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createquiz,
  updatequiz,
  deletequiz,
  viewAllquiz,
  setID,
  searchquizes,
  setUUID
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Quiz {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    setUUID: (id) => {
      dispatch(setUUID(id))
    },
    createquiz: (quiz_name,set,questions) => {
      dispatch(createquiz(quiz_name,set,questions))
    },
    updatequiz: (quiz_id,quiz_name,set,questions) => {
      dispatch(updatequiz(quiz_id,quiz_name,set,questions))
    },
    deletequiz: (quiz_id) => {
      dispatch(deletequiz(quiz_id))
    },
    viewAllquiz: () => {
      dispatch(viewAllquiz())
    },
    setID: (payload) => {
      dispatch(setID(payload))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    searchquizes: (all_quizes, e) => {
      dispatch(searchquizes(all_quizes, e))
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);