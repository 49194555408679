import React, { Component } from "react";
import { connect } from "react-redux";
import Package from "../component/Package";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createChapter,
    updateChapter,
    deleteChapter,
    viewAllChapter,
    viewAllPackage,
    deletepackage,
    updatePackage,
    searchPackages,
    setpackageId
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Package {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    setpackageId: (c) => {
      dispatch(setpackageId(c))
    },
    createChapter: (chapter_name) => {
      dispatch(createChapter(chapter_name))
    },
    updateChapter: (chapter_id,chapter_name) => {
      dispatch(updateChapter(chapter_id,chapter_name))
    },
    deleteChapter: (chapter_id) => {
      dispatch(deleteChapter(chapter_id))
    },
    viewAllChapter: () => {
      dispatch(viewAllChapter())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllPackage: () => {
      dispatch(viewAllPackage())
    },
    deletepackage: (package_id) => {
      dispatch(deletepackage(package_id))
    },
    updatePackage: (student_special, package_id) => {
      dispatch(updatePackage(student_special, package_id))
    },
    searchPackages:(all_packages,e) => {
      dispatch(searchPackages(all_packages,e))
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);