import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    DialogActions,
    DialogTitle,
    Tooltip,
    Typography

} from "@mui/material";
import "../style/category.css"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Delete from '../images/trash.svg';
import Edit from '../images/pencil.svg';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Pagination from '@mui/material/Pagination';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Search from "../../admin/images/search-normal.png";
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            subject_name: "",
            page: 0,
            setPage: 0,
            rowsPerPage: 30,
            searchTerm: ''

        }

        this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 500);
    }


    debouncedSearch(value) {
        this.props.viewAllSubject(this.props.page, this.props.rowsPerPage, value);
    }

    handleSearchChange = (event) => {
        const value = event.target.value;
        this.setState({ searchTerm: value });
        this.debouncedSearch(value);
    };

    componentDidMount() {
        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
    }



    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;

        const handleChangePage = (event, newPage) => {
            this.props.viewAllSubject(newPage - 1, this.state.rowsPerPage, "");
            this.setState({
                page: newPage,

            });

        };
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%" }}>
                    <Box className='categoryname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Subject</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='categoryadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}>
                                <IconButton>
                                    <AddIcon style={{ color: 'white' }} />
                                </IconButton>
                                Add Subject
                            </Button>
                        </center>
                    </Box>
                </Grid>
                <Paper
                    component="form"
                    style={{
                        marginTop: "5%",
                        display: "flex",
                        width: "350px",
                        height: "42px",
                        marginLeft: "4%",
                        boxShadow: "none",
                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                    }}
                >
                    <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                        <SearchIcon style={{ fontSize: "25px" }} />
                    </IconButton>
                    <InputBase
                        className="search-text"
                        placeholder="Search"
                        inputProps={{ "aria-label": "Search" }}
                        value={this.state.searchTerm}
                        onChange={this.handleSearchChange}
                    />
                </Paper>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell>Subject Name</TableCell>
                                            <TableCell style={{ paddingLeft: "22%" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="">
                                        {Array.isArray(admin.all_subject !== undefined && admin.all_subject) && admin.all_subject.sort((a, b) => a.subject_name?.localeCompare(b.subject_name)).map((c) => {
                                            return (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell >{c.subject_name}</TableCell>
                                                    <TableCell style={{ paddingLeft: "20%" }} >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        edit: true,
                                                                        subject_id: c._id,
                                                                        subject_name: c.subject_name,
                                                                    });
                                                                }}
                                                                style={{ marginLeft: "-10px" }}
                                                            >
                                                                {/* <EditIcon style={{ color: 'green' }} /> */}
                                                                <img src={Edit} height={24} width={24} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delete: true,
                                                                        subject_name: c.subject_name,
                                                                        subject_id: c._id,
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Delete} height={24} width={24} />
                                                                {/* <span style={{color:"red",fontWeight:"bold"}} class="material-symbols-outlined">
                                                            delete
                                                        </span> */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </CardContent>
                        <div style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                            <Pagination
                                count={parseInt(admin.subject_length / this.state.rowsPerPage) + 1}
                                siblingCount={0}
                                size="small"
                                onChange={handleChangePage}
                            />
                        </div>
                    </Box>



                    <Dialog
                        open={this.state.add}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Add Subject name</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "160px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter subject name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        onChange={(e) => { this.setState({ subject_name: e.target.value }) }}
                                    />
                                </div>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ add: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        disabled={this.state.subject_name === ""}
                                        onClick={() => {
                                            this.props.createSubject(this.state.subject_name, this.state.page, this.state.rowsPerPage);
                                            this.setState({ add: false });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.edit}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px" }}>Edit Subject name</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "160px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                    <TextField
                                        required
                                        // id="outlined-required"
                                        // label="Category Name"
                                        placeholder='Enter Subject name'
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "450px" }}
                                        value={this.state.subject_name}
                                        onChange={(e) => { this.setState({ subject_name: e.target.value }) }}
                                    />
                                </div>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>Close</Button>
                                    <Button
                                        className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => {
                                            this.props.updateSubject(this.state.subject_id, this.state.subject_name, this.state.page, this.state.rowsPerPage);
                                            this.setState({ edit: false });
                                        }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Edit/Submit</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        style={{ height: "600px" }}>
                        <Typography style={{ color: "#000", marginTop: "30px", marginLeft: "24px", fontWeight: "bold" }}>Warning!</Typography>
                        <Grid style={{ backgroundColor: "#fff", height: "330px", minWidth: "480px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>We would like to confirm that the course <b>"{this.state.subject_name}"</b> has been successfully deleted from the admin panel as per your request.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>Please note that all associated data and information  related to this field have been permanently removed from our system and    cannot be recovered.</Typography>
                                <Typography style={{ color: "#141226", marginTop: "30px", marginLeft: "24px", textAlign: "justify", marginRight: "24px", }}>If you have any further queries or concerns regarding  this deletion, please do not hesitate to contact us.</Typography>
                                <center>
                                    <Button
                                        // className='btns'
                                        variant="outlined"
                                        onClick={() => {
                                            this.setState({ delete: false });
                                        }}
                                        style={{ marginLeft: "20px", color: "#6257CD", marginBottom: "30px", marginTop: "20px", borderColor: "#6257CD" }}>No</Button>
                                    <Button
                                        // className='btns'

                                        // disabled={this.state.category_name === ""}
                                        onClick={() => { this.props.deleteSubject(this.state.subject_id, this.state.page, this.state.rowsPerPage); this.setState({ delete: false }) }}
                                        style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white", backgroundColor: "#C0392B" }}>Yes, Delete</Button>
                                </center>
                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>
                <LoaderCon />
                {/* <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          /> */}

            </Grid>
        )
    }
}
