import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Snackbar from "../../common/snackbar/components/snackbar";
import "../styles/style.scss";
import LoaderCon from "../../common/loader/containers/loader_cont";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { TextField } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      type: "",
      active: false,
      phone: "",
      showPassword: false
    };
  }
  componentDidMount() {
    this.props.clear_login()

  }
  render() {
    const {
      login,
      snackbar,
      close_snack_bar,
      super_login
    } = this.props;

    if (login.user_id !== "") {
      return <Navigate to="/dashboard" />
    }

    return (

      <Grid className="login-background" >
        <Grid container spacing={10} justifyContent="center" marginTop="-10%" >
          <Grid item md={4} xs={12}>
            <Card style={{
              boxShadow: "0px 4px 4px 4px #00000040",
              width: "105%",
              marginTop: "15%",
              borderradius: 15,
              height: "55%"


            }} className="login_card">
              {/* <img
                  src={{}}
                  alt="logo_img" style={{ height: 169 }}
                /> */}
              <Typography style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Login to Dashboard</Typography>


              <Grid container marginTop={3} style={{
                // boxShadow: "0px 4px 4px 4px #00000040",
                height: "212px",
                width: "90%",
                // marginLeft: "10%",
                top: "234px",
                borderradius: "20px", 'borderRadius': '12px'



              }}>
                <Grid item xs={12} marginTop={0}  >
                  <Typography style={{ fontFamily: "sans-serif", textAlign: "left" }}>Email or Mobile Number</Typography>

                  <TextField
                    placeholder="Please enter your Email ID"
                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                    style={{
                      width: '110%',
                      height: "2%",
                      marginTop: "2%"

                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment position="start">
                        <MarkunreadIcon />
                      </InputAdornment>
                    }
                  />
                </Grid>


                <Grid item xs={12} marginTop={0}>
                    <Typography style={{ fontFamily: "sans-serif", textAlign: "left" ,marginTop:"5%"}}>Password</Typography>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      placeholder="Please enter your Password"

                      type={this.state.showPassword ? 'text' : 'password'}
                      size="small"
                      style={{width:"110%",marginTop:"2%"}}
                      onChange={(e) => { this.setState({ password: e.target.value }) }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { this.setState({ showPassword: !(this.state.showPassword) }) }}
                            edge="end"
                           
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                 />
                </Grid>



                <Grid item xs={12} marginTop={0}>


                  <Button
                    id="outlined-email-input"
                    variant="contained"
                    style={{
                      width: '110%', backgroundColor:"#6257cd",
                      marginTop:"5%",textAlign:"center"
                    }}
                    disabled={this.state.email === "" || this.state.password === ""}
                    onClick={() => { super_login(this.state.email, this.state.password) }} >
                    LOGIN
                  </Button>
                </Grid>
              </Grid>
              <Grid height="128.9432373046875px"
                width="440px"
                background="#FAFAFA"
              ></Grid>

            </Card>
          </Grid>
        </Grid>
        <LoaderCon />
        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />
      </Grid >

    );
  }
}
// class Login extends Component {
//     render(){
//         return(
//             <div>
//                 helloooooooooooooooooo
//             </div>
//         )
//     }
// }



export default Login;