/* eslint-disable no-useless-concat */
import {
    SET_CATEGORY,
    SET_EXAM,
    SET_EXAMS,
    SET_STUDENT,
    SET_STAGE,
    SET_SUBJECT,
    SET_SECTION,
    SET_CHAPTER,
    SET_LESSON,
    SET_VIDEO,
    SET_PACKAGECATEGORY,
    SET_PDF,
    SET_PRIVIOUS_YEAR,
    SET_SELECT_PRIVIOUS_YEAR,
    SET_QUIZ,
    SET_MINIPACKAGE,
    SET_SELECT_CHAPTER,
    SET_BANNER,
    SET_QUESTION,
    SET_QUESTION_IMAGE,
    SET_OPATION1_IMAGE,
    SET_OPATION2_IMAGE,
    SET_OPATION3_IMAGE,
    SET_OPATION4_IMAGE,
    SET_OPATION5_IMAGE,
    SET_ANSWER_IMAGE,
    SET_QUESTION_IMAGE_REMOVE,
    SET_OPATION1_IMAGE_REMOVE,
    SET_OPATION2_IMAGE_REMOVE,
    SET_OPATION3_IMAGE_REMOVE,
    SET_OPATION4_IMAGE_REMOVE,
    SET_OPATION5_IMAGE_REMOVE,
    SET_ANSWER_IMAGE_REMOVE,
    SET_RESSET,
    SET_QUIZ_ID,
    SET_TQUIZ_ID,
    SET_REVIEW_BY_ID,
    SET_REVIEW_BY_TQUIZID,
    SET_REVIEW_QUASTION_ID,
    SET_QUESTION_ID,
    SET_QUESTION_BY_ID,
    SET_CHAPTER_SELECT,
    SET_PRIVIOUS_YEAR_SELECT,
    SET_CHAPTER_SELECT_LESSON,
    SET_VIDEO_SELECT,
    SET_PDF_SELECT,
    SET_QUIZ_SELECT,
    SET_CATEGORY_SELECT,
    SET_EXAM_SELECT,
    SET_STAGE_SELECT,
    SET_SUBJECT_SELECT,
    SET_MINI_SELECT,
    SET_TEST_SELECT,
    SET_TEST_TYPES_SELECT,
    SET_QUIZZ_SELECT,
    SET_EXAMM_SELECT,
    SET_PACKAGE,
    SET_NEW_QUESTION_IMAGE,
    SET_NEW_OPTION1_IMAGE,
    SET_NEW_OPTION2_IMAGE,
    SET_NEW_OPTION3_IMAGE,
    SET_NEW_OPTION4_IMAGE,
    SET_NEW_OPTION5_IMAGE,
    SET_NEW_ANSWER_IMAGE,
    SET_EDITED_QUESTION_IMAGE_REMOVE,
    SET_EDITED_OPATION1_IMAGE_REMOVE,
    SET_EDITED_OPATION2_IMAGE_REMOVE,
    SET_EDITED_OPATION3_IMAGE_REMOVE,
    SET_EDITED_OPATION4_IMAGE_REMOVE,
    SET_EDITED_OPATION5_IMAGE_REMOVE,
    SET_EDITED_ANSWER_IMAGE_REMOVE,
    SET_CHAPTER_FILTERED_VALUE,
    SET_LESSON_FILTERED_VALUE,
    SET_VIDEO_FILTERED_VALUE,
    SET_PDF_FILTERED_VALUE,
    SET_QUIZ_FILTERED_VALUE,
    SET_CATEGORY_FILTERED_VALUE,
    SET_EXAM_FILTERED_VALUE,
    SET_STAGE_FILTERED_VALUE,
    SET_SUBJECT_FILTERED_VALUE,
    SET_MINIPACKAGE_FILTERED_VALUE,
    SET_TESTSERIES,
    SET_PRIVIOUS_YEAR_FILTERED_VALUE,
    SET_TESTS,
    SET_TEST_TYPES,
    SET_TESTS_FILTERED_VALUE,
    SET_TESTTYPE_FILTERED_VALUE,
    SET_TESTSERIES_FILTERED_VALUE,
    SET_TESTSERIES_SELECT,
    SET_TRENDS,
    SET_EXAM_AI,
    SET_TREND_SELECT,
    SET_TREND_FILTERED_VALUE,
    SEARCH_PREVIOUS_YEAR,
    NOTIFICATION,
    SET_EXAMAI_SELECT,
    SET_ALL_EXAM_AI,
    SET_NOTIFICATION_SELECT,
    SET_NOTIFICATION_ID,

    SET_NOT_NAME,
    SET_NOT_DESC,
    SET_NOT_ISSUED_ON,
    SET_APPLICATION_START_DT,
    SET_LAST_DT_TO_APPLY,
    SET_ONLINE_APP_TIME,
    SET_OFFLINE_CHALAN,
    SET_PAY_THROUGH_CHALAN,
    SET_OFFLINE_CHALAN_TIME,
    SET_APP_CORRECT_DT,
    SET_APP_CORRECT_TIME,
    SET_COM_EXAM_DT,
    SET_CON_BODY,
    SET_EXAM_NAME,
    SET_CON_FOR,
    SET_POST_NAME,
    SET_APP_DATE,
    SET_APP_MODE,
    SET_VACANCY,
    SET_SALARY,
    SET_OFFICIAL_NOT_LINK,
    SET_OFFICIAL_LINK,
    SET_FORM_LINK,
    VIEW_ALL_COUPON,
    SET_EXAM_ID,
    SET_MINIPACKAGE_ID,
    SET_MINIPACKAGE_BY_ID,
    REMOVE_ITEM,
    SET_TESTSERIES_ID,
    SET_PREVIOUSYEAR_ID,
    SET_EXAMAI_ID,
    SET_TESTSERIES_IDS,
    ADD_QUIZ_QUESTION,
    SEARCH_PREVIOUS,
    SEARCH_PACKAGES,
    SEARCH_MINIPACKAGE,
    SEATCH_TESTSERIES,
    SEATCH_EXAMAI,
    SET_PACKAGE_BY_ID,
    SEARCH_QUIZES,
    TEST_SEARCH_QUIZES,
    SET_UUID,
    SEARCH_PDFS,
    SET_QUIZ_R_ID,
    SEARCH_VIDEOES,
    SET_SUBJECT_ID,
    SET_SUBJECTS_ID,
    VIEW_SUBJECT_BY_QUIZ,
    SET_ALL_LESSON,
    VIEW_ALL_LANGUAGE,
    SET_LANGUAGE_FILTERED_VALUE,
    VIEW_ALL_NOTIFICATION,
    VIEW_ALL_TESTPASS,
    SET_SECTION_ID,
    SET_SECTION_FILTERED_VALUE,
    SET_LANGUAGE_ID,
    SET_SUBJECT_IDS,

} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import 'firebase/storage';
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { client } from "../../config/awsAuth"


//category start
export function add_upload_category_logo(category_name, category_logo, page_number, rowPerPage, searchQuery) {
    return dispatch => {
        if (category_logo !== "") {
            const params = {
                Bucket: "examys",
                Key: "CategoryLogo/" + category_name + "." + category_logo.type.split('/')[1],
                Body: category_logo,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {
                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"CategoryLogo/" + category_name + "." + category_logo.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(createCategory(category_name, fileLocation, page_number, rowPerPage, searchQuery))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            createCategory(category_name, category_logo, page_number, rowPerPage, searchQuery)
        }
    }
}
export function createCategory(category_name, category_logo, page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                category_name: category_name,
                category_logo: category_logo,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCategory(page_number, rowPerPage, searchQuery));
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function updateUploadCategory(category_id, category_name, category_logo, category_logo1, page_number, rowPerPage, searchQuery) {
    return dispatch => {
        if (category_logo1 !== "") {
            console.log(category_logo1, "category_logo1")
            const params = {
                Bucket: "examys",
                Key: "CategoryLogo/" + category_name + "." + category_logo1.type.split('/')[1],
                Body: category_logo1,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"CategoryLogo/" + category_name + "." + category_logo1.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(updateCategory(category_id, category_name, fileLocation, page_number, rowPerPage, searchQuery))
                    }

                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            console.log(category_logo1, "category_logo2")
            dispatch(updateCategory(category_id, category_name, category_logo, page_number, rowPerPage, searchQuery))
        }
    }
}
export function updateCategory(category_id, category_name, category_logo, page_number, rowPerPage, searchQuery) {
    console.log("category", category_id, category_name, category_logo);
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                category_id: category_id,
                category_name: category_name,
                category_logo: category_logo,
            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCategory(page_number, rowPerPage, searchQuery));
                    // dispatch(unsetLoader());
                } else {
                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteCategory(category_id, page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                category_id: category_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllCategory(page_number, rowPerPage, searchQuery));

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
// export function viewAllCategory(page_number, rowPerPage, searchQuery) {
//     return (dispatch) => {
//         // dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_category", {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },

//         })
//             .then((response) => response.json())
//             .then((responseJson) => {


//                 if (responseJson.status) {
//                     // dispatch({ type: SET_CATEGORY, payload: responseJson.result });
//                     for (var i = 0; i < responseJson.result.length; i++) {
//                         responseJson.result[i].quantity = 0;
//                         responseJson.result[i].checked = false;
//                         if (i == responseJson.result.length - 1) {
//                             dispatch({ type: SET_CATEGORY, payload: { category: responseJson.result } });
//                         }

//                     }
//                     // dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: SET_CATEGORY, payload: [] })
//                 }
//                 // dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }

export function viewAllCategory(page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());

        let url = UNIVERSAL.BASEURL + "view_all_category_with_pgn";
        if (searchQuery) {
            // Append searchQuery to the URL if provided
            url += `?searchQuery=${encodeURIComponent(searchQuery)}`;
        }

        return fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // No need to include body in GET request
            body: JSON.stringify({
                page_number: page_number,
                rowPerPage: rowPerPage
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // Process fetched PDF data
                    console.log(responseJson, "responseJsonresponseJson");
                    const processedData = responseJson.result.category.map(category => ({
                        ...category,
                        quantity: 0,
                        checked: false
                    }));
                    dispatch({
                        type: SET_CATEGORY, payload: {
                            category: processedData, count: responseJson.result.totalLength
                        }
                    });

                } else {
                    dispatch({ type: SET_CATEGORY, payload: { category: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
                // Handle errors if needed
            });
    };
}
export function CheckedCategory(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_CATEGORY,
        payload: { category: payload }
    }


};
export function searchcategory(all_category, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_CATEGORY_FILTERED_VALUE, payload: all_category });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_category.filter((el) => {
                return (el.category_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_CATEGORY_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
//category end


//EXAM start

export function createExam(exam_name, category_id) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_exam", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                exam_name: exam_name,
                category_id: category_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllExams());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateExam(exam_id, exam_name, category_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_exam", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                exam_id: exam_id,
                exam_name: exam_name,
                category_id: category_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllExams());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteExam(exam_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_exam", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                exam_id: exam_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllExams());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllExams() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_exams", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch({ type: SET_EXAM, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_EXAM, payload: { exam: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_EXAM, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function Checkedexam(index, checked, quantity, all_exam) {
    var payload = all_exam;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_EXAM,
        payload: { exam: payload }
    }
};



export function Checkedpreviousyear(index, checked, quantity, all_previous_year) {
    var payload = all_previous_year;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_PRIVIOUS_YEAR,
        payload: { previousYear: payload }
    }
};

export function Checkedexamai(index, checked, quantity, all_exam_ai) {
    var payload = all_exam_ai;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_EXAM_AI,
        payload: { examai: payload }
    }
};

export function searchexam(all_exam, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_EXAM_FILTERED_VALUE, payload: all_exam });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_exam.filter((el) => {
                return (el.exam_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_EXAM_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

//EXAM end

// ANSWER START

// view answer

export function view_all_answar() {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_answar", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    // dispatch({ type: SET_STUDENT, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_STUDENT, payload: { exam: responseJson.result } });
                        }

                    }
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_STUDENT, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                // console.error(error);
            });
    };
}

// ANSWER END

// DASHBOARD START
export function view_all_student() {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_student", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    // dispatch({ type: SET_STUDENT, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_STUDENT, payload: responseJson.result });
                        }

                    }
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_STUDENT, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
//DASHBOARD END

//NOTIFICATION START

export function createNotification(
    exam_id, not_name,
    not_desc,
    not_issued_on,
    application_start_dt,
    last_dt_to_apply,
    ld_online_application_date,
    ld_online_application_time,
    ld_offline_chalan,
    ld_pay_through_chalan,
    ld_offline_chalan_time,
    application_correction_dt,
    application_correction_time,
    computer_exam_dt,
    conducting_body,
    exam_name,
    conducting_for,
    appliction_date,
    appliction_mode,
    vacency,
    salary,
    official_not_link,
    official_link,
    form_link) {
    console.log("create Notificaton", exam_id, not_name,
        not_desc,
        not_issued_on,
        application_start_dt,
        last_dt_to_apply,
        ld_online_application_date,
        ld_online_application_time,
        ld_offline_chalan,
        ld_pay_through_chalan,
        ld_offline_chalan_time,
        application_correction_dt,
        application_correction_time,
        computer_exam_dt,
        conducting_body,
        exam_name,
        conducting_for,
        appliction_date,
        appliction_mode,
        vacency,
        salary,
        official_not_link,
        official_link,
        form_link)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_examNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                exam_id: exam_id,
                not_name: not_name,
                not_desc: not_desc,
                not_issued_on: not_issued_on,
                application_start_dt: application_start_dt,
                last_dt_to_apply: last_dt_to_apply,
                ld_online_application_date: ld_online_application_date,
                ld_online_application_time: ld_online_application_time,
                ld_offline_chalan: ld_offline_chalan,
                ld_pay_through_chalan: ld_pay_through_chalan,
                ld_offline_chalan_time: ld_offline_chalan_time,
                application_correction_dt: application_correction_dt,
                application_correction_time: application_correction_time,
                computer_exam_dt: computer_exam_dt,
                conducting_body: conducting_body,
                exam_name: exam_name,
                conducting_for: conducting_for,
                application_date: appliction_date,
                appliction_mode: appliction_mode,
                vacency: vacency,
                salary: salary,
                official_not_link: official_not_link,
                official_link: official_link,
                form_link: form_link
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewexamNotification());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateNotification(
    examNotification_id,
    exam_id, not_name,
    not_desc,
    not_issued_on,
    application_start_dt,
    last_dt_to_apply,
    ld_online_application_date,
    ld_online_application_time,
    ld_offline_chalan,
    ld_pay_through_chalan,
    ld_offline_chalan_time,
    application_correction_dt,
    application_correction_time,
    computer_exam_dt,
    conducting_body,
    exam_name,
    conducting_for,
    appliction_date,
    appliction_mode,
    vacency,
    salary,
    official_not_link,
    official_link,
    form_link,) {
    return (dispatch) => {
        console.log("asdf", examNotification_id, exam_id,
            not_name,
            not_desc,
            not_issued_on,
            application_start_dt,
            last_dt_to_apply,
            ld_online_application_date,
            ld_online_application_time,
            ld_offline_chalan,
            ld_pay_through_chalan,
            ld_offline_chalan_time,
            application_correction_dt,
            application_correction_time,
            computer_exam_dt,
            conducting_body,
            exam_name,
            conducting_for,
            appliction_date,
            appliction_mode,
            vacency,
            salary,
            official_not_link,
            official_link,
            form_link,)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_examNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                examNotification_id: examNotification_id,
                exam_id: exam_id,
                not_name: not_name,
                not_desc: not_desc,
                not_issued_on: not_issued_on,
                application_start_dt: application_start_dt,
                last_dt_to_apply: last_dt_to_apply,
                ld_online_application_date: ld_online_application_date,
                ld_online_application_time: ld_online_application_time,
                ld_offline_chalan: ld_offline_chalan,
                ld_pay_through_chalan: ld_pay_through_chalan,
                ld_offline_chalan_time: ld_offline_chalan_time,
                application_correction_dt: application_correction_dt,
                application_correction_time: application_correction_time,
                computer_exam_dt: computer_exam_dt,
                conducting_body: conducting_body,
                exam_name: exam_name,
                conducting_for: conducting_for,
                appliction_date: appliction_date,
                appliction_mode: appliction_mode,
                vacency: vacency,
                salary: salary,
                official_not_link: official_not_link,
                official_link: official_link,
                form_link: form_link
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewexamNotification());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteNotification(examNotification_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_examNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                examNotification_id: examNotification_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewexamNotification());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewexamNotification() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_examsNotification", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    // dispatch({ type: SET_EXAM, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: NOTIFICATION, payload: responseJson.result });
                        }

                    }
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: NOTIFICATION, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//NOTIFICATION END


//Stage start

export function createStage(stage_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_stage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                stage_name: stage_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllStages());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateStage(stage_id, stage_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_stage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                stage_id: stage_id,
                stage_name: stage_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllStages());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteStage(stage_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_stage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                stage_id: stage_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllStages());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllStages() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_stages", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch({ type: SET_STAGE, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_STAGE, payload: { stage: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_STAGE, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function Checkedstage(index, checked, quantity, all_stages) {
    var payload = all_stages;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_STAGE,
        payload: { stage: payload }
    }


};

export function searchstage(all_stages, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_STAGE_FILTERED_VALUE, payload: all_stages });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_stages.filter((el) => {
                return (el.stage_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_STAGE_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

//stage end


//subject start

export function createSubject(subject_name, page_number, rowPerPage,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_name: subject_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllSubject(page_number, rowPerPage, ""));
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateSubject(subject_id, subject_name, page_number, rowPerPage,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_id: subject_id,
                subject_name: subject_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllSubject(page_number, rowPerPage, ""));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteSubject(subject_id, page_number, rowPerPage,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_id: subject_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllSubject(page_number, rowPerPage, ""));

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// export function viewAllSubject() {
//     return (dispatch) => {
//         // dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_subjects", {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },

//         })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 if (responseJson.status) {
//                     // dispatch({ type: SET_SUBJECT, payload: responseJson.result });
//                     for (var i = 0; i < responseJson.result.length; i++) {
//                         responseJson.result[i].quantity = 0;
//                         responseJson.result[i].checked = false;
//                         if (i == responseJson.result.length - 1) {
//                             dispatch({ type: SET_SUBJECT, payload: { subject: responseJson.result } });
//                         }
//                     }
//                     // dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: SET_SUBJECT, payload: [] })
//                 }
//                 // dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }

export function viewAllSubject(page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());

        let url = UNIVERSAL.BASEURL + "view_all_subjects";
        if (searchQuery) {
            // Append searchQuery to the URL if provided
            url += `?searchQuery=${encodeURIComponent(searchQuery)}`;
        }

        return fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // No need to include body in GET request
            body: JSON.stringify({
                page_number: page_number,
                rowPerPage: rowPerPage
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // Process fetched PDF data
                    console.log(responseJson, "responseJsonresponseJson");
                    const processedData = responseJson.result.subject.map(subject => ({
                        ...subject,
                        quantity: 0,
                        checked: false
                    }));
                    dispatch({
                        type: SET_SUBJECT, payload: {
                            subject: processedData, count: responseJson.result.totalLength
                        }
                    });
                    // for (var i = 0; i < responseJson.result.subject.length; i++) {
                    //     responseJson.result[i].subject.quantity = 0;
                    //     responseJson.result.subject[i].checked = false;
                    //     if (i == responseJson.result.subject.length - 1) {
                    //         dispatch({ type: SET_SUBJECT, payload: { subject: responseJson.result.subject, count: responseJson.result.totalLength } });
                    //     }
                    // }
                } else {
                    dispatch({ type: SET_SUBJECT, payload: { subject: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
                // Handle errors if needed
            });
    };
}



export function Checkedsubject(index, checked, quantity, all_subject) {
    var payload = all_subject;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_SUBJECT,
        payload: { subject: payload }
    }


};

export function searchsubject(all_subject, e) {
    return (dispatch) => {
        // dispatch(setLoader());

        if (e === "") {
            dispatch({ type: SET_SUBJECT_FILTERED_VALUE, payload: all_subject });
        }
        else {
            const newArray = all_subject.filter((el) => {
                // 
                return (el.subject_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SET_SUBJECT_FILTERED_VALUE, payload: newArray })
        }
    };
}




//subject end


//section end


export function createSection(section_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_section", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                section_name: section_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllSection());
                } else {
                    dispatch(viewAllSection());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateSection(section_id, section_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_section", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                section_id: section_id,
                section_name: section_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllSection());
                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAllSection());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteSection(section_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_section", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                section_id: section_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllSection());

                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAllSection());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// section end


// export function viewAllSection() {
//     return (dispatch) => {
//         
//         // dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_sections", {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },

//         })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 
//                 if (responseJson.status) {
//                     dispatch({ type: SET_SECTION, payload: responseJson.result });
//                     dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: SET_SECTION, payload: [] })
//                 }
//                 // dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }



export function viewAllSection() {
    return (dispatch) => {
        // dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_section", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())

            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch({ type: SET_CHAPTER, payload: responseJson.result });

                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {

                            dispatch({ type: SET_SECTION, payload: { section: responseJson.result } });

                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_SECTION, payload: [] })


                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function searchSection(all_section, e) {
    return (dispatch) => {
        // dispatch(setLoader());

        if (e === "") {
            dispatch({ type: SET_SECTION_FILTERED_VALUE, payload: all_section });
        }
        else {
            const newArray = all_section.filter((el) => {
                // 
                return (el.section_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SET_SECTION_FILTERED_VALUE, payload: newArray })
        }
    };
}
//chapter start


export function createChapter(chapter_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_chapter", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                chapter_name: chapter_name,
                // language_id:language
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllChapter());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateChapter(chapter_id, chapter_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_chapter", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                chapter_id: chapter_id,
                chapter_name: chapter_name,
                // language_id:language_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllChapter());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteChapter(chapter_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_chapter", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                chapter_id: chapter_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllChapter());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllChapter() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_chapters", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch({ type: SET_CHAPTER, payload: responseJson.result });

                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {

                            dispatch({ type: SET_CHAPTER, payload: { chapter: responseJson.result } });

                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_CHAPTER, payload: [] })


                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function CheckedChapter(index, checked, quantity, all_chapter) {
    var payload = all_chapter;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_CHAPTER,
        payload: { chapter: payload }
    }


};
export function CheckedpriviousYear(index, checked, quantity, all_previous_year) {
    var payload = all_previous_year;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_PRIVIOUS_YEAR,
        payload: { previousYear: payload }
    }


};
var chapterarr = []
export function SelectChapter(chapter) {
    // var payload = chapter;
    if (chapter !== "") {
        chapterarr.push(chapter)
        // payload.chapter = chapterarr;
        return {
            type: SET_CHAPTER_SELECT,
            payload: { chapter: chapterarr }
        }
    }
    else {
        return {
            type: SET_CHAPTER_SELECT,
            payload: []
        }
    }


};
var filteredChapter = [];

export function removeItems(chapter, id) {


    // let remove=chapter.map(i=>)
    // for (let i= 0; i<chapter.length; i++) {
    //     if ( i._id !== id ) {
    //         filteredChapter = [...filteredChapter, chapter[i]];
    //     }
    // }

    filteredChapter = chapter.filter(item => item._id !== id);

    //  

    return {
        type: REMOVE_ITEM,
        payload: { chapter: filteredChapter }
    }
};




var yeararr = []
export function SelectPriviousYear(previous_year) {
    // var payload = chapter;
    yeararr.push(previous_year)
    // payload.chapter = chapterarr;
    return {
        type: SET_PRIVIOUS_YEAR_SELECT,
        payload: { previous_year: yeararr }
    }
};

var lessonarr = [];
export function SelectChapterLesson(chapter, count, lesson) {
    var payload = chapter;
    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            lessonarr.push(lesson)
            payload[i].lesson = lessonarr;
            return {
                type: SET_CHAPTER_SELECT_LESSON,
                payload: payload
            }
        }
    }
};
export function NullLesson() {
    lessonarr = [];
    var payload = lessonarr
    return {
        type: SET_VIDEO_SELECT,
        payload: payload
    }
};
var videoarr = [];
export function StoreVideo(lesson, videocount, video, video_count) {
    var payload = lesson;

    // payload.videolength = video_count;

    for (var i = 0; i < payload.length; i++) {
        if (i === videocount) {
            videoarr.push(video)
            payload[i].video = videoarr;
            payload[i].videolength = video_count + 1;
            return {
                type: SET_VIDEO_SELECT,
                payload: payload
            }
        }
    }


};
export function NullArray() {
    videoarr = [];
    var payload = videoarr
    return {
        type: SET_VIDEO_SELECT,
        payload: payload
    }
};

var pdfarr = [];
export function StorePdf(lesson, pdfcount, pdf, pdf_count) {
    var payload = lesson;
    for (var i = 0; i < payload.length; i++) {
        if (i === pdfcount) {
            pdfarr.push(pdf)
            payload[i].pdf = pdfarr;
            payload[i].pdflength = pdf_count + 1;
            return {
                type: SET_PDF_SELECT,
                payload: payload
            }
        }
    }


};
export function NullPdf() {
    pdfarr = [];
    var payload = pdfarr
    return {
        type: SET_PDF_SELECT,
        payload: payload
    }
};
var quizarr = [];
export function StoreQuiz(lesson, pdfcount, quiz, quiz_count) {
    var payload = lesson;

    for (var i = 0; i < payload.length; i++) {
        if (i === pdfcount) {
            quizarr.push(quiz)
            payload[i].quiz = quizarr;
            payload[i].quizlength = quiz_count + 1;
            return {
                type: SET_QUIZ_SELECT,
                payload: payload
            }
        }
    }


};
export function NullQuiz() {
    quizarr = [];
    var payload = quizarr
    return {
        type: SET_QUIZ_SELECT,
        payload: payload
    }
};



export function searchchapter(all_chapter, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_CHAPTER_FILTERED_VALUE, payload: all_chapter });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_chapter.filter((el) => {
                return (el.chapter_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_CHAPTER_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
export function searchlanguage(all_language, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_LANGUAGE_FILTERED_VALUE, payload: all_language });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_language.filter((el) => {
                return (el.language.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_LANGUAGE_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function searchPriviousYear(all_previous_year, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_PRIVIOUS_YEAR_FILTERED_VALUE, payload: all_previous_year });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_previous_year.filter((el) => {
                return (el.sub_details.subject_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_PRIVIOUS_YEAR_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function searchAllExamAi(all_exam_ai, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_ALL_EXAM_AI, payload: all_exam_ai });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_exam_ai.filter((el) => {
                return (el.examAI_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_ALL_EXAM_AI, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}




//chapter end
// ----------------------------------------------------------------------//
var catearr = []
export function SelectCategory(category) {
    // var payload = chapter;
    catearr.push(category)
    // payload.chapter = chapterarr;
    return {
        type: SET_CATEGORY_SELECT,
        payload: { category: catearr }
    }
};
var tsarr = []
export function SelectTestSeries(testseries) {
    // var payload = chapter;
    tsarr.push(testseries)
    // payload.chapter = chapterarr;
    return {
        type: SET_TESTSERIES_SELECT,
        payload: { testseries: tsarr }
    }
};

var exaiarr = []
export function SelectExamAi(examai) {
    // var payload = chapter;
    exaiarr.push(examai)
    // payload.chapter = chapterarr;
    return {
        type: SET_EXAMAI_SELECT,
        payload: { examai: tsarr }
    }
};
// var examarr = []
// export function SelectExam(exam) {
//     // var payload = chapter;
//     exaiarr.push(exam)
//     // payload.chapter = chapterarr;
//     return {
//         type: SET_EXAM_SELECT,
//         payload: { exam: examarr }
//     }
// };

var examarr = [];
export function SelectExam(category, count, exam) {
    var payload = category;
    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            examarr.push(exam)
            payload[i].exam = examarr;
            return {
                type: SET_EXAM_SELECT,
                payload: payload
            }
        }
    }
};
export function NullExam() {
    examarr = [];
    var payload = examarr
    return {
        type: SET_EXAM_SELECT,
        payload: payload
    }
};
var staarr = [];
export function SelectStage(exam, count, stage) {
    var payload = exam;

    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            staarr.push(stage)
            payload[i].stage = staarr;
            return {
                type: SET_STAGE_SELECT,
                payload: payload
            }
        }
    }
};

export function NullStage() {
    staarr = [];
    var payload = staarr
    return {
        type: SET_STAGE_SELECT,
        payload: payload
    }
};
var subarr = [];
export function SelectSubject(stage, count, sub) {
    var payload = stage;

    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            subarr.push(sub)
            payload[i].subject = subarr;
            return {
                type: SET_SUBJECT_SELECT,
                payload: payload
            }
        }
    }
};
var tsubarr = [];
export function SelectTSubject(testtype, count, tsub) {
    var payload = testtype;

    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            tsubarr.push(tsub)
            payload[i].subject = tsubarr;
            return {
                type: SET_SUBJECT_SELECT,
                payload: payload
            }
        }
    }
};
var presub = []
export function SelectTPreSubject(subject) {
    if (subject !== "") {
        presub.push(subject)
        // payload.chapter = chapterarr;

        return {
            type: SET_SUBJECT_SELECT,
            payload: presub
        }
    }
    else {
        return {
            type: SET_SUBJECT_SELECT,
            payload: []
        }
    }
};
export function NullSubject() {
    subarr = [];
    var payload = subarr
    return {
        type: SET_SUBJECT_SELECT,
        payload: payload
    }
};
var miniarr = [];
export function SelectMini(sub, count, mini) {
    var payload = sub;
    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            miniarr.push(mini)
            payload[i].minipackage = miniarr;
            return {
                type: SET_MINI_SELECT,
                payload: payload
            }
        }
    }
};
export function NullMini() {
    miniarr = [];
    var payload = miniarr
    return {
        type: SET_MINI_SELECT,
        payload: payload
    }
};
// ----------------------------------------------------------------------//
//lesson start

export function createLesson(lesson_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_lessons", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lesson_name: lesson_name,
                // language_id:language
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllLesson());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateLesson(lesson_id, lesson_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_lessons", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lesson_id: lesson_id,
                lesson_name: lesson_name,
                // language_id:language_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllLesson());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteLesson(lesson_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_lessons", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lesson_id: lesson_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllLesson());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllLesson() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_lessons", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch({ type: SET_LESSON, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_LESSON, payload: { lesson: responseJson.result } });
                            dispatch({ type: SET_ALL_LESSON, payload: { lesson: responseJson.result } });
                        }
                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_LESSON, payload: [] })
                    dispatch({ type: SET_ALL_LESSON, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function Checkedlesson(index, checked, quantity, all_lesson) {
    var payload = all_lesson;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_LESSON,
        payload: { lesson: payload }
    }


};
export function Checkedquiz(index, checked, quantity, all_quiz) {
    var payload = all_quiz;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_QUIZ,
        payload: { quiz: payload }
    }


};
//-------------------------------------------------------------------------------------------------
export function UnCheckedExam(all_exam) {
    var payload = all_exam;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_EXAM,
        payload: { exam: payload },
    }
};

export function UnCheckedStage(all_stages) {
    var payload = all_stages;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_STAGE,
        payload: { stage: payload },
    }
};

export function UnCheckedTests(tests) {
    var payload = tests;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_TESTS,
        payload: { stage: payload },
    }
};

export function UnCheckedTestType(testtype) {
    var payload = testtype;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_TEST_TYPES,
        payload: { stage: payload },
    }
};

export function UnCheckedSubject(all_subject) {
    var payload = all_subject;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_SUBJECT,
        payload: { subject: payload },
    }
};

export function UnCheckedMinipackage(all_minipackages) {
    var payload = all_minipackages;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_MINIPACKAGE,
        payload: { minipackage: payload },
    }
};

export function UnCheckedchapter(all_chapter) {
    var payload = all_chapter;

    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_CHAPTER,
        payload: { chapter: payload },

    }


};

export function UnCheckedPriviousYear(all_previous_year) {
    var payload = all_previous_year;

    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_PRIVIOUS_YEAR,
        payload: { previous_year: payload },

    }



};

export function UnCheckedlesson(all_lesson) {
    var payload = all_lesson;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_LESSON,
        payload: { lesson: payload },

    }


};

export function searchlesson(all_lesson, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_LESSON_FILTERED_VALUE, payload: all_lesson });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_lesson.filter((el) => {
                return (el.lesson_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_LESSON_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

//lesson end



//video start
export function UnCheckedvideo(all_video) {
    var payload = all_video;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_VIDEO,
        payload: { video: payload },

    }


};

export function UnCheckedpdf(all_pdf) {
    var payload = all_pdf;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_PDF,
        payload: { pdf: payload },

    }
};

export function UnCheckedquiz(all_quiz) {
    var payload = all_quiz;
    for (var i = 0; i < payload.length; i++) {
        payload[i].quantity = 0;
        payload[i].checked = false;
    }
    return {
        type: SET_QUIZ,
        payload: { quiz: payload },

    }
};

export function add_upload_Video(video_url, video_name) {
    return dispatch => {
        dispatch(setLoader(true));
        const storage = getStorage();
        const metadata = {
            contentType: "image/jpeg"
        };
        const storageRef = ref(storage, "/video/" + video_name + ".mp4")
        const uploadTask = uploadBytesResumable(storageRef, video_url);

        uploadTask.on(
            "state_changed",
            function (snapshot) {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // 
                // switch (snapshot.state) {
                //   case 'paused':
                //     
                //     break;
                //   case 'running':
                //     
                //     break;
                // }
            },
            function (error) {

                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (video_url) {
                    dispatch(createVideo(video_url, video_name));
                });
            }
        );

    }
}


// export function Delete_Firebase_Video( video_name,video_id) {
//     return dispatch => {
//         // 
//         dispatch(setLoader(true));
//         const storage = getStorage();
//         const desertRef = ref(storage, "/video/" + video_name + ".mp4");


//         deleteObject(desertRef).then( (video_name) {
//             dispatch(deleteVideo( video_id,video_name));
//             // File deleted successfully
//           }).catch((error) => {
//             // Uh-oh, an error occurred!
//           });


//             // function () {
//             //     getDownloadURL(uploadTask.snapshot.ref).then(function (video_url) {
//             //         
//             //         dispatch(createVideo(video_url, video_name));
//             //     });
//             // }


//     }
// export function Delete_Firebase_Video( video_name,video_id) {
//     return dispatch => {
//         // 
//         dispatch(setLoader(true));
//         const storage = getStorage();
//         const desertRef = ref(storage, "/video/" + video_name + ".mp4");


//         deleteObject(desertRef).then( (video_name) {
//             dispatch(deleteVideo( video_id,video_name));
//             // File deleted successfully
//           }).catch((error) => {
//             // Uh-oh, an error occurred!
//           });


//             // function () {
//             //     getDownloadURL(uploadTask.snapshot.ref).then(function (video_url) {
//             //         
//             //         dispatch(createVideo(video_url, video_name));
//             //     });
//             // }


//     }
// }



export function deleteVideo(video_id, video_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_video", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                video_id: video_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllVideo());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllVideo() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_videos", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch({ type: SET_VIDEO, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_VIDEO, payload: { video: responseJson.result } });
                        }
                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_VIDEO, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function Checkedvideo(index, checked, quantity, all_video) {
    var payload = all_video;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_VIDEO,
        payload: { video: payload }
    }
};
export function searchvideo(all_video, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_VIDEO_FILTERED_VALUE, payload: all_video });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_video.filter((el) => {
                return (el.video_hint.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_VIDEO_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}


//video end



//pdf start


export function add_upload_pdf(pdf_url, language, pdf_name) {
    return dispatch => {
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/pdf/" + pdf_name + ".pdf")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     
        //   });
        const uploadTask = uploadBytesResumable(storageRef, pdf_url);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {

                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (pdf_url) {
                    // 
                    dispatch(createPdf(pdf_url, language, pdf_name));
                });
            }
        );

    }
}




export function deletePdf(pdf_id, page_number, rowPerPage,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_pdf", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                pdf_id: pdf_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllPdf(page_number, rowPerPage, ""));

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// export function viewAllPdf() {
//     return (dispatch) => {
//         // dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_pdf", {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },

//         })
//             .then((response) => response.json())
//             .then((responseJson) => {


//                 if (responseJson.status) {
//                     // dispatch({ type: SET_PDF, payload: responseJson.result });
//                     for (var i = 0; i < responseJson.result.length; i++) {
//                         responseJson.result[i].quantity = 0;
//                         responseJson.result[i].checked = false;
//                         if (i == responseJson.result.length - 1) {
//                             dispatch({ type: SET_PDF, payload: { pdf: responseJson.result, count: responseJson.length } });
//                         }

//                     }
//                     // dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: SET_PDF, payload: { data: [], count: 0 } })
//                 }
//                 // dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }

export function viewAllPdf(page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());

        let url = UNIVERSAL.BASEURL + "view_all_pdf";
        if (searchQuery) {
            // Append searchQuery to the URL if provided
            url += `?searchQuery=${encodeURIComponent(searchQuery)}`;
        }

        return fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // No need to include body in GET request
            body: JSON.stringify({
                page_number: page_number,
                rowPerPage: rowPerPage
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // Process fetched PDF data
                    console.log(responseJson, "responseJsonresponseJson");
                    const processedData = responseJson.result.pdfs.map(pdf => ({
                        ...pdf,
                        quantity: 0,
                        checked: false
                    }));
                    dispatch({
                        type: SET_PDF, payload: {
                            pdf: processedData, count: responseJson.result.totalLength
                        }
                    });
                } else {
                    dispatch({ type: SET_PDF, payload: { pdf: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
                // Handle errors if needed
            });
    };
}

export function Checkedpdf(index, checked, quantity, all_pdf) {
    var payload = all_pdf;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_PDF,
        payload: { pdf: payload }
    }


};

export function searchpdf(all_pdf, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_PDF_FILTERED_VALUE, payload: all_pdf });

            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_pdf.filter((el) => {
                return (el.pdf_hint.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_PDF_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}


//pdf end


//package category


export function createPackageCategory(packagecategory_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_packagecategory", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                packagecategory_name: packagecategory_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPackageCategory());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updatePackageCategory(packagecategory_id, packagecategory_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_packagecategory", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                packagecategory_id: packagecategory_id,
                packagecategory_name: packagecategory_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPackageCategory());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updatePackage(package_id, student_special) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_as_student_special", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                package_id: package_id,
                student_special: student_special,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPackage());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function edit_upload_package_pdf(package_id, package_name, packagecategory_id, price, duration, package_content, package_desc, student_special, discounted_price, access, old_pdf_url, pdf_url, old_video_url, video_url, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) {
    return dispatch => {
        if (pdf_url !== "") {
            dispatch(setLoader(true));
            const storage = getStorage();
            const metadata = {
                contentType: "image/jpeg"
            };
            const storageRef = ref(storage, "/engvideo/" + pdf_url + ".mp4")
            const uploadTask = uploadBytesResumable(storageRef, pdf_url);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (pdf_url) {

                        dispatch(edit_upload_package_video(package_id, package_name, packagecategory_id, price, duration, package_content, package_desc, student_special, discounted_price, access, pdf_url, old_video_url, video_url, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice));
                    });
                }
            );
        }
        else {
            dispatch(edit_upload_package_video(package_id, package_name, packagecategory_id, price, duration, package_content, package_desc, student_special, discounted_price, access, old_pdf_url, old_video_url, video_url, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice));
        }

    }
}
export function edit_upload_package_video(package_id, package_name, packagecategory_id, price, duration, package_content, package_desc, student_special, discounted_price, access, pdf_url, old_video_url, video_url, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) {
    return dispatch => {
        if (video_url !== "") {
            dispatch(setLoader(true));
            const storage = getStorage();
            const metadata = {
                contentType: "image/jpeg"
            };
            const storageRef = ref(storage, "/engvideo/" + video_url + ".mp4")
            const uploadTask = uploadBytesResumable(storageRef, video_url);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (video_url) {

                        dispatch(Updatepackages(package_id, package_name, packagecategory_id, price, duration, package_content, package_desc, student_special, discounted_price, access, pdf_url, video_url, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice));
                    });
                }
            );
        }
        else {
            dispatch(Updatepackages(package_id, package_name, packagecategory_id, price, duration, package_content, package_desc, student_special, discounted_price, access, pdf_url, old_video_url, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice));
        }

    }
}
export function Updatepackages(package_id, package_name, packagecategory_id, price, duration, package_content, package_desc, student_special, discounted_price, access, pdf_url, video_url, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_package", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                package_id: package_id,
                package_name: package_name,
                packagecategory_id: packagecategory_id,
                package_price: price,
                package_duration: duration,
                package_content: package_content,
                package_desc: package_desc,
                student_special: student_special,
                discounted_price: discounted_price,
                access: access,
                pdf: pdf_url,
                video: video_url,
                course_journey: course_journey,
                team_course: team_course,
                syllabus_pattern: syllabus_pattern,
                faq: faq,
                discountInPer: discountInPer,
                newUserOffer: newUserOffer,
                referralOffer: referralOffer,
                finalPrice: finalPrice,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPackage());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//-------------------------Test Series Update Student Special------------------------------------//
export function updateTestSeries(test_series_id, student_special) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_student_special", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                test_series_id: test_series_id,
                student_special: student_special,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTestSeries());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function UpdatesTestSeries(testSeriesIds_id, package_name, hints, package_desc, package_hint, category, exam, stage, test_content, new_exam_pattern, language) {
    // 
    return (dispatch) => {
        // 

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_test_series", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                testSeries_id: testSeriesIds_id,
                name: package_name,
                hints: hints,
                desc: package_desc,
                hint: package_hint,
                category: category,
                exam: exam,
                stage: stage,
                test_content: test_content,
                new_exam: new_exam_pattern,
                language: language,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTestSeries());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function deletePackageCategory(packagecategory_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_packagecategory", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                packagecategory_id: packagecategory_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllPackageCategory());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllPackageCategory() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_packagecategory", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    dispatch({ type: SET_PACKAGECATEGORY, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_PACKAGECATEGORY, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


//package category end 


//quiz start



export function viewalldatabyQuizId(id) {
    return (dispatch) => {

        // dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "view_quiz_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    dispatch(setID(responseJson.result._id));
                    dispatch({ type: SET_REVIEW_BY_ID, payload: responseJson.result });
                    dispatch({ type: SET_REVIEW_QUASTION_ID, payload: responseJson.result.questions });
                    // dispatch({ type: SET_QUESTION_ID, payload: responseJson.result.questions._id });


                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_REVIEW_BY_ID, payload: [] });
                    dispatch({ type: SET_REVIEW_QUASTION_ID, payload: [] });
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewallSubjectQuizId(id) {
    return (dispatch) => {

        // dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "view_subject_by_quiz_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    // dispatch(setID(responseJson.result._id));
                    dispatch({ type: VIEW_SUBJECT_BY_QUIZ, payload: responseJson.result });
                    // dispatch({ type: SET_REVIEW_QUASTION_ID, payload: responseJson.result.questions });
                    // dispatch({ type: SET_QUESTION_ID, payload: responseJson.result.questions._id });


                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_REVIEW_BY_ID, payload: [] });
                    // dispatch({ type: SET_REVIEW_QUASTION_ID, payload: [] });
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function setID(payload) {
    return {
        type: SET_QUIZ_ID,
        payload: payload,
    };
}
export function setTquizID(payload) {

    return {
        type: SET_TQUIZ_ID,
        payload: payload,
    };
}

export function setRID(payload) {
    return {
        type: SET_QUIZ_R_ID,
        payload: payload,
    };
}
export function setQuestionID(payload) {
    return {
        type: SET_QUESTION_ID,
        payload: payload,
    };
}



export function createquiz(quiz_name, quiz_desc, language, id, time, marks, markss, subject) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_name: quiz_name,
                quiz_desc: quiz_desc,
                language: language,
                temp_quizID: id,
                time: time,
                marks: marks,
                cut_marks: markss,
                subject: subject,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setID((responseJson.insertedId).toString()))
                    dispatch(viewalldatabyQuizId((responseJson.result.insertedId).toString()));
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updatequiz(quiz_id, quiz_name, quiz_desc, quiz_time, quiz_marks, quiz_cut_marks, subject) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: quiz_id,
                quiz_name: quiz_name,
                quiz_desc: quiz_desc,
                time: quiz_time,
                marks: quiz_marks,
                cut_marks: quiz_cut_marks,
                subject: subject

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewalldatabyQuizId(quiz_id));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateQuizSubject(quiz_id, subject) {

    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_quiz_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: quiz_id,
                subject: subject

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllquiz());

                    // dispatch(viewAllTquiz());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deletequiz(quiz_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: quiz_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllquiz());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllquiz() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_quiz", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_QUIZ, payload: { quiz: responseJson.result } });
                        }
                    }
                } else {
                    dispatch({ type: SET_QUIZ, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function searchquiz(all_quiz, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_QUIZ_FILTERED_VALUE, payload: all_quiz });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_quiz.filter((el) => {
                return (el.quiz_desc.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_QUIZ_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

//quiz ends

//question start
export function createQuestion(question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    option5,
    option5_img,
    option5_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc, Uid, subject_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                option5: option5,
                option5_img: option5_img,
                option5_desc: option5_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,
                quiz_id: Uid,
                subject_id: subject_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllQuestion());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function createQuestionSubmit(question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    option5,
    option5_img,
    option5_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc, Uid, subject_id, quiz_name, quiz_desc, language, id, time, marks, markss, subject) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                option5: option5,
                option5_img: option5_img,
                option5_desc: option5_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,
                quiz_id: Uid,
                subject_id: subject_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status === true) {
                    dispatch(createquiz(quiz_name, quiz_desc, language, id, time, marks, markss, subject));
                    dispatch(viewAllQuestion());

                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



// image upload start
export function UploadQuestion(add_qustion_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "QuesImg/" + add_qustion_url + "." + add_qustion_url.type.split('/')[1],
            Body: add_qustion_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"QuesImg/" + add_qustion_url + "." + add_qustion_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setQuestion(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}

export function UploadTQuestion(add_qustion_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "QuesImg/" + add_qustion_url + "." + add_qustion_url.type.split('/')[1],
            Body: add_qustion_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"QuesImg/" + add_qustion_url + "." + add_qustion_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setQuestion(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}


// export function UploadQuestion(add_qustion_url) {
//     return dispatch => {
//         
//         dispatch(setLoader(true));
//         var unique = Math.random();
//         const storageRef = ref(getStorage(), "/Question/" + "Question" + unique + ".png")
//         // .put(image)
//         // uploadBytes(storageRef, image).then((snapshot) => {
//         //     
//         //   });
//         const uploadTask = uploadBytesResumable(storageRef, add_qustion_url);
//         uploadTask.on(
//             "state_changed",
//             function (snapshot) {
//             },
//             function (error) {
//                 
//                 //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
//                 dispatch(setLoader(false));
//             },
//             function () {
//                 getDownloadURL(uploadTask.snapshot.ref).then(function (add_qustion_url) {
//                     
//                     dispatch(setQuestion(add_qustion_url));
//                 });
//             }
//         );

//     }
// }


export function setQuestion(payload) {
    // 
    return {
        type: SET_QUESTION_IMAGE,
        payload: payload,
    };
}


export function UploadOpatin1(option1_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "Option1_Img/" + option1_url + "." + option1_url.type.split('/')[1],
            Body: option1_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"Option1_Img/" + option1_url + "." + option1_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setOpation1(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}


export function setOpation1(payload) {
    // 
    return {
        type: SET_OPATION1_IMAGE,
        payload: payload,
    };
}
export function UploadOpatin2(option2_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "Option2_Img/" + option2_url + "." + option2_url.type.split('/')[1],
            Body: option2_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"Option2_Img/" + option2_url + "." + option2_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setOpation2(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}
export function setOpation2(payload) {
    // 
    return {
        type: SET_OPATION2_IMAGE,
        payload: payload,
    };
}

export function UploadOpatin3(option3_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "Option3_Img/" + option3_url + "." + option3_url.type.split('/')[1],
            Body: option3_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"Option3_Img/" + option3_url + "." + option3_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setOpation3(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}


export function setOpation3(payload) {
    // 
    return {
        type: SET_OPATION3_IMAGE,
        payload: payload,
    };
}

export function UploadOpatin4(option4_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "Option4_Img/" + option4_url + "." + option4_url.type.split('/')[1],
            Body: option4_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"Option4_Img/" + option4_url + "." + option4_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setOpation4(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}

export function setOpation4(payload) {
    // 
    return {
        type: SET_OPATION4_IMAGE,
        payload: payload,
    };
}
export function UploadOpatin5(option5_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "Option4_Img/" + option5_url + "." + option5_url.type.split('/')[1],
            Body: option5_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"Option4_Img/" + option5_url + "." + option5_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setOpation5(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}
export function setOpation5(payload) {
    // 
    return {
        type: SET_OPATION5_IMAGE,
        payload: payload,
    };
}

export function UploadAnswer(answer_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "Answer_Img/" + answer_url + "." + answer_url.type.split('/')[1],
            Body: answer_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"Answer_Img/" + answer_url + "." + answer_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setAnswer(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}

export function setAnswer(payload) {
    return {
        type: SET_ANSWER_IMAGE,
        payload: payload,
    };
}
// image upload end
export function viewAllQuestion() {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_question", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: SET_QUESTION, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_QUESTION, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllQuestionByID(question_id) {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_question_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_id: question_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: SET_QUESTION_BY_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_QUESTION_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// remove reducer image
// actions.js

// export function resetValue (){
//     return{

//         type: SET_QUESTION_IMAGE_REMOVE,
//         // payload:payload
//     }
//   };

export const resetValue = () => ({
    type: SET_QUESTION_IMAGE_REMOVE,
});
export const resetValueopation1 = () => ({
    type: SET_OPATION1_IMAGE_REMOVE,
});
export const resetValueopation2 = () => ({
    type: SET_OPATION2_IMAGE_REMOVE,
});
export const resetValueopation3 = () => ({
    type: SET_OPATION3_IMAGE_REMOVE,
});
export const resetValueopation4 = () => ({
    type: SET_OPATION4_IMAGE_REMOVE,
});
export const resetValueopation5 = () => ({
    type: SET_OPATION5_IMAGE_REMOVE,
});
export const resetValueanswer = () => ({
    type: SET_ANSWER_IMAGE_REMOVE,
});
// remove reducer image end 
export function DeleteAllQuestionrefersh() {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_unuse_question", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 

                if (responseJson.status) {
                    // dispatch({ type: SET_QUESTION, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    // dispatch({ type: SET_QUESTION, payload: [] })
                }
                // dispatch(unsetLoader());
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
//edit images start

export function EditUploadQuestion(question_url) {
    return dispatch => {
        const params = {
            Bucket: "olanger",
            Key: "Ques_Img/" + question_url + "." + question_url.type.split('/')[1],
            Body: question_url,
        };
        const uploadCommand = new PutObjectCommand(params);

        client.send(uploadCommand)
            .then((data) => {
                const fileLocation = `https://olanger.s3.amazonaws.com/${"Ques_Img/" + question_url + "." + question_url.type.split('/')[1]}`;
                if (fileLocation !== "") {
                    dispatch(setEditQuestion(fileLocation));
                }
            })
            .catch((err) => {
                console.error("Error uploading file:", err);
            });
    }
}


export function setEditQuestion(payload) {

    return {
        type: SET_NEW_QUESTION_IMAGE,
        payload: payload,
    };
}

export function EditUploadoption1(new_option1_url) {
    return dispatch => {
        dispatch(setLoader(true));
        var unique = Math.random();
        const storageRef = ref(getStorage(), "/Question/" + "Question" + unique + ".png")

        const uploadTask = uploadBytesResumable(storageRef, new_option1_url);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {

                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (new_option1_url) {
                    dispatch(EditedOption1img(new_option1_url));
                });
            }
        );

    }
}

export function EditedOption1img(payload) {
    return {
        type: SET_NEW_OPTION1_IMAGE,
        payload: payload,
    };
}

export function EditUploadoption2(new_option2_url) {
    return dispatch => {
        dispatch(setLoader(true));
        var unique = Math.random();
        const storageRef = ref(getStorage(), "/Question/" + "Question" + unique + ".png")

        const uploadTask = uploadBytesResumable(storageRef, new_option2_url);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {

                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (new_option2_url) {

                    dispatch(EditedOption2img(new_option2_url));
                });
            }
        );

    }
}

export function EditedOption2img(payload) {
    return {
        type: SET_NEW_OPTION2_IMAGE,
        payload: payload,
    };
}

export function EditUploadoption3(new_option3_url) {
    return dispatch => {
        dispatch(setLoader(true));
        var unique = Math.random();
        const storageRef = ref(getStorage(), "/Question/" + "Question" + unique + ".png")

        const uploadTask = uploadBytesResumable(storageRef, new_option3_url);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {

                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (new_option3_url) {

                    dispatch(EditedOption3img(new_option3_url));
                });
            }
        );

    }
}

export function EditedOption3img(payload) {
    return {
        type: SET_NEW_OPTION3_IMAGE,
        payload: payload,
    };
}

export function EditUploadoption4(new_option4_url) {
    return dispatch => {
        dispatch(setLoader(true));
        var unique = Math.random();
        const storageRef = ref(getStorage(), "/Question/" + "Question" + unique + ".png")

        const uploadTask = uploadBytesResumable(storageRef, new_option4_url);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {

                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (new_option4_url) {

                    dispatch(EditedOption4img(new_option4_url));
                });
            }
        );

    }
}

export function EditedOption4img(payload) {
    return {
        type: SET_NEW_OPTION4_IMAGE,
        payload: payload,
    };

}

export function EditUploadoption5(new_option5_url) {
    return dispatch => {
        dispatch(setLoader(true));
        var unique = Math.random();
        const storageRef = ref(getStorage(), "/Question/" + "Question" + unique + ".png")

        const uploadTask = uploadBytesResumable(storageRef, new_option5_url);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {

                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (new_option5_url) {

                    dispatch(EditedOption5img(new_option5_url));
                });
            }
        );

    }
}

export function EditedOption5img(payload) {
    return {
        type: SET_NEW_OPTION5_IMAGE,
        payload: payload,
    };

}

export function EditUploadANSimg(new_ans_url) {
    const num = Math.random();
    return dispatch => {
        if (new_ans_url !== "") {
            const params = {
                Bucket: "olanger",
                Key: "Question/" + "question" + num + "." + new_ans_url.type.split('/')[1],
                Body: new_ans_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"Previous_Year_Question/" + "question" + num + "." + new_ans_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(EditedANSimg(fileLocation))
                    }
                    // 
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            EditedANSimg(new_ans_url)
        }
    }
}

export function EditedANSimg(payload) {
    return {
        type: SET_NEW_ANSWER_IMAGE,
        payload: payload,
    };
}

//update image ends


//remove edited image

export const resetEditedquestionimage = () => ({
    type: SET_EDITED_QUESTION_IMAGE_REMOVE,
});
export const resetEditedValueopation1 = () => ({
    type: SET_EDITED_OPATION1_IMAGE_REMOVE,
});
export const resetEditedValueopation2 = () => ({
    type: SET_EDITED_OPATION2_IMAGE_REMOVE,
});
export const resetEditedValueopation3 = () => ({
    type: SET_EDITED_OPATION3_IMAGE_REMOVE,
});
export const resetEditedValueopation4 = () => ({
    type: SET_EDITED_OPATION4_IMAGE_REMOVE,
});
export const resetEditedValueopation5 = () => ({
    type: SET_EDITED_OPATION5_IMAGE_REMOVE,
});
export const resetEditedValueanswer = () => ({
    type: SET_EDITED_ANSWER_IMAGE_REMOVE,
});

//remove edited image end
export function updateQuestion(question_id, question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    option5,
    option5_img,
    option5_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc, id) {
    return (dispatch) => {
        console.log(question_id, question_text,
            question_desc,
            question_img,
            option1,
            option1_img,
            option1_desc,
            option2,
            option2_img,
            option2_desc,
            option3,
            option3_img,
            option3_desc,
            option4,
            option4_img,
            option4_desc,
            option5,
            option5_img,
            option5_desc,
            correct_ans,
            correct_ans_img,
            correct_ans_desc, id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_id: question_id,
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                option5: option5,
                option5_img: option5_img,
                option5_desc: option5_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,
                quiz_id: id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewalldatabyQuizId(id));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
//quiz ends

//previous year question starts


export function add_upload_previous_year_question(pdf_url, pdf_name) {
    return dispatch => {
        if (pdf_url !== "") {
            const params = {
                Bucket: "olanger",
                Key: "Previous_Year_Question/" + pdf_name + "." + pdf_url.type.split('/')[1],
                Body: pdf_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"Previous_Year_Question/" + pdf_name + "." + pdf_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(createPdf(fileLocation, pdf_name))
                    }
                    // 
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            createPdf(pdf_url, pdf_name)
        }
    }
}




export function createPreviousYearQuestion(package_name, package_desc, year, access, subject_select, category, exam, stage, language) {

    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_previousyear", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: package_name,
                category: category,
                exam_id: exam,
                stage: stage,
                description: package_desc,
                year: year,
                access: access,
                prev_content: subject_select,
                language_id: language

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(SelectTPreSubject(""))
                    dispatch(viewAllPreviousYearQuestion());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updatePreviousYearQuestion(previousYears_id, package_name, package_desc, year, access, category, exam, stage, prev_content, language_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_previousyear", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                previousyear_id: previousYears_id,
                name: package_name,
                description: package_desc,
                year: year,
                access: access,
                category: category,
                exam_id: exam,
                stage: stage,
                prev_content: prev_content,
                language_id: language_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPreviousYearQuestion());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function deletePreviousYearQuestion(pdf_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_previousyear", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                previousyear_id: pdf_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllPreviousYearQuestion());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllPreviousYearQuestion() {
    return (dispatch) => {
        // dispatch(setLoader());
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_previousyear", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 

                if (responseJson.status) {
                    dispatch({ type: SET_PRIVIOUS_YEAR, payload: { previousYear: responseJson.result } });
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_PRIVIOUS_YEAR, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function searchLanguage(all_pdf, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SET_PDF_FILTERED_VALUE, payload: all_pdf });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_pdf.filter((el) => {
                return (el.language.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SET_PDF_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}


//previous year question ends




//Banner Starts


// export function add_upload_Banner(banner_url, banner_name) {
//     return dispatch => {
//         
//         dispatch(setLoader(true));
//         const storageRef = ref(getStorage(), "/Banner/" + banner_name + ".png")
//         // .put(image)
//         // uploadBytes(storageRef, image).then((snapshot) => {
//         //     
//         //   });
//         const uploadTask = uploadBytesResumable(storageRef, banner_url);
//         uploadTask.on(
//             "state_changed",
//             function (snapshot) {
//             },
//             function (error) {
//                 
//                 //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
//                 dispatch(setLoader(false));
//             },
//             function () {
//                 getDownloadURL(uploadTask.snapshot.ref).then(function (banner_url) {
//                     // 
//                     dispatch(AddBanner(banner_url, banner_name));
//                 });
//             }
//         );

//     }
// }

// const AWS = require("aws-sdk");
//     AWS.config.update({
//         accessKeyId: "AKIAVOXEEFZUUEZYFRXD",
//         secretAccessKey: "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",
//         region: "ap-south-1",
//     });
export function add_upload_Banner(banner_url, banner_name) {
    return dispatch => {
        if (banner_url !== "") {
            const params = {
                Bucket: "examys",
                Key: "Banner/" + banner_name + "." + banner_url.type.split('/')[1],
                Body: banner_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"Banner/" + banner_name + "." + banner_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(AddBanner(fileLocation, banner_name))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            AddBanner(banner_url, banner_name)
        }
    }
}
// async function uploadFileToFolder(bucketName, folderName, fileName, fileData) {
//     const objectKey = `${folderName}/${fileName}`;

//     const uploadParams = {
//       Bucket: bucketName,
//       Key: objectKey,
//       Body: fileData,
//     };

//     try {
//       await s3Client.send(new PutObjectCommand(uploadParams));
//       
//     } catch (err) {
//       console.error("Error uploading file:", err);
//     }
//   }

export function AddBanner(banner_url, banner_name,) {

    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_banner", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                banner_url: banner_url,
                banner_name: banner_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllBanner());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function deleteBanner(banner_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_banner", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                banner_id: banner_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllBanner());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllBanner() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_banner", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    dispatch({ type: SET_BANNER, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_BANNER, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//Banner ends


//minipackage Starts





export function Addminipackage(minipackage_name, minipackage_heading, mini_hint, mini_des, mini_content, language) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_minipackage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                minipackage_name: minipackage_name,
                mini_hint: mini_hint,
                mini_des: mini_des,
                mini_content: mini_content,
                minipackage_heading: minipackage_heading,
                language_id: language
                // previous_year_subject: previous_year
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMiniPackager());
                    dispatch(setChapter(""))
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateMiniPackage(minipackage_id, minipackage_name, minipackage_heading, mini_hint, mini_des, mini_content, language_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_minipackage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                minipackage_id: minipackage_id,
                minipackage_name: minipackage_name,
                mini_hint: mini_hint,
                mini_des: mini_des,
                mini_content: mini_content,
                minipackage_heading: minipackage_heading,
                language_id: language_id
                // previous_year_subject: previous_year
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMiniPackager());
                } else {
                    dispatch(viewAllMiniPackager());

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function addTestSeries(name, hints, desc, hint, category, exam, stage, test_content, new_exam_pattern, language) {
    return (dispatch) => {
        // 
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_test_series", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                hints: hints,
                desc: desc,
                hint: hint,
                category: category,
                exam: exam,
                stage: stage,
                test_content: test_content,
                new_exam: new_exam_pattern,
                language: language
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(SelectTest(""))
                    dispatch(viewAllTestSeries());
                } else {
                    dispatch(SelectTest(""))
                    dispatch(viewAllTestSeries());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllTestSeries() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_test_series_admin", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    // dispatch({ type: SET_MINIPACKAGE, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_TESTSERIES, payload: { testseries: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_TESTSERIES, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function searchtestseries(all_testseries, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {

            dispatch({ type: SET_TESTSERIES_FILTERED_VALUE, payload: all_testseries });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_testseries.filter((el) => {
                return (el.name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_TESTSERIES_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}



export function CheckedTestseries(index, checked, quantity, all_testseries) {
    var payload = all_testseries;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_TESTSERIES,
        payload: { testseries: payload }
    }


};

// export function viewAllTestSeries() {
//     return (dispatch) => {
//         dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_testSeries",
//             {
//                 method: "GET",
//                 headers: {
//                     Accept: "application/json",
//                     "Content-Type": "application/json",

//                 },
//             })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 
//                 if (responseJson.status) {
//                     dispatch({ type: SET_TESTSERIES, payload: responseJson.result });
//                     dispatch(set_snack_bar(responseJson.status, "Some message"));
//                     dispatch(unsetLoader());
//                 } else {

//                     dispatch({ type: SET_TESTSERIES, payload: [] })

//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }



export function deleteTestSeries(testSeries_id) {
    return (dispatch) => {

        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_test_series", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                testSeries_id: testSeries_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllTestSeries());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}





export function deleteMinipackage(minipackage_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_minipackage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                minipackage_id: minipackage_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllMiniPackager());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllMiniPackager() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_minipackage", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    // dispatch({ type: SET_MINIPACKAGE, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_MINIPACKAGE, payload: { minipackage: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_MINIPACKAGE, payload: { minipackage: [] } })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function Checkedminipackage(index, checked, quantity, all_minipackages) {
    var payload = all_minipackages;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_MINIPACKAGE,
        payload: { minipackage: payload }
    }


};


export function setChapter(payload) {

    return {
        type: SET_SELECT_CHAPTER,
        payload: payload,
    }
}
export function setPriviousYear(payload) {

    return {
        type: SET_SELECT_PRIVIOUS_YEAR,
        payload: payload,
    }
}
export function setRes(payload) {

    return {
        type: SET_RESSET,
        payload: payload,
    };
}
export function setpackageId(payload) {

    return {
        type: SET_PACKAGE_BY_ID,
        payload: payload,
    };
}





//package starts 
export function add_upload_package_video(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) {
    return dispatch => {
        if (video !== "") {
            const params = {
                Bucket: "olanger",
                Key: "Video/" + video + "." + video.type.split('/')[1],
                Body: video,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"Video/" + video + "." + video.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(add_upload_package_pdf(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, fileLocation, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(add_upload_package_pdf(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice))
        }
    }
}
export function add_upload_package_pdf(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) {
    return dispatch => {
        if (pdf !== "") {
            const params = {
                Bucket: "olanger",
                Key: "PDF/" + pdf + "." + pdf.type.split('/')[1],
                Body: pdf,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"PDF/" + pdf + "." + pdf.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(Addpackage(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, fileLocation, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(Addpackage(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice))
        }
    }
}

export function Addpackage(package_name, packagecategory_id, package_price, package_duration, package_content, package_desc, student_special, discounted_price, access, video, pdf, course_journey, team_course, syllabus_pattern, faq, discountInPer, newUserOffer, referralOffer, finalPrice) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_package", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                package_name: package_name,
                packagecategory_id: packagecategory_id,
                package_price: package_price,
                package_duration: package_duration,
                package_content: package_content,
                package_desc: package_desc,
                student_special: student_special,
                discounted_price: discounted_price,
                access: access,
                video: video,
                pdf: pdf,
                course_journey: course_journey,
                team_course: team_course,
                syllabus_pattern: syllabus_pattern,
                faq: faq,
                discountInPer: discountInPer,
                newUserOffer: newUserOffer,
                referralOffer: referralOffer,
                finalPrice: finalPrice
                // test_series: test_series,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPackage());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function deletepackage(package_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_package", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                package_id: package_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllPackage());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllPackage() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_package_admin", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 

                if (responseJson.status) {
                    dispatch({ type: SET_PACKAGE, payload: responseJson.result });
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_PACKAGE, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function searchminipackage(all_minipackages, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_MINIPACKAGE_FILTERED_VALUE, payload: all_minipackages });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_minipackages.filter((el) => {
                return (el.mini_hint.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_MINIPACKAGE_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}


var exarr = []
export function SelectExamm(examm) {
    // var payload = chapter;
    exarr.push(examm)
    // payload.chapter = chapterarr;
    return {
        type: SET_EXAMM_SELECT,
        payload: { examm: exarr }
    }


};





















//-------------------------------------------------- Tests ---------------------------------------------//
export function createTest(test_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_test", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                test_name: test_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTests());
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllTests() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_test", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 

                if (responseJson.status) {
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i === responseJson.result.length - 1) {
                            dispatch({ type: SET_TESTS, payload: { tests: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_TESTS, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function updateTest(test_id, test_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_test", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                test_id: test_id,
                test_name: test_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTests());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteTest(test_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_test", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                test_id: test_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTests());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function Checkedtests(index, checked, quantity, tests) {
    var payload = tests;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_TESTS,
        payload: { tests: payload }
    }


};

export function searchtests(tests, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_TESTS_FILTERED_VALUE, payload: tests });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = tests.filter((el) => {
                return (el.test_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_TESTS_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

var testarr = [];
export function SelectTest(test) {
    if (test === "") {
        testarr = []
        return {
            type: SET_TEST_SELECT,
            payload: []
        }
    }
    else {
        testarr.push(test)
        return {
            type: SET_TEST_SELECT,
            payload: { test: testarr }
        }
    }


};

export function NullTest() {
    testarr = [];
    var payload = testarr
    return {
        type: SET_STAGE_SELECT,
        payload: payload
    }
};

//-------------------------------------------------- Test Type ---------------------------------------------//
export function createTestType(test_type_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_test_type", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                test_type_name: test_type_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTestsType());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllTestsType() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_test_type", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 

                if (responseJson.status) {
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i === responseJson.result.length - 1) {
                            dispatch({ type: SET_TEST_TYPES, payload: { testtypes: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_TEST_TYPES, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function updateTestType(test_type_id, test_type_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_test_type", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                test_type_id: test_type_id,
                test_type_name: test_type_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTestsType());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteTestType(test_type_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_test_type", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                test_type_id: test_type_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTestsType());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function Checkedtesttype(index, checked, quantity, testtypes) {
    var payload = testtypes;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_TEST_TYPES,
        payload: { testtypes: payload }
    }


};

export function searchtesttype(test_types, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SET_TESTTYPE_FILTERED_VALUE, payload: test_types });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = test_types.filter((el) => {
                return (el.test_type_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SET_TESTTYPE_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

var testtypearr = [];
export function SelectTestType(test, count, testtype) {
    var payload = test;
    // 
    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            testtypearr.push(testtype)
            payload[i].testtype = testtypearr;
            return {
                type: SET_TEST_TYPES_SELECT,
                payload: payload
            }
        }
    }
};

export function NullTestTypes() {
    testtypearr = [];
    var payload = testtypearr
    return {
        type: SET_TEST_SELECT,
        payload: payload
    }
};

//--------------------------------------------- Test Subject --------------------------------------------//
export function createTSubject(subject_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_t_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_name: subject_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTSubject());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateTSubject(subject_id, subject_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_t_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_id: subject_id,
                subject_name: subject_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTSubject());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteTSubject(subject_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_t_subject", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subject_id: subject_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTSubject());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllTSubject() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_t_subjects", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    // dispatch({ type: SET_SUBJECT, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_SUBJECT, payload: { subject: responseJson.result } });
                        }

                    }
                    // dispatch(viewalldatabyTQuizId((responseJson.result.insertedId).toString()));

                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_SUBJECT, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//--------------------------------------------- Test Quiz --------------------------------------------//
export function createTquiz(quiz_name, quiz_desc, Uid, time, marks, section, setSection, total_question, access) {
    return (dispatch) => {
        // 
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_t_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_name: quiz_name,
                quiz_desc: quiz_desc,
                // language: language,
                temp_quizID: Uid,
                time: time,
                marks: marks,
                // cut_marks: Markss,
                section: section,
                setSection: setSection,
                total_question: total_question,
                access: access,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setID((responseJson.insertedId).toString()))
                    dispatch(viewalldatabyTQuizId((responseJson.result.insertedId).toString()));
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateTquiz(quiz_id, quiz_name, quiz_desc, quiz_time, quiz_marks, language, setSection, total_question, access) {
    // 
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_T_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: quiz_id,
                quiz_name: quiz_name,
                quiz_desc: quiz_desc,
                time: quiz_time,
                marks: quiz_marks,
                language: language,
                setSection: setSection,
                total_question: total_question,
                access: access
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewalldatabyTQuizId(quiz_id));

                    dispatch(viewAllTquiz());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function updateTQuizSubject(quiz_id, subject) {
    console.log(quiz_id, subject, "quiz_id, subject");
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_T_quiz_section", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: quiz_id,
                section: subject

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTquiz());
                    // dispatch(viewalldatabyTQuizId(quiz_id));


                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAllTquiz());
                    // dispatch(viewalldatabyTQuizId(quiz_id));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteTquiz(quiz_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_T_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: quiz_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTquiz());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllTquiz() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_t_quiz", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_QUIZ, payload: { quiz: responseJson.result } });
                        }

                    }
                } else {
                    dispatch({ type: SET_QUIZ, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewalldatabyTQuizId(id) {
    return (dispatch) => {

        // dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "view_test_quiz_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    dispatch({ type: SET_REVIEW_BY_TQUIZID, payload: responseJson.result });
                    dispatch(setTquizID(responseJson.result._id));
                    // dispatch({ type: SET_REVIEW_BY_ID, payload: responseJson.result });
                    // dispatch({ type: SET_REVIEW_QUASTION_ID, payload: responseJson.result.questions });
                    // dispatch({ type: SET_QUESTION_ID, payload: responseJson.result.questions._id });


                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_REVIEW_BY_TQUIZID, payload: [] });
                    // dispatch({ type: SET_REVIEW_BY_ID, payload: [] });
                    // dispatch({ type: SET_REVIEW_QUASTION_ID, payload: [] });
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

var quizzarr = [];
export function SelectQuizz(subject, count, quizz) {
    var payload = subject;
    // 
    for (var i = 0; i < payload.length; i++) {
        if (i === count) {
            quizzarr.push(quizz)
            payload[i].quizz = quizzarr;
            return {
                type: SET_QUIZZ_SELECT,
                payload: payload
            }
        }
    }
};
export function NullQuizz() {
    quizzarr = [];
    var payload = quizzarr
    return {
        type: SET_SUBJECT_SELECT,
        payload: payload
    }
};

//----------------------------------------------Test Questions---------------------------------------------//
export function createTQuestion(
    qustion_title, question_img, option1, opation1_img, option1_desc, option2, opation2_img, option2_desc, option3, opation3_img, option3_desc, option4, opation4_img, option4_desc, answer, answer_img, editorHtml, Uid, subject_id, section_id, question_marks, cut_marks) {
    return (dispatch) => {
        // console.log("addquizzddddddddddddd", question_text,
        //     question_desc,
        //     question_img,
        //     option1,
        //     option1_img,
        //     option1_desc,
        //     option2,
        //     option2_img,
        //     option2_desc,
        //     option3,
        //     option3_img,
        //     option3_desc,
        //     option4,
        //     option4_img,
        //     option4_desc,
        //     correct_ans,
        //     correct_ans_img,
        //     correct_ans_desc)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_text: qustion_title,
                // question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: opation1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: opation2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: opation3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: opation4_img,
                option4_desc: option4_desc,
                correct_ans: answer,
                correct_ans_img: answer_img,
                correct_ans_desc: editorHtml,
                quiz_id: Uid,
                subject_id: subject_id,
                section_id: section_id,
                marks: question_marks,
                cutmark: cut_marks,
                // language:language,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTQuestion());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function createTQuestionSubmit(
    qustion_title, question_img, option1, opation1_img, option1_desc, option2, opation2_img, option2_desc, option3, opation3_img, option3_desc, option4, opation4_img, option4_desc, answer, answer_img, editorHtml, Uid, subject_id, quiz_name, quiz_desc, time, marks, Markss, section, section_id, question_marks, cut_marks, setSection) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_text: qustion_title,
                // question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: opation1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: opation2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: opation3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: opation4_img,
                option4_desc: option4_desc,
                correct_ans: answer,
                correct_ans_img: answer_img,
                correct_ans_desc: editorHtml,
                quiz_id: Uid,
                subject_id: subject_id,
                section_id: section_id,
                marks: question_marks,
                cutmark: cut_marks,
                // language:language
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status === true) {
                    dispatch(createTquiz(quiz_name, quiz_desc, Uid, time, marks, section, setSection))
                    dispatch(viewAllTQuestion());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


// export function UploadTQuestion(add_qustion_url) {
//     return dispatch => {
//         
//         dispatch(setLoader(true));
//         var unique = Math.random();
//         const storageRef = ref(getStorage(), "/Question/" + "Question" + unique + ".png")
//         // .put(image)
//         // uploadBytes(storageRef, image).then((snapshot) => {
//         //     
//         //   });
//         const uploadTask = uploadBytesResumable(storageRef, add_qustion_url);
//         uploadTask.on(
//             "state_changed",
//             function (snapshot) {
//             },
//             function (error) {
//                 
//                 //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
//                 dispatch(setLoader(false));
//             },
//             function () {
//                 getDownloadURL(uploadTask.snapshot.ref).then(function (add_qustion_url) {
//                     
//                     dispatch(setQuestion(add_qustion_url));
//                 });
//             }
//         );

//     }
// }

export function viewAllTQuestion() {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_question", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    dispatch({ type: SET_QUESTION, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_QUESTION, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllTQuestionByID(question_id) {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_question_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_id: question_id,
            })
        })

            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    dispatch({ type: SET_QUESTION_BY_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_QUESTION_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function updateTQuestion(question_id, question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_id: question_id,
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPdf());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//-----------------------------------Trends ------------------------------------------------------------------//

export function createTrends(trends_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_trend", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trend_type: trends_name,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {

                    dispatch(viewAllTrends());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewAllTrends() {
    return (dispatch) => {
        // dispatch(setLoader());
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_trend", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_TRENDS, payload: { trends: responseJson.result } });
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_TRENDS, payload: { trends: [] } })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function updateTrends(trends_id, trends_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_trend", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trend_id: trends_id,
                trend_type: trends_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTrends());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteTrends(trends_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_trend", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                trend_id: trends_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTrends());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
//----------------------------------------Exam Ai -------------------------------------------------------//
export function createExamAi(exam_ai_name, category, exam_id, stage, trend_select, language) {
    // 
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_examysai", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                examAI_name: exam_ai_name,
                category: category,
                exam_id: exam_id,
                stage: stage,
                examysai_content: trend_select,
                language_id: language
                // pdf: pdf
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllExamAi());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateExamAi(examAi_id, exam_ai_name, category, exam_id, stage, examysai_content, language_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_examysai", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                examysai_id: examAi_id,
                examAI_name: exam_ai_name,
                category: category,
                exam_id: exam_id,
                stage: stage,
                examysai_content: examysai_content,
                language_id: language_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllExamAi());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteExamAi(examAi_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_examysai", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                examysai_id: examAi_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllExamAi());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function viewAllExamAi() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_examysai", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: SET_EXAM_AI, payload: { examai: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_EXAM_AI, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


var exarr = []
export function SelectTrends(trends) {
    // var payload = chapter;
    exarr.push(trends)
    // payload.chapter = chapterarr;
    return {
        type: SET_TREND_SELECT,
        payload: { trends: exarr }
    }


};

export function searchTrend(view_all_trends, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_TREND_FILTERED_VALUE, payload: view_all_trends });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = view_all_trends.filter((el) => {
                return (el.trend_type.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_TREND_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
export function Checkedtrend(index, checked, quantity, view_all_trends) {
    var payload = view_all_trends;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    return {
        type: SET_TRENDS,
        payload: { trends: payload }
    }


};
var testarr = [];
export function SelectPdf(trends, count, pdf) {
    var payload = trends;

    for (var i = 0; i < payload.length; i++) {
        if (i === count) {

            testarr.push(pdf)
            payload[i].pdf = testarr;

            return {
                type: SET_PDF_SELECT,
                payload: payload
            }
        }
    }
};




export function searchPreviousYear(all_previous_year, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SEARCH_PREVIOUS_YEAR, payload: all_previous_year });
        }
        else {
            const newArray = all_previous_year.filter((el) => {
                return (el.sub_details.subject_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SEARCH_PREVIOUS_YEAR, payload: newArray })
        }
    };
}
//----------------------------------------video-----------------------//
// export function add_eng_upload_Video(video_name, video_hint, english_url, access) {
//     return dispatch => {
//         if (english_url !== "") {
//             const params = {
//                 Bucket: "examys",
//                 Key: "Video/" + video_name + "." + english_url.type.split('/')[1],
//                 Body: english_url,
//             };
//             const uploadCommand = new PutObjectCommand(params);

//             client.send(uploadCommand)
//                 .then((data) => {

//                     const fileLocation = `https://s3_full_access.examys.s3.ap-south-1.amazonaws.com/${"Video/" + video_name + "." + english_url.type.split('/')[1]}`;
//                     if (fileLocation !== "") {
//                         dispatch(createVideo(video_name, video_hint, fileLocation, access))
//                     }
//                 })
//                 .catch((err) => {
//                     console.error("Error uploading file:", err);
//                 });
//         }
//         else {
//             dispatch(createVideo(video_name, video_hint, english_url, access))
//         }
//     }
// }

export function add_eng_upload_Video(video_name, video_hint, english_url, access) {
    return dispatch => {
        if (english_url instanceof File) {
            const fileName = video_name + "." + english_url.name.split('.').pop();
            const params = {
                Bucket: "examys",
                Key: "video/" + fileName,
                Body: english_url,
            };

            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {
                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/video/${fileName}`; // Constructing file URL
                    dispatch(createVideo(video_name, video_hint, fileLocation, access));
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        } else {
            dispatch(createVideo(video_name, video_hint, english_url, access));
        }
    };
}

export function add_hin_upload_Video(video_name, hindi_video_name, video_hint, hindi_url, english_url, access) {
    return (dispatch) => {
        if (hindi_url !== "" && hindi_url !== null && hindi_url !== undefined) {
            const params = {
                Bucket: "olanger",
                Key: "Video/" + video_name + "(hindi)" + "." + hindi_url.type.split('/')[1],
                Body: hindi_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"Video/" + video_name + "(hindi)" + "." + hindi_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(createVideo(video_name, hindi_video_name, video_hint, fileLocation, english_url, access))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(createVideo(video_name, hindi_video_name, video_hint, hindi_url, english_url, access))
        }
    }
}

export function createVideo(video_name, video_hint, english_url, access) {
    console.log(video_name, video_hint, english_url, access);
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_video", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                eng_video_name: video_name,
                // hnd_video_name: hindi_video_name,
                // hindi_url: hindi_url,
                english_url: english_url,
                access: access,
                video_hint: video_hint
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllVideo());
                } else {
                    dispatch(viewAllVideo());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function edit_eng_upload_Video(video_id, video_name, video_hint, old_english_url, english_url, access) {
    return dispatch => {
        if (english_url !== "") {
            const params = {
                Bucket: "examys",
                Key: "Video/" + video_name + "." + english_url.type.split('/')[1],
                Body: english_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"Video/" + video_name + "." + english_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(updateVideo(video_id, video_name, video_hint, fileLocation, access));
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(updateVideo(video_id, video_name, video_hint, old_english_url, access));
        }
    }
}


export function edit_hin_upload_Video(video_id, video_name, hindi_video_name, video_hint, old_hindi_url, hindi_url, english_url, access) {
    return (dispatch) => {
        if (hindi_url !== "") {
            const params = {
                Bucket: "olanger",
                Key: "Video/" + video_name + "(hindi)" + "." + hindi_url.type.split('/')[1],
                Body: hindi_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"Video/" + video_name + "(hindi)" + "." + hindi_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(updateVideo(video_id, video_name, hindi_video_name, video_hint, hindi_url, english_url, access));
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(updateVideo(video_id, video_name, hindi_video_name, video_hint, old_hindi_url, english_url, access));
        }
    }
}

export function updateVideo(video_id, video_name, video_hint, english_url, access) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_video", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                video_id: video_id,
                eng_video_name: video_name,
                // hnd_video_name: hindi_video_name,
                // hindi_url: hindi_url,
                english_url: english_url,
                access: access,
                video_hint: video_hint,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllVideo());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
//---------------------------------pdf---------------------------------------------------//
// export function add_eng_upload_pdf(pdf_name, pdf_hint, english_url, access) {
//     return dispatch => {
//         if (english_url instanceof File) { 
//             const fileName = pdf_name + "." + english_url.name.split('.').pop(); 
//             const params = {
//                 Bucket: "examys",
//                 Key: "pdf/" + fileName,
//                 Body: english_url,
//             };

//             const uploadCommand = new PutObjectCommand(params);

//             client.send(uploadCommand)
//                 .then((data) => {
//                     const fileLocation = `https://s3_full_access.examys.s3.ap-south-1.amazonaws.com/pdf/${fileName}`;
//                     console.log("fileLocation",fileLocation); // Constructing file URL
//                     dispatch(createPdf(pdf_name, pdf_hint, fileLocation, access));
//                 })
//                 .catch((err) => {
//                     console.error("Error uploading file:", err);
//                 });
//         } else {
//             dispatch(createPdf(pdf_name, pdf_hint, english_url, access));
//         }
//     };
// }

export function add_eng_upload_pdf(pdf_name, pdf_hint, english_url, access, page_number, rowPerPage,) {

    return dispatch => {
        if (english_url !== "") {
            const params = {
                Bucket: "examys",
                Key: "PDF/" + pdf_name + "." + english_url.type.split('/')[1],
                Body: english_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"PDF/" + pdf_name + "." + english_url.type.split('/')[1]}`;
                    console.log(fileLocation, "fileLocation");
                    if (fileLocation !== "") {
                        dispatch(createPdf(pdf_name, pdf_hint, fileLocation, access, page_number, rowPerPage,))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(createPdf(pdf_name, pdf_hint, english_url, access, page_number, rowPerPage,))
        }
    }

}
export function add_hin_upload_pdf(pdf_name, hindi_pdf_name, pdf_hint, hindi_url, english_url, access, page_number, rowPerPage,) {

    return dispatch => {
        if (hindi_url !== "") {
            const params = {
                Bucket: "olanger",
                Key: "PDF/" + pdf_name + "(hindi)" + "." + hindi_url.type.split('/')[1],
                Body: hindi_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"PDF/" + pdf_name + "(hindi)" + "." + hindi_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(createPdf(pdf_name, hindi_pdf_name, pdf_hint, fileLocation, english_url, access, page_number, rowPerPage,))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(createPdf(pdf_name, hindi_pdf_name, pdf_hint, hindi_url, english_url, access, page_number, rowPerPage,))
        }
    }

}

export function createPdf(pdf_name, pdf_hint, english_url, access, page_number, rowPerPage,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_pdf", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                eng_pdf_name: pdf_name,
                // hnd_pdf_name: hindi_pdf_name,
                pdf_hint: pdf_hint,
                // hindi_url: hindi_url,
                english_url: english_url,
                access: access,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllPdf(page_number, rowPerPage, ""));
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function edit_eng_upload_pdf(pdf_id, pdf_name, pdf_hint, old_english_url, english_url, access, page_number, rowPerPage,) {
    return dispatch => {
        if (english_url !== "") {
            const params = {
                Bucket: "examys",
                Key: "PDF/" + pdf_name + "." + english_url.type.split('/')[1],
                Body: english_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"PDF/" + pdf_name + "." + english_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(updatePdf(pdf_id, pdf_name, pdf_hint, fileLocation, access, page_number, rowPerPage,));
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(updatePdf(pdf_id, pdf_name, pdf_hint, old_english_url, access, page_number, rowPerPage,));
        }
    }
}

export function edit_hin_upload_pdf(pdf_id, pdf_name, hindi_pdf_name, pdf_hint, old_hindi_url, hindi_url, english_url, access, page_number, rowPerPage,) {
    return dispatch => {
        if (hindi_url !== "") {
            const params = {
                Bucket: "olanger",
                Key: "PDF/" + hindi_pdf_name + "(hindi)" + "." + hindi_url.type.split('/')[1],
                Body: hindi_url,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"PDF/" + pdf_name + "(hindi)" + "." + hindi_url.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(updatePdf(pdf_id, pdf_name, hindi_pdf_name, pdf_hint, hindi_url, english_url, access, page_number, rowPerPage,));
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(updatePdf(pdf_id, pdf_name, hindi_pdf_name, pdf_hint, old_hindi_url, english_url, access, page_number, rowPerPage,));
        }
    }

}

export function updatePdf(pdf_id, pdf_name, pdf_hint, english_url, access, page_number, rowPerPage,) {
    return (dispatch) => {
        console.log(pdf_id, pdf_name, pdf_hint, english_url, access)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_pdf", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                pdf_id: pdf_id,
                // hnd_pdf_name:hindi_pdf_name,
                eng_pdf_name: pdf_name,
                // hindi_url: hindi_url,
                english_url: english_url,
                access: access,
                pdf_hint: pdf_hint
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllPdf(page_number, rowPerPage, ""));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllCategoryExam(category_id) {
    return (dispatch) => {
        console.log(category_id);
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_exams_by_category_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                category_id: category_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    // dispatch({ type: SET_EXAM, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i === responseJson.result.length - 1) {
                            dispatch({ type: SET_EXAMS, payload: { exam: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_EXAMS, payload: { exam: [] } })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// ----------------------Notification----------------------------------//
export function uploadNotification(exam_id, not_name,
    not_desc,
    not_issued_on,
    application_start_dt,
    last_dt_to_apply,
    ld_online_application_date,
    ld_online_application_time,
    ld_offline_chalan,
    ld_pay_through_chalan,
    ld_offline_chalan_time,
    application_correction_dt,
    application_correction_time,
    computer_exam_dt,
    conducting_body,
    exam_name,
    conducting_for,
    appliction_date,
    appliction_mode,
    vacency,
    salary,
    official_not_link,
    official_link,
    form_link
) {

    return dispatch => {
        if (official_not_link !== "") {
            const params = {
                Bucket: "olanger",
                Key: "Notification/" + official_not_link + "." + official_not_link.type.split('/')[1],
                Body: official_not_link,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"Notification/" + official_not_link + "." + official_not_link.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(uploadApplicationForm(exam_id, not_name,
                            not_desc,
                            not_issued_on,
                            application_start_dt,
                            last_dt_to_apply,
                            ld_online_application_date,
                            ld_online_application_time,
                            ld_offline_chalan,
                            ld_pay_through_chalan,
                            ld_offline_chalan_time,
                            application_correction_dt,
                            application_correction_time,
                            computer_exam_dt,
                            conducting_body,
                            exam_name,
                            conducting_for,
                            appliction_date,
                            appliction_mode,
                            vacency,
                            salary,
                            fileLocation,
                            official_link,
                            form_link))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            uploadApplicationForm(exam_id, not_name,
                not_desc,
                not_issued_on,
                application_start_dt,
                last_dt_to_apply,
                ld_online_application_date,
                ld_online_application_time,
                ld_offline_chalan,
                ld_pay_through_chalan,
                ld_offline_chalan_time,
                application_correction_dt,
                application_correction_time,
                computer_exam_dt,
                conducting_body,
                exam_name,
                conducting_for,
                appliction_date,
                appliction_mode,
                vacency,
                salary,
                official_not_link,
                official_link,
                form_link
            )
        }
    }
}

export function uploadApplicationForm(exam_id, not_name,
    not_desc,
    not_issued_on,
    application_start_dt,
    last_dt_to_apply,
    ld_online_application_date,
    ld_online_application_time,
    ld_offline_chalan,
    ld_pay_through_chalan,
    ld_offline_chalan_time,
    application_correction_dt,
    application_correction_time,
    computer_exam_dt,
    conducting_body,
    exam_name,
    conducting_for,
    appliction_date,
    appliction_mode,
    vacency,
    salary,
    official_not_link,
    official_link,
    form_link) {
    return dispatch => {

        if (form_link !== "") {
            const params = {
                Bucket: "olanger",
                Key: "Notification/" + form_link + "." + form_link.type.split('/')[1],
                Body: form_link,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {

                    const fileLocation = `https://olanger.s3.amazonaws.com/${"Notification/" + form_link + "." + form_link.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(createNotification(exam_id, not_name,
                            not_desc,
                            not_issued_on,
                            application_start_dt,
                            last_dt_to_apply,
                            ld_online_application_date,
                            ld_online_application_time,
                            ld_offline_chalan,
                            ld_pay_through_chalan,
                            ld_offline_chalan_time,
                            application_correction_dt,
                            application_correction_time,
                            computer_exam_dt,
                            conducting_body,
                            exam_name,
                            conducting_for,
                            appliction_date,
                            appliction_mode,
                            vacency,
                            salary,
                            official_not_link,
                            official_link,
                            fileLocation))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            createNotification(exam_id, not_name,
                not_desc,
                not_issued_on,
                application_start_dt,
                last_dt_to_apply,
                ld_online_application_date,
                ld_online_application_time,
                ld_offline_chalan,
                ld_pay_through_chalan,
                ld_offline_chalan_time,
                application_correction_dt,
                application_correction_time,
                computer_exam_dt,
                conducting_body,
                exam_name,
                conducting_for,
                appliction_date,
                appliction_mode,
                vacency,
                salary,
                official_not_link,
                official_link,
                form_link
            )
        }
    }
}


export function updateUploadNotification(
    examNotification_id,
    exam_id,
    not_name,
    not_desc,
    not_issued_on,
    application_start_dt,
    last_dt_to_apply,
    ld_online_application_date,
    ld_online_application_time,
    ld_offline_chalan,
    ld_pay_through_chalan,
    ld_offline_chalan_time,
    application_correction_dt,
    application_correction_time,
    computer_exam_dt,
    conducting_body,
    exam_name,
    conducting_for,
    appliction_date,
    appliction_mode,
    vacency,
    salary,
    old_official_not_link,
    official_not_link,
    official_link,
    old_form_link,
    form_link,
) {
    console.log("testtttttttttt", examNotification_id, exam_id,
        not_name,
        not_desc,
        not_issued_on,
        application_start_dt,
        last_dt_to_apply,
        ld_online_application_date,
        ld_online_application_time,
        ld_offline_chalan,
        ld_pay_through_chalan,
        ld_offline_chalan_time,
        application_correction_dt,
        application_correction_time,
        computer_exam_dt,
        conducting_body,
        exam_name,
        conducting_for,
        appliction_date,
        appliction_mode,
        vacency,
        salary,
        official_not_link,
        official_link,
        old_form_link,
        form_link,)
    return dispatch => {

        if (official_not_link !== "") {
            dispatch(setLoader(true));
            const storage = getStorage();
            const metadata = {
                contentType: "image/jpeg"
            };
            const storageRef = ref(storage, "/official_not_link/" + examNotification_id + ".pdf")
            const uploadTask = uploadBytesResumable(storageRef, official_not_link);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (official_not_link) {

                        dispatch(updateUploadApplicationForm(
                            examNotification_id,
                            exam_id,
                            not_name,
                            not_desc,
                            not_issued_on,
                            application_start_dt,
                            last_dt_to_apply,
                            ld_online_application_date,
                            ld_online_application_time,
                            ld_offline_chalan,
                            ld_pay_through_chalan,
                            ld_offline_chalan_time,
                            application_correction_dt,
                            application_correction_time,
                            computer_exam_dt,
                            conducting_body,
                            exam_name,
                            conducting_for,
                            appliction_date,
                            appliction_mode,
                            vacency,
                            salary,
                            official_not_link,
                            official_link,
                            old_form_link,
                            form_link,
                        ));
                    });
                }
            );
        }
        else {
            dispatch(updateUploadApplicationForm(
                examNotification_id,
                exam_id,
                not_name,
                not_desc,
                not_issued_on,
                application_start_dt,
                last_dt_to_apply,
                ld_online_application_date,
                ld_online_application_time,
                ld_offline_chalan,
                ld_pay_through_chalan,
                ld_offline_chalan_time,
                application_correction_dt,
                application_correction_time,
                computer_exam_dt,
                conducting_body,
                exam_name,
                conducting_for,
                appliction_date,
                appliction_mode,
                vacency,
                salary,
                old_official_not_link,
                official_link,
                old_form_link,
                form_link,
            ));
        }

    }
}

export function updateUploadApplicationForm(
    examNotification_id,
    exam_id,
    not_name,
    not_desc,
    not_issued_on,
    application_start_dt,
    last_dt_to_apply,
    ld_online_application_date,
    ld_online_application_time,
    ld_offline_chalan,
    ld_pay_through_chalan,
    ld_offline_chalan_time,
    application_correction_dt,
    application_correction_time,
    computer_exam_dt,
    conducting_body,
    exam_name,
    conducting_for,
    appliction_date,
    appliction_mode,
    vacency,
    salary,
    official_not_link,
    official_link,
    old_form_link,
    form_link,
) {
    return dispatch => {
        if (form_link !== "") {
            dispatch(setLoader(true));
            const storage = getStorage();
            const metadata = {
                contentType: "image/jpeg"
            };
            const storageRef = ref(storage, "/application_form/" + examNotification_id + ".pdf")
            const uploadTask = uploadBytesResumable(storageRef, form_link);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (form_link) {

                        dispatch(updateNotification(
                            examNotification_id,
                            exam_id,
                            not_name,
                            not_desc,
                            not_issued_on,
                            application_start_dt,
                            last_dt_to_apply,
                            ld_online_application_date,
                            ld_online_application_time,
                            ld_offline_chalan,
                            ld_pay_through_chalan,
                            ld_offline_chalan_time,
                            application_correction_dt,
                            application_correction_time,
                            computer_exam_dt,
                            conducting_body,
                            exam_name,
                            conducting_for,
                            appliction_date,
                            appliction_mode,
                            vacency,
                            salary,
                            official_not_link,
                            official_link,
                            form_link,
                        ));
                    });
                }
            );
        }
        else {
            dispatch(updateNotification(
                examNotification_id,
                exam_id,
                not_name,
                not_desc,
                not_issued_on,
                application_start_dt,
                last_dt_to_apply,
                ld_online_application_date,
                ld_online_application_time,
                ld_offline_chalan,
                ld_pay_through_chalan,
                ld_offline_chalan_time,
                application_correction_dt,
                application_correction_time,
                computer_exam_dt,
                conducting_body,
                exam_name,
                conducting_for,
                appliction_date,
                appliction_mode,
                vacency,
                salary,
                official_not_link,
                old_form_link,
                form_link,
            ));
        }

    }
}




export function setNotificationId(payload) {
    // 
    return {
        type: SET_NOTIFICATION_ID,
        payload: payload,
    };
}
export function setExamId(payload) {
    // 
    return {
        type: SET_EXAM_ID,
        payload: payload,
    };
}

export function setnot_name(payload) {

    return {
        type: SET_NOT_NAME,
        payload: payload,
    };
}
export function setnot_desc(payload) {

    return {
        type: SET_NOT_DESC,
        payload: payload,
    };
}
export function setnot_issued_on(payload) {

    return {
        type: SET_NOT_ISSUED_ON,
        payload: payload,
    };
}
export function setapplication_start_dt(payload) {

    return {
        type: SET_APPLICATION_START_DT,
        payload: payload,
    };
}
export function setlast_dt_to_apply(payload) {

    return {
        type: SET_LAST_DT_TO_APPLY,
        payload: payload,
    };
}
export function setld_online_application_time(payload) {

    return {
        type: SET_ONLINE_APP_TIME,
        payload: payload,
    };
}
export function setld_offline_chalan(payload) {

    return {
        type: SET_OFFLINE_CHALAN,
        payload: payload,
    };
}
export function setld_pay_through_chalan(payload) {

    return {
        type: SET_PAY_THROUGH_CHALAN,
        payload: payload,
    };
}
export function setld_offline_chalan_time(payload) {

    return {
        type: SET_OFFLINE_CHALAN_TIME,
        payload: payload,
    };
}
export function setapplication_correction_dt(payload) {

    return {
        type: SET_APP_CORRECT_DT,
        payload: payload,
    };
}
export function setapplication_correction_time(payload) {

    return {
        type: SET_APP_CORRECT_TIME,
        payload: payload,
    };
}
export function setcomputer_exam_dt(payload) {

    return {
        type: SET_COM_EXAM_DT,
        payload: payload,
    };
}
export function setconducting_body(payload) {

    return {
        type: SET_CON_BODY,
        payload: payload,
    };
}
export function setexam_name(payload) {

    return {
        type: SET_EXAM_NAME,
        payload: payload,
    };
}
export function setconducting_for(payload) {

    return {
        type: SET_CON_FOR,
        payload: payload,
    };
}
export function setpost_name(payload) {

    return {
        type: SET_POST_NAME,
        payload: payload,
    };
}
export function setappliction_date(payload) {

    return {
        type: SET_APP_DATE,
        payload: payload,
    };
}
export function setappliction_mode(payload) {

    return {
        type: SET_APP_MODE,
        payload: payload,
    };
}
export function setvacency(payload) {

    return {
        type: SET_VACANCY,
        payload: payload,
    };
}
export function setsalary(payload) {

    return {
        type: SET_SALARY,
        payload: payload,
    };
}
export function setofficial_not_link(payload) {

    return {
        type: SET_OFFICIAL_NOT_LINK,
        payload: payload,
    };
}
export function setofficial_link(payload) {

    return {
        type: SET_OFFICIAL_LINK,
        payload: payload,
    };
}
export function setform_link(payload) {

    return {
        type: SET_FORM_LINK,
        payload: payload,
    };
}




// -------------------------------------coupon-----------------------------------------

export function addCoupon(course_duration, coupon_code, save_amount, coupon_on, examysFirst) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_coupon ", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                course_duration: course_duration,
                coupon_code: coupon_code,
                save_amount: save_amount,
                coupon_on: coupon_on,
                examysFirst: examysFirst
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllCoupon());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllCoupon() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_coupon ",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {

                    dispatch({ type: VIEW_ALL_COUPON, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_COUPON, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function updateCoupon(coupon_id, course_duration, coupon_code, save_amount, coupon_on, examysFirst) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_coupon ", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                coupon_id: coupon_id,
                course_duration: course_duration,
                coupon_code: coupon_code,
                save_amount: save_amount,
                coupon_on: coupon_on,
                examysFirst: examysFirst
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllCoupon());
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteCoupon(coupon_id) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_coupon ", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                coupon_id: coupon_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllCoupon());
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                console.error(error);
            });
    };
}
//------------------------minipackage-----------------------------------------//
export function setMinipackageId(payload) {
    // 
    return {
        type: SET_MINIPACKAGE_ID,
        payload: payload,
    };
}
//------------------------ Test Series--------------------------//
export function setTextseries(payload) {
    return {
        type: SET_TESTSERIES_IDS,
        payload: payload,
    };
}

//-------------Previous Year --------------------------------//
export function setPreviousYearId(payload) {
    // 
    return {
        type: SET_PREVIOUSYEAR_ID,
        payload: payload,
    };
}
//-------------------------------Exam Ai-------------------------------------//
export function setExamAi(payload) {
    // 
    return {
        type: SET_EXAMAI_ID,
        payload: payload,
    };
}

// export function addQuizTQuestion(

// ) {


//     return {
//         type: ADD_QUIZ_QUESTION,
//         payload:{ }
//     };
// }

export function addQuizTQuestion(
    question_id,
    question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc,

) {
    return (dispatch) => {
        console.log("addquizzddddddddddddd",
            question_text,
            question_desc,
            question_img,
            option1,
            option1_img,
            option1_desc,
            option2,
            option2_img,
            option2_desc,
            option3,
            option3_img,
            option3_desc,
            option4,
            option4_img,
            option4_desc,
            correct_ans,
            correct_ans_img,
            correct_ans_desc,
            question_id
        )
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_update_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: question_id,
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTQuestion());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function addUpdateQuizQuestion(
    question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    option5,
    option5_img,
    option5_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc, id, subject_id
) {
    return (dispatch) => {
        console.log("addquizzddddddddddddd",
            question_text,
            question_desc,
            question_img,
            option1,
            option1_img,
            option1_desc,
            option2,
            option2_img,
            option2_desc,
            option3,
            option3_img,
            option3_desc,
            option4,
            option4_img,
            option4_desc,
            correct_ans,
            correct_ans_img,
            correct_ans_desc,
            id
        )
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_update_question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: id,
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                option5: option5,
                option5_img: option5_img,
                option5_desc: option5_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,
                subject_id: subject_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTQuestion());
                    dispatch(viewalldatabyQuizId(id));
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function add_update_testseries_quiz(
    question_id, question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc, id, subject_id, section_id, question_marks, cut_marks
) {
    return (dispatch) => {
        console.log("addquizzddddddddddddd",
            question_id, question_text,
            question_desc,
            question_img,
            option1,
            option1_img,
            option1_desc,
            option2,
            option2_img,
            option2_desc,
            option3,
            option3_img,
            option3_desc,
            option4,
            option4_img,
            option4_desc,
            correct_ans,
            correct_ans_img,
            correct_ans_desc, id
        )
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_update_testseries_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: id,
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,
                subject_id: subject_id,
                section_id: section_id,
                question_marks: question_marks,
                cut_marks: cut_marks
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewalldatabyTQuizId(id));
                    // dispatch(viewAllTQuestion());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_testseries_question(
    question_id,
    question_text,
    question_desc,
    question_img,
    option1,
    option1_img,
    option1_desc,
    option2,
    option2_img,
    option2_desc,
    option3,
    option3_img,
    option3_desc,
    option4,
    option4_img,
    option4_desc,
    option5,
    option5_img,
    option5_desc,
    correct_ans,
    correct_ans_img,
    correct_ans_desc, id, cut_marks, question_marks, section_id, subject_id, language_id
) {
    return (dispatch) => {
        console.log("editquizzddddddddddddd",
            question_id,
            question_text,
            question_desc,
            question_img,
            option1,
            option1_img,
            option1_desc,
            option2,
            option2_img,
            option2_desc,
            option3,
            option3_img,
            option3_desc,
            option4,
            option4_img,
            option4_desc,
            option5,
            option5_img,
            option5_desc,
            correct_ans,
            correct_ans_img,
            correct_ans_desc, id, cut_marks, question_marks, section_id, subject_id, language_id
        )
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_testseries_questions", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_id: question_id,
                quiz_id: id,
                question_text: question_text,
                question_desc: question_desc,
                question_img: question_img,
                option1: option1,
                option1_img: option1_img,
                option1_desc: option1_desc,
                option2: option2,
                option2_img: option2_img,
                option2_desc: option2_desc,
                option3: option3,
                option3_img: option3_img,
                option3_desc: option3_desc,
                option4: option4,
                option4_img: option4_img,
                option4_desc: option4_desc,
                option5: option5,
                option5_img: option5_img,
                option5_desc: option5_desc,
                correct_ans: correct_ans,
                correct_ans_img: correct_ans_img,
                correct_ans_desc: correct_ans_desc,
                cut_marks: cut_marks,
                question_marks: question_marks,
                section_id: section_id,
                subject_id: subject_id,
                language_id: language_id,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewalldatabyTQuizId(id));
                } else {
                    dispatch(viewalldatabyTQuizId(id));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function update_section(quiz_id, section) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_T_quiz_section", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quiz_id: quiz_id,
                section: section
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewalldatabyTQuizId(quiz_id));
                    dispatch(setTquizID(quiz_id))
                } else {
                    dispatch(viewalldatabyTQuizId(quiz_id));
                    dispatch(setTquizID(quiz_id))
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}






export function view_minipackage_by_id(minipackage_id) {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_minipackage_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                minipackage_id: minipackage_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: SET_MINIPACKAGE_BY_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_MINIPACKAGE_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



export function delete_question_update_quiz(id, Qid) {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_question_update_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_id: id,
                quiz_id: Qid,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: SET_MINIPACKAGE_BY_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                    dispatch(viewalldatabyQuizId(Qid));
                } else {
                    dispatch({ type: SET_MINIPACKAGE_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function delete_question_update_testseries_quiz(id, Qid, section_id, subject_id, language_id) {
    return (dispatch) => {
        dispatch(setLoader());
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_question_testseries_quiz", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                question_index: id,
                quiz_id: Qid,
                section_id: section_id,
                subject_id: subject_id,
                language_id: language_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {

                    dispatch(viewalldatabyTQuizId(Qid));

                } else {
                    dispatch(viewalldatabyTQuizId(Qid));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
///-------------------------------------------------------Search -------------------------------------//
export function searchPrevious(all_previous_year, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_PREVIOUS, payload: all_previous_year });
        }
        else {
            const newArray = all_previous_year.filter((el) => {
                // 
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PREVIOUS, payload: newArray })
        }
    };
}

export function searchPackages(all_packages, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_PACKAGES, payload: all_packages });
        }
        else {
            const newArray = all_packages.filter((el) => {
                // 
                return (el.package_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PACKAGES, payload: newArray })
        }
    };
}





export function searchMiniPackages(all_minipackages, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_MINIPACKAGE, payload: all_minipackages });
        }
        else {
            const newArray = all_minipackages.filter((el) => {
                // 
                return (el.mini_hint.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_MINIPACKAGE, payload: newArray })
        }
    };
}
export function searchTestSeriess(all_testseries, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEATCH_TESTSERIES, payload: all_testseries });
        }
        else {
            const newArray = all_testseries.filter((el) => {

                return (el.hints.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEATCH_TESTSERIES, payload: newArray })
        }
    };
}
export function searchExamAi(all_exam_ai, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEATCH_EXAMAI, payload: all_exam_ai });
        }
        else {
            const newArray = all_exam_ai.filter((el) => {
                // 
                return (el.examAI_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEATCH_EXAMAI, payload: newArray })
        }
    };
}


export function searchquizes(all_quiz, e) {
    return (dispatch) => {
        // dispatch(setLoader());

        if (e === "") {
            dispatch({ type: SEARCH_QUIZES, payload: all_quiz });
        }
        else {
            const newArray = all_quiz.filter((el) => {
                // 
                return (el.quiz_desc.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_QUIZES, payload: newArray })
        }
    };
}


export function testsearchquizes(test_all_quiz, e) {
    return (dispatch) => {
        // dispatch(setLoader());

        if (e === "") {
            dispatch({ type: TEST_SEARCH_QUIZES, payload: test_all_quiz });
        }
        else {
            const newArray = test_all_quiz.filter((el) => {
                // 
                return (el.quiz_desc.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: TEST_SEARCH_QUIZES, payload: newArray })
        }
    };
}

export function searchvideos(all_video, e) {
    return (dispatch) => {
        // dispatch(setLoader());

        if (e === "") {
            dispatch({ type: SEARCH_VIDEOES, payload: all_video });
        }
        else {
            const newArray = all_video.filter((el) => {
                // 
                return (el.video_hint.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_VIDEOES, payload: newArray })
        }
    };
}


export function searchpdfs(all_pdf, e) {
    return (dispatch) => {
        // dispatch(setLoader());

        if (e === "") {
            dispatch({ type: SEARCH_PDFS, payload: all_pdf });
        }
        else {
            const newArray = all_pdf.filter((el) => {
                // 
                return (el.pdf_hint.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PDFS, payload: newArray })
        }
    };
}

export function setUUID(uuid) {
    return {
        type: SET_UUID,
        payload: uuid,
    };
}
export function subject_id(payload) {
    return {
        type: SET_SUBJECT_ID,
        payload: payload,
    };
}
export function subjects_id(payload) {
    return {
        type: SET_SUBJECTS_ID,
        payload: payload,
    };
}
export function section_id(payload) {
    return {
        type: SET_SECTION_ID,
        payload: payload,
    };
}
export function subjectss_id(payload) {
    return {
        type: SET_SUBJECT_IDS,
        payload: payload,
    };
}
export function language_id(payload) {
    return {
        type: SET_LANGUAGE_ID,
        payload: payload,
    };
}


export function viewAllLanguage() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_language", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch({ type: SET_CHAPTER, payload: responseJson.result });

                    // for (var i = 0; i < responseJson.result.length; i++) {
                    //     responseJson.result[i].quantity = 0;
                    //     responseJson.result[i].checked = false;
                    //     if (i == responseJson.result.length - 1) {

                    dispatch({ type: VIEW_ALL_LANGUAGE, payload: { language: responseJson.result } });

                    //     }

                    // }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_LANGUAGE, payload: [] })


                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//-------------------------------------Notification-------------------------------------------------------//
export function uploadNotifications(image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery) {
    return dispatch => {
        if (image !== "") {
            const params = {
                Bucket: "examys",
                Key: "Notification/" + noti_name + "." + image.type.split('/')[1],
                Body: image,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {
                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"Notification/" + noti_name + "." + image.type.split('/')[1]}`;
                    // console.log("fileLocation", fileLocation);
                    if (fileLocation !== "") {
                        dispatch(createNotifications(fileLocation, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(createNotifications(image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery))
        }
    }
}
export function createNotifications(image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_Notification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                image: image,
                not_name: noti_name,
                description: description,
                form_link: link,
                date: date,
                youTubelink: youTubelink,
                category: category,
                exam: exam,
                stage: stage,
                notify_details: notify_details

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllNotification(page_number, rowPerPage, searchQuery));
                } else {
                    dispatch(viewAllNotification(page_number, rowPerPage, searchQuery));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// export function viewAllNotification(page_number, rowPerPage, searchQuery) {
//     return (dispatch) => {
//         // dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "view_all_Notification", {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },

//         })
//             .then((response) => response.json())
//             .then((responseJson) => {


//                 if (responseJson.status) {
//                     // dispatch({ type: SET_CATEGORY, payload: responseJson.result });
//                     for (var i = 0; i < responseJson.result.length; i++) {
//                         responseJson.result[i].quantity = 0;
//                         responseJson.result[i].checked = false;
//                         if (i == responseJson.result.length - 1) {
//                             dispatch({ type: VIEW_ALL_NOTIFICATION, payload: { notification: responseJson.result } });
//                         }

//                     }
//                     // dispatch(unsetLoader());
//                 } else {
//                     dispatch({ type: VIEW_ALL_NOTIFICATION, payload: [] })
//                 }
//                 // dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }

export function viewAllNotification(page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());

        let url = UNIVERSAL.BASEURL + "view_all_notification_with_pgn";
        if (searchQuery) {
            // Append searchQuery to the URL if provided
            url += `?searchQuery=${encodeURIComponent(searchQuery)}`;
        }

        return fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // No need to include body in GET request
            body: JSON.stringify({
                page_number: page_number,
                rowPerPage: rowPerPage
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // Process fetched PDF data
                    console.log(responseJson, "responseJsonresponseJson");
                    const processedData = responseJson.result.notification.map(notification => ({
                        ...notification,
                        quantity: 0,
                        checked: false
                    }));
                    dispatch({
                        type: VIEW_ALL_NOTIFICATION, payload: {
                            notification: processedData, count: responseJson.result.totalLength
                        }
                    });
                    // for (var i = 0; i < responseJson.result.subject.length; i++) {
                    //     responseJson.result[i].subject.quantity = 0;
                    //     responseJson.result.subject[i].checked = false;
                    //     if (i == responseJson.result.subject.length - 1) {
                    //         dispatch({ type: SET_SUBJECT, payload: { subject: responseJson.result.subject, count: responseJson.result.totalLength } });
                    //     }
                    // }
                } else {
                    dispatch({ type: VIEW_ALL_NOTIFICATION, payload: { notification: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
                // Handle errors if needed
            });
    };
}


export function uploadUpdateNotification(notification_id, old_image, image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery) {
    console.log("ggggggggggg", notification_id, old_image, image);
    return dispatch => {
        if (image !== "") {
            const params = {
                Bucket: "examys",
                Key: "Notification/" + noti_name + "." + image.type.split('/')[1],
                Body: image,
            };
            const uploadCommand = new PutObjectCommand(params);

            client.send(uploadCommand)
                .then((data) => {
                    const fileLocation = `https://examys.s3.ap-south-1.amazonaws.com/${"Notification/" + noti_name + "." + image.type.split('/')[1]}`;
                    if (fileLocation !== "") {
                        dispatch(updateNotifications(notification_id, fileLocation, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery))
                    }
                })
                .catch((err) => {
                    console.error("Error uploading file:", err);
                });
        }
        else {
            dispatch(updateNotifications(notification_id, old_image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery))
        }
    }
}

export function updateNotifications(notification_id, image, noti_name, date, description, link, youTubelink, category, exam, stage, notify_details, page_number, rowPerPage, searchQuery) {
    console.log("fffffffffssssssssssss", notification_id, image, noti_name,);
    return (dispatch) => {
        console.log("dddddddddd", notification_id, image, noti_name,);

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_Notification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                notification_id: notification_id,
                image: image,
                not_name: noti_name,
                date: date,
                description: description,
                form_link: link,
                youTubelink: youTubelink,
                category: category,
                exam: exam,
                stage: stage,
                notify_details: notify_details
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllNotification(page_number, rowPerPage, searchQuery));
                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAllNotification(page_number, rowPerPage, searchQuery));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteNotifications(notification_id, page_number, rowPerPage, searchQuery) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_Notification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                notification_id: notification_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllNotification(page_number, rowPerPage, searchQuery));

                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAllNotification(page_number, rowPerPage, searchQuery));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
//----------------------------------------------------Testpass Pro------------------------------------------------//
export function createTestPass(name, title, price, discount, discounted_price, offer, ref_offer, total_amount) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_testpasspro", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                title: title,
                price: price,
                discount: discount,
                discounted_price: discounted_price,
                offer: offer,
                ref_offer: ref_offer,
                total_amount: total_amount

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTestPass());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllTestPass() {
    return (dispatch) => {
        // dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_testpasspro", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {


                if (responseJson.status) {
                    // dispatch({ type: SET_CATEGORY, payload: responseJson.result });
                    for (var i = 0; i < responseJson.result.length; i++) {
                        responseJson.result[i].quantity = 0;
                        responseJson.result[i].checked = false;
                        if (i == responseJson.result.length - 1) {
                            dispatch({ type: VIEW_ALL_TESTPASS, payload: { testpass: responseJson.result } });
                        }

                    }
                    // dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TESTPASS, payload: [] })
                }
                // dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function updateTestPass(testpass_id, name, title, price, discount, discounted_price, offer, ref_offer, total_amount) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_testpasspro", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                testpass_id: testpass_id,
                name: name,
                title: title,
                price: price,
                discount: discount,
                discounted_price: discounted_price,
                offer: offer,
                ref_offer: ref_offer,
                total_amount: total_amount
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTestPass());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteTestpass(testpass_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_testpasspro", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                testpass_id: testpass_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllTestPass());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}