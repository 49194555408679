import React, { Component } from 'react'
import {Grid, CardContent, Box, Button } from "@mui/material";
import "../style/category.css"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Snackbar from "../../../common/snackbar/components/snackbar";
// import LoaderCon from "../../../common/loader/containers/loader_cont";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            student_id: ""

        }
    }

    componentDidMount() {
        this.props.view_all_student();
    }

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;

        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between", marginLeft: "4%", marginRight: "1%", textAlign: 'center', }}>
                    <Box className='categoryname' >
                        <center>
                            <Button style={{ color: "white ", textAlign: "center",}}>Users</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "1.5%" }}>
                        <center style={{ borderRadius: 0 }}>
                        </center>
                    </Box>
                </Grid>
                <Box marginLeft={'2.5%'} marginRight={"30%"}>
                    <Box className='border'>
                        <CardContent style={{ marginRight: "0%" }}>
                            <TableContainer >
                                <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                    <TableHead className="categoryhead">
                                        <TableRow >
                                            <TableCell style={{ textAlign: 'left' }} >Name</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Phone No.</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Examys First</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Array.isArray(admin.all_student) && admin.all_student.map((item) => {
                                            
                                            return (
                                                <TableRow>
                                                    
                                                    <TableCell style={{ textAlign: 'left' }} >{item.student_name}</TableCell> 
                                                    <TableCell style={{ textAlign: 'center'}} >+91 {item.mobile} </TableCell>
                                                    <TableCell style={{ textAlign: 'center' }} >{ item.examysFirst !== "" ? <CheckCircleIcon style={{color: "green"}} /> : "" }</TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>

                </Box>
                {/* <LoaderCon /> */}
                {/* <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          /> */}

            </Grid>
        )
    }
}