import React, { Component } from "react";
import { connect } from "react-redux";
import PackageCategory from "../component/packagecategory";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createPackageCategory,
    updatePackageCategory,
    deletePackageCategory,
    viewAllPackageCategory
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <PackageCategory {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createPackageCategory: (packagecategory_name) => {
      dispatch(createPackageCategory(packagecategory_name))
    },
    updatePackageCategory: (packagecategory_id,packagecategory_name) => {
      dispatch(updatePackageCategory(packagecategory_id,packagecategory_name))
    },
    deletePackageCategory: (packagecategory_id) => {
      dispatch(deletePackageCategory(packagecategory_id))
    },
    viewAllPackageCategory: () => {
      dispatch(viewAllPackageCategory())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);