import React, { Component } from "react";
import { connect } from "react-redux";
import Coupon from "../component/Coupon";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  addCoupon,
  viewAllCoupon,
  updateCoupon,
  deleteCoupon
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Coupon {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {

    addCoupon: (course_duration, coupon_code, save_amount, coupon_on,examysFirst) => {
      dispatch(addCoupon(course_duration, coupon_code, save_amount, coupon_on,examysFirst))
    },   

    viewAllCoupon: () => {
      dispatch(viewAllCoupon())
    }, 

    updateCoupon: (coupon_id,course_duration, coupon_code, save_amount, coupon_on,examysFirst) => {
      dispatch(updateCoupon(coupon_id,course_duration, coupon_code, save_amount, coupon_on,examysFirst))
    }, 

    deleteCoupon: (coupon_id) => {
      dispatch(deleteCoupon(coupon_id))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);