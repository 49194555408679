/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from "react-router-dom";
import LG_Cont from "../containers/Lg_Cont";
import "../styles/drawer.css";
import Logo2 from "../../pages/admin/images/Logo2.png"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {
  all_admin_option,
  // all_super_admin_options,
  // all_corporate_options,
  // all_tourism_options
  // all_manager_options,

} from "../constant";
import { Avatar, Grid } from '@mui/material';
import Icon from "@mui/material/Icon";
import ListItemIcon from '@mui/material/ListItemIcon';
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor:'red',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function MiniDrawer(props) {
  const [open, setOpen] = React.useState(false);
  const [expandopen, setExpand] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [openValue, setValue] = React.useState(true);
  const [countexp, setExp] = React.useState(0);



  const setIndex = (bkb, index) => {
    if (bkb > 0 && expandopen === index) {
      setExpand("")
    }
  };
  function sideBar(type, open) {
    if (type === "A") {
      return (

        <div style={{ padding: '10px'}}>
          {all_admin_option.map((option, index) => (
            <Link
              key={option.link} to={option.link} style={{ fontFamily: 'Georgia', fontWeight: 400, fontSize: "16px", textDecoration: "none", color: 'black' }}   >
              {option.expand ?
                <div key={option.id}>
                  <div>
                    <ListItem key={index} sx={{ "&:hover": { backgroundColor: "transparent", color: '#6257CD' } }}
                      onClick={() => {
                        setExpand(index)
                        setExp(countexp + 1)
                        setIndex(countexp, index)
                      }} className="drawertext"  >

                      <div style={{ display: "flex", justifyContent: "space-between", width: "150px" }}>
                        <div>{option.name}</div>

                        <div style={{}} >{expandopen === index ? <ExpandLess className="expand_icon" /> : <ExpandMore className="expand_icon" />}</div>
                      </div>
                    </ListItem>
                  </div>
                  {/* <Divider /> */}
                  {expandopen === index &&
                    <Collapse in={expandopen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding style={{ marginLeft: "5px", backgroundColor: "white" }}>
                        {option.item.map((item) => (
                          <Link to={item.link} style={{ textDecoration: 'none', color: 'black' }}>
                            <ListItemButton sx={{ "&:hover": { backgroundColor: "transparent", color: 'black', fontSize: '20px' }, color: '#6257CD' }} id={item.id} className="subdrawertext">

                              {item.name}

                            </ListItemButton>
                          </Link>
                        ))}
                      </List>
                    </Collapse>}
                </div>
                :
                <ListItem sx={{ "&:hover": { backgroundColor: "transparent", color: '#6257CD' } }} button key={option.name} className="drawertext" >
                  {option.name}
                </ListItem>
              }

            </Link>
          ))}
        </div>
      );
    }

  }

  return (
    <Box sx={{ display: 'flex',}}>
      <CssBaseline />
      <Drawer variant="permanent" open={true} >
        <Grid style={{ backgroundColor: '#EEECFE', marginTop: 0, height: '110%', paddingTop: 20, overflowY: 'scroll'}}>
          <center>
            <Avatar src={Logo2} variant="rounded" alt="no_img" style={{width: 100, height: 100,}} />
          </center>

          <List >
            {sideBar(props.type, open)}
          </List>

          <center>
            <LG_Cont />

          </center>
        </Grid>
      </Drawer>


    </Box>
  );
}
