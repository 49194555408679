const UNIVERSAL = {
  // BASEURL: "http://localhost:8000/",
  // BASEURL: "http://192.168.1.49:8010/",
  // BASEURL: "https://efdc-103-191-198-88.ngrok-free.app/",
   BASEURL: "https://api.examys.com/",
  // BASEURL: "https://apitest.examys.com/",//
  // BASEURL: " https://olangartest-tlc77burdq-el.a.run.app/",
  // BASEURL: "https://olangartest-tlc77burdq-el.a.run.app/",
  
};
export default UNIVERSAL;


