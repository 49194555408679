import React, { Component } from "react";
import { connect } from "react-redux";
import Edittextseries from "../component/Edittextseries";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewAllCategory,
  viewAllExams,
  viewAllStages,
  viewAllSubject,
  CheckedCategory,
  Checkedexam,
  Checkedstage,
  Checkedsubject,
  viewAllPackageCategory,
  Checkedminipackage,
  UnCheckedExam,
  UnCheckedStage,
  UnCheckedSubject,
  UnCheckedMinipackage,
  SelectCategory,
  SelectExam,
  SelectExamm,
  NullExam,
  SelectStage,
  NullStage,
  SelectSubject,
  NullSubject,
  SelectMini,
  NullMini,
  viewAllMiniPackager,
  searchcategory,
  searchexam,
  searchstage,
  searchsubject,
  searchminipackage,
  NullTest,
  NullTestTypes,
  NullQuiz,viewAllTests,
  Checkedtests,
  searchtests,
  SelectTest,
  searchtesttype,
  Checkedtesttype,
  SelectTestType,
  viewAllTestsType,
  viewAllTSubject,
  viewAllTquiz,
  Checkedquiz,
  SelectTSubject,
  SelectQuizz,
  viewAllCategoryExam,
  searchquiz,
  setTextseries,
  UpdatesTestSeries

} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Edittextseries {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    setTextseries: (payload) => {
      dispatch(setTextseries(payload))
    },
    viewAllExams: () => {
      dispatch(viewAllExams())
    },
    viewAllStages: () => {
      dispatch(viewAllStages())
    },
    viewAllSubject: () => {
      dispatch(viewAllSubject())
    },
    viewAllTests: () => {
      dispatch(viewAllTests())
    },
    viewAllTestsType: () => {
      dispatch(viewAllTestsType())
    },
    viewAllTSubject: () => {
      dispatch(viewAllTSubject())
    },
    viewAllTquiz: () => {
      dispatch(viewAllTquiz())
    },
    CheckedCategory: (index,checked,quantity,all_category) => {
      dispatch(CheckedCategory(index,checked,quantity,all_category))
    },
    Checkedexam: (index,checked,quantity,all_exam) => {
      dispatch(Checkedexam(index,checked,quantity,all_exam))
    },
    Checkedstage: (index,checked,quantity,all_stages) => {
      dispatch(Checkedstage(index,checked,quantity,all_stages))
    },
    Checkedsubject: (index,checked,quantity,all_subject) => {
      dispatch(Checkedsubject(index,checked,quantity,all_subject))
    },
    Checkedminipackage: (index, checked, quantity, all_minipackages) => {
      dispatch(Checkedminipackage(index, checked, quantity, all_minipackages))
    },
    Checkedtests: (index, checked, quantity, tests) => {
      dispatch(Checkedtests(index, checked, quantity, tests))
    },
    Checkedtesttype: (index, checked, quantity, testtypes) => {
      dispatch(Checkedtesttype(index, checked, quantity, testtypes))
    },
    Checkedquiz: (index, checked, quantity, all_quiz) => {
      dispatch(Checkedquiz(index, checked, quantity, all_quiz))
    },
    viewAllPackageCategory: () => {
      dispatch(viewAllPackageCategory())
    },
    viewAllMiniPackager: () => {
      dispatch(viewAllMiniPackager())
    },
    UnCheckedExam: (all_exam) => {
      dispatch(UnCheckedExam(all_exam))
    },
    UnCheckedStage: (all_stages) => {
      dispatch(UnCheckedStage(all_stages))
    },
    UnCheckedSubject: (all_subject) => {
      dispatch(UnCheckedSubject(all_subject))
    },
    UnCheckedMinipackage: (all_minipackages) => {
      dispatch(UnCheckedMinipackage(all_minipackages))
    },
    SelectCategory: (category) => {
      dispatch(SelectCategory(category))
    },
    SelectExam: (category, count, exam) => {
      dispatch(SelectExam(category, count, exam))
    },
    SelectExamm: (exam) => {
      dispatch(SelectExamm(exam))
    },
    NullExam: () => {
      dispatch(NullExam())
    },
    SelectStage: (exam, count, stage) => {
      dispatch(SelectStage(exam, count, stage))
    },
    NullStage: () => {
      dispatch(NullStage())
    },
    SelectSubject: (stage, count, sub) => {
      dispatch(SelectSubject(stage, count, sub))
    },
    SelectTSubject: (testtype, count, sub) => {
      dispatch(SelectTSubject(testtype, count, sub))
    },
    SelectQuizz: (subject, count, quizz) => {
      dispatch(SelectQuizz(subject, count, quizz))
    },
    NullSubject: () => {
      dispatch(NullSubject())
    },
    SelectMini: (sub, count, mini) => {
      dispatch(SelectMini(sub, count, mini))
    },
    SelectTest: (stage, count, test) => {
      dispatch(SelectTest(stage, count, test))
    },
    SelectTestType: (test, count, testtype) => {
      dispatch(SelectTestType(test, count, testtype))
    },
    searchtesttype: (testtypes, e) => {
      dispatch(searchtesttype(testtypes, e))
    },
    NullMini: () => {
      dispatch(NullMini())
    },
    
    NullTest: () => {
      dispatch(NullTest())
    },
    NullTestTypes: () => {
      dispatch(NullTestTypes())
    },
    NullQuiz: () => {
      dispatch(NullQuiz())
    },
    searchquiz: (quiz,e) => {
      dispatch(searchquiz(quiz,e))
    },
    
    UpdatesTestSeries:(testSeriesIds_id, package_name, hints,package_desc, package_hint, category, exam, stage, test_content,new_exam_pattern,language) => {
      dispatch(UpdatesTestSeries(testSeriesIds_id, package_name,hints, package_desc, package_hint, category, exam, stage, test_content,new_exam_pattern,language))
    },
   
    searchcategory: (category,e) => {
      dispatch(searchcategory(category,e))
    },
    searchexam: (exam,e) => {
      dispatch(searchexam(exam,e))
    },
    searchstage: (stage,e) => {
      dispatch(searchstage(stage,e))
    },
    searchsubject: (subject,e) => {
      dispatch(searchsubject(subject,e))
    },
    searchminipackage: (minipackage,e) => {
      dispatch(searchminipackage(minipackage,e))
    },
    searchtests: (tests, e) => {
      dispatch(searchtests(tests, e))
    },
    viewAllCategoryExam: (category_id) => {
      dispatch(viewAllCategoryExam(category_id))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);