import { initializeApp } from "firebase/app";


export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const HANDLEDRAWER = "HANDLEDRAWER";


const firebaseConfig = {
  apiKey: "AIzaSyDcExAHP8k4q77qXsyfbs0k4s9r3VSUNGU",
  authDomain: "olanger-2f470.firebaseapp.com",
  projectId: "olanger-2f470",
  storageBucket: "olanger-2f470.appspot.com",
  messagingSenderId: "257506803719",
  appId: "1:257506803719:web:d1007c53af9d0cb0e9eaa2",
  measurementId: "G-NNE7LSZTSC"
};

const app = initializeApp(firebaseConfig);
