import React, { Component } from 'react'
import {
    Grid,
    Box,
    IconButton,
    Button,
    Typography,
    FormControl,
    Select,
    InputLabel,
    Chip
} from "@mui/material";
import "../style/category.css"
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase"
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Loader from '../../../common/loader/containers/loader_cont';
import NorthIcon from '@mui/icons-material/North';
import CheckIcon from '@mui/icons-material/Check';
import Pagination from '@mui/material/Pagination';
// import SearchIcon from '@material-ui/icons/Search';
import Paper from "@mui/material/Paper";
import { debounce } from 'lodash';

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Lid: 0,
            Vid: 0,
            Pid: 0,
            Qid: 0,
            package_content: this.props.admin.package_id.package_content,
            categorymodal: false,
            Exammodal: false,
            stagemodal: false,
            subjectmodal: false,
            minipackagemodal: false,
            previousyearmodal: false,
            category_name: "",
            exam_name: "",
            subject_name: "",
            stage_name: "",
            package_name: this.props.admin.package_id.package_name,
            price: this.props.admin.package_id.package_price,
            duration: this.props.admin.package_id.package_duration,
            ex: "",
            sta: "",
            cat: "",
            sub: "",
            min: "",
            testseries: "",
            package_category: "",
            packagecategory_id: this.props.admin.package_id.packagecategory_id,
            tsmodal: false,
            userinfo: {
                chapter_array: [],
                response: [],
            },
            countcat: 0,
            countex: 0,
            countsta: 0,
            countsub: 0,
            countmin: 0,
            catcount: -1,
            excount: -1,
            stacount: -1,
            subcount: -1,
            minicount: -1,
            package_desc: this.props.admin.package_id.package_desc,
            course_journey: this.props.admin.package_id.course_journey,
            team_course: this.props.admin.package_id.team_course,
            syllabus_pattern: this.props.admin.package_id.syllabus_pattern,
            faq: this.props.admin.package_id.faq,
            discounted_price: this.props.admin.package_id.discounted_price,
            student_special: this.props.admin.package_id.student_special,
            access: this.props.admin.package_id.access,
            old_video_url: this.props.admin.package_id.video,
            old_pdf_url: this.props.admin.package_id.pdf,
            finalPrice: this.props.admin.package_id.finalPrice,
            referralOffer: this.props.admin.package_id.referralOffer,
            newUserOffer: this.props.admin.package_id.newUserOffer,
            discountInPer: this.props.admin.package_id.discountInPer,
            video_url: "",
            pdf_url: "",
            details: [
                {
                    access_name: "Paid"
                },
                {
                    access_name: "Free"
                },
            ],


            categoryChecked: [],
            category_filtered: [],
            examChecked: [],
            exam_filtered: [],
            stage_filtered: [],
            stageChecked: [],
            subjectChecked: [],
            subject_filtered: [],
            minipackageChecked: [],
            minipackage_filtered: [],
            page: 0,
            setPage: 0,
            rowsPerPage: 30,
            searchTerm: '',
            searchCategory: ''
        }
        this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 500);
        this.debouncedSearchcategory = debounce(this.debouncedSearchcategory.bind(this), 500);
    }


    UnCheckedcategory = (all_category, itemToUncheck) => {
        this.setState((prevState) => ({
            categoryChecked: prevState.categoryChecked.filter((item) => item !== itemToUncheck),
            category_filtered: all_category,
            // ... other state updates
        }));
    };
    UnCheckedexam = (all_exam, itemToUncheck) => {
        this.setState((prevState) => ({
            examChecked: prevState.examChecked.filter((item) => item !== itemToUncheck),
            exam_filtered: all_exam,
            // ... other state updates
        }));
    };
    UnCheckedstage = (all_stages, itemToUncheck) => {
        this.setState((prevState) => ({
            stageChecked: prevState.stageChecked.filter((item) => item !== itemToUncheck),
            stage_filtered: all_stages,
            // ... other state updates
        }));
    };
    UnCheckedsubject = (all_subject, itemToUncheck) => {
        this.setState((prevState) => ({
            subjectChecked: prevState.subjectChecked.filter((item) => item !== itemToUncheck),
            subject_filtered: all_subject,
            // ... other state updates
        }));
    };
    UnCheckedminipackage = (all_minipackages, itemToUncheck) => {
        this.setState((prevState) => ({
            minipackageChecked: prevState.minipackageChecked.filter((item) => item !== itemToUncheck),
            minipackage_filtered: all_minipackages,
            // ... other state updates
        }));
    };

    CheckedCategory(p, index, category) {
        // 
        if (p.target.checked) {
            this.props.CheckedCategory(index, true, 1, category)
        }
        else {
            this.props.CheckedCategory(index, false, 0, category)
        }

    }
    Checkedexam(p, index, exams) {
        // 
        if (p.target.checked) {
            this.props.Checkedexam(index, true, 1, exams)
        }
        else {
            this.props.Checkedexam(index, false, 0, exams)
        }
    }

    Checkedexamai(p, index, examai) {

        if (p.target.checked) {
            this.props.Checkedexamai(index, true, 1, examai)
        }
        else {
            this.props.Checkedexamai(index, false, 0, examai)
        }
    }

    Checkedpreviousyear(p, index, previousYear) {

        if (p.target.checked) {
            this.props.Checkedpreviousyear(index, true, 1, previousYear)
        }
        else {
            this.props.Checkedpreviousyear(index, false, 0, previousYear)
        }
    }

    Checkedstage(p, index, stage) {
        // 
        if (p.target.checked) {
            this.props.Checkedstage(index, true, 1, stage)
        }
        else {
            this.props.Checkedstage(index, false, 0, stage)
        }

    }
    Checkedsubject(p, index, subjects) {
        // 
        if (p.target.checked) {
            this.props.Checkedsubject(index, true, 1, subjects)
        }
        else {
            this.props.Checkedsubject(index, false, 0, subjects)
        }

    }
    Checkedminipackage(p, index, minipackage) {
        // 
        if (p.target.checked) {
            this.props.Checkedminipackage(index, true, 1, minipackage)
        }
        else {
            this.props.Checkedminipackage(index, false, 0, minipackage)
        }

    }
    CheckedTestseries(p, index, testseries) {
        // 
        if (p.target.checked) {
            this.props.CheckedTestseries(index, true, 1, testseries)
        }
        else {
            this.props.CheckedTestseries(index, false, 0, testseries)
        }
    }

    debouncedSearch(value) {
        // this.props.viewAllCategory(this.state.page, this.state.rowsPerPage, value)
        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, value)
    }

    handleSearchChange = (event) => {
        const value = event.target.value;
        this.setState({ searchTerm: value });
        this.debouncedSearch(value);
    };
    debouncedSearchcategory(value) {
        this.props.viewAllCategory(this.state.page, this.state.rowsPerPage, value)
    }
    handleSearchChangeCategory = (event) => {
        const value = event.target.value;
        this.setState({ searchCategory: value });
        this.debouncedSearchcategory(value);
    };


    componentDidMount() {
        this.props.viewAllCategory(this.state.page, this.state.rowsPerPage, "");
        this.props.viewAllExams();
        this.props.viewAllStages();
        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
        this.props.viewAllPackageCategory();
        this.props.viewAllMiniPackager();
        this.props.viewAllTestSeries();
        // this.props.add_examNotification();

        this.props.viewAllPreviousYearQuestion()


        this.props.viewAllExamAi()
    }
    handleDeleteC = (id) => {

        let updated = this.state.package_content.category.filter(i => i._id !== id)

        this.setState(() => this.state.package_content.category = updated)

    }

    handleDeleteExam = (Lid, id) => {
        this.setState(prevState => {
            const newState = { ...prevState };
            const newArr = [...newState.package_content.category[Lid].exam];
            newArr.splice(id, 1);
            newState.package_content.category[Lid].exam = newArr;
            return newState;
        });
    }
    handleDeleteStage = (Lid, Vid, id) => {
        this.setState(prevState => {
            const newState = { ...prevState };
            const newArr = [...newState.package_content.category[Lid].exam[Vid].stage];
            newArr.splice(id, 1);
            newState.package_content.category[Lid].exam[Vid].stage = newArr;
            return newState;
        });
    }
    handleDeleteSub = (Lid, Vid, Qid, id) => {
        this.setState(prevState => {
            const newState = { ...prevState };
            const newArr = [...newState.package_content.category[Lid].exam[Vid].stage[Qid].subject];
            newArr.splice(id, 1);
            newState.package_content.category[Lid].exam[Vid].stage[Qid].subject = newArr;
            return newState;
        });
    }
    handleDeleteMin = (Lid, Vid, Qid, Pid, id) => {
        this.setState(prevState => {
            const newState = { ...prevState };
            const newArr = [...newState.package_content.category[Lid].exam[Vid].stage[Qid].subject[Pid].minipackage];
            newArr.splice(id, 1);
            newState.package_content.category[Lid].exam[Vid].stage[Qid].subject[Pid].minipackage = newArr;
            return newState;
        });
    }


    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        let { Lid, Vid, Qid, Pid, package_content } = this.state;

        const handleChangePage = (event, newPage) => {
            this.props.viewAllCategory(newPage - 1, this.state.rowsPerPage, "");
            this.props.viewAllSubject(newPage - 1, this.state.rowsPerPage, "");
            this.setState({
                page: newPage,

            });

        };
        return (
            <Grid>
                <Box className='categoryname'>
                    <center style={{ display: "flex", }}>
                        <Link to="/package" style={{ textDecoration: "none" }}>
                            <IconButton
                            // style={{ marginLeft: "18px" }}
                            >
                                <ArrowBackIcon style={{ color: "white" }} />
                            </IconButton>
                        </Link>
                        <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: "8px", textTransform: "capitalize" }}>Package</Typography>
                    </center>
                </Box>
                <Grid style={{ marginLeft: "8%" }}>
                    {/* <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Select Access</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <Select
                                    style={{
                                        background: "white",
                                        height: '55px',
                                        width: "100%",
                                        border: "1px solid #888",
                                        fontSize: 16,
                                        borderRadius: 5,
                                        padding: 7,
                                        color: 'gray'
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="select category"
                                    select
                                    value={this.state.access}
                                    onChange={(e) => {
                                        this.setState({ access: e.target.value });
                                    }}
                                >
                                    <MenuItem value={"Access"}>{"Select Access"}</MenuItem>
                                    {this.state.details.map((e) => (
                                        <MenuItem value={e.access_name}>{e.access_name}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                    </Grid> */}
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center
                            >
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Select Access</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <select
                                    style={{
                                        background: "white",
                                        height: '55px',
                                        width: "100%",
                                        border: "1px solid #888",
                                        fontSize: 16,
                                        borderRadius: 5,
                                        padding: 7,
                                        color: 'gray',
                                        placeholder: "Select Access"
                                    }}
                                    select
                                    value={this.state.access}
                                    onChange={(e) => {
                                        this.setState({ access: e.target.value });
                                    }}
                                >
                                    <option value={""}>Select Access</option>
                                    <option value={"Paid"}>Paid</option>
                                    <option value={"Free"}>Free</option>

                                </select>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center
                            >
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Add Package Category</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <select
                                    style={{
                                        background: "white",
                                        height: '55px',
                                        width: "100%",
                                        border: "1px solid #888",
                                        fontSize: 16,
                                        borderRadius: 5,
                                        padding: 7,
                                        color: 'gray'
                                    }}
                                    select
                                    value={this.state.packagecategory_id}
                                    onChange={(e) => {
                                        this.setState({ packagecategory_id: e.target.value });
                                        // this.props.viewBatteryByid(e.target.value);
                                    }}
                                >
                                    <option value="" >Select package category</option>
                                    {admin.all_packagecategory.map((e) => (
                                        <option value={e._id}>{e.packagecategory_name}</option>
                                    ))}
                                </select>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Add Duration</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Duration '
                                    multiline
                                    rows={1}
                                    value={this.state.duration}
                                    // defaultValue="Default Value"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ duration: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Mark as Student Special</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <FormGroup>
                                    <FormControlLabel required control={<Checkbox checked={this.state.student_special} onClick={() => { this.setState({ student_special: !this.state.student_special }) }} />} label="Student's Special" />
                                </FormGroup>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Add Price</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Price'
                                    multiline
                                    rows={1}
                                    value={this.state.price}
                                    // defaultValue="Default Value"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ price: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Discounted Price</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%" }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Discounted Price'
                                    multiline
                                    rows={1}
                                    value={this.state.discounted_price}
                                    // defaultValue="Default Value"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ discounted_price: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Discounted In Percentage</Typography>
                            </center>
                        </Box>


                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%" }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Discounted In Percentage'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.discountInPer}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ discountInPer: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>New User Offer</Typography>
                            </center>
                        </Box>








                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%" }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='New User Offer'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.newUserOffer}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ newUserOffer: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>


                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Refferal Offer</Typography>
                            </center>
                        </Box>








                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%" }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Refferal Offer'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.referralOffer}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ referralOffer: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Final Price</Typography>
                            </center>
                        </Box>








                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%" }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Final Price'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.finalPrice}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ finalPrice: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >

                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>
                                    Add Package Name
                                </Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Package Name'
                                    multiline
                                    rows={1}
                                    value={this.state.package_name}
                                    // defaultValue="Default Value"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ package_name: e.target.value }) }}
                                />

                            </div>

                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Package Description
                                </Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>

                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Package Description'
                                    multiline
                                    rows={1}
                                    value={this.state.package_desc}
                                    // defaultValue="Default Value"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ package_desc: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Course Journey</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Page URL'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.course_journey}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ course_journey: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>Team & Course</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Page URL'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.team_course}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ team_course: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>syllabus & patterns</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Page URL'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.syllabus_pattern}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ syllabus_pattern: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='mainsecation'
                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "8%" }}
                    >
                        <Box className='addmininamess'>
                            <center>
                                <Typography style={{ fontWeight: "bold", width: "100%" }}>FAQ</Typography>
                            </center>
                        </Box>
                        <Grid style={{ marginTop: "2%", width: 600 }}>
                            <div class="input-group mb-3" style={{ marginRight: "20%", }}>
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    placeholder='Enter Page URL'
                                    multiline
                                    rows={1}
                                    // defaultValue="Default Value"
                                    value={this.state.faq}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "40px", width: "100%" }}
                                    onChange={(e) => { this.setState({ faq: e.target.value }) }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3.5}>
                            <Typography style={{ fontWeight: "bold", width: "100%", marginTop: "13%" }}>Introductory Pdf </Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={7.5}>
                            {(this.state.old_pdf_url !== "" && this.state.pdf_url === "") && <Button variant="outlined" component="label" className='upload' style={{ width: "58%", marginTop: "4%", marginLeft: "15%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                {this.state.old_pdf_url !== "" ? "Change" : "Upload"}
                                <input hidden type="file" multiple
                                    accept="pdf/mp4,pdf/x-m4v,pdf/*"
                                    onChange={(e) => { this.setState({ pdf_url: e.target.files[0] }) }}
                                />
                            </Button>}
                            {this.state.pdf_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "58%", marginTop: "4%", marginLeft: "15%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                Successfully uploaded
                            </Button>}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3.5}>
                            <Typography style={{ fontWeight: "bold", width: "100%", marginTop: "13%" }}>Introductory Video </Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={7.5}>
                            {(this.state.old_video_url !== "" && this.state.video_url === "") && <Button variant="outlined" component="label" className='upload' style={{ width: "58%", marginTop: "4%", marginLeft: "15%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                startIcon={<NorthIcon style={{ height: 24, color: "#6257CD" }} />}>
                                {this.state.old_video_url !== "" ? "Change" : "Upload"}
                                <input hidden type="file" multiple
                                    accept="video/mp4,video/x-m4v,video/*"
                                    onChange={(e) => { this.setState({ video_url: e.target.files[0] }) }}
                                />
                            </Button>}
                            {this.state.video_url !== "" && <Button variant="outlined" component="label" className='upload' style={{ width: "58%", marginTop: "4%", marginLeft: "15%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", color: "#c4c4c4", borderColor: "#c4c4c4", height: "50px", backgroundColor: "#fff", textTransform: "capitalize" }}
                                startIcon={<CheckIcon style={{ height: 24, color: "#6257CD" }} />}>
                                Successfully uploaded
                            </Button>}
                        </Grid>
                    </Grid>

                    {/* <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" &&
                                // this.state.package_category !== "" &&
                                <Button variant="outlined"
                                    style={{ border: "2px solid #6257cd" }} onClick={() => {
                                        this.setState({
                                            tsmodal: true,
                                        })
                                    }}>

                                    <IconButton>
                                        <AddIcon style={{ color: '#6257CD' }} />
                                    </IconButton>Add Test Series</Button>}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "0px", marginRight: "12%" }}>
                            {admin.all_testseries.filter(el => el.quantity > 0).map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Checkbox
                                            value={e.name}
                                            onClick={(p) => {
                                                this.setState({ testseries: "1" })
                                                this.props.SelectTestSeries(e)
                                                // 
                                            }}

                                        />
                                        <Typography style={{ color: "#000", display: "flex", textAlign: "center", alignItems: "center" }}>
                                            {e.name}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Box> */}

                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.duration == "" && this.state.price !== "" && this.state.package_name !== "" && */}
                            {/* // this.state.package_category !== "" && */}
                            <Button variant="outlined"
                                style={{ border: "2px solid #6257cd" }}
                            >

                                Add Category</Button>
                            {/* } */}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {(package_content.category ?? []).map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", }}>
                                        <Chip
                                            style={{
                                                backgroundColor: this.state.Lid == index ? "#6257CD" : '',
                                                color: this.state.Lid == index ? "white" : ''
                                            }}
                                            variant={package_content?.category?.[index].hasOwnProperty('exam') ? "filled" : "outlined"}

                                            onClick={() => {
                                                this.setState({ Lid: index })
                                            }}
                                            label={e.category_name}
                                            onDelete={() => {
                                                this.handleDeleteC(e._id)
                                                this.UnCheckedcategory(admin.all_category, e.category_name);
                                            }}

                                        />

                                    </div>
                                );
                            })}
                            <AddIcon

                                onClick={() => {
                                    this.setState({
                                        categorymodal: true,
                                    })
                                }}
                                style={{ color: '#6257CD' }}></AddIcon>
                        </Grid>
                    </Box>
                    {/* exammmm */}
                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" && this.state.cat !== "" && */}
                            {/* this.state.package_category !== "" && */}
                            <Button
                                variant="outlined"
                                style={{ border: "2px solid #6257cd" }}
                            >

                                Add exam</Button>
                            {/* } */}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {(package_content?.category?.[Lid]?.exam ?? []).map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", }}>
                                        <Chip
                                            style={{
                                                backgroundColor: this.state.Vid == index ? "#6257CD" : '',
                                                color: this.state.Vid == index ? "white" : ''
                                            }}
                                            variant={package_content?.category?.[Lid]?.exam?.[index].hasOwnProperty('stage') ? "filled" : "outlined"}

                                            onClick={() => {
                                                this.setState({ Vid: index })
                                            }}
                                            label={e.exam_name}
                                            onDelete={() => {
                                                this.handleDeleteExam(Lid, index)
                                                this.UnCheckedexam(admin.all_exam, e.exam_name)
                                            }}

                                        />

                                    </div>
                                );
                            })}
                            <AddIcon

                                onClick={() => {
                                    this.setState({
                                        Exammodal: true,
                                    })
                                }}
                                style={{ color: '#6257CD' }}></AddIcon>
                        </Grid>
                    </Box>

                    {/* text series */}
                    {/* <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" &&
                                this.state.ex !== "" &&
                                <Button variant="outlined"
                                    style={{ border: "2px solid #6257cd" }} onClick={() => {
                                        this.setState({
                                            tsmodal: true,
                                        })
                                    }}>
                                    <IconButton>
                                        <AddIcon style={{ color: '#6257CD' }} />
                                    </IconButton>Add Test Series</Button>}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "0px", marginRight: "12%" }}>
                            {admin.all_testseries.filter(el => el.quantity > 0).map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Checkbox
                                            value={e.name}
                                            onClick={(p) => {
                                                this.setState({ exam: "1" })
                                                this.props.SelectExam(admin.category_selct.category, this.state.excount, e)
                                                
                                            }}
                                        />
                                        <Typography style={{ color: "#000", display: "flex", textAlign: "center", alignItems: "center" }}>
                                            {e.name}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Box> */}

                    {/* previous year */}
                    {/* <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" &&
                                this.state.ex !== "" &&
                                <Button variant="outlined"
                                    style={{ border: "2px solid #6257cd" }} onClick={() => {
                                        this.setState({
                                            previousyearmodal: true,
                                        })
                                    }}>
                                    <IconButton>
                                        <AddIcon style={{ color: '#6257CD' }} />
                                    </IconButton>Add Previous year</Button>}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "0px", marginRight: "12%" }}>
                            {admin.all_previous_year.filter(el => el.quantity > 0).map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Checkbox
                                            value={e.sub_details.subject_name}
                                            onClick={(p) => {
                                                this.setState({ exam: "1" })
                                                this.props.SelectExam(admin.category_selct.category, this.state.excount, e)
                                                
                                            }}
                                        />
                                        <Typography style={{ color: "#000", display: "flex", textAlign: "center", alignItems: "center" }}>
                                            {e.sub_details.subject_name}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Box> */}

                    {/* examAi */}
                    {/* <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" &&
                                this.state.ex !== "" &&
                                <Button variant="outlined"
                                    style={{ border: "2px solid #6257cd" }} onClick={() => {
                                        this.setState({
                                            examai: true,
                                        })
                                    }}>
                                    <IconButton>
                                        <AddIcon style={{ color: '#6257CD' }} />
                                    </IconButton>Add ExamAi</Button>}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "0px", marginRight: "12%" }}>
                            {admin.all_exam_ai.filter(el => el.quantity > 0).map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Checkbox
                                            value={e.examAI_name}
                                            onClick={(p) => {
                                                this.setState({ exam: "1" })
                                                this.props.SelectExam(admin.category_selct.category, this.state.excount, e)
                                                
                                            }}
                                        />
                                        <Typography style={{ color: "#000", display: "flex", textAlign: "center", alignItems: "center" }}>
                                            {e.examAI_name}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Box> */}
                    {/* {stagesss} */}
                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" && this.state.ex !== "" && */}
                            {/* // this.state.package_category !== "" && */}
                            <Button
                                style={{ border: "2px solid #6257cd" }}
                            >

                                Add stage</Button>
                            {/* } */}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {/* {admin.all_video.filter(el => el.quantity).map((e, index) => { */}
                            {/* {Array.isArray(admin.all_stages) && admin.all_stages.filter(el => el.quantity > 0).map((e, index) => { */}
                            {(package_content?.category?.[Lid]?.exam?.[Vid]?.stage ?? []).map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", }}>

                                        <Chip label={e.stage_name}
                                            style={{
                                                backgroundColor: this.state.Qid == index ? "#6257CD" : '',
                                                color: this.state.Qid == index ? "white" : ''
                                            }}
                                            variant={package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[index].hasOwnProperty('subject') ? "filled" : "outlined"}

                                            onClick={() => {
                                                this.setState({ Qid: index })
                                            }}
                                            onDelete={() => {
                                                this.handleDeleteStage(Lid, Vid, index)
                                                this.UnCheckedstage(admin.all_stages, e.stage_name)
                                            }}
                                        />
                                    </div>
                                );
                            })}
                            <AddIcon

                                onClick={() => {
                                    this.setState({
                                        stagemodal: true,
                                    })
                                }}
                                style={{ color: '#6257CD' }}></AddIcon>
                        </Grid>
                    </Box>


                    {/* subject */}
                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" && this.state.sta !== "" && */}
                            {/* // this.state.package_category !== "" && */}

                            <Button
                                variant="outlined"
                                style={{ border: "2px solid #6257cd" }}
                            >

                                Add Subject</Button>
                            {/* } */}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {/* {admin.all_video.filter(el => el.quantity).map((e, index) => { */}
                            {(package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[Qid]?.subject ?? []).map((e, index) => {

                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", }}>

                                        <Chip label={e.subject_name}
                                            style={{
                                                backgroundColor: this.state.Pid == index ? "#6257CD" : '',
                                                color: this.state.Pid == index ? "white" : ''
                                            }}
                                            variant={package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[Qid].subject?.[index].hasOwnProperty('minipackage') ? "filled" : "outlined"}

                                            onClick={() => {
                                                this.setState({ Pid: index })
                                            }}
                                            onDelete={() => {
                                                this.handleDeleteSub(Lid, Vid, Qid, index)
                                                this.UnCheckedsubject(admin.all_subject, e.subject_name)
                                            }}
                                        />
                                    </div>
                                );
                            })}


                            <AddIcon
                                onClick={() => {
                                    this.setState({
                                        subjectmodal: true,
                                    })
                                }}


                                style={{ color: '#6257CD' }}></AddIcon>
                        </Grid>
                    </Box>
                    {/* mini packages */}
                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                        <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", width: "100%", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            {/* {this.state.duration !== "" && this.state.price !== "" && this.state.package_name !== "" && this.state.sub !== "" && */}
                            {/* // this.state.package_category !== "" && */}
                            <Button
                                style={{ border: "2px solid #6257cd" }}
                            >

                                Select Mini Package</Button>
                            {/* } */}
                        </center>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12xx%", alignItems: "center" }}>
                            {/* {admin.all_video.filter(el => el.quantity).map((e, index) => { */}
                            {(package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[Qid]?.subject?.[Pid]?.minipackage ?? []).map((e, index) => {

                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "10px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px" }}>
                                        {/* <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography> */}
                                        {/* <Checkbox
                                            value={e.mini_hint}
                                            onClick={(p) => {
                                                this.props.SelectMini(admin.category_selct.category[this.state.catcount].exam[this.state.excount].stage[this.state.stacount].subject, this.state.subcount, e)
                                                // 
                                                this.setState({ min: "1" })
                                            }}
                                        />
                                        
                                        
                                        
                                        
                                        <Typography style={{ color: "#000", display: "flex", textAlign: "center", alignItems: "center" }}>{e.minipackage_name}</Typography> */}


                                        <Chip label={e.mini_hint}

                                            variant="outlined"

                                            onDelete={() => {
                                                this.handleDeleteMin(Lid, Vid, Qid, Pid, index)
                                                this.UnCheckedminipackage(admin.all_minipackages, e.mini_hint)
                                            }}
                                        />

                                    </div>
                                );
                            })}
                            <AddIcon
                                onClick={() => {
                                    this.setState({
                                        minipackagemodal: true,
                                    })
                                }}



                                style={{ color: '#6257CD' }}></AddIcon>
                        </Grid>
                    </Box>
                </Grid>
                <Dialog
                    open={this.state.Exammodal}
                    style={{ height: "600px" }}
                    onClose={() => this.setState({ Exammodal: false })}>
                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,
                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Exam "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchexam(admin.all_exam, e)}
                                />
                            </Search>
                        </Grid>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.exam_filtered) && admin.exam_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.exam_name}</Typography>
                                        <Checkbox
                                            value={e.exam_name}
                                            checked={package_content?.category?.[Lid]?.exam?.some(item2 => e?._id === item2?._id)}
                                            // onClick={() => {
                                            //     if (!package_content.category[Lid].hasOwnProperty('exam')) {
                                            //         package_content.category[Lid].exam = [];
                                            //         // this.setState(() => all.chapter.push(e))
                                            //     }
                                            //     // this.setState(() => all.chapter.push(e))
                                            //     if (!package_content.category[Lid].exam.some(chapter => chapter._id == e._id)) {

                                            //         this.setState(() => package_content.category[Lid].exam.push(e))

                                            //     } else {
                                            //         // Handle duplicate value (e.g., display an error, log a message)
                                            //         
                                            //     }
                                            //     

                                            //     
                                            // }}
                                            // onClick={(p) => { this.Checkedexam(p, index, admin.all_exam) }}
                                            onClick={(p) => {
                                                // this.props.viewAllExams();
                                                const currentLesson = package_content?.category?.[Lid];

                                                if (!currentLesson.hasOwnProperty('exam')) {
                                                    currentLesson.exam = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.exam && currentLesson.exam.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.exam = [...(currentLesson.exam || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            examChecked: [...prevState.examChecked, e.exam_name],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedexam(admin.all_exam, e.exam_name);
                                                        currentLesson.exam = currentLesson.exam.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            examChecked: prevState.examChecked.filter(desc => desc !== e.exam_name),
                                                        }));
                                                    }

                                                }


                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ Exammodal: false });
                                    this.props.viewAllExams();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>
                            <Button
                                className='btns'
                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ Exammodal: false });
                                    this.props.viewAllExams();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                </Dialog>
                {/* previous year */}
                <Dialog
                    open={this.state.previousyearmodal}
                    style={{ height: "600px" }}
                    onClose={() => this.setState({ previousyearmodal: false })}>

                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,
                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search previous year question"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchPriviousYear(admin.all_previous_year, e)}
                                />
                            </Search>
                        </Grid>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.privous_year_filtered !== undefined && admin.privous_year_filtered) && admin.privous_year_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.sub_details.subject_name}</Typography>

                                        <Checkbox
                                            value={e.sub_details.subject_name}
                                            onClick={(p) => { this.Checkedpreviousyear(p, index, admin.all_previous_year) }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ previousyearmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>
                            <Button
                                className='btns'
                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ previousyearmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                </Dialog>

                {/* exam ai modal */}
                <Dialog
                    open={this.state.examai}
                    style={{ height: "600px" }}
                    onClose={() => this.setState({ examai: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,
                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Exam Ai"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchAllExamAi(admin.all_exam_ai, e)}
                                />
                            </Search>
                        </Grid>


                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(this.props.admin.search_examai) && this.props.admin.search_examai.map((item, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{item.examAI_name}</Typography>
                                        <Checkbox
                                            value={item.examAI_name} checked={false}
                                            onClick={(p) => { this.Checkedexamai(p, index, admin.all_exam_ai) }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ examai: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'
                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ examai: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                </Dialog>
                <Dialog
                    open={this.state.stagemodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ stagemodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,
                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Stage "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchstage(admin.all_stages, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.stage_filtered) && admin.stage_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.stage_name}</Typography>

                                        <Checkbox
                                            value={e.stage_name}
                                            checked={package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.some(item2 => e?._id === item2?._id)}
                                            // onClick={() => {
                                            //     if (!package_content.category[Lid].exam[Vid].hasOwnProperty('stage')) {
                                            //         package_content.category[Lid].exam[Vid].stage = [];
                                            //         // this.setState(() => all.chapter.push(e))
                                            //     }
                                            //     // this.setState(() => all.chapter.push(e))
                                            //     if (!package_content.category[Lid].exam[Vid].stage.some(chapter => chapter._id === e._id)) {

                                            //         this.setState(() => package_content.category[Lid].exam[Vid].stage.push(e))

                                            //     } else {
                                            //         // Handle duplicate value (e.g., display an error, log a message)
                                            //         
                                            //     }

                                            // }}


                                            // onClick={(p) => { this.Checkedstage(p, index, admin.all_stages) }}

                                            onClick={(p) => {
                                                // this.props.viewAllStages();
                                                // const { Lid, Vid, package_content } = this.props;
                                                const currentLesson = package_content?.category?.[Lid]?.exam?.[Vid];
                                                const stageName = e.stage_name;

                                                if (!currentLesson.hasOwnProperty('stage')) {
                                                    currentLesson.stage = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.stage && currentLesson.stage.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.stage = [...(currentLesson.stage || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            stageChecked: [...prevState.stageChecked, e.stage_name],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        currentLesson.stage = currentLesson.stage.filter(video => video._id !== e._id);

                                                        this.setState((prevState) => ({
                                                            stageChecked: prevState.stageChecked.filter(desc => desc !== e.stage_name),
                                                        }));
                                                    }

                                                }
                                            }}

                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ stagemodal: false });
                                    this.props.viewAllStages();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    // this.props.setlesson(this.state.userinfo);
                                    this.setState({ stagemodal: false });
                                    this.props.viewAllStages();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>


                </Dialog>
                <Dialog

                    open={this.state.categorymodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ categorymodal: false, searchCategory: '' })
                        this.props.viewAllCategory(this.state.page, this.state.rowsPerPage, "");
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Paper
                                component="form"
                                style={{
                                    marginTop: "5%",
                                    display: "flex",
                                    width: "350px",
                                    height: "42px",
                                    marginLeft: "4%",
                                    boxShadow: "none",
                                    border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                }}
                            >
                                <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                                    <SearchIcon style={{ fontSize: "25px" }} />
                                </IconButton>
                                <InputBase
                                    className="search-text"
                                    placeholder="Search"
                                    inputProps={{ "aria-label": "Search" }}
                                    value={this.state.searchCategory}
                                    onChange={this.handleSearchChangeCategory}
                                />
                            </Paper>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            <div style={{ marginTop: "40px", }}>
                                <Pagination
                                    count={parseInt(admin.category_length / this.state.rowsPerPage) + 1}
                                    siblingCount={0}
                                    size="small"
                                    onChange={handleChangePage}
                                />
                            </div>
                            {Array.isArray(admin.all_category) && admin.all_category?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.category_name}</Typography>
                                        <Checkbox
                                            value={e.category_name}
                                            checked={package_content.category?.some(item2 => e?._id === item2?._id)}
                                            // onClick={() => {
                                            //     if (!package_content.hasOwnProperty('category')) {
                                            //         package_content.category = [];
                                            //         // this.setState(() => all.chapter.push(e))
                                            //     }
                                            //     // this.setState(() => all.chapter.push(e))
                                            //     if (!package_content.category.some(chapter => chapter._id === e._id)) {

                                            //         this.setState(() => package_content.category.push(e))

                                            //     } else {
                                            //         // Handle duplicate value (e.g., display an error, log a message)
                                            //         
                                            //     }
                                            //     
                                            // }}
                                            // onClick={(p) => { this.CheckedCategory(p, index, admin.all_category) }}
                                            onClick={(p) => {
                                                // this.props.viewAllCategory();
                                                const currentLesson = package_content;

                                                if (!package_content.hasOwnProperty('category')) {
                                                    package_content.category = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.category && currentLesson.category.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.category = [...(currentLesson.category || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            categoryChecked: [...prevState.categoryChecked, e.category_name],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedcategory(admin.all_category, e.category_name);
                                                        currentLesson.category = currentLesson.category.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            categoryChecked: prevState.categoryChecked.filter(desc => desc !== e.category_name),
                                                        }));
                                                    }

                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ categorymodal: false, searchCategory: '' });
                                    this.props.viewAllCategory(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>
                            <Button
                                className='btns'
                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ categorymodal: false, searchCategory: '' });
                                    this.props.viewAllCategory(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                </Dialog>
                <Dialog
                    open={this.state.subjectmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ subjectmodal: false, searchTerm: '' })
                        this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid lg={4}>
                            <Paper
                                component="form"
                                style={{
                                    marginTop: "5%",
                                    display: "flex",
                                    width: "350px",
                                    height: "42px",
                                    marginLeft: "4%",
                                    boxShadow: "none",
                                    border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                }}
                            >
                                <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                                    <SearchIcon style={{ fontSize: "25px" }} />
                                </IconButton>
                                <InputBase
                                    className="search-text"
                                    placeholder="Search"
                                    inputProps={{ "aria-label": "Search" }}
                                    value={this.state.searchTerm}
                                    onChange={this.handleSearchChange}
                                />
                            </Paper>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            <div style={{ marginTop: "40px", }}>
                                <Pagination
                                    count={parseInt(admin.subject_length / this.state.rowsPerPage) + 1}
                                    siblingCount={0}
                                    size="small"
                                    onChange={handleChangePage}
                                />
                            </div>
                            {Array.isArray(admin.subject_filtered) && admin.subject_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.subject_name}</Typography>

                                        <Checkbox
                                            value={e.subject_name}
                                            checked={package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[Qid]?.subject?.some(item2 => e?._id === item2?._id)}

                                            // onClick={() => {
                                            //     if (!package_content.category[Lid].exam[Vid].stage[Qid].hasOwnProperty('subject')) {
                                            //         package_content.category[Lid].exam[Vid].stage[Qid].subject = [];
                                            //         // this.setState(() => all.chapter.push(e))
                                            //     }
                                            //     // this.setState(() => all.chapter.push(e))
                                            //     if (!package_content.category[Lid].exam[Vid].stage[Qid].subject.some(chapter => chapter._id == e._id)) {

                                            //         this.setState(() => package_content.category[Lid].exam[Vid].stage[Qid].subject.push(e))

                                            //     } else {
                                            //         // Handle duplicate value (e.g., display an error, log a message)
                                            //         
                                            //     }

                                            // }}


                                            // onClick={(p) => { this.Checkedsubject(p, index, admin.all_subject) }}

                                            onClick={(p) => {
                                                // this.props.viewAllSubject();
                                                const currentLesson = package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[Qid];

                                                if (!currentLesson.hasOwnProperty('subject')) {
                                                    currentLesson.subject = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.subject && currentLesson.subject.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.subject = [...(currentLesson.subject || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            subjectChecked: [...prevState.subjectChecked, e.subject_name],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedsubject(admin.all_subject, e.subject_name);
                                                        currentLesson.subject = currentLesson.subject.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            subjectChecked: prevState.subjectChecked.filter(desc => desc !== e.subject_name),
                                                        }));
                                                    }

                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ subjectmodal: false, searchTerm: '' });
                                    this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>
                            <Button
                                className='btns'
                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ subjectmodal: false, searchTerm: '' });
                                    this.props.viewAllSubject(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                </Dialog>

                <Dialog
                    open={this.state.minipackagemodal}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ minipackagemodal: false })}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,
                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Minipackage"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchminipackage(admin.all_minipackages, e)}
                                />
                            </Search>
                        </Grid>
                        {/* </Grid> */}
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.minipackage_filtered) && admin.minipackage_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.mini_hint}</Typography>
                                        <Checkbox
                                            checked={package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[Qid]?.subject?.[Pid]?.minipackage?.some(item2 => e?._id === item2?._id)}
                                            value={e.mini_hint}
                                            // onClick={() => {
                                            //     if (!package_content.category[Lid].exam[Vid].stage[Qid].subject[Pid].hasOwnProperty('minipackage')) {
                                            //         package_content.category[Lid].exam[Vid].stage[Qid].subject[Pid].minipackage = [];
                                            //         // this.setState(() => all.chapter.push(e))
                                            //     }
                                            //     // this.setState(() => all.chapter.push(e))
                                            //     if (!package_content.category[Lid].exam[Vid].stage[Qid].subject[Pid].minipackage.some(chapter => chapter._id === e._id)) {

                                            //         this.setState(() => package_content.category[Lid].exam[Vid].stage[Qid].subject[Pid].minipackage.push(e))

                                            //     } else {
                                            //         // Handle duplicate value (e.g., display an error, log a message)
                                            //         
                                            //     }

                                            // }}
                                            // onClick={(p) => { this.Checkedminipackage(p, index, admin.all_minipackages) }}

                                            onClick={(p) => {
                                                // this.props.viewAllMiniPackager();
                                                const currentLesson = package_content?.category?.[Lid]?.exam?.[Vid]?.stage?.[Qid]?.subject?.[Pid];

                                                if (!currentLesson.hasOwnProperty('minipackage')) {
                                                    currentLesson.minipackage = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.minipackage && currentLesson.minipackage.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.minipackage = [...(currentLesson.minipackage || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            minipackageChecked: [...prevState.minipackageChecked, e.mini_hint],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedminipackage(admin.all_minipackages, e.mini_hint);
                                                        currentLesson.minipackage = currentLesson.minipackage.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            minipackageChecked: prevState.minipackageChecked.filter(desc => desc !== e.mini_hint),
                                                        }));
                                                    }

                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ minipackagemodal: false });
                                    this.props.viewAllMiniPackager();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>
                            <Button
                                className='btns'
                                onClick={() => {
                                    // this.setState({ sub: "1" })
                                    this.setState({ minipackagemodal: false });
                                    this.props.viewAllMiniPackager();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                </Dialog>

                {/* <Dialog
                    open={this.state.tsmodal}
                    style={{ height: "100%" }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>
                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,
                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Test Series"
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchtestseries(admin.all_testseries, e)}
                                />
                            </Search>
                        </Grid>
                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {admin.testseries_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.name}</Typography>
                                        <Checkbox
                                            value={e.name}
                                            onClick={(p) => {
                                                this.CheckedTestseries(p, index, admin.all_testseries)
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ tsmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>
                            <Button
                                className='btns'

                                onClick={() => {
                                    this.setState({ tsmodal: false });
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>
                </Dialog> */}
                <center>
                    {/* {(this.state.min !== "")&& */}
                    <Link to="/package" >
                        <Button
                            className='btns'
                            // disabled={(this.state.package_name === '') || (this.state.packagecategory_id === '') || (this.state.duration === '') || (this.state.package_desc === "")  || (this.state.discounted_price === "")  || (this.state.access === "")}
                            onClick={() => {
                                this.props.edit_upload_package_pdf(this.props.admin.package_id._id, this.state.package_name, this.state.packagecategory_id, this.state.price, this.state.duration, this.state.package_content, this.state.package_desc, this.state.student_special, this.state.discounted_price, this.state.access, this.state.old_pdf_url, this.state.pdf_url, this.state.old_video_url, this.state.video_url, this.state.course_journey, this.state.team_course, this.state.syllabus_pattern, this.state.faq, this.state.discountInPer, this.state.newUserOffer, this.state.referralOffer, this.state.finalPrice)
                            }}
                            style={{ marginBottom: "30px", marginTop: "10px", color: "white", width: "20px" }}
                        >SUBMIT</Button></Link>
                    {/* } */}
                </center>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
                <Loader />
            </Grid>
        )
    }
}