import React, { Component } from "react";
import { connect } from "react-redux";
import AddExamAi from "../component/AddExamAi";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  createExamAi,
  viewAllPdf,
  viewAllSubject,
  Checkedpdf,
  StorePdf,
  searchpdf,
  UnCheckedpdf,
  NullPdf,
  searchLanguage,
  viewAllTrends,
  viewAllExams,
  SelectTrends,
  searchTrend,
  Checkedtrend,
  NullSubject,
  SelectTSubject,
  SelectSubject,
  UnCheckedSubject,
  Checkedsubject,
  SelectPdf,
  viewAllCategoryExam,
  searchsubject,
  viewAllStages,
  viewAllLanguage,
  searchlanguage,
  viewAllCategory
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <AddExamAi {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createExamAi: (exam_ai_name, category, exam_id, stage, trend_select, language) => {
      dispatch(createExamAi(exam_ai_name, category, exam_id, stage, trend_select, language))
    },
    Checkedpdf: (index, checked, quantity, all_pdf) => {
      dispatch(Checkedpdf(index, checked, quantity, all_pdf))
    },
    UnCheckedpdf: (all_pdf) => {
      dispatch(UnCheckedpdf(all_pdf))
    },
    viewAllPdf: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllPdf(page_number, rowPerPage, searchQuery))
    },
    searchpdf: (pdf, e) => {
      dispatch(searchpdf(pdf, e))
    },
    StorePdf: (lesson, pdfcount, pdf, pdf_count) => {
      dispatch(StorePdf(lesson, pdfcount, pdf, pdf_count))
    },
    SelectTSubject: (testtype, count, sub) => {
      dispatch(SelectTSubject(testtype, count, sub))
    },
    SelectSubject: (stage, count, sub) => {
      dispatch(SelectSubject(stage, count, sub))
    },
    Checkedsubject: (index, checked, quantity, all_subject) => {
      dispatch(Checkedsubject(index, checked, quantity, all_subject))
    },
    NullPdf: () => {
      dispatch(NullPdf())
    },
    viewAllSubject: (page_number, rowPerPage, searchQuery) => {
      dispatch(viewAllSubject(page_number, rowPerPage, searchQuery))
    },
    viewAllStages: () => {
      dispatch(viewAllStages())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    searchLanguage: (pdf, e) => {
      dispatch(searchLanguage(pdf, e));
    },
    viewAllExams: () => {
      dispatch(viewAllExams())
    },
    viewAllTrends: () => {
      dispatch(viewAllTrends())
    },
    SelectTrends: (trend) => {
      dispatch(SelectTrends(trend))
    },
    searchTrend: (trend, e) => {
      dispatch(searchTrend(trend, e))
    },
    searchsubject: (all_subject, e) => {
      dispatch(searchsubject(all_subject, e))
    },
    NullSubject: () => {
      dispatch(NullSubject())
    },
    UnCheckedSubject: (all_subject) => {
      dispatch(UnCheckedSubject(all_subject))
    },
    viewAllCategoryExam: (category_id) => {
      dispatch(viewAllCategoryExam(category_id))
    },
    SelectPdf: (trend, count, pdf) => {
      dispatch(SelectPdf(trend, count, pdf))
    },
    Checkedtrend: (index, checked, quantity, view_all_trends) => {
      dispatch(Checkedtrend(index, checked, quantity, view_all_trends))
    },
    viewAllLanguage: () => {
      dispatch(viewAllLanguage())
    },

    searchlanguage: (all_language, e) => {
      dispatch(searchlanguage(all_language, e))
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },



  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);