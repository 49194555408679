import React, { Component } from "react";
import { connect } from "react-redux";
import Stage from "../component/stage";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createStage,
    updateStage,
    deleteStage,
    viewAllStages
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Stage {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createStage: (stage_name) => {
      dispatch(createStage(stage_name))
    },
    updateStage: (stage_id,stage_name) => {
      dispatch(updateStage(stage_id,stage_name))
    },
    deleteStage: (stage_id) => {
      dispatch(deleteStage(stage_id))
    },
    viewAllStages: () => {
      dispatch(viewAllStages())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);