import React, { Component } from 'react'
import {
    Grid,
    Box,
    IconButton,
    Button,
    Typography,
    Select,
    MenuItem,

} from "@mui/material";
// import AddIcon from '@mui/icons-material/Add';
import "../style/category.css"
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Vedio from '../images/video-camera.svg';
import Pdf from '../images/pdf.svg';
import Quiz from '../images/quiz.svg';
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from '../../../common/loader/containers/loader_cont';

import CloseIcon from '@mui/icons-material/Close';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { debounce } from 'lodash';
import Paper from "@mui/material/Paper";
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };






export default class addminipackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Lid: 0,
            Vid: 0,
            Pid: 0,
            Qid: 0,
            Lang: 0,

            languagemodal: false,
            chaptermodal: false,
            lessonmodal: false,
            videomodal: false,
            pdfmodal: false,
            quizmodal: false,
            priviousYear: false,
            chapter_name: "",
            lesson_name: "",
            video_name: "",
            quiz_name: "",
            pdf_name: "",
            previousyear_subject: "",
            mini_pack: this.props.admin.minipackage_id.minipackage_name,
            hint: this.props.admin.minipackage_id.mini_hint,
            description: this.props.admin.minipackage_id.mini_des,
            minipackage_heading: this.props.admin.minipackage_id.minipackage_heading,
            language_id: this.props.admin.minipackage_id.language_id,
            countchap: 0,
            countYear: 0,
            countless: 0,
            countpdf: 0,
            countvideo: 0,
            countquiz: 0,
            isChecked: false,
            // mini_content: this.props.admin.minipackage_id.mini_content,
            chapter_content: this.props.admin.minipackage_id.mini_content.chapter,
            all: this.props.admin.minipackage_id.mini_content,
            lesson_content: [],
            video_content: [],
            pdf_content: [],
            quiz_content: [],
            chaptervalue: '',
            lesscount: -1,
            count: -1,
            chap: "",
            less: "",
            vid: "",
            qui: "",
            year: "",
            mini_content: "",
            activeindex1: 0,
            // chapter_array:[],
            userinfo: {
                chapter_array: [],
                response: [],
            },
            userinformation: {
                privious_array: [],
                respons: [],
            },
            // filterValue: this.props.all_model.all_model,
            video_count: 0,
            pdf_count: 0,
            quiz_count: 0,

            page: 0,
            setPage: 0,
            rowsPerPage: 30,
            //--------------------------------//
            chapterChecked: [],
            chapter_filtered: [],
            lessonChecked: [],
            lesson_filtered: [],
            video_filtered: [],
            videoChecked: [],
            pdf_filtered: [],
            pdfChecked: [],
            quizzChecked: [],
            quizz_filtered: [],
            languageChecked: [],
            language_filtered: [],
            searchTerm: ''

        }
        this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 500);
    }

    debouncedSearch(value) {
        this.props.viewAllPdf(this.props.page, this.props.rowsPerPage, value);
    }

    handleSearchChange = (event) => {
        const value = event.target.value;
        this.setState({ searchTerm: value });
        this.debouncedSearch(value);
    };
    handleDelete = (data, id) => {

        // let updated = data.filter(i => i._id !== id)

        // this.setState(() => this.state.all.chapter = updated)

    }

    UnCheckedlanguage = (all_language, itemToUncheck) => {
        this.setState((prevState) => ({
            languageChecked: prevState.languageChecked.filter((item) => item !== itemToUncheck),
            language_filtered: all_language,
            // ... other state updates
        }));
    };
    UnCheckedchapter = (all_chapter, itemToUncheck) => {
        this.setState((prevState) => ({
            chapterChecked: prevState.chapterChecked.filter((item) => item !== itemToUncheck),
            chapter_filtered: all_chapter,
            // ... other state updates
        }));
    };
    UnCheckedlesson = (all_lesson, itemToUncheck) => {
        this.setState((prevState) => ({
            lessonChecked: prevState.lessonChecked.filter((item) => item !== itemToUncheck),
            lesson_filtered: all_lesson,
            // ... other state updates
        }));
    };
    UnCheckedvideo = (all_video, itemToUncheck) => {
        this.setState((prevState) => ({
            videoChecked: prevState.videoChecked.filter((item) => item !== itemToUncheck),
            video_filtered: all_video,
            // ... other state updates
        }));
    };
    UnCheckedpdf = (all_pdf, itemToUncheck) => {
        this.setState((prevState) => ({
            pdfChecked: prevState.pdfChecked.filter((item) => item !== itemToUncheck),
            pdf_filtered: all_pdf,
            // ... other state updates
        }));
    };
    UnCheckedquizz = (all_quiz, itemToUncheck) => {
        this.setState((prevState) => ({
            quizzChecked: prevState.quizzChecked.filter((item) => item !== itemToUncheck),
            quizz_filtered: all_quiz,
            // ... other state updates
        }));
    };





    handlearray = () => {
        const { chapter_content, all } = this.state;
        const newValue = this.state.chaptervalue;
        const updatedArray = [...chapter_content, newValue]; // Add the new value to the existing array
        this.setState({ chapter_content: updatedArray }); // Update the state with the new array
        // 
    }


    chapterCheck(p, index, chapters) {
        // 
        if (p.target.checked) {
            this.props.CheckedChapter(index, true, 1, chapters)
        }
        else {
            this.props.CheckedChapter(index, false, 0, chapters)


        }

    }
    priviousYearCheck(p, index, PreviousYears) {
        // 
        if (p.target.checked) {
            this.props.CheckedpriviousYear(index, true, 1, PreviousYears)
        }
        else {
            this.props.CheckedpriviousYear(index, false, 0, PreviousYears)


        }

    }

    Checkedlesson(p, index, lessons) {
        // 
        if (p.target.checked) {
            this.props.Checkedlesson(index, true, 1, lessons)
        }
        else {
            this.props.Checkedlesson(index, false, 0, lessons)


        }

    }
    Checkedvideo(p, index, videos) {
        // 
        if (p.target.checked) {
            this.props.Checkedvideo(index, true, 1, videos)
        }
        else {
            this.props.Checkedvideo(index, false, 0, videos)


        }

    }
    Checkedpdf(p, index, pdfs) {
        // 
        if (p.target.checked) {
            this.props.Checkedpdf(index, true, 1, pdfs)
        }
        else {
            this.props.Checkedpdf(index, false, 0, pdfs)


        }

    }
    Checkedquiz(p, index, pdfs) {
        // 
        if (p.target.checked) {
            this.props.Checkedquiz(index, true, 1, pdfs)
        }
        else {
            this.props.Checkedpdf(index, false, 0, pdfs)


        }

    }
    componentDidMount() {
        this.props.viewAllChapter();
        this.props.viewAllLesson();
        this.props.viewAllVideo();
        this.props.viewAllquiz();
        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
        this.props.viewAllPreviousYearQuestion();
        this.props.viewAllLanguage();
        // 
        this.props.view_minipackage_by_id(this.props.admin.minipackage_id._id)
        // 

    }

    handleDeleteL = (data, id) => {

        let updated = data.filter(i => i._id !== id)

        this.setState(() => this.state.all.language = updated)

    }

    handleDeleteC = (Lang, id) => {
        const newArrr = [...this.state.all.language]
        newArrr[Lang].chapter.splice(id, 1);

        this.setState({ all: { language: newArrr } })

    }
    // handleDeleteC = (data, id) => {

    //     let updated = data.filter(i => i._id !== id)

    //     this.setState(() => this.state.all.chapter = updated)

    // }
    // handleDelete = (Lid, id) => {
    //     const newArrr = [...this.state.all.chapter]
    //     newArrr[Lid].lesson.splice(id, 1);

    //     this.setState({ all: { chapter: newArrr } })

    // }


    handleDelete = (Lang, Lid, id) => {
        this.setState(prevState => {
            // Create a shallow copy of the state
            const newState = { ...prevState };

            // Create a shallow copy of the subject array to be modified
            const newArr = [...newState.all.language[Lang].chapter[Lid].lesson];
            // const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            // Use splice to remove the element at the specified index
            newArr.splice(id, 1);

            // Update the state with the modified subject array
            newState.all.language[Lang].chapter[Lid].lesson = newArr;

            // Return the updated state
            return newState;
        });
    }

    handleDeleteVideo = (Lang, Lid, Vid, id) => {
        this.setState(prevState => {
            // Create a shallow copy of the state
            const newState = { ...prevState };

            // Create a shallow copy of the subject array to be modified
            const newArr = [...newState.all.language[Lang].chapter[Lid].lesson[Vid].video];
            // const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            // Use splice to remove the element at the specified index
            newArr.splice(id, 1);

            // Update the state with the modified subject array
            newState.all.language[Lang].chapter[Lid].lesson[Vid].video = newArr;

            // Return the updated state
            return newState;
        });
    }
    handleDeletePdf = (Lang, Lid, Vid, id) => {
        this.setState(prevState => {
            // Create a shallow copy of the state
            const newState = { ...prevState };

            // Create a shallow copy of the subject array to be modified
            const newArr = [...newState.all.language[Lang].chapter[Lid]?.lesson[Vid]?.pdf];
            // const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            // Use splice to remove the element at the specified index
            newArr.splice(id, 1);

            // Update the state with the modified subject array
            newState.all.language[Lang].chapter[Lid].lesson[Vid].pdf = newArr;

            // Return the updated state
            return newState;
        });
    }
    handleDeleteQuiz = (Lang, Lid, Vid, id) => {
        this.setState(prevState => {
            // Create a shallow copy of the state
            const newState = { ...prevState };

            // Create a shallow copy of the subject array to be modified
            const newArr = [...newState.all.language[Lang].chapter[Lid]?.lesson[Vid]?.quizz];
            // const newArr = [...newState.all.test[Lid].testtype[Vid].subject];

            // Use splice to remove the element at the specified index
            newArr.splice(id, 1);

            // Update the state with the modified subject array
            newState.all.language[Lang].chapter[Lid].lesson[Vid].quizz = newArr;

            // Return the updated state
            return newState;
        });
    }



    removeElements = (elementsToRemove) => {
        this.setState(prevState => ({
            myArray: prevState.myArray.filter(item => !elementsToRemove.includes(item)),
        }));
    };

    render() {
        const {
            admin,
            snackbar,
            close_snack_bar
        } = this.props;
        // 
        const { chapter_array, response } = this.state.userinfo;
        const { privious_array, respons } = this.state.userinformation;
        let { chapter_content, all, Lid, Vid, Pid, Qid, Lang } = this.state;
        const handleChangePage = (event, newPage) => {
            this.props.viewAllPdf(newPage - 1, this.state.rowsPerPage, "");
            this.setState({
                page: newPage,

            });

        };
        return (
            <Grid>
                <Box className='categorynames'  >

                    <center style={{ display: "flex", marginLeft: "10px" }}>
                        <Link to="/minipackage" style={{ textDecoration: "none" }}>
                            <IconButton
                            // style={{ marginLeft: "18px" }}

                            >
                                <ArrowBackIcon style={{ color: "white" }} />
                            </IconButton>
                        </Link>
                        <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: "8px", fontSize: "20px", fontWeight: 700 }}>MiniPackage</Typography>


                    </center>
                </Box>
                <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "10%", marginBottom: "7%" }}
                >

                    <Box className='addmininame' style={{ marginTop: "2%" }}>
                        <center
                            style={{ height: "44px", color: "#6257CD", textAlign: 'center', backgroundColor: "#fff", border: "2px solid #6257cd", width: "300px", borderRadius: 5, display: "flex", flexDirection: "row" }}
                        >
                            <Typography style={{ alignSelf: "center", fontWeight: 600, marginLeft: "20px", fontSize: "18px" }}> Edit Description of the course</Typography>
                        </center>
                    </Box>
                    <Grid style={{ marginTop: "2%", width: 600 }}>
                        <div class="input-group mb-3" style={{ marginTop: "5%" }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder=' Name of mini Package'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                InputLabelProps={{ shrink: true, required: true }}
                                value={this.state.mini_pack}
                                style={{ height: "30px", width: "100%" }}
                                onChange={(e) => { this.setState({ mini_pack: e.target.value }) }}
                            />
                        </div>
                        <div class="input-group mb-3" style={{ marginTop: "5%" }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder=' Name of mini Package'
                                multiline
                                rows={1}
                                // defaultValue="Default Value"
                                InputLabelProps={{ shrink: true, required: true }}
                                value={this.state.minipackage_heading}
                                style={{ height: "30px", width: "100%" }}
                                onChange={(e) => { this.setState({ minipackage_heading: e.target.value }) }}
                            />
                        </div>
                        <div class="input-group mb-3" style={{ marginTop: "6%" }}>

                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Write a of mini Package Hint'
                                multiline
                                rows={1}
                                InputLabelProps={{ shrink: true, required: true }}
                                value={this.state.hint}
                                style={{ height: "30px", width: "100%" }}
                                onChange={(e) => { this.setState({ hint: e.target.value }) }}
                            />
                        </div>
                        <div class="input-group mb-3" style={{ marginTop: "6%" }}>
                            <TextField
                                required
                                id="outlined-multiline-static"
                                placeholder='Enter Description of mini Package'
                                multiline
                                rows={1}
                                InputLabelProps={{ shrink: true, required: true }}
                                value={this.state.description}
                                style={{ height: "30px", width: "100%" }}
                                onChange={(e) => { this.setState({ description: e.target.value }) }}
                            />

                        </div>
                    </Grid>

                </Grid>
                {/* <Grid className='mainsecation'
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "8%" }}
                >
                    <Box className='addmininamess'>
                        <center>
                            <Typography style={{ marginTop: "-18%", marginLeft: "45px", fontSize: "20px", fontWeight: 500 }}>Select Language:
                            </Typography>
                        </center>
                    </Box>
                    <Grid style={{ width: 600, marginRight: "13%" }}>
                        <div class="input-group mb-3" style={{ marginLeft: "20px", }}>
                            <Select
                                style={{
                                    background: "white",
                                    height: "50px", width: "600px",
                                    border: "1px solid #888",
                                    fontSize: 16,
                                    borderRadius: 5,
                                    padding: 7,
                                    color: 'gray'
                                }}
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                label="Select Langauage"
                                select
                                value={this.state.language_id}
                                onChange={(e) => {
                                    this.setState({ language_id: e.target.value });
                                }}
                            >
                                <MenuItem value={"Langauge"}>{"Select Language"}</MenuItem>
                                {Array.isArray(admin.all_language) && admin.all_language?.map((e) => {
                                    // 
                                    return (
                                        <MenuItem value={e._id}>{e.language}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>
                </Grid> */}
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                    {/* {this.state.mini_pack !== "" && this.state.hint !== "" && this.state.description !== "" && */}
                    <Button
                        // disabled={this.state.mini_pack === "" || this.state.hint === "" || this.state.description === ""}

                        // style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", borderWidth: 2, borderColor: "#6257cd", marginLeft: "65px", width: "100%" }}
                        style={{ height: "44px", color: "#6257CD", textAlign: 'center', backgroundColor: "#fff", borderRadius: 5, border: "2px solid #6257cd", width: "170px" }}
                    // onClick={() => {
                    //     this.setState({
                    //         chaptermodal: true,
                    //     })
                    // }}
                    >


                        {/* <AddIcon style={{ color: '#6257CD' }} />  */}
                        Add Language</Button>
                    {/* } */}


                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center" }}>
                        {Array.isArray(all.language) && all.language?.map((e, index) => {
                            return (
                                <div key={index} style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>
                                    <Chip
                                        label={e.language}
                                        style={{
                                            backgroundColor: this.state.Lang === index ? "#6257CD" : '',
                                            color: this.state.Lang === index ? "white" : '',
                                            border: this.state?.all?.language?.[index]?.chapter === undefined ? "2px solid black" : 'none'
                                        }}
                                        variant={this.state?.all?.language?.[index]?.chapter === undefined ? "outlined" : "filled"}
                                        onClick={() => {
                                            this.setState({ Lang: index });
                                        }}
                                        onDelete={() => {
                                            this.handleDeleteL(all.language, e._id);
                                            this.UnCheckedlanguage(admin.all_language, e.language);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                languagemodal: true,
                            });
                        }} />
                    </Grid>

                </Box>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "4%" }}>

                    {/* {this.state.mini_pack !== "" && this.state.hint !== "" && this.state.description !== "" && */}
                    <Button
                        // disabled={this.state.mini_pack === "" || this.state.hint === "" || this.state.description === ""}

                        // style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", borderWidth: 2, borderColor: "#6257cd", marginLeft: "65px", width: "100%" }}
                        style={{ height: "44px", color: "#6257CD", textAlign: 'center', backgroundColor: "#fff", borderRadius: 5, border: "2px solid #6257cd", width: "170px" }}
                    // onClick={() => {
                    //     this.setState({
                    //         chaptermodal: true,
                    //     })
                    // }}
                    >


                        {/* <AddIcon style={{ color: '#6257CD' }} />  */}
                        Add chapter</Button>
                    {/* } */}


                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "6%", border: "2px solid #6257cd", padding: 10, alignItems: "center" }}>
                        {Array.isArray(all?.language?.[Lang]?.chapter) && all?.language?.[Lang]?.chapter?.filter(el => el.quantity >= 0).map((e, index) => {
                            return (
                                <div key={index} style={{ borderColor: "black", display: "inline-flex", flexDirection: "row", borderWidth: "10px", margin: 3 }}>
                                    <Chip
                                        label={e.chapter_name}
                                        style={{
                                            backgroundColor: this.state.Lid === index ? "#6257CD" : '',
                                            color: this.state.Lid === index ? "white" : '',
                                            border: this.state?.all?.language[Lang]?.chapter?.[index]?.lesson === undefined ? "2px solid black" : 'none'
                                        }}
                                        variant={this.state?.all?.language[Lang]?.chapter?.[index]?.lesson === undefined ? "outlined" : "filled"}
                                        onClick={() => {
                                            this.setState({ Lid: index })
                                        }}
                                        onDelete={() => {
                                            this.handleDeleteC(Lang, index)
                                            this.UnCheckedchapter(admin.all_chapter, e.chapter_name)
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                chaptermodal: true,
                            })
                        }} />
                    </Grid>

                </Box>

                <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "0px", marginRight: "12xx%", alignItems: "center" }}>
                    {Array.isArray(admin.all_previous_year) && admin.all_previous_year.filter(el => el.quantity > 0).map((e, index) => {

                        return (
                            <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                <Checkbox
                                    value={e.name}

                                    // onChange={this.handleCheckboxChange}
                                    onClick={(p) => {
                                        this.setState({ countYear: this.state.countYear + 1 })
                                        this.setState({ lesscount: this.state.lesscount + 1 })
                                        this.props.NullPdf();
                                        this.props.NullArray();
                                        this.props.NullQuiz();
                                        this.props.NullLesson();
                                        this.setState({ year: "1" })
                                        this.props.SelectPriviousYear(e)
                                        // 
                                        if (this.state.countYear > 0) {
                                            this.props.UnCheckedlesson(admin.all_lesson);
                                            this.props.UnCheckedpdf(admin.all_pdf);
                                            this.props.UnCheckedquiz(admin.all_quiz);
                                            this.props.UnCheckedvideo(admin.all_video);
                                        }
                                    }}

                                />
                                <Typography style={{ color: "#000", display: "flex", textAlign: "center", alignItems: "center" }}>
                                    {e.name}
                                    {/* {this.state.isChecked ? (this.state.isChecked).toString() : 'hjk'} */}
                                </Typography>



                            </div>
                        );
                    })}
                </Grid>









                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>

                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >
                        <Button
                            style={{ border: "2px solid #6257cd", width: "11em" }}

                        >

                            {/* <IconButton>
                                <AddIcon style={{ color: '#6257CD' }} />
                            </IconButton> */}
                            Add Lesson
                        </Button>
                        {/* } */}
                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "5%", marginRight: "12%", border: "2px solid #6257cd", alignItems: "center" }}>
                        {(all?.language?.[Lang]?.chapter?.[Lid]?.lesson ?? []).filter(el => el.quantity >= 0).map((e, index) => {
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 5 }}>
                                    <Chip
                                        label={e?.lesson_name}
                                        style={{
                                            backgroundColor: this.state.Vid === index ? "#6257CD" : '',
                                            color: this.state.Vid === index ? "white" : ''
                                        }}
                                        variant={this.state.all?.language[Lang]?.chapter?.[Lid]?.lesson?.[index]?.video === undefined ? "outlined" : "filled"}
                                        onClick={() => this.setState({ Vid: index })}
                                        onDelete={() => {
                                            this.handleDelete(Lang, Lid, index);
                                            this.UnCheckedlesson(admin.all_lesson, e?.lesson_name);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                lessonmodal: true,
                            });
                        }} />
                    </Grid>



                </Box>


                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>



                    {/* {this.state.mini_pack !== "" && this.state.hint !== "" && this.state.description !== "" && this.state.less !== "" && */}
                    <Button
                        // variant="outlined" 
                        style={{ border: "2px solid #6257cd", width: "12%" }}
                    //  onClick={() => {
                    //     this.setState({
                    //         videomodal: true,
                    //     })
                    // }}
                    >

                        {/* <IconButton>
                            <AddIcon style={{ color: '#6257CD' }} />
                        </IconButton> */}
                        Add Video</Button>
                    {/* } */}
                    {/* </center> */}
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", border: "2px solid #6257cd", flexDirection: "row", marginLeft: "6%", marginRight: "12%", alignItems: "center" }}>
                        {(all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid]?.video ?? []).filter(el => el.quantity >= 0).map((e, index) => {
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", padding: 6 }}>
                                    <Chip
                                        label={e?.video_hint}
                                        variant="outlined"
                                        onDelete={() => {
                                            this.handleDeleteVideo(Lang, Lid, Vid, index);
                                            this.UnCheckedvideo(admin.all_video, e?.video_hint);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                videomodal: true,
                            });
                        }} />
                    </Grid>



                </Box>


                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "30px" }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >

                        {/* {this.state.mini_pack !== "" && this.state.hint !== "" && this.state.description !== "" && this.state.less !== "" && */}
                        <Button
                            style={{ border: "2px solid #6257cd", width: "10em" }}
                        // onClick={() => {
                        //     this.setState({
                        //         pdfmodal: true,
                        //     })
                        // }}
                        >

                            {/* <IconButton>
                                <AddIcon style={{ color: '#6257CD' }} />
                            </IconButton> */}
                            Add PDF</Button>
                        {/* } */}
                    </center>

                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "5%", marginRight: "12%", border: "2px solid #6257cd", padding: 6, alignItems: "center" }}>
                        {(all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid]?.pdf ?? []).filter(el => el.quantity >= 0).map((e, index) => {
                            return (
                                <div style={{ borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", margin: 10 }}>
                                    <Chip
                                        label={e?.pdf_hint}
                                        variant="outlined"
                                        onDelete={() => {
                                            this.handleDeletePdf(Lang, Lid, Vid, index);
                                            this.UnCheckedpdf(admin.all_pdf, e?.pdf_hint);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                pdfmodal: true,
                            });
                        }} />
                    </Grid>


                </div>


                <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-evenly", marginTop: "4%" }}>
                    <center style={{ height: "44px", color: "#6257CD", textAlign: 'center', textTransform: "capitalize", backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row" }}
                    >


                        {/* {this.state.mini_pack !== "" && this.state.hint !== "" && this.state.description !== "" && this.state.less !== "" && */}
                        <Button
                            style={{ border: "2px solid #6257cd", width: "10em" }}
                        // onClick={() => {
                        //     this.setState({
                        //         quizmodal: true,
                        //     })
                        // }}
                        >

                            {/* <IconButton>
                                <AddIcon style={{ color: '#6257CD' }} />
                            </IconButton> */}
                            Add QUIZ</Button>

                    </center>
                    <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "5%", marginRight: "12%", border: "2px solid #6257cd", alignItems: "center" }}>
                        {(all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid]?.quizz ?? []).filter(el => el.quantity >= 0).map((e, index) => {
                            return (
                                <div style={{ padding: 6, borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px" }}>
                                    <Chip
                                        label={e?.quiz_desc}
                                        variant="outlined"
                                        onDelete={() => {
                                            this.handleDeleteQuiz(Lang, Lid, Vid, index);
                                            this.UnCheckedquizz(admin.all_quiz, e?.quiz_desc);
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <AddIcon onClick={() => {
                            this.setState({
                                quizmodal: true,
                            });
                        }} />
                    </Grid>



                </Box>


                <Dialog

                    open={
                        this.state.languagemodal
                    }
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ languagemodal: false })}
                // fullScreen={"100px"}

                // fullWidth={true}
                >
                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#EEECFE",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Chapter  "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchlanguage(admin.all_language, e)}
                                />
                            </Search>
                        </Grid>

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.filter_language) && admin.filter_language?.map((e, index) => {
                                return (
                                    <div key={index} style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.language}</Typography>
                                        <Checkbox
                                            value={e.language}
                                            checked={all?.language?.some(item2 => e?._id === item2?._id)}
                                            onClick={(event) => {
                                                const currentLesson = { ...all };

                                                if (!currentLesson.hasOwnProperty('language')) {
                                                    currentLesson.language = [];
                                                }

                                                const videoAlreadyExists = currentLesson.language && currentLesson.language.some(video => video._id === e._id);

                                                if (!videoAlreadyExists) {
                                                    currentLesson.language = [...(currentLesson.language || []), e];

                                                    // Update the checked state
                                                    this.setState((prevState) => ({
                                                        all: currentLesson,
                                                        languageChecked: [...prevState.languageChecked, e.language],
                                                    }));
                                                } else {
                                                    // Uncheck the item
                                                    currentLesson.language = currentLesson.language?.filter(video => video._id !== e._id);
                                                    this.setState((prevState) => ({
                                                        all: currentLesson,
                                                        languageChecked: prevState.languageChecked?.filter(desc => desc !== e.language),
                                                    }));
                                                }
                                            }}
                                        />

                                    </div>
                                );
                            })}
                        </Grid>



                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ languagemodal: false });
                                    // this.props.UnCheckedchapter(admin.all_chapter);
                                    this.props.viewAllLanguage();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                onClick={() => {
                                    // this.setState({ chap: "1" })
                                    // this.props.setChapter(all.chapter);
                                    this.setState({ languagemodal: false });
                                    this.props.viewAllLanguage();
                                }}
                                // style={{ marginLeft: "450px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Submit</Button>
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>

                        </center>
                    </div>

                </Dialog>

                <Dialog

                    open={
                        this.state.chaptermodal
                    }
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ chaptermodal: false })
                        this.props.viewAllChapter()
                    }}
                // fullScreen={"100px"}

                // fullWidth={true}
                >
                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#EEECFE",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Chapter  "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchchapter(admin.all_chapter, e)}
                                />
                            </Search>
                        </Grid>

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.chapter_filtered) && admin.chapter_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.chapter_name}</Typography>

                                        <Checkbox
                                            value={e.chapter_name}
                                            //    onChange={(e) => { this.props.setChapter(value)
                                            // this.setState({ chapter_array: e.target.value }) 
                                            // }}

                                            // onClick={(p) => {
                                            //     // all.chapter.push(e)
                                            //     // this.chapterCheck(p, index, admin.all_chapter)
                                            //     if (!all.chapter.some(chapter => chapter._id === e._id)) {

                                            //         this.setState(() => all.chapter.push(e))

                                            //     } else {
                                            //         // Handle duplicate value (e.g., display an error, log a message)
                                            //         
                                            //     }
                                            // }}

                                            // checked={this.state.chapterChecked.includes(e.chapter_name)}
                                            checked={all?.language?.[Lang]?.chapter?.some(item2 => e?._id === item2?._id)}
                                            onClick={(p) => {
                                                // this.props.viewAllChapter();
                                                const currentLesson = all?.language?.[Lang];

                                                if (!currentLesson.hasOwnProperty('chapter')) {
                                                    currentLesson.chapter = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.chapter && currentLesson.chapter.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.chapter = [...(currentLesson.chapter || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            chapterChecked: [...prevState.chapterChecked, e.chapter_name],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedchapter(admin.all_chapter, e.chapter_name);
                                                        currentLesson.chapter = currentLesson.chapter?.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            chapterChecked: prevState.chapterChecked?.filter(desc => desc !== e.chapter_name),
                                                        }));
                                                    }
                                                }
                                            }}
                                        />

                                    </div>
                                );
                            })}
                        </Grid>


                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ chaptermodal: false });
                                    // this.props.UnCheckedchapter(admin.all_chapter);
                                    this.props.viewAllChapter();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                onClick={() => {
                                    // this.setState({ chap: "1" })
                                    this.props.setChapter(all.chapter);
                                    this.setState({ chaptermodal: false });
                                    this.props.viewAllChapter();
                                }}
                                // style={{ marginLeft: "450px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Submit</Button>
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>

                        </center>
                    </div>

                </Dialog>



                <Dialog

                    open={this.state.priviousYear}
                    style={{ height: "100%" }}
                    onClose={() => this.setState({ priviousYear: false })}
                // fullScreen={"100px"}

                // fullWidth={true}
                >
                    {/* <div style={{overflowX:"hidden",background:"#F3F3F3",position:"relative",width:"1020px"}}> */}
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#EEECFE",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Privious Year  "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchPriviousYear(admin.all_privous_year, e)}
                                />
                            </Search>
                        </Grid>

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.privous_year_filtered) && admin.privous_year_filtered.map((e, index) => {

                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.name}</Typography>

                                        <Checkbox
                                            value={e.name}
                                            //    onChange={(e) => { this.props.setChapter(value)
                                            // this.setState({ chapter_array: e.target.value }) 
                                            // }}
                                            onClick={(p) => { this.priviousYearCheck(p, index, admin.all_previous_year) }}

                                        />



                                    </div>
                                );
                            })}
                        </Grid>


                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ priviousYear: false });
                                    this.props.UnCheckedPriviousYear(admin.all_previous_year);
                                    // 
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                onClick={() => {
                                    // this.setState({ chap: "1" })
                                    this.props.setPriviousYear(this.state.userinformation);
                                    this.setState({ priviousYear: false });
                                }}
                                // style={{ marginLeft: "450px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Submit</Button>
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>

                        </center>
                    </div>

                </Dialog>






                <Dialog

                    open={this.state.lessonmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ lessonmodal: false })
                        this.props.viewAllLesson()
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    // marginBottom:"200px",
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Lesson  "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchlesson(admin.all_lesson, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}


                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.lesson_filtered) && admin.lesson_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>

                                        <Typography style={{ color: "#000", margin: "10px", textAlign: "center" }}>{e.lesson_name}</Typography>

                                        <Checkbox
                                            value={e.lesson_name}
                                            checked={all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.some(item2 => e?._id === item2?._id)}

                                            onClick={(p) => {
                                                // this.props.viewAllLesson();
                                                const currentLesson = all?.language?.[Lang]?.chapter?.[Lid]

                                                if (!currentLesson.hasOwnProperty('lesson')) {
                                                    currentLesson.lesson = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.lesson && currentLesson.lesson.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.lesson = [...(currentLesson.lesson || []), e];

                                                        // Update the checked state
                                                        this.setState((prevState) => ({
                                                            lessonChecked: [...prevState.lessonChecked, e.lesson_name],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedlesson(admin.all_lesson, e.lesson_name);
                                                        currentLesson.lesson = currentLesson.lesson.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            lessonChecked: prevState.lessonChecked.filter(desc => desc !== e.lesson_name),
                                                        }));
                                                    }
                                                }
                                            }}

                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ lessonmodal: false });
                                    // this.props.UnCheckedlesson(admin.all_lesson);
                                    this.props.viewAllLesson();

                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    // this.setState({ less: "1" })

                                    this.setState({ lessonmodal: false });
                                    this.props.viewAllLesson();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                <Dialog

                    open={this.state.videomodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ videomodal: false })
                        this.props.viewAllVideo()
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Video  "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchvideo(admin.all_video, e)}
                                />
                            </Search>
                        </Grid>




                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.video_filtered) && admin.video_filtered.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <IconButton

                                            style={{ marginLeft: "-0px" }}
                                        >
                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                            <img src={Vedio} height={24} width={24} />
                                        </IconButton>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.video_hint}</Typography>

                                        <Checkbox
                                            value={e.video_hint}
                                            checked={all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid]?.video?.some(item2 => e?._id === item2?._id)}
                                            // onClick={(p) => {
                                            //     const currentLesson = all.chapter[Lid]?.lesson[Vid];

                                            //     if (currentLesson) {
                                            //         const videoAlreadyExists = currentLesson.video && currentLesson.video.some(video => video._id === e._id);

                                            //         if (!videoAlreadyExists) {
                                            //             currentLesson.video = [...(currentLesson.video || []), e];
                                            //         }
                                            //     }
                                            // }}
                                            onClick={(p) => {
                                                // this.props.viewAllVideo();
                                                const currentLesson = all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid];

                                                if (!currentLesson.hasOwnProperty('video')) {
                                                    currentLesson.video = [];
                                                    // this.setState(() => all.chapter.push(e))
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.video && currentLesson.video.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.video = [...(currentLesson.video || []), e];
                                                        this.setState((prevState) => ({
                                                            videoChecked: [...prevState.videoChecked, e.video_hint],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedvideo(admin.all_video, e.video_hint);
                                                        currentLesson.video = currentLesson.video?.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            videoChecked: prevState.videoChecked.filter(desc => desc !== e.video_hint),
                                                        }));
                                                    }
                                                }
                                            }}

                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ videomodal: false });
                                    // this.props.UnCheckedvideo(admin.all_video);
                                    this.props.viewAllVideo();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    this.setState({ vid: "1" })
                                    this.setState({ videomodal: false });
                                    this.props.viewAllVideo();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>

                        </center>
                    </div>
                </Dialog>

                <Dialog

                    open={this.state.pdfmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ pdfmodal: false, searchTerm: '' })
                        this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "")
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid style={{ display: "flex", flexdirection: "row", width: "100%", marginTop: 20 }}>

                            <div>
                                <Paper
                                    component="form"
                                    style={{
                                        marginTop: "5%",
                                        display: "flex",
                                        width: "350px",
                                        height: "42px",
                                        marginLeft: "4%",
                                        boxShadow: "none",
                                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                    }}
                                >
                                    <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
                                        <SearchIcon style={{ fontSize: "25px" }} />
                                    </IconButton>
                                    <InputBase
                                        className="search-text"
                                        placeholder="Search"
                                        inputProps={{ "aria-label": "Search" }}
                                        value={this.state.searchTerm}
                                        onChange={this.handleSearchChange}
                                    />
                                </Paper>
                            </div>
                            {/* <div
                                onChange={(e) => {
                                    this.props.searchLanguage(admin.all_pdf, e.target.value);
                                    // 
                                }}
                            >


                                <select className="dfilter" style={{ width: "100px", height: 32, marginLeft: "50px" }}>
                                    <option value="">Filter</option>
                                    <option value="Hindi">Hindi</option>
                                    <option value="English">English</option>
                                </select>
                            </div> */}
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            <div style={{ marginTop: "40px" }}>
                                <Pagination
                                    count={parseInt(admin.pdf_length / this.state.rowsPerPage) + 1}
                                    siblingCount={0}
                                    size="small"
                                    onChange={handleChangePage}
                                />
                            </div>
                            {Array.isArray(admin.all_pdf) && admin.all_pdf?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <IconButton

                                            style={{ marginLeft: "-0px" }}
                                        >
                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                            <img src={Pdf} height={24} width={24} />
                                        </IconButton>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.pdf_hint}</Typography>

                                        <Checkbox
                                            value={e.pdf_hint}
                                            checked={all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid]?.pdf?.some(item2 => e?._id === item2?._id)}
                                            // onClick={(p) => {
                                            //     // this.Checkedpdf(p, index, admin.all_pdf)
                                            //     const currentLesson = all.chapter[Lid]?.lesson[Vid];

                                            //     if (currentLesson) {
                                            //         const videoAlreadyExists = currentLesson.pdf && currentLesson.pdf.some(pdf => pdf._id === e._id);

                                            //         if (!videoAlreadyExists) {
                                            //             currentLesson.pdf = [...(currentLesson.pdf || []), e];
                                            //         }
                                            //     }
                                            // }}

                                            onClick={(p) => {
                                                // this.props.viewAllPdf();
                                                const currentLesson = all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid];

                                                if (!currentLesson.hasOwnProperty('pdf')) {
                                                    currentLesson.pdf = [];
                                                    // this.setState(() => all.chapter.push(e))
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.pdf && currentLesson.pdf.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        currentLesson.pdf = [...(currentLesson.pdf || []), e];
                                                        this.setState((prevState) => ({
                                                            pdfChecked: [...prevState.pdfChecked, e.pdf_hint],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        // this.UnCheckedpdf(admin.all_pdf, e.pdf_hint);
                                                        currentLesson.pdf = currentLesson.pdf.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            pdfChecked: prevState.pdfChecked.filter(desc => desc !== e.pdf_hint),
                                                        }));
                                                    }
                                                }
                                            }}

                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ pdfmodal: false, searchTerm: '' });
                                    // this.props.UnCheckedpdf(admin.all_pdf);
                                    this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    // this.setState({ vid:"1" }) 
                                    this.setState({ pdfmodal: false, searchTerm: '' });
                                    this.props.viewAllPdf(this.state.page, this.state.rowsPerPage, "");
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                <Dialog

                    open={this.state.quizmodal}
                    style={{ height: "100%" }}
                    onClose={() => {
                        this.setState({ quizmodal: false })
                        this.props.viewAllquiz()
                    }}>
                    <div style={{ backgroundColor: "#F3F3F3", height: "100%", minWidth: "600px", overflowX: "hidden" }}>


                        <Grid lg={4}>
                            <Search
                                style={{
                                    backgroundColor: "#DCE4E5",
                                    height: 32,

                                    marginLeft: "32px",
                                    marginTop: 10,
                                    width: "90%",
                                    border: "2px solid #000000"
                                }}
                                direction="row"
                            >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search quiz  "
                                    color="black"
                                    inputProps={{ "aria-label": "search" }}
                                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                                    onChange={(e) => this.props.searchquiz(admin.all_quiz, e)}
                                />
                            </Search>
                        </Grid>

                        {/* </Grid> */}

                        <Grid container md={12} xl={12} lg={12} spacing={2} style={{ display: "flex", flexDirection: "row", marginLeft: "14px" }}>
                            {Array.isArray(admin.quiz_filtered) && admin.quiz_filtered?.map((e, index) => {
                                return (
                                    <div style={{ marginLeft: "20px", marginTop: "50px", borderColor: "black", display: "flex", flexDirection: "row", borderWidth: "10px", border: '2px solid #888' }}>
                                        <IconButton

                                            style={{ marginLeft: "-0px" }}
                                        >
                                            {/* <EditIcon style={{ color: 'green' }} /> */}
                                            <img src={Quiz} height={24} width={24} />
                                        </IconButton>
                                        <Typography style={{ color: "#000", margin: "10px" }}>{e.quiz_desc}</Typography>

                                        <Checkbox
                                            // {...label}
                                            // defaultChecked
                                            // sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                            value={e.quiz_desc}
                                            checked={all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid]?.quizz?.some(item2 => e?._id === item2?._id)}
                                            // onClick={(p) => {
                                            //     // this.Checkedquiz(p, index, admin.all_quiz)
                                            //     const currentLesson = all.chapter[Lid]?.lesson[Vid];

                                            //     if (currentLesson) {
                                            //         const videoAlreadyExists = currentLesson.quiz && currentLesson.quiz.some(quiz => quiz._id === e._id);
                                            //         if (!videoAlreadyExists) {
                                            //             currentLesson.quiz = [...(currentLesson.quiz || []), e];
                                            //         }
                                            //     }
                                            // }}
                                            onClick={() => {
                                                // this.props.viewAllquiz();
                                                const currentLesson = all?.language?.[Lang]?.chapter?.[Lid]?.lesson?.[Vid];
                                                const quizDesc = e.quiz_desc;

                                                if (!currentLesson.hasOwnProperty('quizz')) {
                                                    currentLesson.quizz = [];
                                                }

                                                if (currentLesson) {
                                                    const videoAlreadyExists = currentLesson.quizz && currentLesson.quizz.some(video => video._id === e._id);

                                                    if (!videoAlreadyExists) {
                                                        // Check the item
                                                        currentLesson.quizz = [...(currentLesson.quizz || []), e];
                                                        this.setState((prevState) => ({
                                                            quizzChecked: [...prevState.quizzChecked, quizDesc],
                                                        }));
                                                    } else {
                                                        // Uncheck the item
                                                        currentLesson.quizz = currentLesson.quizz.filter(video => video._id !== e._id);
                                                        this.setState((prevState) => ({
                                                            quizzChecked: prevState.quizzChecked.filter(desc => desc !== quizDesc),
                                                        }));
                                                    }
                                                }
                                            }}

                                        />


                                    </div>
                                );
                            })}
                        </Grid>
                        <center>
                            <Button
                                // className='btns'
                                variant="outlined"
                                onClick={() => {
                                    this.setState({ quizmodal: false });
                                    this.props.viewAllquiz();
                                    // this.props.UnCheckedquiz(admin.all_quiz);
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "black", width: "20px" }}>Close</Button>

                            <Button
                                className='btns'

                                // disabled={this.state.lesson_name === ""}
                                onClick={() => {
                                    // this.props.createLesson(this.state.lesson_name);
                                    // this.setState({ add: false });
                                    this.setState({ quizmodal: false });
                                    this.props.viewAllquiz();
                                }}
                                style={{ marginLeft: "30px", marginBottom: "30px", marginTop: "10px", color: "#fff", width: "20px" }}>Save</Button>
                        </center>
                    </div>

                </Dialog>
                <center>
                    {/* {(this.state.qui !== "") && */}
                    <Link to="/minipackage">
                        <Button
                            className='btns'
                            onClick={() => {
                                this.props.updateMiniPackage(this.props.admin.minipackage_id._id, this.state.mini_pack, this.state.minipackage_heading, this.state.hint, this.state.description, this.state.all, this.state.language_id)
                                this.setState({ mini_pack: "", hint: "", description: "", all: "", minipackage_heading: "" })
                            }}
                            style={{ marginBottom: "30px", marginTop: "10px", color: "white", width: "20px" }}

                        >SUBMIT</Button></Link>
                    {/* } */}
                </center>
                <Loader />
            </Grid>
        )
    }
}