import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../component/dashboard";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    // createExam,
    // updateExam,
    // deleteStudent,
    view_all_student
} from "../action";
// import { Dashboard } from "@material-ui/icons";
export class Controller extends Component {
  render() {
    return (
      <Dashboard {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin

  };
};
export const mapDispatchToProps = dispatch => {
  return {

    view_all_student: () => {
      dispatch(view_all_student())
    },

    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);